import styled from "styled-components";

export const RescheduleDepartureContent = styled.div`
      background-color: #f4f4f4;
      border: 1px solid #eaeaea;
      padding: 20px;
      border-radius: 5px;

      .form-label {
            font-weight: 510;
      }
`;

export const RescheduleDepartureList = styled.div`
      display: flex;
      align-items: center;
      gap: 20px;
`;

export const RescheduleDepartureItem = styled.div`
      padding: 5px 20px;
      min-width: 220px;
      text-align: center;
      background-color: #fff;
      border: 1px solid #eaeaea;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &.active,
      &:hover {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
      }
`;
