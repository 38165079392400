import React, { useState } from "react";
import {
      RoomAccount,
      RoomDescription,
      RoomDescriptionItem,
      RoomDetailItem,
      RoomImage,
      RoomInfo,
      RoomItem,
      RoomOptionButton,
      RoomPrice,
      RoomTitle,
} from "./style";
import { convertDate } from "../../hotel_confirmations/HotelTicketLayout";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import PolicyModalView from "../../../components/Frontend/hotel/HotelFareSummary/PolicyModalView";

const OtherRoomOptions = ({
      hotel,
      selectedRoom,
      setCurrentRoom,
      setSelectedRoom,
      currentRoom,
      handleTotalPrice,
}) => {
      const [optionId, setOptionId] = useState("");

      const [show, setShow] = useState(false);
      const handleShow = (id) => {
            setOptionId(id);
            setShow(true);
      };
      const handleClose = () => setShow(false);

      let currentItem;
      let options;

      const handleOptions = () => {
            if (selectedRoom) {
                  if (selectedRoom[0]?.selectedOption) {
                        currentItem = selectedRoom[0]?.selectedOption;
                        if (selectedRoom[0]?.selectedOption?.ris) {
                              options = selectedRoom[0]?.selectedOption?.ris;
                              return selectedRoom[0]?.selectedOption?.ris[
                                    currentRoom - 1
                              ];
                        }
                  }
            }
      };
      const room = handleOptions();

      const activeRoom = () => {
            if (selectedRoom) {
                  if (selectedRoom[currentRoom - 1]?.selectedOption) {
                        return selectedRoom[currentRoom - 1]?.selectedOption;
                  }
            }
      };

      const checkOption = (prices, id) => {
            const item = prices?.find((item) => item?.id === id);
            if (item) {
                  return true;
            } else {
                  return false;
            }
      };

      const handleOtherRoom = (value) => {
            if (currentRoom - 1 !== options?.length) {
                  setSelectedRoom((prevSelectedRooms) => {
                        // Create a shallow copy of the state
                        const updatedRooms = [...prevSelectedRooms];

                        if (updatedRooms.length > 0) {
                              // Update the selectedOption for the first room
                              updatedRooms[currentRoom - 1] = {
                                    ...updatedRooms[currentRoom - 1],
                                    selectedOption: value,
                              };
                        }

                        return updatedRooms;
                  });
                  if (currentRoom - 1 !== options?.length - 1) {
                        setCurrentRoom(currentRoom + 1);
                  }
            }
      };

      return (
            <>
                  <RoomItem>
                        <div className="d-flex">
                              <RoomInfo>
                                    <div className="text-center">
                                          {room?.srn}
                                    </div>
                                    <RoomImage>
                                          <img
                                                src={
                                                      room?.imgs
                                                            ? room?.imgs[0]?.url
                                                            : "/images/no-image.webp" ||
                                                              "/images/no-image.webp"
                                                }
                                                alt="Hotel"
                                                onError={(e) => {
                                                      e.target.onerror = null; // Prevent infinite loop
                                                      e.target.src =
                                                            "/images/no-image.webp"; // Fallback image
                                                }}
                                          />
                                    </RoomImage>
                                    <div>
                                          <RoomTitle>Room Info</RoomTitle>
                                    </div>
                              </RoomInfo>
                              <RoomDescription>
                                    <RoomDescriptionItem>
                                          <RoomDetailItem>
                                                <div className="room__detail--title">
                                                      {room?.rc}
                                                </div>
                                                <div
                                                      className="room__detail--subtitle clickable"
                                                      onClick={() =>
                                                            handleShow(
                                                                  currentItem?.id
                                                            )
                                                      }
                                                >
                                                      Booking Policy
                                                </div>
                                          </RoomDetailItem>
                                          <RoomDetailItem width="30%">
                                                <div className="room__detail--title">
                                                      {room?.mb}
                                                </div>
                                                <div
                                                      className={`room__detail--cancellation ${
                                                            !currentItem?.cnp
                                                                  ?.ifra
                                                                  ? "text-muted"
                                                                  : ""
                                                      }`}
                                                >
                                                      {currentItem?.cnp?.ifra
                                                            ? "Free Cancellation"
                                                            : "Non Refundable"}
                                                </div>
                                                {currentItem?.ddt && (
                                                      <div className="room__detail--deadline">
                                                            till{" "}
                                                            {convertDate(
                                                                  currentItem?.ddt
                                                            )}
                                                      </div>
                                                )}
                                          </RoomDetailItem>
                                          <RoomAccount className={`pt-0 pb-0`}>
                                                <RoomPrice>
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  handleTotalPrice(
                                                                        options
                                                                  )
                                                            )
                                                      )}
                                                </RoomPrice>
                                                <RoomOptionButton
                                                      onClick={() =>
                                                            handleOtherRoom(
                                                                  room
                                                            )
                                                      }
                                                      className={`py-1 ${
                                                            checkOption(
                                                                  options,
                                                                  activeRoom()
                                                                        ?.id
                                                            )
                                                                  ? "active"
                                                                  : ""
                                                      }`}
                                                >
                                                      {checkOption(
                                                            options,
                                                            activeRoom()?.id
                                                      )
                                                            ? "Selected"
                                                            : "Select"}
                                                </RoomOptionButton>
                                          </RoomAccount>
                                    </RoomDescriptionItem>
                              </RoomDescription>
                        </div>
                  </RoomItem>
                  {show && (
                        <PolicyModalView
                              hotelId={hotel?.id}
                              optionId={optionId}
                              handleClose={handleClose}
                              show={show}
                        />
                  )}
            </>
      );
};

export default OtherRoomOptions;
