import React from "react";
import FlightDetailBox from "../../checkout/FlightDetailBox";
import TravellersDetailBox from "../../checkout/TravellersDetailBox/index";
import FlightReviewDetail from "../FlightReviewDetail";

const FlightReviewOptions = ({
      addedAmount,
      options,
      currentOption,
      setOptions,
      setCurrentOption,
      tripInfos,
      optionId,
      reviewId,
      searchQuery,
      bookingId,
      conditions,
      serviceList,
      setServiceList,
      setDestinationDetails,
      destinationDetails,
      totalAmount,
      setTotalAmount,
      totalNetAmount,
      setTotalNetAmount,
      seriesFareStatus,
}) => {
      return (
            <>
                  {optionId === "travellers" && (
                        <>
                              <FlightDetailBox
                                    tripInfos={tripInfos}
                                    marginBottom={"20px"}
                              />
                              <TravellersDetailBox
                                    bookingId={bookingId}
                                    searchQuery={searchQuery}
                                    marginBottom={"30px"}
                                    reviewId={reviewId}
                                    options={options}
                                    tripInfos={tripInfos}
                                    setOptions={setOptions}
                                    currentOption={currentOption}
                                    setCurrentOption={setCurrentOption}
                                    conditions={conditions}
                                    serviceList={serviceList}
                                    setServiceList={setServiceList}
                                    destinationDetails={destinationDetails}
                                    setDestinationDetails={
                                          setDestinationDetails
                                    }
                                    totalAmount={totalAmount}
                                    setTotalAmount={setTotalAmount}
                                    totalNetAmount={totalNetAmount}
                                    setTotalNetAmount={setTotalNetAmount}
                              />
                        </>
                  )}
                  {optionId === "review" && (
                        <FlightReviewDetail
                              addedAmount={addedAmount}
                              options={options}
                              setOptions={setOptions}
                              currentOption={currentOption}
                              setCurrentOption={setCurrentOption}
                              reviewId={reviewId}
                              tripInfos={tripInfos}
                              bookingId={bookingId}
                              totalAmount={totalAmount}
                              searchQuery={searchQuery}
                              totalNetAmount={totalNetAmount}
                              seriesFareStatus={seriesFareStatus}
                        />
                  )}
            </>
      );
};

export default FlightReviewOptions;
