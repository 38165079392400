export const handleHotelCommission = ({
      prices,
      commissions,
      domesticStatus,
      hotelRating,
}) => {
      let totalPrice = 0;

      const findCommission = commissions?.find(
            (item) => item?.default === true
      );

      prices?.map((item) => {
            if (findCommission) {
                  if (domesticStatus) {
                        const commission = findCommission?.fareType?.domestic;
                        if (hotelRating === 1) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.oneStar);
                        }

                        if (hotelRating === 2) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.twoStar);
                        }
                        if (hotelRating === 3) {
                              totalPrice +=
                                    item?.tp +
                                    parseFloat(commission?.threeStar);
                        }
                        if (hotelRating === 4) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.fourStar);
                        }
                        if (hotelRating === 5) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.fiveStar);
                        }
                  } else {
                        const commission =
                              findCommission?.fareType?.international;
                        if (hotelRating === 1) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.oneStar);
                        }
                        if (hotelRating === 2) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.twoStar);
                        }
                        if (hotelRating === 3) {
                              totalPrice +=
                                    item?.tp +
                                    parseFloat(commission?.threeStar);
                        }
                        if (hotelRating === 4) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.fourStar);
                        }
                        if (hotelRating === 5) {
                              totalPrice +=
                                    item?.tp + parseFloat(commission?.fiveStar);
                        }
                  }
            } else {
                  totalPrice += item?.tp;
            }
      });

      return totalPrice;
};

export const handleHotelCommissionOnly = ({
      commissions,
      domesticStatus,
      hotelRating,
}) => {
      let commissionAmount = 0;

      const findCommission = commissions?.find(
            (item) => item?.default === true
      );

      if (findCommission) {
            if (domesticStatus) {
                  const commission = findCommission?.fareType?.domestic;
                  if (hotelRating === 1) {
                        commissionAmount = parseFloat(commission?.oneStar);
                  }

                  if (hotelRating === 2) {
                        commissionAmount = parseFloat(commission?.twoStar);
                  }
                  if (hotelRating === 3) {
                        commissionAmount = parseFloat(commission?.threeStar);
                  }
                  if (hotelRating === 4) {
                        commissionAmount = parseFloat(commission?.fourStar);
                  }
                  if (hotelRating === 5) {
                        commissionAmount = parseFloat(commission?.fiveStar);
                  }
            } else {
                  const commission = findCommission?.fareType?.international;
                  if (hotelRating === 1) {
                        commissionAmount = parseFloat(commission?.oneStar);
                  }
                  if (hotelRating === 2) {
                        commissionAmount = parseFloat(commission?.twoStar);
                  }
                  if (hotelRating === 3) {
                        commissionAmount = parseFloat(commission?.threeStar);
                  }
                  if (hotelRating === 4) {
                        commissionAmount = parseFloat(commission?.fourStar);
                  }
                  if (hotelRating === 5) {
                        commissionAmount = parseFloat(commission?.fiveStar);
                  }
            }
      }

      return commissionAmount;
};

export const handleHotelCommissionTotal = ({ prices, commissionsAmount }) => {
      let totalPrice = 0;

      prices?.map((item) => {
            if (commissionsAmount) {
                  totalPrice += item?.tp + parseFloat(commissionsAmount);
            } else {
                  totalPrice += item?.tp;
            }
      });

      return totalPrice;
};
