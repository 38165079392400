import React from "react";
import {
      FooterBlock,
      FooterContainer,
      FooterContent,
      FooterCopyRight,
      FooterCopyRightText,
      FooterList,
      FooterLogo,
      FooterSocial,
      FooterText,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import SocialMediaItem from "../../components/Common/SocialMediaItem";
import { socialmedias } from "../../data/socialmedias";
import FooterButton from "../../components/layout/footer/FooterButton";
import FooterItem from "../../components/layout/footer/FooterItem";

const Footer = () => {
      return (
            <>
                  <FooterContainer>
                        <FooterContent>
                              <Row>
                                    <Col lg={5}>
                                          <FooterBlock>
                                                <FooterLogo>
                                                      <img
                                                            src="/images/logo/logo.png"
                                                            alt="logo"
                                                            width="100%"
                                                            height="100%"
                                                      />
                                                </FooterLogo>
                                                <FooterText>
                                                      Follow us on social media
                                                </FooterText>
                                                <FooterSocial>
                                                      {socialmedias.map(
                                                            (item, index) => (
                                                                  <SocialMediaItem
                                                                        data={
                                                                              item
                                                                        }
                                                                        key={
                                                                              index
                                                                        }
                                                                  />
                                                            )
                                                      )}
                                                </FooterSocial>
                                          </FooterBlock>
                                    </Col>
                                    <Col lg={2}>
                                          <FooterBlock>
                                                <FooterButton
                                                      title={"Email Support"}
                                                />
                                                <FooterList>
                                                      <FooterItem
                                                            link="mailto:support@aircasa.in"
                                                            titles="support@aircasa.in"
                                                      />
                                                      <FooterItem
                                                            link="mailto:flight@aircasa.in"
                                                            titles="flight@aircasa.in"
                                                      />
                                                      <FooterItem
                                                            link="mailto:hotel@aircasa.in"
                                                            titles="hotel@aircasa.in"
                                                      />
                                                      <FooterItem
                                                            link="mailto:package@aircasa.in"
                                                            titles="package@aircasa.in"
                                                      />
                                                </FooterList>
                                          </FooterBlock>
                                    </Col>
                                    <Col lg={2}>
                                          <FooterBlock>
                                                <FooterButton title={"Links"} />
                                                <FooterList>
                                                      <FooterItem
                                                            link="/privacy-policy"
                                                            titles="Privacy Policy"
                                                      />
                                                      <FooterItem
                                                            link="/terms-and-conditions"
                                                            titles="Terms & Conditions"
                                                      />
                                                      <FooterItem
                                                            link="/refund-policy"
                                                            titles="Refund Policy"
                                                      />
                                                      <FooterItem
                                                            link="/baggage-policy"
                                                            titles="Baggage Policy"
                                                      />
                                                </FooterList>
                                          </FooterBlock>
                                    </Col>
                                    <Col lg={3}>
                                          <FooterBlock>
                                                <FooterButton
                                                      title={"Contact"}
                                                />
                                                <FooterList>
                                                      <FooterItem
                                                            titles={[
                                                                  "+91 8766601888",
                                                                  "+91 7737025370",
                                                            ]}
                                                            image={
                                                                  "/images/svg/phone.svg"
                                                            }
                                                      />
                                                      <FooterItem
                                                            titles="Info@Aircasa.In"
                                                            image={
                                                                  "/images/svg/email.svg"
                                                            }
                                                      />
                                                      <FooterItem
                                                            titles={
                                                                  <>
                                                                        357,
                                                                        Agrasen
                                                                        Path,
                                                                        Officers
                                                                        Campus{" "}
                                                                        <br />{" "}
                                                                        Main,
                                                                        Sirsi
                                                                        Road,
                                                                        Jaipur-302012
                                                                  </>
                                                            }
                                                            image={
                                                                  "/images/svg/location.svg"
                                                            }
                                                      />
                                                </FooterList>
                                          </FooterBlock>
                                    </Col>
                              </Row>
                        </FooterContent>
                        <FooterCopyRight>
                              <FooterCopyRightText className="mb-0 d-flex align-items-center justify-content-center gap-2">
                                    <i className="bx bx-copyright"></i>
                                    2025 All rights reserved by Aircasa
                              </FooterCopyRightText>
                        </FooterCopyRight>
                  </FooterContainer>
            </>
      );
};

export default Footer;
