import React, { useState } from "react";
import {
      MoreRoomOption,
      RoomAccount,
      RoomDescriptionItem,
      RoomDetailItem,
      RoomOptionButton,
      RoomPrice,
} from "../HotelRoom/style";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import { convertDate } from "../../booking/OnewayTicket";
import PolicyModalView from "../../../components/Frontend/hotel/HotelFareSummary/PolicyModalView";
import { CgFileDocument } from "react-icons/cg";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CheckTooltip from "../../../components/form/CheckTooltip";

const RoomOptionList = ({
      items,
      limit,
      setLimit,
      hotel,
      handleTotalPrice,
      handleSelectFirstRoom,
      activeRoom,
}) => {
      const [loading, setLoading] = useState(false);
      const [optionId, setOptionId] = useState("");

      const [show, setShow] = useState(false);
      const handleShow = (id) => {
            setOptionId(id);
            setShow(true);
      };
      const handleClose = () => setShow(false);

      const handleMore = async () => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
                  if (items?.length === limit) {
                        setLimit(2);
                  } else {
                        setLimit(items?.length);
                  }
            }, 500);
      };

      const checkOption = (prices, id) => {
            if (prices?.id === id) {
                  return true;
            } else {
                  return false;
            }
      };

      return (
            <>
                  {items?.slice(0, limit)?.map((option, index) => (
                        <RoomDescriptionItem
                              className={limit - 1 == index ? "border-0" : ""}
                              key={index}
                        >
                              <RoomDetailItem>
                                    <div className="room__detail--title">
                                          {option?.ris[0]?.rc}
                                    </div>
                                    <div
                                          className="room__detail--subtitle clickable"
                                          onClick={() => handleShow(option?.id)}
                                    >
                                          Booking Policy
                                    </div>
                              </RoomDetailItem>
                              <RoomDetailItem width="30%">
                                    <div className="room__detail--title">
                                          {option?.ris[0]?.mb}
                                    </div>
                                    <div
                                          className={`room__detail--cancellation ${
                                                !option?.cnp?.ifra
                                                      ? "text-muted"
                                                      : ""
                                          }`}
                                    >
                                          {option?.cnp?.ifra
                                                ? "Free Cancellation"
                                                : "Non Refundable"}
                                    </div>
                                    {option?.cnp?.ifra && (
                                          <div className="room__detail--deadline">
                                                till {convertDate(option?.ddt)}
                                          </div>
                                    )}
                                    <div
                                          className={`room__detail--cancellation room__detail-pan ${
                                                !option?.ipr ? "text-muted" : ""
                                          }`}
                                    >
                                          {option?.ipr && "PAN card required"}
                                    </div>
                              </RoomDetailItem>
                              <RoomAccount className={`pt-0 pb-0`}>
                                    <RoomPrice>
                                          ₹{" "}
                                          {convertAmount(
                                                convertFloat(
                                                      handleTotalPrice(
                                                            option?.ris
                                                      )
                                                )
                                          )}
                                    </RoomPrice>
                                    <RoomOptionButton
                                          onClick={() =>
                                                handleSelectFirstRoom(option)
                                          }
                                          className={`py-1 ${
                                                checkOption(
                                                      option,
                                                      activeRoom()?.id
                                                )
                                                      ? "active"
                                                      : ""
                                          }`}
                                    >
                                          {checkOption(option, activeRoom()?.id)
                                                ? "Selected"
                                                : "Select"}
                                    </RoomOptionButton>
                              </RoomAccount>
                        </RoomDescriptionItem>
                  ))}
                  {items?.length > 2 && (
                        <MoreRoomOption onClick={handleMore}>
                              Show {limit === items?.length ? "Less" : "More"}{" "}
                              {!loading ? (
                                    limit === items?.length ? (
                                          <IoIosArrowUp />
                                    ) : (
                                          <IoIosArrowDown />
                                    )
                              ) : (
                                    <Spinner />
                              )}
                        </MoreRoomOption>
                  )}
                  {show && (
                        <PolicyModalView
                              hotelId={hotel?.id}
                              optionId={optionId}
                              handleClose={handleClose}
                              show={show}
                        />
                  )}
            </>
      );
};

export default RoomOptionList;
