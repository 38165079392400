import React from "react";
import { Link } from "react-router-dom";
import {
      HotelAddress,
      HotelAddressIcon,
      HotelHeading,
} from "../../../../frontend/hotels/styles";
import HotelRating from "../HotelRating";
import { MapIcon } from "../../../../icons";
import {
      HotelSummaryAmenity,
      HotelSummaryFacility,
      HotelSummaryFacilityList,
      HotelSummaryItem,
      HotelSummaryMore,
      HotelSummaryReview,
      HotelSummaryReviewImage,
      HotelSummaryReviewInfo,
      HotelSummaryReviewRate,
      HotelSummaryReviewTitle,
      HotelSummaryTitle,
} from "./style";
import {
      MdOutlineSpa,
      MdOutlineBathroom,
      MdOutlineRestaurant,
      MdOutlineAirportShuttle,
} from "react-icons/md";
import { TbGymnastics } from "react-icons/tb";
import { LuCircleParking } from "react-icons/lu";
import { FaWifi } from "react-icons/fa";
import { IoIosFitness } from "react-icons/io";
import HotelSummarySkeleton from "./HotelSummarySkeleton";
import HotelSummaryList from "./HotelSummaryList";
import HotelMapSkeleton from "./HotelMapSkeleton";
import { FaCheck } from "react-icons/fa6";

const HotelSummary = ({ hotel, scrollToFacility, loading }) => {
      return (
            <div>
                  {!loading ? (
                        <>
                              <HotelAddress>
                                    <HotelHeading marginBottom="0px">
                                          {hotel?.name}
                                    </HotelHeading>
                                    <HotelAddressIcon iconFont="16px">
                                          <HotelRating num={hotel?.rt} />
                                    </HotelAddressIcon>
                              </HotelAddress>
                              <HotelAddress>
                                    <HotelAddressIcon color="rgba(33, 37, 41, 0.75)">
                                          <MapIcon />
                                          {hotel?.ad?.adr}
                                          {hotel?.ad?.state?.name &&
                                                `, ${hotel?.ad?.state?.name}`}
                                          {hotel?.ad?.city?.name &&
                                                `, ${hotel?.ad?.city?.name}`}
                                    </HotelAddressIcon>
                              </HotelAddress>
                        </>
                  ) : (
                        <HotelSummarySkeleton />
                  )}
                  {/* <HotelSummaryItem>
                        <HotelSummaryTitle>Amenities</HotelSummaryTitle>
                        <HotelSummaryAmenity>
                              <ul>
                                    <li>
                                          <MdOutlineSpa /> Spa
                                    </li>
                                    <li>
                                          <MdOutlineBathroom /> Swimming Pools
                                    </li>
                                    <li>
                                          <TbGymnastics /> Gym
                                    </li>
                                    <li>
                                          <Link>+63 Amenities</Link>
                                    </li>
                              </ul>
                        </HotelSummaryAmenity>
                  </HotelSummaryItem> */}
                  <HotelSummaryFacility className="mt-4">
                        <div>
                              <HotelSummaryTitle>Facilities</HotelSummaryTitle>
                              <HotelSummaryFacilityList>
                                    <ul>
                                          {!loading ? (
                                                <>
                                                      {hotel?.fl
                                                            ?.slice(0, 6)
                                                            ?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <FaCheck />
                                                                              {
                                                                                    item
                                                                              }
                                                                        </li>
                                                                  )
                                                            )}
                                                </>
                                          ) : (
                                                <>
                                                      <HotelSummaryList />
                                                </>
                                          )}
                                          <li
                                                onClick={() =>
                                                      scrollToFacility(
                                                            "Facility"
                                                      )
                                                }
                                          >
                                                <Link>View More Details</Link>
                                          </li>
                                    </ul>
                              </HotelSummaryFacilityList>
                        </div>
                        <div>
                              {/* <HotelSummaryReview>
                                    <HotelSummaryReviewRate>
                                          3.9
                                    </HotelSummaryReviewRate>
                                    <HotelSummaryReviewTitle>
                                          Very Good <span>(11705 ratings)</span>
                                    </HotelSummaryReviewTitle>
                                    <HotelSummaryMore>
                                          All Reviews
                                    </HotelSummaryMore>
                              </HotelSummaryReview> */}
                              <HotelSummaryReview>
                                    {!loading ? (
                                          <>
                                                <HotelSummaryReviewImage>
                                                      <img
                                                            src="/images/map-sample.png"
                                                            alt=""
                                                      />
                                                </HotelSummaryReviewImage>
                                                <HotelSummaryReviewInfo>
                                                      <div className="hotel__review-title">
                                                            {
                                                                  hotel?.ad
                                                                        ?.city
                                                                        ?.name
                                                            }
                                                      </div>
                                                      <div className="hotel__review-summary">
                                                            {hotel?.ad?.adr}
                                                      </div>
                                                </HotelSummaryReviewInfo>
                                          </>
                                    ) : (
                                          <HotelMapSkeleton />
                                    )}
                                    <HotelSummaryMore
                                          onClick={() =>
                                                scrollToFacility("Location")
                                          }
                                    >
                                          See on Map
                                    </HotelSummaryMore>
                              </HotelSummaryReview>
                        </div>
                  </HotelSummaryFacility>
            </div>
      );
};

export default HotelSummary;
