import React, { useState } from "react";
import {
      HotelFareCheckContent,
      HotelFareCheck,
      HotelFareCheckInfo,
      HotelFareContainer,
      HotelFareHeader,
      HotelFareInfoSummary,
      HotelFareName,
      HotelFareRoomAmount,
      HotelFareContinue,
} from "./style";
import HotelRating from "../HotelRating";
import { MapIcon } from "../../../../icons";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
      calculateTotalNights,
      convertAmount,
      convertFloat,
      convertFullDate,
} from "../../../../hooks/CurrentData";
import { handleHotelCommission } from "../../../../hooks/hotelCommission";
import HotelSummarySkeleton from "../HotelSummary/HotelSummarySkeleton";
import { Skeleton } from "@mui/material";
import PolicyModalView from "./PolicyModalView";
import RoomPayList from "./RoomPayList";

const HotelFareSummary = ({
      hotel,
      selectedOption,
      searchQuery,
      loadingData,
      userCtx,
      selectedRoom,
      scrollToFacility,
      bookSection,
}) => {
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);
      const [optionId, setOptionId] = useState("");

      const [show, setShow] = useState(false);
      const handleShow = (value) => {
            setOptionId(value);
            setShow(true);
      };
      const handleClose = () => setShow(false);

      let options;

      const handleOptions = () => {
            if (selectedRoom) {
                  if (selectedRoom[0]?.selectedOption) {
                        if (selectedRoom[0]?.selectedOption?.ris) {
                              options = selectedRoom[0]?.selectedOption?.ris;
                        }
                  }
            }
      };
      handleOptions();

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            if (handleTotalPrice(options) === 0) {
                  scrollToFacility("Room");
                  setLoading(false);
                  return;
            }
            setTimeout(() => {
                  navigate(
                        `/hotel/travellers/${hotel?.id}/${selectedRoom[0]?.selectedOption?.id}`
                  );
                  setLoading(false);
            }, 3000);
      };

      const handleTotalPrice = (prices) => {
            const totalAmount = handleHotelCommission({
                  prices: prices,
                  commissions: userCtx?.profile?.hotelCommission,
                  domesticStatus: true,
                  hotelRating: hotel?.rt,
            });
            // console.log(totalAmount);
            return totalAmount;
      };
      const totalChild = searchQuery?.roomInfo?.reduce(
            (sum, room) => sum + room.numberOfChild,
            0
      );

      const totalAdults = searchQuery?.roomInfo?.reduce(
            (sum, room) => sum + room.numberOfAdults,
            0
      );

      const totalNights =
            searchQuery?.checkinDate &&
            searchQuery?.checkoutDate &&
            calculateTotalNights(
                  searchQuery?.checkinDate,
                  searchQuery?.checkoutDate
            );

      return (
            <>
                  <HotelFareContainer>
                        <HotelFareHeader className="mb-0">
                              {!loadingData ? (
                                    <>
                                          <HotelFareName iconFont="16px">
                                                {hotel?.name}
                                                <HotelRating num={hotel?.rt} />
                                          </HotelFareName>
                                          <HotelFareInfoSummary
                                                gap="2px"
                                                iconFont="22px"
                                                // alignItems="start"
                                          >
                                                <MapIcon /> {hotel?.ad?.adr}
                                                {hotel?.ad?.state?.name &&
                                                      `, ${hotel?.ad?.state?.name}`}
                                                {hotel?.ad?.city?.name &&
                                                      `, ${hotel?.ad?.city?.name}`}
                                          </HotelFareInfoSummary>
                                    </>
                              ) : (
                                    <HotelSummarySkeleton />
                              )}
                        </HotelFareHeader>
                        <HotelFareCheckContent marginTop="1.5rem">
                              <HotelFareCheckInfo marginBottom="1rem">
                                    <HotelFareCheck>
                                          Check-in:{" "}
                                          <span>
                                                {searchQuery?.checkinDate
                                                      ? convertFullDate(
                                                              searchQuery?.checkinDate
                                                        )
                                                      : "-"}
                                          </span>{" "}
                                    </HotelFareCheck>
                                    <HotelFareCheck>
                                          Check-out:{" "}
                                          <span>
                                                {searchQuery?.checkoutDate
                                                      ? convertFullDate(
                                                              searchQuery?.checkoutDate
                                                        )
                                                      : "-"}
                                          </span>{" "}
                                    </HotelFareCheck>
                              </HotelFareCheckInfo>
                              <HotelFareCheckInfo marginBottom="1rem">
                                    <HotelFareCheck>
                                          {totalNights} Night
                                          {totalNights > 1 ? "s" : ""} |{" "}
                                          {totalAdults} Adult
                                          {totalAdults > 1 ? "s" : ""}{" "}
                                          {totalChild > 0
                                                ? totalChild + totalChild > 1
                                                      ? "Child"
                                                      : "Children"
                                                : ""}{" "}
                                          | {selectedOption?.ris?.length} Room
                                    </HotelFareCheck>
                              </HotelFareCheckInfo>
                        </HotelFareCheckContent>

                        <RoomPayList
                              selectedRoom={selectedRoom}
                              handleShow={handleShow}
                        />

                        <HotelFareRoomAmount>
                              <div>Total Net Price</div>
                              <div>
                                    {!loadingData ? (
                                          <>
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            handleTotalPrice(
                                                                  options
                                                            )
                                                      )
                                                )}
                                          </>
                                    ) : (
                                          <Skeleton
                                                height="24px"
                                                width="80px"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                                className="py-2 mx-auto"
                                          />
                                    )}
                              </div>
                        </HotelFareRoomAmount>
                        {!loadingData ? (
                              <HotelFareContinue
                                    type="button"
                                    ref={bookSection}
                                    onClick={handleSubmit}
                              >
                                    {!loading ? "Continue" : <Spinner />}
                              </HotelFareContinue>
                        ) : (
                              <Skeleton
                                    height="20px"
                                    width="200px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                                    className="py-3 my-4 rounded-pill mx-auto"
                              />
                        )}
                  </HotelFareContainer>
                  {show && (
                        <PolicyModalView
                              optionId={optionId}
                              show={show}
                              hotelId={hotel?.id}
                              handleClose={handleClose}
                        />
                  )}
            </>
      );
};

export default HotelFareSummary;
