import React from "react";
import { HotelSummaryReviewImage, HotelSummaryReviewInfo } from "./style";
import { Skeleton } from "@mui/material";

const HotelMapSkeleton = () => {
      return (
            <>
                  <HotelSummaryReviewImage>
                        <Skeleton
                              height="50px"
                              width="60px"
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </HotelSummaryReviewImage>
                  <HotelSummaryReviewInfo style={{ minWidth: "160px" }}>
                        <div className="hotel__review-title mb-2">
                              <Skeleton
                                    height="18px"
                                    width="60px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </div>
                        <div className="hotel__review-summary">
                              <Skeleton
                                    height="16px"
                                    width="140px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                                    className="mb-1"
                              />
                              <Skeleton
                                    height="16px"
                                    width="140px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </div>
                  </HotelSummaryReviewInfo>
            </>
      );
};

export default HotelMapSkeleton;
