import styled from "styled-components";
import { CommonContainer } from "../../styles/frontend/GlobalStyle";
import { FormWrapper } from "../../components/form/styles";
import { SearchFormWrapper } from "../../components/Frontend/home/SearchForm/styles";
import { ToogleButtonContainer } from "../../components/Common/CommonToogle/styles";

export const DashboardContainer = styled.div`
      ${CommonContainer}
      background-color: ${(props) => props.theme.background};
      padding-top: ${(props) => props.padding || "164px"};
      padding-bottom: 60px;
      display: flex;
      gap: 20px;
      min-height: 900px;
      justify-content: flex-start;
      width: 100%;
      flex-direction: ${(props) => props.flexDirection};

      ${SearchFormWrapper} {
            box-shadow: none;
            form {
                  justify-content: flex-start;
                  input {
                        text-align: center;
                  }
            }
      }
      ${ToogleButtonContainer} {
            padding-bottom: 0px;
      }
`;
export const DashboardContent = styled.div`
      width: calc(100% - (22% + 20px));
`;

export const DashboardForm = styled.div`
      background: ${(props) => props.theme.white};
`;

export const DashboardMenu = styled.div`
      width: 22%;
      /* margin-top: 60px; */
`;
export const DashboardTitle = styled.h3`
      font-size: 16px;
`;
export const DashboardMenuContent = styled.div`
      background: ${(props) => props.theme.white};
      border: 1px solid #e2e2e2;
      border-radius: 5px;
      margin-top: ${props => props.marginTop};
`;

export const FilterContainer = styled.div`
      background: ${(props) => props.theme.white};
      padding: 20px 20px;
      border-radius: 5px;

      .tab-content {
            padding-top: 10px;
      }
`;

export const DashboardButton = styled.div`
      button {
            font-size: 15px;
      }
`;

export const DashboardFlightRanges = styled.div``;
export const DashboardFlightWrapper = styled.div``;
