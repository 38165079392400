import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { PriceSummary } from "../checkout/styles";
import { Rating } from "@mui/material";
import { FlightModal } from "../flight_confirmations/FlightConfirmModal/style";
import {
      Col,
      Form,
      FormControl,
      ModalBody,
      ModalHeader,
      Row,
} from "react-bootstrap";
import CommonButton from "../../components/Common/CommonButton";

const HotelFeedbackForm = ({ bookingId, agentOrder }) => {
      const [show, setShow] = useState(false);
      const [rate, setRate] = useState(0);
      const [loading, setLoading] = useState(false);
      const [feedback, setFeedback] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const handleClose = () => {
            setShow(false);
      };

      const handleShow = (event) => {
            if (event.target.checked) {
                  if (agentOrder?.feedback) {
                        setRate(agentOrder?.feedback?.rate);
                  } else {
                        setRate(event.target.value);
                  }
                  setShow(true);
            }
      };

      useEffect(() => {
            if (agentOrder?.feedback) {
                  setFeedback(agentOrder?.feedback?.feedback);
                  setRate(agentOrder?.feedback?.rate);
            }
      }, [agentOrder]);

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  rate: rate,
                  feedback: feedback,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel/${bookingId}/feedback`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              toast.success(response.data.message);
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.rate) {
                                    toast.error(response.data.message.rate);
                              }
                              if (response.data.message.feedback) {
                                    toast.error(response.data.message.feedback);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <PriceSummary>
                        <div className="price__box py-3 mb-4">
                              <div className="price__heading mb-0">
                                    <div className="price__title w-75">
                                          Submit Feedback
                                    </div>
                                    <Rating
                                          name="size-large"
                                          value={rate}
                                          size="large"
                                          readOnly={agentOrder?.feedback?.rate}
                                          onClick={handleShow}
                                    />
                              </div>
                        </div>
                  </PriceSummary>
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Your Feedback</ModalHeader>
                        <ModalBody>
                              <Form onSubmit={handleSubmit}>
                                    <Row>
                                          <Col lg={12}>
                                                <Rating
                                                      name="size-large"
                                                      value={rate}
                                                      size="large"
                                                      readOnly={
                                                            agentOrder?.feedback
                                                                  ?.rate
                                                      }
                                                      onClick={handleShow}
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <FormControl
                                                      rows={4}
                                                      onChange={(event) =>
                                                            setFeedback(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      disabled={
                                                            agentOrder?.feedback
                                                                  ?.feedback
                                                      }
                                                      value={feedback}
                                                      as={"textarea"}
                                                      placeholder="Your Feedback *"
                                                />
                                          </Col>
                                          {!agentOrder?.feedback && (
                                                <Col lg={12}>
                                                      <CommonButton
                                                            loading={loading}
                                                            button={true}
                                                            type="submit"
                                                            title="Submit"
                                                            classes="justify-content-center mt-3"
                                                            padding="0.6rem 1.5rem"
                                                      />
                                                </Col>
                                          )}
                                    </Row>
                              </Form>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default HotelFeedbackForm;
