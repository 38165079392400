import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { DashboardContainer } from "../layouts/style";
import TicketLayout from "../../frontend/booking/TicketLayout";

const FlightTicketView = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.AIR
                                                ?.tripInfos
                                    );
                                    setTotalPriceInfo(
                                          response.data.itemInfos?.AIR
                                                ?.totalPriceInfo
                                                ?.totalFareDetail
                                    );
                                    setTravellerInfos(
                                          response.data.itemInfos?.AIR
                                                ?.travellerInfos
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const updateData = async () => {
            const pnrNumber =
                  travellerInfos[0]?.pnrDetails[
                        `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                  ];
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              pnr_number: pnrNumber,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            updateData();
      }, [travellerInfos]);

      return (
            <>
                  <DashboardContainer flexDirection="column" padding="120px">
                        <TicketLayout
                              bookingId={bookingId}
                              order={order}
                              travellerInfos={travellerInfos}
                              currentPriceStatus="with-price"
                              tripInfos={tripInfos}
                              totalPriceInfo={totalPriceInfo}
                              markupPrice={0}
                              agentOrder={agentOrder}
                        />
                  </DashboardContainer>
            </>
      );
};

export default FlightTicketView;
