import React, { useEffect, useState } from "react";
import { RoomContainer, RoomFilterContainer } from "./style";
import { Col, Row } from "react-bootstrap";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Select from "react-select";

const normalizeMealBasis = (mb) =>
      mb
            ?.toLowerCase() // Convert to lowercase
            ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
            ?.trim() // Remove leading and trailing spaces
            ?.split(" ") // Split into words
            ?.map(
                  (word) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
            )
            ?.join(" ");

const HotelRoomFilter = ({ rooms, mealBasis, setFilteredRooms, roomTypes }) => {
      const [refundableStatus, setRefundableStatus] = useState(false);
      const [nonRefundableStatus, setNonRefundableStatus] = useState(false);
      const [panStatus, setPanStatus] = useState(false);
      const [noPanStatus, setNoPanStatus] = useState(false);
      const [roomType, setRoomType] = useState("");
      const [meal, setMeal] = useState("");

      const handleChange = (type) => {
            if (type === "refundable") {
                  setRefundableStatus(!refundableStatus);
            }
            if (type === "nonRefundable") {
                  setNonRefundableStatus(!nonRefundableStatus);
            }
            if (type === "pan") {
                  setPanStatus(!panStatus);
            }
            if (type === "no-pan") {
                  setNoPanStatus(!noPanStatus);
            }
      };

      useEffect(() => {
            let filteredItems = rooms;

            if (noPanStatus) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const filteredRooms = item?.rooms?.filter(
                                    (room) => room?.ipr !== noPanStatus
                              );

                              if (filteredRooms.length > 0) {
                                    return {
                                          ...item,
                                          rooms: filteredRooms,
                                    };
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            if (refundableStatus) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const filteredRooms = item?.rooms?.filter(
                                    (room) =>
                                          room?.cnp?.ifra === refundableStatus
                              );

                              if (filteredRooms.length > 0) {
                                    return {
                                          ...item,
                                          rooms: filteredRooms,
                                    };
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            if (nonRefundableStatus) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const filteredRooms = item?.rooms?.filter(
                                    (room) =>
                                          room?.cnp?.ifra !==
                                          nonRefundableStatus
                              );

                              if (filteredRooms.length > 0) {
                                    return {
                                          ...item,
                                          rooms: filteredRooms,
                                    };
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            if (panStatus) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const filteredRooms = item?.rooms?.filter(
                                    (room) => room?.ipr === panStatus
                              );

                              if (filteredRooms.length > 0) {
                                    return {
                                          ...item,
                                          rooms: filteredRooms,
                                    };
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            if (meal?.length > 0) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const filteredRooms = item?.rooms?.filter(
                                    (room) => {
                                          return room?.ris?.some((option) =>
                                                meal.some(
                                                      (mealOption) =>
                                                            normalizeMealBasis(
                                                                  option?.mb
                                                            ) ===
                                                            mealOption.value
                                                )
                                          );
                                    }
                              );

                              if (filteredRooms.length > 0) {
                                    return {
                                          ...item,
                                          rooms: filteredRooms,
                                    };
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            if (roomType?.length > 0) {
                  filteredItems = filteredItems
                        ?.map((item) => {
                              const matchesRoomType = roomType.some(
                                    (type) =>
                                          type?.value?.toLowerCase() ===
                                          item?.srn?.toLowerCase()
                              );

                              if (matchesRoomType) {
                                    return item;
                              }

                              return null;
                        })
                        ?.filter((item) => item !== null);
            }

            setFilteredRooms(
                  !panStatus &&
                        !noPanStatus &&
                        !nonRefundableStatus &&
                        !refundableStatus &&
                        meal?.length === 0 &&
                        roomType?.length === 0
                        ? rooms
                        : filteredItems
            );
      }, [
            refundableStatus,
            nonRefundableStatus,
            panStatus,
            noPanStatus,
            roomType,
            meal,
      ]);

      const handleClear = () => {
            setRefundableStatus(false);
            setNonRefundableStatus(false);
            setPanStatus(false);
            setNoPanStatus(false);
      };

      return (
            <>
                  <RoomContainer marginBottom="1rem">
                        <RoomFilterContainer>
                              <div>Room Filter</div>
                              <div onClick={handleClear} className="clickable">
                                    Clear All
                              </div>
                        </RoomFilterContainer>
                        <Row className="g-2">
                              <Col lg={12}>
                                    <Row className="g-3">
                                          <Col lg={3}>
                                                <Select
                                                      options={roomTypes}
                                                      onChange={setRoomType}
                                                      isMulti={true}
                                                      placeholder="Select Room Type"
                                                />
                                          </Col>
                                          <Col lg={3}>
                                                <Select
                                                      options={mealBasis}
                                                      onChange={setMeal}
                                                      isMulti
                                                      placeholder="Select Board Basis"
                                                />
                                          </Col>
                                    </Row>
                              </Col>
                              <Col lg={12}>
                                    <Row className="g-3">
                                          <Col lg={3}>
                                                <FormGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        onClick={() =>
                                                                              handleChange(
                                                                                    "refundable"
                                                                              )
                                                                        }
                                                                        checked={
                                                                              refundableStatus
                                                                        }
                                                                  />
                                                            }
                                                            label="Refundable"
                                                      />
                                                </FormGroup>
                                          </Col>
                                          <Col lg={3}>
                                                <FormGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        onClick={() =>
                                                                              handleChange(
                                                                                    "nonRefundable"
                                                                              )
                                                                        }
                                                                        checked={
                                                                              nonRefundableStatus
                                                                        }
                                                                  />
                                                            }
                                                            label="Non Refundable"
                                                      />
                                                </FormGroup>
                                          </Col>
                                          <Col lg={3}>
                                                <FormGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        onClick={() =>
                                                                              handleChange(
                                                                                    "pan"
                                                                              )
                                                                        }
                                                                        checked={
                                                                              panStatus
                                                                        }
                                                                  />
                                                            }
                                                            label="PAN Required"
                                                      />
                                                </FormGroup>
                                          </Col>
                                          <Col lg={3}>
                                                <FormGroup>
                                                      <FormControlLabel
                                                            control={
                                                                  <Checkbox
                                                                        onClick={() =>
                                                                              handleChange(
                                                                                    "no-pan"
                                                                              )
                                                                        }
                                                                        checked={
                                                                              noPanStatus
                                                                        }
                                                                  />
                                                            }
                                                            label="PAN not Required"
                                                      />
                                                </FormGroup>
                                          </Col>
                                    </Row>
                              </Col>
                        </Row>
                  </RoomContainer>
            </>
      );
};

export default HotelRoomFilter;
