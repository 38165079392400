import React, { useEffect, useState } from "react";
import FlightInfo from "../../../Dashboard/FlightInfo";
import FlightPriceFilter from "../../../../frontend/flight/FlightPriceFilter";
import { Spinner } from "react-bootstrap";

const OnewayFlight = ({
      flights,
      netFare,
      searchDetail,
      fullFare,
      setFilteredFlights,
      domesticStatus
}) => {
      const [limit, setLimit] = useState(20);

      const handleScroll = () => {
            if (
                  window.innerHeight + document.documentElement.scrollTop >=
                  document.documentElement.offsetHeight - 50
            ) {
                  setLimit((prevLimit) => prevLimit + 20); // Load 20 more flights
            }
      };

      useEffect(() => {
            window.addEventListener("scroll", handleScroll);
            return () => {
                  // Cleanup the event listener on component unmount
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);

      return (
            <>
                  {flights?.length > 0 && (
                        <FlightPriceFilter
                              filteredFlights={flights}
                              searchDetail={searchDetail}
                              setFilteredFlights={setFilteredFlights}
                        />
                  )}
                  {flights?.slice(0, limit)?.map((item, index) => (
                        <FlightInfo
                              item={item}
                              key={index}
                              netFare={netFare}
                              fullFare={fullFare}
                              searchDetail={searchDetail}
                              flights={flights}
                              domesticStatus={domesticStatus}
                        />
                  ))}

                  {limit < flights.length && (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                              <Spinner />
                        </div>
                  )}
            </>
      );
};

export default OnewayFlight;
