import styled from "styled-components";

export const FlightModalReturn = styled.div`
      display: flex;
      align-items: ${(props) => props.alignItems || "center"};
      width: ${(props) => props.width};
      flex-direction: ${(props) => props.flexDirection};
`;

export const FlightModalReturnDate = styled.div`
      width: calc(100% - 24px);
`;

export const FlightReturnCancel = styled.div`
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
            background-color: #f5f5f5;
            border-radius: 50rem;
      }
`;
