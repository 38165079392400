import React from "react";
import {
      RoomAccount,
      RoomDescription,
      RoomDescriptionItem,
      RoomDetailItem,
      RoomImage,
      RoomInfo,
      RoomItem,
      RoomPrice,
      RoomTitle,
} from "../HotelRoom/style";
import { Skeleton } from "@mui/material";

const RoomInfoSkeleton = () => {
      return (
            <>
                  <RoomItem>
                        <div className="d-flex">
                              <RoomInfo>
                                    <div className="text-center">
                                          <Skeleton
                                                height="24px"
                                                width="160px"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </div>
                                    <RoomImage>
                                          <Skeleton
                                                height="100%"
                                                width="100%"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </RoomImage>
                                    <div>
                                          <RoomTitle>
                                                <Skeleton
                                                      height="20px"
                                                      width="74px"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                />
                                          </RoomTitle>
                                    </div>
                              </RoomInfo>
                              <RoomDescription>
                                    <RoomDescriptionItem>
                                          <RoomDetailItem width="30%">
                                                <div className="room__detail--title">
                                                      <Skeleton
                                                            height="16px"
                                                            width="110px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                                <div
                                                      className={`room__detail--cancellation`}
                                                >
                                                      <Skeleton
                                                            height="16px"
                                                            width="140px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                          </RoomDetailItem>
                                          <RoomDetailItem width="30%">
                                                <div className="room__detail--title">
                                                      <Skeleton
                                                            height="16px"
                                                            width="110px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                                <div
                                                      className={`room__detail--cancellation`}
                                                >
                                                      <Skeleton
                                                            height="16px"
                                                            width="140px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                          </RoomDetailItem>
                                          <RoomAccount
                                                className={`pt-0 pb-0 me-0`}
                                          >
                                                <RoomPrice>
                                                      <Skeleton
                                                            height="24px"
                                                            width="70px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2 mx-auto"
                                                      />
                                                </RoomPrice>
                                                <Skeleton
                                                      height="34px"
                                                      width="125px"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                      className="rounded-pill"
                                                />
                                          </RoomAccount>
                                    </RoomDescriptionItem>
                                    <RoomDescriptionItem>
                                          <RoomDetailItem width="30%">
                                                <div className="room__detail--title">
                                                      <Skeleton
                                                            height="16px"
                                                            width="110px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                                <div
                                                      className={`room__detail--cancellation`}
                                                >
                                                      <Skeleton
                                                            height="16px"
                                                            width="140px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                          </RoomDetailItem>
                                          <RoomDetailItem width="30%">
                                                <div className="room__detail--title">
                                                      <Skeleton
                                                            height="16px"
                                                            width="110px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                                <div
                                                      className={`room__detail--cancellation`}
                                                >
                                                      <Skeleton
                                                            height="16px"
                                                            width="140px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2"
                                                      />
                                                </div>
                                          </RoomDetailItem>
                                          <RoomAccount
                                                className={`pt-0 pb-0 me-0`}
                                          >
                                                <RoomPrice>
                                                      <Skeleton
                                                            height="24px"
                                                            width="70px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                            className="mb-2 mx-auto"
                                                      />
                                                </RoomPrice>
                                                <Skeleton
                                                      height="34px"
                                                      width="125px"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                      className="rounded-pill"
                                                />
                                          </RoomAccount>
                                    </RoomDescriptionItem>
                              </RoomDescription>
                        </div>
                  </RoomItem>
            </>
      );
};

export default RoomInfoSkeleton;
