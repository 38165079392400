import React, { useRef, useEffect } from "react";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { FancyBoxContainer } from "./style";

import { de } from "@fancyapps/ui/dist/fancybox/l10n/de.esm.js";
function Fancybox(props) {
      const containerRef = useRef(null);

      useEffect(() => {
            const container = containerRef.current;

            const delegate = props.delegate || "[data-fancybox]";
            const options = props.options || { l10n: de };

            NativeFancybox.bind(container, delegate, options);

            return () => {
                  NativeFancybox.unbind(container);
                  NativeFancybox.close();
            };
      });

      return (
            <FancyBoxContainer ref={containerRef}>
                  {props.children}
            </FancyBoxContainer>
      );
}

export default Fancybox;
