import React from "react";
import {
      HotelItem,
      HotelItemContainer,
      HotelItemContent,
      HotelItemImage,
      HotelItemInfo,
      HotelItemOffer,
      HotelItemSummary,
      HotelItemTitle,
      HotelOfferItem,
      HotelSubItem,
      HotelSubItemPrice,
} from "../HotelCard/style";
import { Skeleton } from "@mui/material";

const HotelCardSkeleton = () => {
      return (
            <>
                  <HotelItemContainer>
                        <HotelItem>
                              <HotelItemImage>
                                    <Skeleton
                                          height="100%"
                                          width="100%"
                                          style={{
                                                transform: "scale(1)",
                                          }}
                                    />
                              </HotelItemImage>
                              <HotelItemContent>
                                    <HotelItemInfo width="80%">
                                          <HotelItemTitle
                                                width="100%"
                                                className="mb-2"
                                          >
                                                <Skeleton
                                                      height="25px"
                                                      width="100%"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                />
                                          </HotelItemTitle>
                                          <HotelItemSummary>
                                                <Skeleton
                                                      height="18px"
                                                      width="40px"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                />
                                                <Skeleton
                                                      height="18px"
                                                      width="30%"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                />
                                          </HotelItemSummary>
                                          <HotelItemOffer marginTop="1.2rem">
                                                <HotelOfferItem padding="0">
                                                      <Skeleton
                                                            height="18px"
                                                            width="80px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </HotelOfferItem>
                                                <HotelOfferItem padding="0">
                                                      <Skeleton
                                                            height="18px"
                                                            width="80px"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </HotelOfferItem>
                                          </HotelItemOffer>
                                    </HotelItemInfo>
                                    <HotelSubItem>
                                          <div className="w-100 d-flex align-items-center justify-content-center mb-2">
                                                <div className="w-75 ">
                                                      <Skeleton
                                                            height="20px"
                                                            width="100%"
                                                            style={{
                                                                  transform:
                                                                        "scale(1)",
                                                            }}
                                                      />
                                                </div>
                                          </div>
                                          <HotelSubItemPrice>
                                                <Skeleton
                                                      height="20px"
                                                      width="80px"
                                                      style={{
                                                            transform:
                                                                  "scale(1)",
                                                      }}
                                                />
                                          </HotelSubItemPrice>
                                    </HotelSubItem>
                              </HotelItemContent>
                        </HotelItem>
                  </HotelItemContainer>
            </>
      );
};

export default HotelCardSkeleton;
