import { Accordion, AccordionHeader } from "react-bootstrap";
import styled from "styled-components";

export const TravellersDetail = styled.div`
      margin-bottom: 10px;

      .travellers__heading {
            display: flex;
            align-items: center;
            justify-content: space-between;
      }

      .travellers__title {
            font-size: ${(props) => props.fontSize || "18px"};
            font-weight: 400;
            text-align: left;
            color: ${(props) => props.theme.primary};

            span {
                  font-size: 13px;
                  color: #333;
            }
      }
      .travellers__Detail {
            font-size: 13px;
            min-width: 25%;
            font-weight: 400;
            text-align: left;
            color: ${(props) => props.theme.primary};

            .form-control {
                  font-size: 14px;
            }
      }
`;

export const TravellerAccordion = styled(Accordion)`
      /* .accordion-button::after {
            --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
            --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      } */
`;

export const TravellerAccordionTitle = styled.div`
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: baseline;

      .accordion-button {
            background-color: transparent !important;
            color: ${(props) => props.theme.primary};
            border: 0;
            padding: 0;
            box-shadow: none;
      }

      h3 {
            font-size: ${(props) => props.fontSize || "17px"};
            font-weight: 400;
            text-align: left;
            margin: 0;
      }
      span {
            font-size: 13px;
            color: #ccc;
      }
`;

export const TravellerAccordionHeader = styled(AccordionHeader)`
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: baseline;
      background-color: #f5f5f5;

      .accordion-button {
            background-color: transparent !important;
            /* color: ${(props) => props.theme.primary}; */
            border: 0;
            padding: 0;
            box-shadow: none;
      }

      h3 {
            font-size: ${(props) => props.fontSize || "17px"};
            font-weight: 400;
            text-align: left;
            margin: 0;
      }
      span {
            font-size: 13px;
            color: #ccc;
      }
`;

export const TravellersTitle = styled.div`
      /* border-top: 1px dashed ${(props) => props.theme.primary}; */
      padding-bottom: 10px;
      border-bottom: 1px dashed ${(props) => props.theme.primary};
      display: flex;
      align-items: baseline;

      h3 {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            margin: 0;
      }

      span {
            font-size: 13px;
            color: ${(props) => props.theme.primary};
      }
`;
