import { FlightReviewIcon, TravellerDetailIcon, WalletIcon } from "../icons";

export const areasList = {
      id: 1,
      title: "Popular Area",
      items: [
            {
                  id: 1,
                  title: "Jaipur Airport",
                  value: 0,
                  checked: false,
            },
            {
                  id: 2,
                  title: "Bani Park",
                  value: 0,
                  checked: false,
            },
            {
                  id: 3,
                  title: "Station Road",
                  value: 0,
                  checked: false,
            },
            {
                  id: 4,
                  title: "Tonk Road",
                  value: 0,
                  checked: false,
            },
            {
                  id: 5,
                  title: "Amer Road",
                  value: 0,
                  checked: false,
            },
      ],
};

export const userRatings = {
      id: 1,
      title: "User Rating",
      items: [
            {
                  id: 1,
                  title: "Excellent: 4.5+ Rating",
                  value: 0,
                  checked: false,
            },
            {
                  id: 2,
                  title: "Very Good: 4+ Rating",
                  value: 0,
                  checked: false,
            },
            {
                  id: 3,
                  title: "Good: 3.5+ Rating",
                  value: 0,
                  checked: false,
            },
            {
                  id: 4,
                  title: "Pleasant: 3+ Rating",
                  value: 0,
                  checked: false,
            },
      ],
};

export const ratings = {
      id: 1,
      title: "Star Rating",
      items: [
            {
                  id: 1,
                  title: "5 Star",
                  value: 0,
                  checked: false,
                  rate: 5,
                  disable: false,
            },
            {
                  id: 2,
                  title: "4 Star",
                  value: 0,
                  checked: false,
                  rate: 4,
                  disable: false,
            },
            {
                  id: 3,
                  title: "3 Star",
                  value: 0,
                  checked: false,
                  rate: 3,
                  disable: false,
            },
            {
                  id: 4,
                  title: "2 Star",
                  value: 0,
                  checked: false,
                  rate: 2,
                  disable: true,
            },
            {
                  id: 5,
                  title: "1 Star",
                  value: 0,
                  checked: false,
                  rate: 1,
                  disable: true,
            },
            {
                  id: 6,
                  title: "No Rating",
                  value: 0,
                  checked: false,
                  rate: 1,
                  disable: true,
            },
            {
                  id: 7,
                  title: "Special Category",
                  value: 0,
                  checked: false,
                  rate: 1,
                  disable: false,
            },
      ],
};

export const stops = [
      {
            id: 1,
            title: "Stops",
            items: [
                  {
                        id: 1,
                        title: "Non-stop",
                        name: "",
                        value: 0,
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "1 Stop",
                        name: "",
                        value: 0,
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "1+ Stops",
                        name: "",
                        value: 0,
                        checked: false,
                  },
            ],
      },
];

export const departures = [
      {
            title: "Departure from ",
            items: [
                  {
                        id: 1,
                        title: "00 - 06",
                        image: "/images/morning_inactive.webp",
                        imageWhite: "/images/morning_inactive_white.png",
                        value: "00 - 06",
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "06 - 12",
                        image: "/images/noon_inactive.png",
                        imageWhite: "/images/noon_inactive_white.png",
                        value: "06 - 12",
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "12 - 18",
                        image: "/images/evening_inactive.png",
                        imageWhite: "/images/evening_inactive_white.png",
                        value: "12 - 18",
                        checked: false,
                  },
                  {
                        id: 4,
                        title: "18 - 00",
                        image: "/images/night_inactive.png",
                        imageWhite: "/images/night_inactive_white.png",
                        value: "18 - 00",
                        checked: false,
                  },
            ],
      },
];

export const arrivals = [
      {
            title: "Arrival at ",
            items: [
                  {
                        id: 1,
                        title: "00 - 06",
                        image: "/images/morning_inactive.webp",
                        imageWhite: "/images/morning_inactive_white.png",
                        value: "00 - 06",
                        checked: false,
                  },
                  {
                        id: 2,
                        title: "06 - 12",
                        image: "/images/noon_inactive.png",
                        imageWhite: "/images/noon_inactive_white.png",
                        value: "06 - 12",
                        checked: false,
                  },
                  {
                        id: 3,
                        title: "12 - 18",
                        image: "/images/evening_inactive.png",
                        imageWhite: "/images/evening_inactive_white.png",
                        value: "12 - 18",
                        checked: false,
                  },
                  {
                        id: 4,
                        title: "18 - 00",
                        image: "/images/night_inactive.png",
                        imageWhite: "/images/night_inactive_white.png",
                        value: "18 - 00",
                        checked: false,
                  },
            ],
      },
];

export const airlines = [
      {
            title: "Airlines",
            items: [
                  {
                        id: 1,
                        title: "Air India (47)",
                        name: "",
                        value: "₹5,503",
                  },
                  {
                        id: 2,
                        title: "Air India Express (10)",
                        name: "",
                        value: "₹5,558",
                  },
                  {
                        id: 3,
                        title: "Akasa Air (3)",
                        name: "",
                        value: "₹5,670",
                  },
                  {
                        id: 4,
                        title: "IndiGo (45)",
                        name: "",
                        value: "₹4,870",
                  },
                  {
                        id: 5,
                        title: "SpiceJet (5)",
                        name: "",
                        value: "₹5,401",
                  },
            ],
      },
];

export const slider = [
      {
            id: 1,
            title: "Price",
            slider: "true",
            items: [{}],
      },
];

export const flightRanges = [
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
      {
            id: 1,
            day: "Sat, 18 May",
            price: "₹5,351",
      },
];

export const optionInfo = [
      {
            index: 0,
            id: "travellers",
            title: "Traveller Details",
            subtitle: "First Step",
            icon: <TravellerDetailIcon />,
            status: false,
      },
      {
            index: 1,
            id: "review",
            title: "Review",
            subtitle: "Second Step",
            icon: <FlightReviewIcon />,
            status: false,
      },
      {
            index: 2,
            id: "payments",
            title: "Payments",
            subtitle: "Final Step",
            icon: <WalletIcon />,
            status: false,
      },
];

export const flightInfo = [
      {
            id: 1,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
      {
            id: 2,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
      {
            id: 3,
            image: "/images/logo/Indigo.png",
            title: "IndiGo",
            time: "12:30",
            leavingFrom: "DEL",
            destination: "BOM",
            arrival: "14:40",
            duration: "02h 10m",
            durationType: "Non-stop",
            price: "₹ 5,376",
      },
];

export const titleList = [
      {
            label: "Mr",
            value: "Mr",
      },
      {
            label: "Mrs",
            value: "Mrs",
      },
      {
            label: "Miss",
            value: "Ms",
      },
];

export const inFantList = [
      {
            label: "Miss",
            value: "Ms",
      },
      {
            label: "Master",
            value: "Master",
      },
];

export const countryList = [
      { label: "Afghanistan", value: "AF" },
      { label: "Albania", value: "AL" },
      { label: "Algeria", value: "DZ" },
      { label: "Andorra", value: "AD" },
      { label: "Angola", value: "AO" },
      { label: "Antigua and Barbuda", value: "AG" },
      { label: "Argentina", value: "AR" },
      { label: "Armenia", value: "AM" },
      { label: "Australia", value: "AU" },
      { label: "Austria", value: "AT" },
      { label: "Austrian Empire", value: "AT" }, // Historical reference, using "AT" for Austria
      { label: "Azerbaijan", value: "AZ" },
      { label: "Bahamas", value: "BS" },
      { label: "Bahrain", value: "BH" },
      { label: "Bangladesh", value: "BD" },
      { label: "Barbados", value: "BB" },
      { label: "Bavaria*", value: "DE" }, // Historical region in Germany, using "DE" for Germany
      { label: "Belarus", value: "BY" },
      { label: "Belgium", value: "BE" },
      { label: "Belize", value: "BZ" },
      { label: "Benin (Dahomey)", value: "BJ" }, // "Dahomey" is the former name, "BJ" is the current code
      { label: "Bolivia", value: "BO" },
      { label: "Bosnia and Herzegovina", value: "BA" },
      { label: "Botswana", value: "BW" },
      { label: "Brazil", value: "BR" },
      { label: "Brunei", value: "BN" },
      { label: "Brunswick and Lüneburg*", value: "DE" }, // Historical region in Germany, using "DE" for Germany
      { label: "Bulgaria", value: "BG" },
      { label: "Burkina Faso (Upper Volta)", value: "BF" }, // "Upper Volta" is the former name, "BF" is the current code
      { label: "Burma", value: "MM" }, // "Burma" is the former name, "MM" is the current code for Myanmar
      { label: "Burundi", value: "BI" },
      { label: "Cabo Verde", value: "CV" },
      { label: "Cambodia", value: "KH" },
      { label: "Cameroon", value: "CM" },
      { label: "Canada", value: "CA" },
      { label: "Cayman Islands", value: "KY" },
      { label: "Central African Republic", value: "CF" },
      { label: "Central American Federation*", value: "N/A" }, // Historical entity, no current code
      { label: "Chad", value: "TD" },
      { label: "Chile", value: "CL" },
      { label: "China", value: "CN" },
      { label: "Colombia", value: "CO" },
      { label: "Comoros", value: "KM" },
      { label: "Congo Free State", value: "CD" }, // Former name, now Democratic Republic of the Congo
      { label: "Costa Rica", value: "CR" },
      { label: "Cote d’Ivoire (Ivory Coast)", value: "CI" },
      { label: "Croatia", value: "HR" },
      { label: "Cuba", value: "CU" },
      { label: "Cyprus", value: "CY" },
      { label: "Czechia", value: "CZ" },
      { label: "Czechoslovakia*", value: "CZ" },
      { label: "Democratic Republic of the Congo", value: "CD" },
      { label: "Denmark", value: "DK" },
      { label: "Djibouti", value: "DJ" },
      { label: "Dominica", value: "DM" },
      { label: "Dominican Republic", value: "DO" },
      { label: "Duchy of Parma", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "East Germany (German Democratic Republic)*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Ecuador", value: "EC" },
      { label: "Egypt", value: "EG" },
      { label: "El Salvador", value: "SV" },
      { label: "Equatorial Guinea", value: "GQ" },
      { label: "Eritrea", value: "ER" },
      { label: "Estonia", value: "EE" },
      { label: "Eswatini", value: "SZ" },
      { label: "Ethiopia", value: "ET" },
      { label: "Federal Government of Germany (1848-49)*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Fiji", value: "FJ" },
      { label: "Finland", value: "FI" },
      { label: "France", value: "FR" },
      { label: "Gabon", value: "GA" },
      { label: "Gambia", value: "GM" },
      { label: "Georgia", value: "GE" },
      { label: "Germany", value: "DE" },
      { label: "Ghana", value: "GH" },
      { label: "Grand Duchy of Tuscany", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Greece", value: "GR" },
      { label: "Grenada", value: "GD" },
      { label: "Guatemala", value: "GT" },
      { label: "Guinea", value: "GN" },
      { label: "Guinea-Bissau", value: "GW" },
      { label: "Guyana", value: "GY" },
      { label: "Haiti", value: "HT" },
      { label: "Hanover", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Hanseatic Republics*", value: "DE" }, // Historical entity, using "DE" for Germany
      { label: "Hawaii", value: "US" }, // Part of the United States, using "US" for the United States
      { label: "Hesse*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Holy See", value: "VA" },
      { label: "Honduras", value: "HN" },
      { label: "Hungary", value: "HU" },
      { label: "Iceland", value: "IS" },
      { label: "India", value: "IN" },
      { label: "Indonesia", value: "ID" },
      { label: "Iran", value: "IR" },
      { label: "Iraq", value: "IQ" },
      { label: "Ireland", value: "IE" },
      { label: "Israel", value: "IL" },
      { label: "Italy", value: "IT" },
      { label: "Jamaica", value: "JM" },
      { label: "Japan", value: "JP" },
      { label: "Jordan", value: "JO" },
      { label: "Kazakhstan", value: "KZ" },
      { label: "Kenya", value: "KE" },
      { label: "Kiribati", value: "KI" },
      { label: "Korea", value: "KR" }, // South Korea, using "KR" for Korea
      { label: "Kosovo", value: "XK" }, // Unofficial ISO code for Kosovo
      { label: "Kuwait", value: "KW" },
      { label: "Kyrgyzstan", value: "KG" },
      { label: "Laos", value: "LA" },
      { label: "Latvia", value: "LV" },
      { label: "Lebanon", value: "LB" },
      { label: "Lesotho", value: "LS" },
      { label: "Lew Chew (Loochoo)*", value: "JP" }, // Historical name for Okinawa, using "JP" for Japan
      { label: "Liberia", value: "LR" },
      { label: "Libya", value: "LY" },
      { label: "Liechtenstein", value: "LI" },
      { label: "Lithuania", value: "LT" },
      { label: "Luxembourg", value: "LU" },
      { label: "Madagascar", value: "MG" },
      { label: "Malawi", value: "MW" },
      { label: "Malaysia", value: "MY" },
      { label: "Maldives", value: "MV" },
      { label: "Mali", value: "ML" },
      { label: "Malta", value: "MT" },
      { label: "Marshall Islands", value: "MH" },
      { label: "Mauritania", value: "MR" },
      { label: "Mauritius", value: "MU" },
      { label: "Mecklenburg-Schwerin*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Mecklenburg-Strelitz*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Mexico", value: "MX" },
      { label: "Micronesia", value: "FM" },
      { label: "Moldova", value: "MD" },
      { label: "Monaco", value: "MC" },
      { label: "Mongolia", value: "MN" },
      { label: "Montenegro", value: "ME" },
      { label: "Morocco", value: "MA" },
      { label: "Mozambique", value: "MZ" },
      { label: "Namibia", value: "NA" },
      { label: "Nassau*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Nauru", value: "NR" },
      { label: "Nepal", value: "NP" },
      { label: "Netherlands", value: "NL" },
      { label: "New Zealand", value: "NZ" },
      { label: "Nicaragua", value: "NI" },
      { label: "Niger", value: "NE" },
      { label: "Nigeria", value: "NG" },
      { label: "North German Confederation*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "North German Union*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "North Macedonia", value: "MK" },
      { label: "Norway", value: "NO" },
      { label: "Oldenburg*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Oman", value: "OM" },
      { label: "Orange Free State*", value: "ZA" }, // Historical region, using "ZA" for South Africa
      { label: "Pakistan", value: "PK" },
      { label: "Palau", value: "PW" },
      { label: "Panama", value: "PA" },
      { label: "Papal States*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Papua New Guinea", value: "PG" },
      { label: "Paraguay", value: "PY" },
      { label: "Peru", value: "PE" },
      { label: "Philippines", value: "PH" },
      { label: "Piedmont-Sardinia*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Poland", value: "PL" },
      { label: "Portugal", value: "PT" },
      { label: "Qatar", value: "QA" },
      { label: "Republic of Genoa*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Republic of Korea (South Korea)", value: "KR" },
      { label: "Republic of the Congo", value: "CG" },
      { label: "Romania", value: "RO" },
      { label: "Russia", value: "RU" },
      { label: "Rwanda", value: "RW" },
      { label: "Saint Kitts and Nevis", value: "KN" },
      { label: "Saint Lucia", value: "LC" },
      { label: "Saint Vincent and the Grenadines", value: "VC" },
      { label: "Samoa", value: "WS" },
      { label: "San Marino", value: "SM" },
      { label: "Sao Tome and Principe", value: "ST" },
      { label: "Saudi Arabia", value: "SA" },
      { label: "Schaumburg-Lippe*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Senegal", value: "SN" },
      { label: "Serbia", value: "RS" },
      { label: "Seychelles", value: "SC" },
      { label: "Sierra Leone", value: "SL" },
      { label: "Singapore", value: "SG" },
      { label: "Slovakia", value: "SK" },
      { label: "Slovenia", value: "SI" },
      { label: "Solomon Islands", value: "SB" },
      { label: "Somalia", value: "SO" },
      { label: "South Africa", value: "ZA" },
      { label: "South Sudan", value: "SS" },
      { label: "Spain", value: "ES" },
      { label: "Sri Lanka", value: "LK" },
      { label: "Sudan", value: "SD" },
      { label: "Suriname", value: "SR" },
      { label: "Sweden", value: "SE" },
      { label: "Switzerland", value: "CH" },
      { label: "Syria", value: "SY" },
      { label: "Tajikistan", value: "TJ" },
      { label: "Tanzania", value: "TZ" },
      { label: "Texas*", value: "US" }, // Part of the United States, using "US" for the United States
      { label: "Thailand", value: "TH" },
      { label: "Timor-Leste", value: "TL" },
      { label: "Togo", value: "TG" },
      { label: "Tonga", value: "TO" },
      { label: "Trinidad and Tobago", value: "TT" },
      { label: "Tunisia", value: "TN" },
      { label: "Turkey", value: "TR" },
      { label: "Turkmenistan", value: "TM" },
      { label: "Tuvalu", value: "TV" },
      { label: "Two Sicilies*", value: "IT" }, // Historical region, using "IT" for Italy
      { label: "Uganda", value: "UG" },
      { label: "Ukraine", value: "UA" },
      { label: "Union of Soviet Socialist Republics*", value: "RU" }, // Historical region, using "RU" for Russia
      { label: "United Arab Emirates", value: "AE" },
      { label: "United Kingdom", value: "GB" },
      { label: "Uruguay", value: "UY" },
      { label: "Uzbekistan", value: "UZ" },
      { label: "Vanuatu", value: "VU" },
      { label: "Venezuela", value: "VE" },
      { label: "Vietnam", value: "VN" },
      { label: "Württemberg*", value: "DE" }, // Historical region, using "DE" for Germany
      { label: "Yemen", value: "YE" },
      { label: "Zambia", value: "ZM" },
      { label: "Zimbabwe", value: "ZW" },
];

export const countryCodeList = [
      {
            label: "Afghanistan (+93)",
            value: "+93",
      },
      {
            label: "Albania (+355)",
            value: "+355",
      },
      {
            label: "Algeria (+213)",
            value: "+213",
      },
      {
            label: "Andorra (+376)",
            value: "+376",
      },
      {
            label: "Angola (+244)",
            value: "+244",
      },
      {
            label: "Antigua and Barbuda (+1-268)",
            value: "+1-268",
      },
      {
            label: "Argentina (+54)",
            value: "+54",
      },
      {
            label: "Armenia (+374)",
            value: "+374",
      },
      {
            label: "Australia (+61)",
            value: "+61",
      },
      {
            label: "Austria (+43)",
            value: "+43",
      },
      {
            label: "Azerbaijan (+994)",
            value: "+994",
      },
      {
            label: "Bahamas (+1-242)",
            value: "+1-242",
      },
      {
            label: "Bahrain (+973)",
            value: "+973",
      },
      {
            label: "Bangladesh (+880)",
            value: "+880",
      },
      {
            label: "Barbados (+1-246)",
            value: "+1-246",
      },
      {
            label: "Belarus (+375)",
            value: "+375",
      },
      {
            label: "Belgium (+32)",
            value: "+32",
      },
      {
            label: "Belize (+501)",
            value: "+501",
      },
      {
            label: "Benin (+229)",
            value: "+229",
      },
      {
            label: "Bolivia (+591)",
            value: "+591",
      },
      {
            label: "Bosnia and Herzegovina (+387)",
            value: "+387",
      },
      {
            label: "Botswana (+267)",
            value: "+267",
      },
      {
            label: "Brazil (+55)",
            value: "+55",
      },
      {
            label: "Brunei (+673)",
            value: "+673",
      },
      {
            label: "Bulgaria (+359)",
            value: "+359",
      },
      {
            label: "Burkina Faso (+226)",
            value: "+226",
      },
      {
            label: "Burma (+95)",
            value: "+95",
      },
      {
            label: "Burundi (+257)",
            value: "+257",
      },
      {
            label: "Cabo Verde (+238)",
            value: "+238",
      },
      {
            label: "Cambodia (+855)",
            value: "+855",
      },
      {
            label: "Cameroon (+237)",
            value: "+237",
      },
      {
            label: "Canada (+1)",
            value: "+1",
      },
      {
            label: "Cayman Islands (+1-345)",
            value: "+1-345",
      },
      {
            label: "Central African Republic (+236)",
            value: "+236",
      },
      {
            label: "Chad (+235)",
            value: "+235",
      },
      {
            label: "Chile (+56)",
            value: "+56",
      },
      {
            label: "China (+86)",
            value: "+86",
      },
      {
            label: "Colombia (+57)",
            value: "+57",
      },
      {
            label: "Comoros (+269)",
            value: "+269",
      },
      {
            label: "Costa Rica (+506)",
            value: "+506",
      },
      {
            label: "Cote d'Ivoire (Ivory Coast) (+225)",
            value: "+225",
      },
      {
            label: "Croatia (+385)",
            value: "+385",
      },
      {
            label: "Cuba (+53)",
            value: "+53",
      },
      {
            label: "Cyprus (+357)",
            value: "+357",
      },
      {
            label: "Czechia (+420)",
            value: "+420",
      },
      {
            label: "Democratic Republic of the Congo (+243)",
            value: "+243",
      },
      {
            label: "Denmark (+45)",
            value: "+45",
      },
      {
            label: "Djibouti (+253)",
            value: "+253",
      },
      {
            label: "Dominica (+1-767)",
            value: "+1-767",
      },
      {
            label: "Dominican Republic (+1-809, +1-829, +1-849)",
            value: "+1-809",
      },
      {
            label: "Ecuador (+593)",
            value: "+593",
      },
      {
            label: "Egypt (+20)",
            value: "+20",
      },
      {
            label: "El Salvador (+503)",
            value: "+503",
      },
      {
            label: "Equatorial Guinea (+240)",
            value: "+240",
      },
      {
            label: "Eritrea (+291)",
            value: "+291",
      },
      {
            label: "Estonia (+372)",
            value: "+372",
      },
      {
            label: "Eswatini (+268)",
            value: "+268",
      },
      {
            label: "Ethiopia (+251)",
            value: "+251",
      },
      {
            label: "Fiji (+679)",
            value: "+679",
      },
      {
            label: "Finland (+358)",
            value: "+358",
      },
      {
            label: "France (+33)",
            value: "+33",
      },
      {
            label: "Gabon (+241)",
            value: "+241",
      },
      {
            label: "Gambia (+220)",
            value: "+220",
      },
      {
            label: "Georgia (+995)",
            value: "+995",
      },
      {
            label: "Germany (+49)",
            value: "+49",
      },
      {
            label: "Ghana (+233)",
            value: "+233",
      },
      {
            label: "Greece (+30)",
            value: "+30",
      },
      {
            label: "Grenada (+1-473)",
            value: "+1-473",
      },
      {
            label: "Guatemala (+502)",
            value: "+502",
      },
      {
            label: "Guinea (+224)",
            value: "+224",
      },
      {
            label: "Guinea-Bissau (+245)",
            value: "+245",
      },
      {
            label: "Guyana (+592)",
            value: "+592",
      },
      {
            label: "Haiti (+509)",
            value: "+509",
      },
      {
            label: "Hawaii (+1-808)",
            value: "+1-808",
      },
      {
            label: "Holy See (+39)",
            value: "+39",
      },
      {
            label: "Honduras (+504)",
            value: "+504",
      },
      {
            label: "Hungary (+36)",
            value: "+36",
      },

      {
            label: "Iceland (+354)",
            value: "+354",
      },
      {
            label: "India (+91)",
            value: "+91",
      },
      {
            label: "Indonesia (+62)",
            value: "+62",
      },
      {
            label: "Iran (+98)",
            value: "+98",
      },
      {
            label: "Iraq (+964)",
            value: "+964",
      },
      {
            label: "Ireland (+353)",
            value: "+353",
      },
      {
            label: "Israel (+972)",
            value: "+972",
      },
      {
            label: "Italy (+39)",
            value: "+39",
      },
      {
            label: "Jamaica (+1-876)",
            value: "+1-876",
      },
      {
            label: "Japan (+81)",
            value: "+81",
      },
      {
            label: "Jordan (+962)",
            value: "+962",
      },
      {
            label: "Kazakhstan (+7)",
            value: "+7",
      },
      {
            label: "Kenya (+254)",
            value: "+254",
      },
      {
            label: "Kiribati (+686)",
            value: "+686",
      },
      {
            label: "North Korea (+850)",
            value: "+850",
      },
      {
            label: "South Korea (+82)",
            value: "+82",
      },
      {
            label: "Kosovo (+383)",
            value: "+383",
      },
      {
            label: "Kuwait (+965)",
            value: "+965",
      },
      {
            label: "Kyrgyzstan (+996)",
            value: "+996",
      },
      {
            label: "Laos (+856)",
            value: "+856",
      },
      {
            label: "Latvia (+371)",
            value: "+371",
      },
      {
            label: "Lebanon (+961)",
            value: "+961",
      },
      {
            label: "Lesotho (+266)",
            value: "+266",
      },
      {
            label: "Liberia (+231)",
            value: "+231",
      },
      {
            label: "Libya (+218)",
            value: "+218",
      },
      {
            label: "Liechtenstein (+423)",
            value: "+423",
      },
      {
            label: "Lithuania (+370)",
            value: "+370",
      },
      {
            label: "Luxembourg (+352)",
            value: "+352",
      },
      {
            label: "Madagascar (+261)",
            value: "+261",
      },
      {
            label: "Malawi (+265)",
            value: "+265",
      },
      {
            label: "Malaysia (+60)",
            value: "+60",
      },
      {
            label: "Maldives (+960)",
            value: "+960",
      },
      {
            label: "Mali (+223)",
            value: "+223",
      },
      {
            label: "Malta (+356)",
            value: "+356",
      },
      {
            label: "Marshall Islands (+692)",
            value: "+692",
      },
      {
            label: "Mauritania (+222)",
            value: "+222",
      },
      {
            label: "Mauritius (+230)",
            value: "+230",
      },
      {
            label: "Mexico (+52)",
            value: "+52",
      },
      {
            label: "Micronesia (+691)",
            value: "+691",
      },
      {
            label: "Moldova (+373)",
            value: "+373",
      },
      {
            label: "Monaco (+377)",
            value: "+377",
      },
      {
            label: "Mongolia (+976)",
            value: "+976",
      },
      {
            label: "Montenegro (+382)",
            value: "+382",
      },
      {
            label: "Morocco (+212)",
            value: "+212",
      },
      {
            label: "Mozambique (+258)",
            value: "+258",
      },
      {
            label: "Namibia (+264)",
            value: "+264",
      },
      {
            label: "Nauru (+674)",
            value: "+674",
      },
      {
            label: "Nepal (+977)",
            value: "+977",
      },
      {
            label: "Netherlands (+31)",
            value: "+31",
      },
      {
            label: "New Zealand (+64)",
            value: "+64",
      },
      {
            label: "Nicaragua (+505)",
            value: "+505",
      },
      {
            label: "Niger (+227)",
            value: "+227",
      },
      {
            label: "Nigeria (+234)",
            value: "+234",
      },
      {
            label: "North Macedonia (+389)",
            value: "+389",
      },
      {
            label: "Norway (+47)",
            value: "+47",
      },
      {
            label: "Oman (+968)",
            value: "+968",
      },
      {
            label: "Pakistan (+92)",
            value: "+92",
      },
      {
            label: "Palau (+680)",
            value: "+680",
      },
      {
            label: "Panama (+507)",
            value: "+507",
      },
      {
            label: "Papua New Guinea (+675)",
            value: "+675",
      },
      {
            label: "Paraguay (+595)",
            value: "+595",
      },
      {
            label: "Peru (+51)",
            value: "+51",
      },
      {
            label: "Philippines (+63)",
            value: "+63",
      },
      {
            label: "Poland (+48)",
            value: "+48",
      },
      {
            label: "Portugal (+351)",
            value: "+351",
      },
      {
            label: "Qatar (+974)",
            value: "+974",
      },
      {
            label: "Republic of Korea (South Korea) (+82)",
            value: "+82",
      },
      {
            label: "Republic of the Congo (+242)",
            value: "+242",
      },
      {
            label: "Romania (+40)",
            value: "+40",
      },
      {
            label: "Russia (+7)",
            value: "+7",
      },
      {
            label: "Rwanda (+250)",
            value: "+250",
      },
      {
            label: "Saint Kitts and Nevis (+1-869)",
            value: "+1869",
      },
      {
            label: "Saint Lucia (+1-758)",
            value: "+1758",
      },
      {
            label: "Saint Vincent and the Grenadines (+1-784)",
            value: "+1784",
      },
      {
            label: "Samoa (+685)",
            value: "+685",
      },
      {
            label: "San Marino (+378)",
            value: "+378",
      },
      {
            label: "Sao Tome and Principe (+239)",
            value: "+239",
      },
      {
            label: "Saudi Arabia (+966)",
            value: "+966",
      },
      {
            label: "Senegal (+221)",
            value: "+221",
      },
      {
            label: "Serbia (+381)",
            value: "+381",
      },
      {
            label: "Seychelles (+248)",
            value: "+248",
      },
      {
            label: "Sierra Leone (+232)",
            value: "+232",
      },
      {
            label: "Singapore (+65)",
            value: "+65",
      },
      {
            label: "Slovakia (+421)",
            value: "+421",
      },
      {
            label: "Slovenia (+386)",
            value: "+386",
      },
      {
            label: "Solomon Islands (+677)",
            value: "+677",
      },
      {
            label: "Somalia (+252)",
            value: "+252",
      },
      {
            label: "South Africa (+27)",
            value: "+27",
      },
      {
            label: "South Sudan (+211)",
            value: "+211",
      },
      {
            label: "Spain (+34)",
            value: "+34",
      },
      {
            label: "Sri Lanka (+94)",
            value: "+94",
      },
      {
            label: "Sudan (+249)",
            value: "+249",
      },
      {
            label: "Suriname (+597)",
            value: "+597",
      },
      {
            label: "Sweden (+46)",
            value: "+46",
      },
      {
            label: "Switzerland (+41)",
            value: "+41",
      },
      {
            label: "Syria (+963)",
            value: "+963",
      },
      {
            label: "Tajikistan (+992)",
            value: "+992",
      },
      {
            label: "Tanzania (+255)",
            value: "+255",
      },
      {
            label: "Thailand (+66)",
            value: "+66",
      },
      {
            label: "Timor-Leste (+670)",
            value: "+670",
      },
      {
            label: "Togo (+228)",
            value: "+228",
      },
      {
            label: "Tonga (+676)",
            value: "+676",
      },
      {
            label: "Trinidad and Tobago (+1-868)",
            value: "+1868",
      },
      {
            label: "Tunisia (+216)",
            value: "+216",
      },
      {
            label: "Turkey (+90)",
            value: "+90",
      },
      {
            label: "Turkmenistan (+993)",
            value: "+993",
      },
      {
            label: "Tuvalu (+688)",
            value: "+688",
      },
      {
            label: "Uganda (+256)",
            value: "+256",
      },
      {
            label: "Ukraine (+380)",
            value: "+380",
      },
      {
            label: "United Arab Emirates (+971)",
            value: "+971",
      },
      {
            label: "United Kingdom (+44)",
            value: "+44",
      },
      {
            label: "Uruguay (+598)",
            value: "+598",
      },
      {
            label: "Uzbekistan (+998)",
            value: "+998",
      },
      {
            label: "Vanuatu (+678)",
            value: "+678",
      },
      {
            label: "Venezuela (+58)",
            value: "+58",
      },
      {
            label: "Vietnam (+84)",
            value: "+84",
      },
      {
            label: "Württemberg*",
            value: "Württemberg*",
      },
      {
            label: "Yemen (+967)",
            value: "+967",
      },
      {
            label: "Zambia (+260)",
            value: "+260",
      },
      {
            label: "Zimbabwe (+263)",
            value: "+263",
      },
];

export const staticCountries = [
      {
            label: "India",
            name: "India",
            value: "91",
            value: "106",
            code: "IN",
            isocode: "IND",
      },
      {
            label: "Afghanistan",
            name: "Afghanistan",
            value: "93",
            countryid: "97",
            code: "AF",
            isocode: "AFG",
      },
      {
            label: "Albania",
            name: "Albania",
            value: "355",
            countryid: "43",
            code: "AL",
            isocode: "ALB",
      },
      {
            label: "Algeria",
            name: "Algeria",
            value: "213",
            countryid: "142",
            code: "DZ",
            isocode: "DZA",
      },
      {
            label: "Andorra",
            name: "Andorra",
            value: "376",
            countryid: "44",
            code: "AD",
            isocode: "AND",
      },
      {
            label: "Angola",
            name: "Angola",
            value: "244",
            countryid: "143",
            code: "AO",
            isocode: "AGO",
      },
      {
            label: "Argentina",
            name: "Argentina",
            value: "54",
            countryid: "29",
            code: "AR",
            isocode: "ARG",
      },
      {
            label: "Armenia",
            name: "Armenia",
            value: "374",
            countryid: "45",
            code: "AM",
            isocode: "ARM",
      },
      {
            label: "Aruba",
            name: "Aruba",
            value: "297",
            countryid: "231",
            code: "AW",
            isocode: "ABW",
      },
      {
            label: "Australia",
            name: "Australia",
            value: "61",
            countryid: "196",
            code: "AU",
            isocode: "AUS",
      },
      {
            label: "Austria",
            name: "Austria",
            value: "43",
            countryid: "46",
            code: "AT",
            isocode: "AUT",
      },
      {
            label: "Azerbaijan",
            name: "Azerbaijan",
            value: "994",
            countryid: "47",
            code: "AZ",
            isocode: "AZE",
      },
      {
            label: "Bahamas",
            name: "Bahamas",
            value: "1242",
            countryid: "3",
            code: "BS",
            isocode: "BHS",
      },
      {
            label: "Bahrain",
            name: "Bahrain",
            value: "973",
            countryid: "98",
            code: "BH",
            isocode: "BHR",
      },
      {
            label: "Bangladesh",
            name: "Bangladesh",
            value: "880",
            countryid: "99",
            code: "BD",
            isocode: "BGD",
      },
      {
            label: "Barbados",
            name: "Barbados",
            value: "1246",
            countryid: "4",
            code: "BB",
            isocode: "BRB",
      },
      {
            label: "Belarus",
            name: "Belarus",
            value: "375",
            countryid: "48",
            code: "BY",
            isocode: "BLR",
      },
      {
            label: "Belgium",
            name: "Belgium",
            value: "32",
            countryid: "49",
            code: "BE",
            isocode: "BEL",
      },
      {
            label: "Belize",
            name: "Belize",
            value: "501",
            countryid: "5",
            code: "BZ",
            isocode: "BLZ",
      },
      {
            label: "Benin",
            name: "Benin",
            value: "229",
            countryid: "144",
            code: "BJ",
            isocode: "BEN",
      },
      {
            label: "Bermuda",
            name: "Bermuda",
            value: "1441",
            countryid: "6",
            code: "BM",
            isocode: "BMU",
      },
      {
            label: "Bhutan",
            name: "Bhutan",
            value: "975",
            countryid: "100",
            code: "BT",
            isocode: "BTN",
      },
      {
            label: "Bolivia",
            name: "Bolivia",
            value: "591",
            countryid: "30",
            code: "BO",
            isocode: "BOL",
      },
      {
            label: "Bosnia and Herzegovina",
            name: "Bosnia and Herzegovina",
            value: "387",
            countryid: "50",
            code: "BA",
            isocode: "BIH",
      },
      {
            label: "Botswana",
            name: "Botswana",
            value: "267",
            countryid: "145",
            code: "BW",
            isocode: "BWA",
      },
      {
            label: "Brazil",
            name: "Brazil",
            value: "55",
            countryid: "31",
            code: "BR",
            isocode: "BRA",
      },
      {
            label: "British Virgin Islands",
            name: "British Virgin Islands",
            value: "1284",
            countryid: "7",
            code: "VG",
            isocode: "VGB",
      },
      {
            label: "Brunei",
            name: "Brunei",
            value: "673",
            countryid: "101",
            code: "BN",
            isocode: "BRN",
      },
      {
            label: "Bulgaria",
            name: "Bulgaria",
            value: "359",
            countryid: "51",
            code: "BG",
            isocode: "BGR",
      },
      {
            label: "Burkina Faso",
            name: "Burkina Faso",
            value: "226",
            countryid: "146",
            code: "BF",
            isocode: "BFA",
      },
      {
            label: "Burundi",
            name: "Burundi",
            value: "257",
            countryid: "147",
            code: "BI",
            isocode: "BDI",
      },
      {
            label: "Cambodia",
            name: "Cambodia",
            value: "855",
            countryid: "102",
            code: "KH",
            isocode: "KHM",
      },
      {
            label: "Cameroon",
            name: "Cameroon",
            value: "237",
            countryid: "148",
            code: "CM",
            isocode: "CMR",
      },
      {
            label: "Canada",
            name: "Canada",
            value: "1",
            countryid: "2",
            code: "CA",
            isocode: "CAN",
      },
      {
            label: "Cape Verde",
            name: "Cape Verde",
            value: "238",
            countryid: "149",
            code: "CV",
            isocode: "CPV",
      },
      {
            label: "Cayman Islands",
            name: "Cayman Islands",
            value: "1345",
            countryid: "8",
            code: "KY",
            isocode: "CYM",
      },
      {
            label: "Central African Republic",
            name: "Central African Republic",
            value: "236",
            countryid: "150",
            code: "CF",
            isocode: "CAF",
      },
      {
            label: "Chad",
            name: "Chad",
            value: "235",
            countryid: "151",
            code: "TD",
            isocode: "TCD",
      },
      {
            label: "Chile",
            name: "Chile",
            value: "56",
            countryid: "32",
            code: "CL",
            isocode: "CHL",
      },
      {
            label: "China",
            name: "China",
            value: "86",
            countryid: "103",
            code: "CN",
            isocode: "CHN",
      },
      {
            label: "Colombia",
            name: "Colombia",
            value: "57",
            countryid: "33",
            code: "CO",
            isocode: "COL",
      },
      {
            label: "Comoros",
            name: "Comoros",
            value: "269",
            countryid: "294",
            code: "KM",
            isocode: "COM",
      },
      {
            label: "Costa Rica",
            name: "Costa Rica",
            value: "506",
            countryid: "9",
            code: "CR",
            isocode: "CRC",
      },
      {
            label: "Croatia",
            name: "Croatia",
            value: "385",
            countryid: "52",
            code: "HR",
            isocode: "HRV",
      },
      {
            label: "Cuba",
            name: "Cuba",
            value: "53",
            countryid: "10",
            code: "CU",
            isocode: "CUB",
      },
      {
            label: "Cyprus",
            name: "Cyprus",
            value: "357",
            countryid: "53",
            code: "CY",
            isocode: "CYP",
      },
      {
            label: "Czech Republic",
            name: "Czech Republic",
            value: "420",
            countryid: "54",
            code: "CZ",
            isocode: "CZE",
      },
      {
            label: "Democratic Republic of the Congo",
            name: "Democratic Republic of the Congo",
            value: "243",
            countryid: "153",
            code: "CD",
            isocode: "COD",
      },
      {
            label: "Denmark",
            name: "Denmark",
            value: "45",
            countryid: "55",
            code: "DK",
            isocode: "DNK",
      },
      {
            label: "Djibouti",
            name: "Djibouti",
            value: "253",
            countryid: "154",
            code: "DJ",
            isocode: "DJI",
      },
      {
            label: "Dominica",
            name: "Dominica",
            value: "1767",
            countryid: "11",
            code: "DM",
            isocode: "DMA",
      },
      {
            label: "Dominican Republic",
            name: "Dominican Republic",
            value: "1 809, 1 829, 1 849",
            countryid: "12",
            code: "DO",
            isocode: "DOM",
      },
      {
            label: "Ecuador",
            name: "Ecuador",
            value: "593",
            countryid: "34",
            code: "EC",
            isocode: "ECU",
      },
      {
            label: "Egypt",
            name: "Egypt",
            value: "20",
            countryid: "155",
            code: "EG",
            isocode: "EGY",
      },
      {
            label: "El Salvador",
            name: "El Salvador",
            value: "503",
            countryid: "13",
            code: "SV",
            isocode: "SLV",
      },
      {
            label: "Equatorial Guinea",
            name: "Equatorial Guinea",
            value: "240",
            countryid: "156",
            code: "GQ",
            isocode: "GNQ",
      },
      {
            label: "Eritrea",
            name: "Eritrea",
            value: "291",
            countryid: "157",
            code: "ER",
            isocode: "ERI",
      },
      {
            label: "Estonia",
            name: "Estonia",
            value: "372",
            countryid: "56",
            code: "EE",
            isocode: "EST",
      },
      {
            label: "Ethiopia",
            name: "Ethiopia",
            value: "251",
            countryid: "158",
            code: "ET",
            isocode: "ETH",
      },
      {
            label: "Falkland Islands",
            name: "Falkland Islands",
            value: "500",
            countryid: "35",
            code: "FK",
            isocode: "FLK",
      },
      {
            label: "Fiji",
            name: "Fiji",
            value: "679",
            countryid: "198",
            code: "FJ",
            isocode: "FJI",
      },
      {
            label: "Finland",
            name: "Finland",
            value: "358",
            countryid: "57",
            code: "FI",
            isocode: "FIN",
      },
      {
            label: "France",
            name: "France",
            value: "33",
            countryid: "58",
            code: "FR",
            isocode: "FRA",
      },
      {
            label: "French Guiana",
            name: "French Guiana",
            value: "594",
            countryid: "36",
            code: "GF",
            isocode: "GUF",
      },
      {
            label: "French Polynesia",
            name: "French Polynesia",
            value: "689",
            countryid: "199",
            code: "PF",
            isocode: "PYF",
      },
      {
            label: "Gabon",
            name: "Gabon",
            value: "241",
            countryid: "159",
            code: "GA",
            isocode: "GAB",
      },
      {
            label: "Gambia",
            name: "Gambia",
            value: "220",
            countryid: "160",
            code: "GM",
            isocode: "GMB",
      },
      {
            label: "Georgia",
            name: "Georgia",
            value: "995",
            countryid: "59",
            code: "GE",
            isocode: "GEO",
      },
      {
            label: "Germany",
            name: "Germany",
            value: "49",
            countryid: "60",
            code: "DE",
            isocode: "DEU",
      },
      {
            label: "Ghana",
            name: "Ghana",
            value: "233",
            countryid: "161",
            code: "GH",
            isocode: "GHA",
      },
      {
            label: "Gibraltar",
            name: "Gibraltar",
            value: "350",
            countryid: "61",
            code: "GI",
            isocode: "GIB",
      },
      {
            label: "Greece",
            name: "Greece",
            value: "30",
            countryid: "62",
            code: "GR",
            isocode: "GRC",
      },
      {
            label: "Greenland",
            name: "Greenland",
            value: "299",
            countryid: "14",
            code: "GL",
            isocode: "GRL",
      },
      {
            label: "Grenada",
            name: "Grenada",
            value: "1473",
            countryid: "15",
            code: "GD",
            isocode: "GRD",
      },
      {
            label: "Guadeloupe",
            name: "Guadeloupe",
            value: "590",
            countryid: "16",
            code: "GP",
            isocode: "GLP",
      },
      {
            label: "Guam",
            name: "Guam",
            value: "1671",
            countryid: "200",
            code: "GU",
            isocode: "GUM",
      },
      {
            label: "Guatemala",
            name: "Guatemala",
            value: "502",
            countryid: "17",
            code: "GT",
            isocode: "GTM",
      },
      {
            label: "Guinea",
            name: "Guinea",
            value: "224",
            countryid: "162",
            code: "GN",
            isocode: "GIN",
      },
      {
            label: "Guinea-Bissau",
            name: "Guinea-Bissau",
            value: "245",
            countryid: "163",
            code: "GW",
            isocode: "GNB",
      },
      {
            label: "Guyana",
            name: "Guyana",
            value: "592",
            countryid: "37",
            code: "GY",
            isocode: "GUY",
      },
      {
            label: "Haiti",
            name: "Haiti",
            value: "509",
            countryid: "18",
            code: "HT",
            isocode: "HTI",
      },
      {
            label: "Honduras",
            name: "Honduras",
            value: "504",
            countryid: "19",
            code: "HN",
            isocode: "HND",
      },
      {
            label: "Hong Kong",
            name: "Hong Kong",
            value: "852",
            countryid: "105",
            code: "HK",
            isocode: "HKG",
      },
      {
            label: "Hungary",
            name: "Hungary",
            value: "36",
            countryid: "64",
            code: "HU",
            isocode: "HUN",
      },
      {
            label: "Iceland",
            name: "Iceland",
            value: "354",
            countryid: "65",
            code: "IS",
            isocode: "IS",
      },
      {
            label: "Indonesia",
            name: "Indonesia",
            value: "62",
            countryid: "107",
            code: "ID",
            isocode: "IDN",
      },
      {
            label: "Iran",
            name: "Iran",
            value: "98",
            countryid: "108",
            code: "IR",
            isocode: "IRN",
      },
      {
            label: "Iraq",
            name: "Iraq",
            value: "964",
            countryid: "109",
            code: "IQ",
            isocode: "IRQ",
      },
      {
            label: "Ireland",
            name: "Ireland",
            value: "353",
            countryid: "66",
            code: "IE",
            isocode: "IRL",
      },
      {
            label: "Isle of Man",
            name: "Isle of Man",
            value: "44",
            countryid: "67",
            code: "IM",
            isocode: "IMN",
      },
      {
            label: "Israel",
            name: "Israel",
            value: "972",
            countryid: "110",
            code: "IL",
            isocode: "ISR",
      },
      {
            label: "Italy",
            name: "Italy",
            value: "39",
            countryid: "68",
            code: "IT",
            isocode: "ITA",
      },
      {
            label: "Ivory Coast",
            name: "Ivory Coast",
            value: "225",
            countryid: "164",
            code: "CI",
            isocode: "CIV",
      },
      {
            label: "Jamaica",
            name: "Jamaica",
            value: "1876",
            countryid: "20",
            code: "JM",
            isocode: "JAM",
      },
      {
            label: "Japan",
            name: "Japan",
            value: "81",
            countryid: "111",
            code: "JP",
            isocode: "JPN",
      },
      {
            label: "Jersey",
            name: "Jersey",
            value: "44",
            countryid: "69",
            code: "JE",
            isocode: "JEY",
      },
      {
            label: "Jordan",
            name: "Jordan",
            value: "962",
            countryid: "112",
            code: "JO",
            isocode: "JOR",
      },
      {
            label: "Kazakhstan",
            name: "Kazakhstan",
            value: "7",
            countryid: "113",
            code: "KZ",
            isocode: "KAZ",
      },
      {
            label: "Kenya",
            name: "Kenya",
            value: "254",
            countryid: "165",
            code: "KE",
            isocode: "KEN",
      },
      {
            label: "Kiribati",
            name: "Kiribati",
            value: "686",
            countryid: "201",
            code: "KI",
            isocode: "KIR",
      },
      {
            label: "Kuwait",
            name: "Kuwait",
            value: "965",
            countryid: "114",
            code: "KW",
            isocode: "KWT",
      },
      {
            label: "Kyrgyzstan",
            name: "Kyrgyzstan",
            value: "996",
            countryid: "115",
            code: "KG",
            isocode: "KGZ",
      },
      {
            label: "Laos",
            name: "Laos",
            value: "856",
            countryid: "116",
            code: "LA",
            isocode: "LAO",
      },
      {
            label: "Latvia",
            name: "Latvia",
            value: "371",
            countryid: "71",
            code: "LV",
            isocode: "LVA",
      },
      {
            label: "Lebanon",
            name: "Lebanon",
            value: "961",
            countryid: "117",
            code: "LB",
            isocode: "LBN",
      },
      {
            label: "Lesotho",
            name: "Lesotho",
            value: "266",
            countryid: "166",
            code: "LS",
            isocode: "LSO",
      },
      {
            label: "Liberia",
            name: "Liberia",
            value: "231",
            countryid: "167",
            code: "LR",
            isocode: "LBR",
      },
      {
            label: "Libya",
            name: "Libya",
            value: "218",
            countryid: "168",
            code: "LY",
            isocode: "LBY",
      },
      {
            label: "Liechtenstein",
            name: "Liechtenstein",
            value: "423",
            countryid: "72",
            code: "LI",
            isocode: "LIE",
      },
      {
            label: "Lithuania",
            name: "Lithuania",
            value: "370",
            countryid: "73",
            code: "LT",
            isocode: "LTU",
      },
      {
            label: "Luxembourg",
            name: "Luxembourg",
            value: "352",
            countryid: "74",
            code: "LU",
            isocode: "LUX",
      },
      {
            label: "Macau",
            name: "Macau",
            value: "853",
            countryid: "118",
            code: "MO",
            isocode: "MAC",
      },
      {
            label: "Macedonia",
            name: "Macedonia",
            value: "389",
            countryid: "75",
            code: "MK",
            isocode: "MKD",
      },
      {
            label: "Madagascar",
            name: "Madagascar",
            value: "261",
            countryid: "169",
            code: "MG",
            isocode: "MDG",
      },
      {
            label: "Malawi",
            name: "Malawi",
            value: "265",
            countryid: "170",
            code: "MW",
            isocode: "MWI",
      },
      {
            label: "Malaysia",
            name: "Malaysia",
            value: "60",
            countryid: "119",
            code: "MY",
            isocode: "MYS",
      },
      {
            label: "Maldives",
            name: "Maldives",
            value: "960",
            countryid: "120",
            code: "MV",
            isocode: "MDV",
      },
      {
            label: "Mali",
            name: "Mali",
            value: "223",
            countryid: "171",
            code: "ML",
            isocode: "MLI",
      },
      {
            label: "Malta",
            name: "Malta",
            value: "356",
            countryid: "76",
            code: "MT",
            isocode: "MLT",
      },
      {
            label: "Marshall Islands",
            name: "Marshall Islands",
            value: "692",
            countryid: "202",
            code: "MH",
            isocode: "MHL",
      },
      {
            label: "Martinique",
            name: "Martinique",
            value: "596",
            countryid: "21",
            code: "MQ",
            isocode: "MTQ",
      },
      {
            label: "Mauritania",
            name: "Mauritania",
            value: "222",
            countryid: "172",
            code: "MR",
            isocode: "MRT",
      },
      {
            label: "Mauritius",
            name: "Mauritius",
            value: "230",
            countryid: "173",
            code: "MU",
            isocode: "MUS",
      },
      {
            label: "Mexico",
            name: "Mexico",
            value: "52",
            countryid: "22",
            code: "MX",
            isocode: "MEX",
      },
      {
            label: "Micronesia",
            name: "Micronesia",
            value: "691",
            countryid: "203",
            code: "FM",
            isocode: "FSM",
      },
      {
            label: "Moldova",
            name: "Moldova",
            value: "373",
            countryid: "77",
            code: "MD",
            isocode: "MDA",
      },
      {
            label: "Monaco",
            name: "Monaco",
            value: "377",
            countryid: "78",
            code: "MC",
            isocode: "MCO",
      },
      {
            label: "Mongolia",
            name: "Mongolia",
            value: "976",
            countryid: "121",
            code: "MN",
            isocode: "MNG",
      },
      {
            label: "Montenegro",
            name: "Montenegro",
            value: "382",
            countryid: "79",
            code: "ME",
            isocode: "MNE",
      },
      {
            label: "Montserrat",
            name: "Montserrat",
            value: "1664",
            countryid: "23",
            code: "MS",
            isocode: "MSR",
      },
      {
            label: "Morocco",
            name: "Morocco",
            value: "212",
            countryid: "174",
            code: "MA",
            isocode: "MAR",
      },
      {
            label: "Mozambique",
            name: "Mozambique",
            value: "258",
            countryid: "175",
            code: "MZ",
            isocode: "MOZ",
      },
      {
            label: "Namibia",
            name: "Namibia",
            value: "264",
            countryid: "176",
            code: "NA",
            isocode: "NAM",
      },
      {
            label: "Nepal",
            name: "Nepal",
            value: "977",
            countryid: "123",
            code: "NP",
            isocode: "NPL",
      },
      {
            label: "Netherlands",
            name: "Netherlands",
            value: "31",
            countryid: "80",
            code: "NL",
            isocode: "NLD",
      },
      {
            label: "New Caledonia",
            name: "New Caledonia",
            value: "687",
            countryid: "205",
            code: "NC",
            isocode: "NCL",
      },
      {
            label: "New Zealand",
            name: "New Zealand",
            value: "64",
            countryid: "197",
            code: "NZ",
            isocode: "NZL",
      },
      {
            label: "Nicaragua",
            name: "Nicaragua",
            value: "505",
            countryid: "24",
            code: "NI",
            isocode: "NIC",
      },
      {
            label: "Niger",
            name: "Niger",
            value: "227",
            countryid: "177",
            code: "NE",
            isocode: "NER",
      },
      {
            label: "Nigeria",
            name: "Nigeria",
            value: "234",
            countryid: "178",
            code: "NG",
            isocode: "NGA",
      },
      {
            label: "North Korea",
            name: "North Korea",
            value: "850",
            countryid: "124",
            code: "KP",
            isocode: "PRK",
      },
      {
            label: "Norway",
            name: "Norway",
            value: "47",
            countryid: "81",
            code: "NO",
            isocode: "NOR",
      },
      {
            label: "Oman",
            name: "Oman",
            value: "968",
            countryid: "125",
            code: "OM",
            isocode: "OMN",
      },
      {
            label: "Pakistan",
            name: "Pakistan",
            value: "92",
            countryid: "126",
            code: "PK",
            isocode: "PAK",
      },
      {
            label: "Palestine",
            name: "Palestine",
            value: "970",
            countryid: "228",
            code: "PS",
            isocode: "PSE",
      },
      {
            label: "Panama",
            name: "Panama",
            value: "507",
            countryid: "25",
            code: "PA",
            isocode: "PAN",
      },
      {
            label: "Papua New Guinea",
            name: "Papua New Guinea",
            value: "675",
            countryid: "206",
            code: "PG",
            isocode: "PNG",
      },
      {
            label: "Paraguay",
            name: "Paraguay",
            value: "595",
            countryid: "38",
            code: "PY",
            isocode: "PRY",
      },
      {
            label: "Peru",
            name: "Peru",
            value: "51",
            countryid: "39",
            code: "PE",
            isocode: "PER",
      },
      {
            label: "Philippines",
            name: "Philippines",
            value: "63",
            countryid: "127",
            code: "PH",
            isocode: "PHL",
      },
      {
            label: "Poland",
            name: "Poland",
            value: "48",
            countryid: "82",
            code: "PL",
            isocode: "POL",
      },
      {
            label: "Portugal",
            name: "Portugal",
            value: "351",
            countryid: "83",
            code: "PT",
            isocode: "PRT",
      },
      {
            label: "Puerto Rico",
            name: "Puerto Rico",
            value: "1 787, 1 939",
            countryid: "26",
            code: "PR",
            isocode: "PRI",
      },
      {
            label: "Qatar",
            name: "Qatar",
            value: "974",
            countryid: "128",
            code: "QA",
            isocode: "QAT",
      },
      {
            label: "Republic of the Congo",
            name: "Republic of the Congo",
            value: "242",
            countryid: "152",
            code: "CG",
            isocode: "COG",
      },
      {
            label: "Romania",
            name: "Romania",
            value: "40",
            countryid: "84",
            code: "RO",
            isocode: "ROU",
      },
      {
            label: "Russia",
            name: "Russia",
            value: "7",
            countryid: "85",
            code: "RU",
            isocode: "RUS",
      },
      {
            label: "Rwanda",
            name: "Rwanda",
            value: "250",
            countryid: "180",
            code: "RW",
            isocode: "RWA",
      },
      {
            label: "Samoa",
            name: "Samoa",
            value: "685",
            countryid: "207",
            code: "WS",
            isocode: "WSM",
      },
      {
            label: "San Marino",
            name: "San Marino",
            value: "378",
            countryid: "86",
            code: "SM",
            isocode: "SMR",
      },
      {
            label: "Sao Tome and Principe",
            name: "Sao Tome and Principe",
            value: "239",
            countryid: "181",
            code: "ST",
            isocode: "STP",
      },
      {
            label: "Saudi Arabia",
            name: "Saudi Arabia",
            value: "966",
            countryid: "129",
            code: "SA",
            isocode: "SAU",
      },
      {
            label: "Senegal",
            name: "Senegal",
            value: "221",
            countryid: "182",
            code: "SN",
            isocode: "SEN",
      },
      {
            label: "Serbia",
            name: "Serbia",
            value: "381",
            countryid: "87",
            code: "RS",
            isocode: "SRB",
      },
      {
            label: "Seychelles",
            name: "Seychelles",
            value: "248",
            countryid: "183",
            code: "SC",
            isocode: "SYC",
      },
      {
            label: "Sierra Leone",
            name: "Sierra Leone",
            value: "232",
            countryid: "184",
            code: "SL",
            isocode: "SLE",
      },
      {
            label: "Singapore",
            name: "Singapore",
            value: "65",
            countryid: "130",
            code: "SG",
            isocode: "SGP",
      },
      {
            label: "Slovakia",
            name: "Slovakia",
            value: "421",
            countryid: "88",
            code: "SK",
            isocode: "SVK",
      },
      {
            label: "Slovenia",
            name: "Slovenia",
            value: "386",
            countryid: "89",
            code: "SI",
            isocode: "SVN",
      },
      {
            label: "Solomon Islands",
            name: "Solomon Islands",
            value: "677",
            countryid: "208",
            code: "SB",
            isocode: "SLB",
      },
      {
            label: "Somalia",
            name: "Somalia",
            value: "252",
            countryid: "185",
            code: "SO",
            isocode: "SOM",
      },
      {
            label: "South Africa",
            name: "South Africa",
            value: "27",
            countryid: "186",
            code: "ZA",
            isocode: "ZAF",
      },
      {
            label: "South Korea",
            name: "South Korea",
            value: "82",
            countryid: "131",
            code: "KR",
            isocode: "KOR",
      },
      {
            label: "Spain",
            name: "Spain",
            value: "34",
            countryid: "90",
            code: "ES",
            isocode: "ESP",
      },
      {
            label: "Sri Lanka",
            name: "Sri Lanka",
            value: "94",
            countryid: "132",
            code: "LK",
            isocode: "LKA",
      },
      {
            label: "Sudan",
            name: "Sudan",
            value: "249",
            countryid: "187",
            code: "SD",
            isocode: "SDN",
      },
      {
            label: "Suriname",
            name: "Suriname",
            value: "597",
            countryid: "40",
            code: "SR",
            isocode: "SUR",
      },
      {
            label: "Swaziland",
            name: "Swaziland",
            value: "268",
            countryid: "188",
            code: "SZ",
            isocode: "SWZ",
      },
      {
            label: "Sweden",
            name: "Sweden",
            value: "46",
            countryid: "91",
            code: "SE",
            isocode: "SWE",
      },
      {
            label: "Switzerland",
            name: "Switzerland",
            value: "41",
            countryid: "92",
            code: "CH",
            isocode: "CHE",
      },
      {
            label: "Syria",
            name: "Syria",
            value: "963",
            countryid: "133",
            code: "SY",
            isocode: "SYR",
      },
      {
            label: "Taiwan",
            name: "Taiwan",
            value: "886",
            countryid: "134",
            code: "TW",
            isocode: "TWN",
      },
      {
            label: "Tajikistan",
            name: "Tajikistan",
            value: "992",
            countryid: "135",
            code: "TJ",
            isocode: "TJK",
      },
      {
            label: "Tanzania",
            name: "Tanzania",
            value: "255",
            countryid: "189",
            code: "TZ",
            isocode: "TZA",
      },
      {
            label: "Thailand",
            name: "Thailand",
            value: "66",
            countryid: "136",
            code: "TH",
            isocode: "THA",
      },
      {
            label: "Togo",
            name: "Togo",
            value: "228",
            countryid: "190",
            code: "TG",
            isocode: "TGO",
      },
      {
            label: "Trinidad and Tobago",
            name: "Trinidad and Tobago",
            value: "1868",
            countryid: "27",
            code: "TT",
            isocode: "TTO",
      },
      {
            label: "Tunisia",
            name: "Tunisia",
            value: "216",
            countryid: "191",
            code: "TN",
            isocode: "TUN",
      },
      {
            label: "Turkey",
            name: "Turkey",
            value: "90",
            countryid: "93",
            code: "TR",
            isocode: "TUR",
      },
      {
            label: "Turkmenistan",
            name: "Turkmenistan",
            value: "993",
            countryid: "137",
            code: "TM",
            isocode: "TKM",
      },
      {
            label: "Tuvalu",
            name: "Tuvalu",
            value: "688",
            countryid: "210",
            code: "TV",
            isocode: "TUV",
      },
      {
            label: "Uganda",
            name: "Uganda",
            value: "256",
            countryid: "192",
            code: "UG",
            isocode: "UGA",
      },
      {
            label: "Ukraine",
            name: "Ukraine",
            value: "380",
            countryid: "94",
            code: "UA",
            isocode: "UKR",
      },
      {
            label: "United Arab Emirates",
            name: "United Arab Emirates",
            value: "971",
            countryid: "138",
            code: "AE",
            isocode: "ARE",
      },
      {
            label: "United Kingdom",
            name: "United Kingdom",
            value: "44",
            countryid: "95",
            code: "GB",
            isocode: "GBR",
      },
      {
            label: "United States",
            name: "United States",
            value: "1",
            countryid: "1",
            code: "US",
            isocode: "USA",
      },
      {
            label: "Uruguay",
            name: "Uruguay",
            value: "598",
            countryid: "41",
            code: "UY",
            isocode: "URY",
      },
      {
            label: "Uzbekistan",
            name: "Uzbekistan",
            value: "998",
            countryid: "139",
            code: "UZ",
            isocode: "UZB",
      },
      {
            label: "Vanuatu",
            name: "Vanuatu",
            value: "678",
            countryid: "211",
            code: "VU",
            isocode: "VUT",
      },
      {
            label: "Venezuela",
            name: "Venezuela",
            value: "58",
            countryid: "42",
            code: "VE",
            isocode: "VEN",
      },
      {
            label: "Vietnam",
            name: "Vietnam",
            value: "84",
            countryid: "140",
            code: "VN",
            isocode: "VNM",
      },
      {
            label: "Wallis and Futuna",
            name: "Wallis and Futuna",
            value: "681",
            countryid: "212",
            code: "WF",
            isocode: "WLF",
      },
      {
            label: "Western Sahara",
            name: "Western Sahara",
            value: "212",
            countryid: "193",
            code: "EH",
            isocode: "ESH",
      },
      {
            label: "Yemen",
            name: "Yemen",
            value: "967",
            countryid: "141",
            code: "YE",
            isocode: "YEM",
      },
      {
            label: "Zambia",
            name: "Zambia",
            value: "260",
            countryid: "194",
            code: "ZM",
            isocode: "ZMB",
      },
      {
            label: "Zimbabwe",
            name: "Zimbabwe",
            value: "263",
            countryid: "195",
            code: "ZW",
            isocode: "ZWE",
      },
];
