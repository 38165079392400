import React, { useEffect, useState } from "react";
import { FormControl, ModalBody, ModalHeader } from "react-bootstrap";
import CommonButton from "../../../components/Common/CommonButton";
import { FlightModal, FlightPriceItem } from "./style";
import { FormControlLabel, Radio, RadioGroup, Skeleton } from "@mui/material";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import TicketLayout from "../../booking/TicketLayout";
import { usePDF } from "react-to-pdf";

const FlightConfirmModal = ({
      totalPriceInfo,
      bookingId,
      order,
      tripInfos,
      travellerInfos,
      agentOrder,
      loadingData,
}) => {
      const { toPDF, targetRef } = usePDF({
            filename:
                  `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}` +
                  ".pdf",
      });

      const [loading, setLoading] = useState(false);
      const [show, setShow] = useState(false);
      const [loadingPdf, setLoadingPdf] = useState(false);
      const [currentPriceStatus, setCurrentPriceStatus] =
            useState("with-price");
      const [markupPrice, setMarkupPrice] = useState(0);
      const [grandTotal, setGrandTotal] = useState(0);

      const handleShow = () => {
            setLoading(true);

            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 3000);
      };

      const handleClose = () => {
            setShow(false);
      };

      const handleChange = (event) => {
            setCurrentPriceStatus(event.target.value);
      };

      const handleInput = (event) => {
            // Convert input value to a float for proper addition
            const inputValue = parseFloat(event.target.value) || 0;
            setMarkupPrice(inputValue);
      };

      const totalAmount = () => {
            let totalAmount = 0;
            totalAmount += totalPriceInfo?.fC?.TF;
            if (agentOrder.seriesFareStatus === 1) {
                  totalAmount += agentOrder.commission;
            }
            return totalAmount;
      };

      useEffect(() => {
            setGrandTotal(totalAmount() + markupPrice);
      }, [markupPrice, totalPriceInfo]);

      useEffect(() => {
            setMarkupPrice(0);
      }, [currentPriceStatus]);

      const handlePrintPdf = async () => {
            setLoadingPdf(true);

            setTimeout(() => {
                  setLoadingPdf(false);
                  toPDF();
            }, 3000);
      };

      return (
            <>
                  {!loadingData ? (
                        <CommonButton
                              handleClick={handleShow}
                              loading={loading}
                              button={true}
                              type="button"
                              title="Download Voucher"
                        />
                  ) : (
                        <Skeleton
                              height="40px"
                              width={"160px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                              className="rounded-pill"
                        />
                  )}
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Download Voucher</ModalHeader>
                        <ModalBody>
                              <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                              >
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value="with-price"
                                                      checked={
                                                            currentPriceStatus ===
                                                            "with-price"
                                                      }
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="With Price"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value="without-price"
                                                      checked={
                                                            currentPriceStatus ===
                                                            "without-price"
                                                      }
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="Without Price"
                                    />
                              </RadioGroup>
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem className="mt-2 border-top pt-3">
                                          <div>Total Net Price</div>
                                          <div>
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            totalAmount()
                                                      )
                                                )}
                                          </div>
                                    </FlightPriceItem>
                              )}
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem>
                                          <div>Enter your Markup</div>
                                          <div>
                                                <FormControl
                                                      type="text"
                                                      onChange={handleInput}
                                                />
                                          </div>
                                    </FlightPriceItem>
                              )}
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem className="border-top border-bottom py-2 ">
                                          <div className="grand__total">
                                                Total Price
                                          </div>
                                          <div className="grand__total">
                                                ₹ {convertAmount(grandTotal)}
                                          </div>
                                    </FlightPriceItem>
                              )}
                              <CommonButton
                                    button={true}
                                    type="button"
                                    classes={"mt-3"}
                                    handleClick={handlePrintPdf}
                                    loading={loadingPdf}
                                    justifyContent="center"
                                    title="Download"
                              />
                        </ModalBody>
                  </FlightModal>
                  <div className="offscreen">
                        <div ref={targetRef}>
                              <TicketLayout
                                    bookingId={bookingId}
                                    order={order}
                                    travellerInfos={travellerInfos}
                                    currentPriceStatus={currentPriceStatus}
                                    tripInfos={tripInfos}
                                    totalPriceInfo={totalPriceInfo}
                                    markupPrice={markupPrice}
                                    agentOrder={agentOrder}
                              />
                        </div>
                  </div>
            </>
      );
};

export default FlightConfirmModal;
