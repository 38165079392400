import styled from "styled-components";

export const HotelDetailContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      border-radius: 5px;
      margin-bottom: 2rem;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const HotelDetailInfo = styled.div`
      width: 70%;
`;

export const HotelDetailContent = styled.div`
      padding: 20px;
`;

export const HotelDetailFare = styled.div`
      width: 30%;
`;
