import React, { useState } from "react";
import getCurrentDate from "../../../../hooks/CurrentData";
import {
      HotelFormContainer,
      SearchFormAction,
      SearchFormWrapper,
} from "../../home/SearchForm/styles";
import { Col, Form, FormLabel } from "react-bootstrap";
import CustomButton from "../../../Common/CustomButton";
import HotelTraveler from "../../../form/HotelTraveler";
import { useNavigate } from "react-router-dom";
import HotelCheckInput from "../../../form/HotelCheckInput";
import FormDate from "../../../form/FormDate";
import FormDateRange from "../../../form/FormDateRange";
import Select from "react-select";
import {
      countryCodeList,
      countryList,
      staticCountries,
} from "../../../../data/dashboard";
import RatingCheckbox from "./RatingCheckbox";

const storeRoomInfoInLocalStorage = (room) => {
      // Generate a unique key using Date.now or any other method
      const uniqueKey = `roomInfo_${Date.now()}`;

      // Store the data in localStorage
      localStorage.setItem(uniqueKey, JSON.stringify(room));

      return uniqueKey;
};

const HotelForm = ({ width, marginTop, marginBottom }) => {
      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const [room, setRoom] = useState([
            {
                  numberOfAdults: 2,
                  numberOfChild: 0,
                  childAge: [],
            },
      ]);
      const [ratings, setRatings] = useState({
            fiveStar: true,
            fourStar: true,
            threeStar: true,
            twoStar: false,
            oneStar: false,
            unrated: false,
      });
      const [destinations, setDestinations] = useState([]);
      const [destination, setDestination] = useState([]);
      const [checkoutDate, setCheckoutDate] = useState("");
      const [checkinDate, setCheckinDate] = useState(getCurrentDate());
      const [nationality, setNationality] = useState({
            label: "India",
            value: "106",
      });

      const handleSubmit = (e) => {
            e.preventDefault();

            setLoading(true);
            const roomInfo = storeRoomInfoInLocalStorage(room);

            const data = {
                  destination: destination?.fullRegionName,
                  checkInDate: checkinDate,
                  checkOutDate: checkoutDate,
                  nationality: nationality?.value,
                  roomInfo,
                  ratings: Object.keys(ratings)
                        .filter((key) => ratings[key]) // Include only selected ratings
                        .map((key) => {
                              switch (key) {
                                    case "fiveStar":
                                          return "5";
                                    case "fourStar":
                                          return "4";
                                    case "threeStar":
                                          return "3";
                                    case "twoStar":
                                          return "2";
                                    case "oneStar":
                                          return "1";
                                    case "unrated":
                                          return "0";
                                    default:
                                          return "";
                              }
                        }),
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  localStorage.setItem(
                        "hotelDestination",
                        JSON.stringify(destination)
                  );

                  navigate(`/hotel/search?${queryString}`);
            }, 3000);
      };

      return (
            <SearchFormWrapper
                  width={width}
                  marginBottom={marginBottom}
                  marginTop={marginTop}
                  flex="flex"
                  wrap="no-wrap"
            >
                  <Form onSubmit={handleSubmit}>
                        <HotelFormContainer>
                              <HotelCheckInput
                                    label="Enter City or Hotel"
                                    type="text"
                                    placeholder="Search"
                                    width="30%"
                                    options={destinations}
                                    state={true}
                                    required={true}
                                    destination={destination}
                                    setDestination={setDestination}
                                    destinations={destinations}
                                    setDestinations={setDestinations}
                              />
                              <FormDate
                                    label="Check In"
                                    required={true}
                                    value={checkinDate}
                                    defaultValue={new Date()}
                                    onChange={setCheckinDate}
                                    returnDate={checkoutDate}
                                    setReturnDate={setCheckoutDate}
                                    width="15%"
                                    returnStatus={true}
                              />
                              <FormDateRange
                                    label="Check Out"
                                    required={true}
                                    defaultValue={checkinDate}
                                    value={checkoutDate}
                                    onChange={setCheckoutDate}
                                    width="15%"
                                    returnStatus={true}
                              />
                              <HotelTraveler
                                    label="Rooms & Guest"
                                    rooms={room}
                                    setRooms={setRoom}
                              />
                              <SearchFormAction>
                                    <label style={{ visibility: "hidden" }}>
                                          Submit
                                    </label>
                                    <CustomButton
                                          type={"submit"}
                                          className="mt-3"
                                          title={"Search "}
                                          loading={loading}
                                    />
                              </SearchFormAction>
                        </HotelFormContainer>

                        <Col lg={2} className="mt-3">
                              <FormLabel>Nationality</FormLabel>
                              <Select
                                    options={staticCountries}
                                    value={nationality}
                                    onChange={setNationality}
                              />
                        </Col>
                        <Col lg={2} className="mt-3 ms-4">
                              <FormLabel>Rating</FormLabel>
                              <RatingCheckbox
                                    ratings={ratings}
                                    setRatings={setRatings}
                              />
                        </Col>
                  </Form>
            </SearchFormWrapper>
      );
};

export default HotelForm;
