import React, { useState } from "react";
import {
      HotelButton,
      HotelItem,
      HotelItemContainer,
      HotelItemContent,
      HotelItemImage,
      HotelItemInfo,
      HotelItemOffer,
      HotelItemSummary,
      HotelItemTitle,
      HotelOfferItem,
      HotelSubItem,
      HotelSubItemPrice,
} from "./style";
import { Fade } from "react-reveal";
import { MapIcon } from "../../../../icons";
import HotelRating from "../HotelRating";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";
import { Spinner } from "react-bootstrap";
import { handleHotelCommission } from "../../../../hooks/hotelCommission";

const HotelCard = ({ hotel, userCtx, hotelSearchDetails }) => {
      const [loading, setLoading] = useState(false);

      const handleClick = (hotelId) => {
            setLoading(true);

            setTimeout(() => {
                  setLoading(false);
                  localStorage.setItem(
                        "hotelSearchDetails",
                        hotelSearchDetails
                  );
                  const url = `/hotel/${hotelId}`;
                  window.open(url, "_blank");
            }, 2000);
      };

      const handleTotalPrice = (prices) => {
            console.log(prices)
            const totalAmount = handleHotelCommission({
                  prices: prices,
                  commissions: userCtx?.profile?.hotelCommission,
                  domesticStatus: true,
                  hotelRating: hotel?.rt,
            });
            return totalAmount;
      };

      return (
            <>
                  <Fade>
                        <HotelItemContainer>
                              <HotelItem>
                                    <HotelItemImage>
                                          <img
                                                src={
                                                      hotel.img?.[0]?.url ||
                                                      "/images/no-image.webp"
                                                }
                                                alt="Hotel"
                                                onError={(e) => {
                                                      e.target.onerror = null; // Prevent infinite loop
                                                      e.target.src =
                                                            "/images/no-image.webp"; // Fallback image
                                                }}
                                          />
                                    </HotelItemImage>
                                    <HotelItemContent>
                                          <HotelItemInfo width="55%">
                                                <HotelItemTitle>
                                                      {hotel.name}
                                                </HotelItemTitle>
                                                <HotelItemSummary fontSize="13px">
                                                      <MapIcon /> {hotel.ad.adr}
                                                      , {hotel.ad.city.name}
                                                </HotelItemSummary>
                                                <HotelRating num={hotel.rt} />
                                                <HotelItemOffer marginTop="15px">
                                                      <HotelOfferItem>
                                                            {
                                                                  hotel.pops[0]
                                                                        .fc[0]
                                                            }
                                                      </HotelOfferItem>
                                                      <HotelOfferItem>
                                                            {hotel.pt}
                                                      </HotelOfferItem>
                                                </HotelItemOffer>
                                          </HotelItemInfo>
                                          <HotelItemInfo width="45%">
                                                {/* <RatingBadgeContent>
                                                      <RatingBadge>
                                                            4.7
                                                      </RatingBadge>
                                                      Excellent (2,505)
                                                </RatingBadgeContent>
                                                <div>
                                                      <HotelFareRefundContent>
                                                            <HotelFareRefund>
                                                                  R
                                                            </HotelFareRefund>{" "}
                                                            <HotelFareRoomFree>
                                                                  Free
                                                                  Cancellation{" "}
                                                            </HotelFareRoomFree>
                                                      </HotelFareRefundContent>
                                                </div> */}
                                          </HotelItemInfo>
                                          <HotelSubItem className="mt-2">
                                                <HotelSubItemPrice>
                                                      {" ₹ "}
                                                      {hotel.ops?.[0].ris[0] &&
                                                            convertAmount(
                                                                  convertFloat(
                                                                        handleTotalPrice(
                                                                              hotel
                                                                                    ?.ops[0]
                                                                                    .ris
                                                                        )
                                                                  )
                                                            )}
                                                </HotelSubItemPrice>
                                                <HotelButton
                                                      onClick={() =>
                                                            handleClick(
                                                                  hotel?.id
                                                            )
                                                      }
                                                      fontSize="14px"
                                                >
                                                      {loading ? (
                                                            <Spinner />
                                                      ) : (
                                                            "Select Room"
                                                      )}
                                                </HotelButton>
                                          </HotelSubItem>
                                    </HotelItemContent>
                              </HotelItem>
                        </HotelItemContainer>
                  </Fade>
            </>
      );
};

export default HotelCard;
