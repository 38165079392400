import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { DashboardContainer } from "../dashboard/styles";
import toast from "react-hot-toast";
import { CountdownTimer } from "../../components/Frontend/flight_reviews";
import { PaymentConfirmationContainer } from "../payment_confirmations/style";
import { convertFloat } from "../../hooks/CurrentData";

const formatDateToDayMonth = (dateString) => {
      const date = new Date(dateString);

      return date.toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
      });
};

const FlightPaymentConfirmation = () => {
      const { topupId } = useParams();
      const userCtx = useContext(UserContext);
      const navigate = useNavigate();

      const [topup, setTopup] = useState([]);

      const [loading, setLoading] = useState(false);
      const [showData, setShowData] = useState(false);
      const [conditions, setConditions] = useState([]);
      const [bookingId, setBookingId] = useState("");
      const [statusShow, setStatusShow] = useState(false);
      const [tripInfos, setTripInfos] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [modalStatus, setModalStatus] = useState(false);
      const [message, setMessage] = useState();
      const [messageType, setMessageType] = useState(); // Default Amount


      const travellers = JSON.parse(localStorage.getItem("travellers"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));
      const gstDetails = JSON.parse(localStorage.getItem("gstDetails"));
      const services = JSON.parse(localStorage.getItem("services"));

      const [travellerDetail, setTravellerDetail] = useState("");

      const token = JSON.parse(localStorage.getItem("token"));

      const [seriesFareStatus, setSeriesFareStatus] = useState(false);


      const updatePayment = async () => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/phonePe/${topupId}`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              userCtx.loadData();
                              setTopup(response.data.topup);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            updatePayment();
      }, []);

      const loadData = async () => {
            setLoading(true);
            if (topup?.comment && topup?.status === "Success") {
                  const splitArray = topup?.comment.split(",");
                  let data;
                  if (splitArray?.length > 1) {
                        data = {
                              priceIds: [splitArray[0], splitArray[1]],
                        };
                  } else {
                        data = {
                              priceIds: [splitArray[0]],
                        };
                  }

                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/fms/v1/review`,
                              data,
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status.success === true) {
                                    setShowData(true);
                                    setStatusShow(true);
                                    setTripInfos(response.data.tripInfos);
                                    setSearchQuery(response.data.searchQuery);
                                    setBookingId(response.data.bookingId);
                                    setConditions(response.data.conditions);
                                    
                              }
                        })
                        .catch((error) => {
                              setStatusShow(true);
                              if (
                                    error.response.data.status.success === false
                              ) {
                                    setShowData(false);
                                    setModalStatus(true);
                              }
                              if (error?.response?.status === 400) {
                                    toast.error(
                                          error?.response?.data?.errors[0]
                                                ?.details
                                    );
                              }
                              if (error?.response?.status === 404) {
                                    setMessageType(
                                          error?.response?.data?.errors[0]
                                                ?.errCode
                                    );
                                    setMessage(
                                          error?.response?.data?.errors[0]
                                                ?.message
                                    );
                              }
                        });
            }
            if (
                  topup?.status === "Payment Failed" ||
                  topup?.status === "Payment Pending"
            ) {
                  localStorage.setItem("paymentStatus", "Payment Failed");
                  navigate(`/flight/review/${topup?.comment}/`);
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [topup]);

      const handleBooking = async () => {
            let travellerDetails;

            if (showData === true) {
                  const flightDetails = searchQuery?.routeInfos[0];
                  const totalTraveller =
                        searchQuery?.paxInfo?.ADULT +
                        searchQuery?.paxInfo?.CHILD +
                        searchQuery?.paxInfo?.INFANT;

                  const handleSetTravellerDetails = () => {
                        let details = "";

                        tripInfos?.forEach((item) => {
                              item?.sI?.forEach((segmentId) => {
                                    const detail = `${
                                          segmentId?.fD?.aI?.code
                                    }-${segmentId?.fD?.eT} ${
                                          segmentId?.da?.cityCode
                                    }-${
                                          segmentId?.aa?.cityCode
                                    } ${formatDateToDayMonth(segmentId?.dt)}, `;
                                    details += detail;
                              });
                        });

                        setTravellerDetail(details);
                  };
                  handleSetTravellerDetails(tripInfos);

                  const checkService = () => {
                        return services[
                              `${flightDetails?.fromCityOrAirport?.cityCode}-${flightDetails?.toCityOrAirport?.cityCode}`
                        ];
                  };

                  if (topup?.status === "Success") {
                        if (!(userCtx?.profile?.balance >= topup?.netAmount)) {
                              toast.error("Insufficient Balance");
                              setLoading(false);
                              return;
                        }

                        if (travellers && showData) {
                              travellerDetails = travellers?.map((item) => {
                                    const baggageCode = checkService()
                                          ? checkService()[item?.ptName]
                                                  ?.Baggage?.code
                                          : false;
                                    const mealCode = checkService()
                                          ? checkService()[item?.ptName]?.Meal
                                                  ?.code
                                          : false;
                                    const seatCode = checkService()
                                          ? checkService()[item?.ptName]?.Seat
                                                  ?.code
                                          : false;

                                    return {
                                          ti: item?.title,
                                          fN: item?.firstName,
                                          lN: item?.lastName,
                                          pt: item?.pt,

                                          ...(baggageCode && {
                                                ssrBaggageInfos: [
                                                      {
                                                            key: tripInfos[0]
                                                                  ?.sI[0]?.id,
                                                            code: baggageCode,
                                                      },
                                                ],
                                          }),
                                          ...(mealCode && {
                                                ssrMealInfos: [
                                                      {
                                                            key: tripInfos[0]
                                                                  ?.sI[0]?.id,
                                                            code: mealCode,
                                                      },
                                                ],
                                          }),
                                          ...(seatCode && {
                                                ssrSeatInfos: [
                                                      {
                                                            key: tripInfos[0]
                                                                  ?.sI[0]?.id,
                                                            code: seatCode,
                                                      },
                                                ],
                                          }),
                                          ...((item?.pt === "CHILD" ||
                                                item?.pt === "INFANT") && {
                                                dob: item?.dateOfBirth,
                                          }),
                                          ...(!searchQuery?.isDomestic && {
                                                pNum: item?.passportNumber,
                                                eD: item?.passportExpiry,
                                          }),
                                    };
                              });

                              const data = {
                                    bookingId: bookingId,
                                    travellerInfo: travellerDetails,
                                    paymentInfos: [
                                          {
                                                amount: convertFloat(
                                                      topup?.amount
                                                ),
                                          },
                                    ],
                                    ...(gstDetails && {
                                          gstInfo: {
                                                gstNumber:
                                                      gstDetails?.registrationNumber,
                                                email: gstDetails?.companyEmail,
                                                registeredName:
                                                      gstDetails?.companyName,
                                                mobile: gstDetails?.companyPhone,
                                                address: gstDetails?.companyAddress,
                                          },
                                    }),
                                    deliveryInfo: {
                                          emails: [
                                                contactDetails?.emailAddress,
                                          ],
                                          contacts: [
                                                contactDetails?.phoneNumber,
                                          ],
                                    },
                              };
                              const response = await axios
                                    .post(
                                          `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/air/book`,
                                          data,
                                          {
                                                headers: {
                                                      apikey: process.env
                                                            .REACT_APP_Flight_Api_Key,
                                                },
                                          }
                                    )
                                    .catch((error) => {
                                          console.log(error.message);
                                    });
                              if (response?.data) {
                                    const bookingData = {
                                          booking_id: bookingId,
                                          name: `${travellers[0]?.title} ${travellers[0]?.firstName} ${travellers[0]?.lastName}`,
                                          email: contactDetails?.emailAddress,
                                          summary:
                                                travellerDetail +
                                                " x " +
                                                totalTraveller,
                                          amount: topup?.amount,
                                          totalNetAmount: topup?.netAmount,
                                          order_type: "AIR",
                                          status:
                                                response.data.status
                                                      ?.success === true
                                                      ? "Success"
                                                      : "Pending",
                                          commission: topup?.commission,
                                          payableAmount: topup?.payableAmount,
                                          domesticStatus:
                                                searchQuery?.isDomestic,
                                          seriesFareStatus: seriesFareStatus,
                                          paymentStatus:
                                                topup?.amount === "0"
                                                      ? "Success"
                                                      : "Pending",
                                    };

                                    await axios
                                          .post(
                                                `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings`,
                                                bookingData,
                                                {
                                                      headers: {
                                                            Authorization: `Bearer ${token}`,
                                                      },
                                                }
                                          )
                                          .then((response) => {
                                                if (
                                                      response.data.result ===
                                                      "success"
                                                ) {
                                                      setLoading(false);
                                                      userCtx.loadData();
                                                      navigate(
                                                            `/flights/confirmation/${response?.data?.bookingId}`
                                                      );
                                                }
                                          })
                                          .catch((error) => {
                                                toast.error(error.message);
                                          });
                              }
                        }
                  }
            }
      };

      useEffect(() => {
            if (showData) {
                  handleBooking();
            }
      }, [showData]);

      return (
            <>
                  <DashboardContainer>
                        {!loading ? (
                              <>
                                    <PaymentConfirmationContainer
                                          style={{
                                                height: "320px",
                                          }}
                                    >
                                          <div
                                                lg={12}
                                                className="text-center my-auto py-5"
                                          >
                                                Booking Processing ...
                                                <br />
                                                <Spinner />
                                          </div>
                                    </PaymentConfirmationContainer>
                                    {!loading && statusShow ? (
                                          <>
                                                <CountdownTimer
                                                      conditions={conditions}
                                                      messageType={messageType}
                                                      message={message}
                                                      modalStatus={modalStatus}
                                                      setModalStatus={
                                                            setModalStatus
                                                      }
                                                />
                                          </>
                                    ) : null}
                              </>
                        ) : (
                              <>
                                    <PaymentConfirmationContainer
                                          style={{ height: "320px" }}
                                    >
                                          <div
                                                lg={12}
                                                className="text-center my-auto py-5"
                                          >
                                                Booking Processing ...
                                                <br />
                                                <Spinner />
                                          </div>
                                    </PaymentConfirmationContainer>
                              </>
                        )}
                  </DashboardContainer>
            </>
      );
};

export default FlightPaymentConfirmation;
