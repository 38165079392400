import React, { useContext, useEffect, useRef, useState } from "react";
import { DashboardContainer } from "../../dashboard/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import HotelRoom from "../HotelRoom";
import { HotelImageSlider } from "../../../components/sliders";
import { HotelHeading } from "../styles";
import {
      HotelDetailContainer,
      HotelDetailContent,
      HotelDetailFare,
      HotelDetailInfo,
} from "./style";
import HotelFareSummary from "../../../components/Frontend/hotel/HotelFareSummary";
import HotelSummary from "../../../components/Frontend/hotel/HotelSummary";
import {
      HotelAboutDetail,
      HotelAmenities,
      HotelDetailLocation,
      HotelPolicies,
} from "../../hotel_details";
import UserContext from "../../../context/UserContext";

const HotelDetail = () => {
      const { hotelId } = useParams();
      const userCtx = useContext(UserContext);
      const [selectedRoom, setSelectedRoom] = useState([]);
      const [currentRoom, setCurrentRoom] = useState(1);

      const [loading, setLoading] = useState(false);
      const [hotel, setHotel] = useState([]);
      const [description, setDescription] = useState([]);
      const [largeImages, setLargeImages] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [selectedOption, setSelectedOption] = useState([]);

      const facilitySection = useRef(null);
      const locationSection = useRef(null);
      const roomSection = useRef(null);
      const bookSection = useRef(null);

      const handleData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotelDetail-search`,
                        {
                              id: hotelId,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.hotel) {
                              setLoading(false);
                        }
                        setHotel(response.data.hotel);
                        if (response.data.hotel) {
                              if (response.data.hotel.ops) {
                                    setSelectedOption(
                                          response.data.hotel.ops[0]
                                    );
                              }
                        }
                        if (response.data.hotel.img) {
                              const responseImages =
                                    response.data.hotel.img.filter(
                                          (item) =>
                                                (item.sz === "XL" ||
                                                      item.sz === "XXL") &&
                                                item
                                    );
                              setLargeImages(responseImages);
                        }
                        setSearchQuery(response.data.searchQuery);
                        if (response.data.searchQuery?.roomInfo) {
                              const rooms =
                                    response.data.searchQuery.roomInfo.map(
                                          (item, index) => ({
                                                room: item,
                                                selectedOption: [],
                                                default:
                                                      index === 0
                                                            ? true
                                                            : false,
                                          })
                                    );
                              setSelectedRoom(rooms);
                        }
                        if (response.data?.hotel?.des) {
                              setDescription(
                                    JSON.parse(response.data?.hotel?.des)
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            handleData();
      }, []);

      const scrollToFacility = (jumpToLocation) => {
            if (jumpToLocation === "Facility") {
                  if (facilitySection.current) {
                        const elementPosition =
                              facilitySection.current.getBoundingClientRect()
                                    .top;
                        const offsetPosition =
                              elementPosition + window.scrollY - 80; // Subtract 140px

                        window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                        });
                  }
            }
            if (jumpToLocation === "Location") {
                  if (locationSection.current) {
                        const elementPosition =
                              locationSection.current.getBoundingClientRect()
                                    .top;
                        const offsetPosition =
                              elementPosition + window.scrollY - 80; // Subtract 140px

                        window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                        });
                  }
            }

            if (jumpToLocation === "Room") {
                  if (roomSection.current) {
                        const elementPosition =
                              roomSection.current.getBoundingClientRect().top;
                        const offsetPosition =
                              elementPosition + window.scrollY - 80; // Subtract 140px

                        window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                        });
                  }
            }
            if (jumpToLocation === "Book") {
                  if (bookSection.current) {
                        const elementPosition =
                              bookSection.current.getBoundingClientRect().top;
                        const offsetPosition =
                              elementPosition + window.scrollY - 80; // Subtract 140px

                        window.scrollTo({
                              top: offsetPosition,
                              behavior: "smooth",
                        });
                  }
            }
      };

      return (
            <>
                  <DashboardContainer padding="120px">
                        <Row>
                              <HotelDetailInfo>
                                    <Row>
                                          <Col lg={12}>
                                                <HotelDetailContainer>
                                                      <HotelImageSlider
                                                            images={largeImages}
                                                            title={hotel?.name}
                                                            height={"320px"}
                                                            loading={loading}
                                                      />

                                                      <HotelDetailContent>
                                                            <div>
                                                                  <HotelSummary
                                                                        hotel={
                                                                              hotel
                                                                        }
                                                                        loading={
                                                                              loading
                                                                        }
                                                                        scrollToFacility={
                                                                              scrollToFacility
                                                                        }
                                                                  />
                                                            </div>
                                                      </HotelDetailContent>
                                                </HotelDetailContainer>
                                          </Col>

                                          <Col lg={12}>
                                                <div>
                                                      <HotelHeading
                                                            ref={roomSection}
                                                      >
                                                            Select Your own
                                                            Rooms
                                                      </HotelHeading>
                                                      <div>
                                                            <HotelRoom
                                                                  selectedRoom={
                                                                        selectedRoom
                                                                  }
                                                                  setSelectedRoom={
                                                                        setSelectedRoom
                                                                  }
                                                                  items={
                                                                        hotel?.ops
                                                                  }
                                                                  hotel={hotel}
                                                                  currentRoom={
                                                                        currentRoom
                                                                  }
                                                                  setCurrentRoom={
                                                                        setCurrentRoom
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  userCtx={
                                                                        userCtx
                                                                  }
                                                            />
                                                      </div>
                                                </div>
                                          </Col>
                                    </Row>
                                    <HotelAmenities
                                          facilitySection={facilitySection}
                                          facilities={hotel?.fl}
                                          loading={loading}
                                    />
                                    <HotelDetailLocation
                                          hotel={hotel}
                                          loading={loading}
                                          locationSection={locationSection}
                                    />
                                    {!loading ? (
                                          <HotelAboutDetail
                                                description={description}
                                          />
                                    ) : null}
                                    {!loading ? (
                                          <HotelPolicies
                                                instructions={hotel?.inst}
                                          />
                                    ) : null}
                              </HotelDetailInfo>
                              <HotelDetailFare>
                                    <HotelFareSummary
                                          selectedOption={selectedOption}
                                          hotel={hotel}
                                          selectedRoom={selectedRoom}
                                          searchQuery={searchQuery}
                                          loadingData={loading}
                                          userCtx={userCtx}
                                          bookSection={bookSection}
                                          scrollToFacility={scrollToFacility}
                                    />
                              </HotelDetailFare>
                        </Row>
                  </DashboardContainer>
            </>
      );
};

export default HotelDetail;
