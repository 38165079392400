import React from "react";
import {
      HotelAddress,
      HotelAddressIcon,
      HotelHeading,
} from "../../../../frontend/hotels/styles";
import { Skeleton } from "@mui/material";
import { MapIcon } from "../../../../icons";

const HotelSummarySkeleton = () => {
      return (
            <>
                  <HotelAddress>
                        <HotelHeading marginBottom="0px">
                              <Skeleton
                                    height="24px"
                                    width="260px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </HotelHeading>
                  </HotelAddress>
                  <HotelAddress className="mt-2">
                        <HotelAddressIcon color="rgba(33, 37, 41, 0.75)">
                              <MapIcon />
                              <Skeleton
                                    height="18px"
                                    width="260px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </HotelAddressIcon>
                  </HotelAddress>
            </>
      );
};

export default HotelSummarySkeleton;
