import React from "react";
import { FlightModal } from "../../../frontend/flight_confirmations/FlightConfirmModal/style";
import { Col, ModalBody, ModalHeader, Row } from "react-bootstrap";
import { MoreImage } from "./styles";
import Fancybox from "../../Common/FancyBox";

const HotelImageModal = ({ images, show, handleClose }) => {
      return (
            <FlightModal
                  backdrop="static"
                  show={show}
                  top="18px"
                  size="lg"
                  onHide={handleClose}
            >
                  <ModalHeader closeButton>Hotel Gallery</ModalHeader>
                  <ModalBody>
                        <Fancybox>
                              <Row className="g-4">
                                    {images?.map((item, index) => (
                                          <Col lg={4} key={index}>
                                                <MoreImage>
                                                      <a
                                                            href={item?.url}
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={
                                                                        item?.url
                                                                  }
                                                                  key={index}
                                                                  alt="Hotel"
                                                                  onError={(
                                                                        e
                                                                  ) => {
                                                                        e.target.onerror =
                                                                              null; // Prevent infinite loop
                                                                        e.target.src =
                                                                              "/images/no-image.webp"; // Fallback image
                                                                  }}
                                                            />
                                                      </a>
                                                </MoreImage>
                                          </Col>
                                    ))}
                              </Row>
                        </Fancybox>
                  </ModalBody>
            </FlightModal>
      );
};

export default HotelImageModal;
