import { Route } from "react-router-dom";
import { BankList } from "../account/bank_lists";
import { AccountStatement } from "../account/account_statements";
import { MyAccount } from "../account/my_account";
import { FlightBooking, HotelBooking, MyBooking } from "../account/my_bookings";
import { ChangePassword } from "../account/change_password";
import {
      DepositList,
      DepositManage,
      DepositRequest,
} from "../account/bank_deposits";
import AddMoneyPage from "../account/wallet_loads";

export const accountManages = [
      // My Account
      <Route path="/my-account" element={<MyAccount />} />,

      // My Booking
      <Route path="/my-bookings" element={<MyBooking />} />,
      <Route path="/view-flight-booking" element={<FlightBooking />} />,
      <Route path="/view-hotel-booking" element={<HotelBooking />} />,

      // Banks
      <Route path="/bank-detail" element={<BankList />} />,

      // Deposit Manage
      <Route path="/wallet-manage" element={<DepositManage />} />,
      <Route path="/deposit-request" element={<DepositRequest />} />,
      <Route path="/view-deposit" element={<DepositList />} />,
      <Route path="/add-money" element={<AddMoneyPage />} />,

      // Account Statement
      <Route path="/account-statement" element={<AccountStatement />} />,

      // Change Password
      <Route path="/change-password" element={<ChangePassword />} />,
];
