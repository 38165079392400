import React, { useEffect, useState } from "react";
import { HotelHeading, LocationMap } from "../../hotels/styles";
import { Icon, Skeleton } from "@mui/material";
import GoogleMapReact from "google-map-react";
import locationIcon from "@iconify/icons-mdi/map-marker";
import { RoomContainer } from "../../hotels/HotelRoom/style";
import { HotelAboutList } from "../HotelAmenities/style";
import { FiMapPin } from "react-icons/fi";
import HotelSummarySkeleton from "../../../components/Frontend/hotel/HotelSummary/HotelSummarySkeleton";

const LocationPin = () => (
      <div className="pin">
            <Icon icon={locationIcon} className="pin-icon" />
      </div>
);

const HotelDetailLocation = ({ hotel, loading, locationSection }) => {
      const [location, setLocation] = useState({
            address: "1600 Amphitheatre Parkway, Mountain View, california.",
            lat: 37.42216,
            lng: -122.08427,
      });

      // useEffect(() => {
      //       setLocation({
      //             address: hotel?.name,
      //             lat: hotel?.gl?.lt,
      //             lng: hotel?.gl?.ln,
      //       });
      // }, [hotel]);
      return (
            <>
                  <HotelHeading ref={locationSection} className="mt-4 pt-2">
                        Location
                  </HotelHeading>
                  <RoomContainer className="d-flex gap-3">
                        <LocationMap>
                              {!loading ? (
                                    <GoogleMapReact
                                          bootstrapURLKeys={{
                                                key: "AIzaSyD9hKUXIQsHlb4SuiAktynQr8cHej3Ix5c",
                                          }}
                                          defaultCenter={location}
                                          defaultZoom={15}
                                    >
                                          <LocationPin
                                                lat={location?.lat}
                                                lng={location?.lng}
                                                title={hotel?.name}
                                                address={`${hotel?.ad?.adr}${
                                                      hotel?.ad?.state?.name &&
                                                      ", "
                                                }${
                                                      hotel?.ad?.state?.name &&
                                                      hotel?.ad?.state?.name
                                                }${
                                                      hotel?.ad?.city?.name &&
                                                      ", "
                                                }${
                                                      hotel?.ad?.city?.name &&
                                                      hotel?.ad?.city?.name
                                                }`}
                                          />
                                    </GoogleMapReact>
                              ) : (
                                    <Skeleton
                                          height="100%"
                                          width="100%"
                                          style={{
                                                transform: "scale(1)",
                                          }}
                                    />
                              )}
                        </LocationMap>
                        <HotelAboutList>
                              {!loading ? (
                                    <div className="about__item-list py-2">
                                          <h4>{hotel?.name}</h4>
                                          <div className="d-flex align-items-center">
                                                <FiMapPin />
                                                <span className="ps-2">{`${
                                                      hotel?.ad?.adr
                                                }${
                                                      hotel?.ad?.state?.name &&
                                                      ", "
                                                }${
                                                      hotel?.ad?.state?.name &&
                                                      hotel?.ad?.state?.name
                                                }${
                                                      hotel?.ad?.city?.name &&
                                                      ", "
                                                }${
                                                      hotel?.ad?.city?.name &&
                                                      hotel?.ad?.city?.name
                                                }`}</span>
                                          </div>
                                    </div>
                              ) : (
                                    <HotelSummarySkeleton />
                              )}
                        </HotelAboutList>
                  </RoomContainer>
            </>
      );
};

export default HotelDetailLocation;
