import styled from "styled-components";

export const HotelCardContainer = styled.div`
      border-radius: 12px;
      border: 1.5px solid rgb(231, 231, 231);
      box-shadow: rgba(26, 26, 26, 0.08) 0px -4px 16px;
      background-color: rgb(255, 255, 255);
      margin-bottom: 24px;
`;

export const HotelCardHeader = styled.div`
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 24px;
`;

export const HotelCardContent = styled.div`
      display: flex;
      gap: 10px;
`;

export const HotelHeaderInfo = styled.div`
      width: calc(100% - 120px);
`;

export const HotelHeaderSubtitle = styled.div`
      color: rgb(128, 128, 128);
`;

export const HotelHeaderTitle = styled.div`
      font-size: 24px;
`;

export const HotelHeaderImage = styled.div`
      height: 100px;
      width: 120px;
      border-radius: 10px;
      border: 1px solid #e6e6e6;

      img {
            height: 100%;
            width: 100%;
            border-radius: 10px;
      }
`;

export const HotelHorizontalLine = styled.div`
      padding-bottom: 24px;
      padding-top: 24px;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;

      .left__style {
            position: absolute;
            top: 16px;
            background: #f7f7f730;
            width: 2px;
            height: 18px;
            left: -2px;
      }

      .right__style {
            position: absolute;
            top: 16px;
            background: #f7f7f730;
            width: 2px;
            height: 18px;
            right: -2px;
      }
`;

export const HotelHorizontalBorder = styled.div`
      border: 1px dashed rgb(230, 230, 230);
      position: relative;

      &::before {
            left: -23px;
            transform: rotate(90deg);
            content: "";
            position: absolute;
            width: 20px;
            height: 10px;
            border-top: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            top: -6px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background: #f4f4f4;
            box-shadow: inset 0 4px 16px 0 rgba(26, 26, 26, 0.08);
      }

      &::after {
            right: -23px;
            transform: rotate(-90deg);
            content: "";
            position: absolute;
            width: 20px;
            height: 10px;
            border-top: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            top: -6px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            background: #f4f4f4;
            box-shadow: inset 0 4px 16px 0 rgba(26, 26, 26, 0.08);
      }
`;

export const HotelFooterContent = styled.div`
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      column-gap: ${(props) => props.gap || "64px"};
      justify-content: ${(props) => props.justifyContent};
`;

export const HotelFooterContainer = styled.div`
      padding-bottom: 24px;
      padding-left: 24px;
      padding-right: 24px;
`;

export const HotelFooterTitle = styled.div`
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: rgb(26, 26, 26);
      text-decoration: none;
      cursor: auto;
`;

export const HotelNightRoom = styled.div`
      background: #f3f3f3;
      width: 64px;
      height: 24px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: rgb(128, 128, 128);
      text-decoration: none;
      cursor: auto;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
`;

export const HotelFooterSubtitle = styled.div`
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: rgb(128, 128, 128);
      text-decoration: none;
      cursor: auto;
`;

export const HotelFooterLine = styled.div`
      width: 1px;
      border-bottom: 48px solid rgb(243, 243, 243);
`;

export const HReviewContainer = styled.div``;

export const HReviewContent = styled.div`
      display: flex;
`;

export const HReviewImage = styled.div`
      width: 240px;
      height: 160px;

      img {
            width: 100%;
            height: 100%;
      }
`;

export const HReviewTitle = styled.div`
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
`;

export const HReviewFacility = styled.div`
      ul {
            display: flex;
            align-items: center;
            gap: 10px;
            list-style: none;
            margin: 0;
            padding-left: 20px;

            li {
                  padding: 6px 14px;
                  width: fit-content;
                  white-space: nowrap;
                  border-style: none;
                  background-color: rgb(243, 243, 243);
                  border-radius: 30px;
                  font-size: 14px;
                  cursor: pointer;
                  box-shadow: rgb(231, 231, 231) 0px 0px 0px 1px inset;
            }
      }
`;

export const HReviewExtraDetail = styled.div`
      padding-top: 10px;
`;

export const HReviewExtra = styled.div`
      margin-top: 2rem;
      ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            gap: 30px;
            padding-left: 20px;

            li {
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  font-size: 15px;
            }
      }
`;
