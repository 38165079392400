import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { MyBookingIcon } from "../../icons";
import FlightTable from "./FlightTable";

const FlightBooking = () => {
      return (
            <>
                  <DashboardLayout title="Search Flight Bookings">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <MyBookingIcon />
                                          </MenuIcon>
                                          Search Your Bookings
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="20px">
                                    <FlightTable />
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default FlightBooking;
