import styled from "styled-components";

export const HotelSummaryItem = styled.div`
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
`;

export const HotelSummaryTitle = styled.div`
      font-size: 18px;
      font-weight: 550;
      margin-bottom: 5px;
`;

export const HotelSummaryAmenity = styled.div`
      ul {
            display: flex;
            gap: 15px;
            margin: 0;
            padding: 0;

            li {
                  list-style: none;
                  display: flex;
                  max-width: fit-content;
                  align-items: center;
                  gap: 4px;
                  color: rgba(33, 37, 41, 0.75);
                  font-size: 15px;
            }
      }
`;

export const HotelSummaryFacility = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: end;
`;

export const HotelSummaryFacilityList = styled.div`
      ul {
            display: grid;
            margin: 0;
            padding: 0;
            row-gap: 10px;
            column-gap: 15px;
            grid-template-columns: repeat(2, minmax(0, 1fr));

            li {
                  list-style: none;
                  font-size: 15px;
                  display: flex;
                  gap: 5px;
                  align-items: center;
            }
      }
`;

export const HotelSummaryReview = styled.div`
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      border-bottom: 1px solid #dfe0e4;
      padding-bottom: 14px;
      margin-bottom: 7px;

      &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
      }
`;

export const HotelSummaryReviewRate = styled.div`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      padding: 8px 14px;
      font-size: 20px;
      font-weight: 600;
      border-radius: 1rem;
`;

export const HotelSummaryReviewTitle = styled.div`
      color: ${(props) => props.theme.primary};
      font-size: 16px;
      font-weight: 600;

      span {
            font-size: 14px;
            font-weight: 500;
            color: rgba(33, 37, 41, 0.75);
      }
`;

export const HotelSummaryReviewInfo = styled.div`
      max-width: 180px;

      .hotel__review-title {
            font-weight: 550;
            font-size: 16px;
      }

      .hotel__review-summary {
            font-size: 14px;
            color: rgba(33, 37, 41, 0.75);
      }
`;

export const HotelSummaryReviewImage = styled.div`
      width: 60px;

      img {
            height: 100%;
            width: 100%;
      }
`;

export const HotelSummaryMore = styled.div`
      color: ${(props) => props.theme.primary};
      font-weight: 550;
      font-size: 14px;
      cursor: pointer;
      text-align: left;
      width: 80px;
`;
