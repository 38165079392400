import React, { useContext, useEffect, useState } from "react";
import {
      FlightInfoButton,
      FlightInfoContainer,
      FlightInfoContent,
      FlightInfoDuration,
      FlightInfoLogo,
      FlightInfoMultipleLogo,
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "./styles";
import CommonButton from "../../Common/CommonButton";
import FlightAccordion from "../FlightAccordion";
import FlightPrice from "../FlightPrice";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import {
      checkCommission,
      totalAmountAfterCommission,
} from "../../../data/commission";
import UserContext from "../../../context/UserContext";

const FlightInfo = ({
      flights,
      item,
      netFare,
      searchDetail,
      fullFare,
      domesticStatus,
}) => {
      const userCtx = useContext(UserContext);

      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const [status, setStatus] = useState(false);
      const [fareId, setFareId] = useState([]);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const checkCabinClassPrice = () => {
            let filteredData = item?.totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );

            return filteredData.sort(
                  (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
            );
      };

      useEffect(() => {
            setFareId(checkCabinClassPrice()[0]);
            setStatus(false);
      }, [flights]);

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            const optionId = event.target.elements.option.value;

            localStorage.setItem("previousSearch", previousLink);
            localStorage.setItem("reviewId", optionId);

            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");
            localStorage.removeItem("totalAmount");
            localStorage.removeItem("totalNetAmount");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/travellers/${optionId}`);
            }, 3000);
      };

      const calculateTotalAmount = () => {
            let newAmount = 0;

            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        totalAmountAfterCommission({
                              airlineCode: item?.sI[0]?.fD?.aI?.code,
                              domesticStatus: domesticStatus,
                              commission: userCtx?.profile?.commission,
                              fareDetail: fareId,
                              totalFare: fareId?.fd?.ADULT?.fC?.TF,
                        }) *
                              (fullFare ? searchDetail?.adult : 1);
            }
            if (fullFare) {
                  if (fareId?.fd?.CHILD) {
                        newAmount =
                              newAmount +
                              totalAmountAfterCommission({
                                    airlineCode: item?.sI[0]?.fD?.aI?.code,
                                    domesticStatus: domesticStatus,
                                    commission: userCtx?.profile?.commission,
                                    fareDetail: fareId,
                                    totalFare: fareId?.fd?.CHILD?.fC?.TF,
                              }) *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (fareId?.fd?.INFANT) {
                        newAmount =
                              newAmount +
                              fareId?.fd?.INFANT?.fC?.TF *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }

            return convertAmount(convertFloat(newAmount));
      };

      const calculateTotalNetAmount = () => {
            let newAmount = 0;

            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        checkCommission({
                              airlineCode: item?.sI[0]?.fD?.aI?.code,
                              fareDetail: fareId,
                              commission: userCtx?.profile?.commission,
                              domesticStatus: domesticStatus,
                              totalFare: fareId?.fd?.ADULT?.fC?.TF,
                              netFare: fareId?.fd?.ADULT?.fC?.NF,
                        }) *
                              (fullFare ? searchDetail?.adult : 1);
                  
            }

            if (fullFare) {
                  if (fareId?.fd?.CHILD) {
                        newAmount =
                              newAmount +
                              checkCommission({
                                    airlineCode: item?.sI[0]?.fD?.aI?.code,
                                    fareDetail: fareId,
                                    commission: userCtx?.profile?.commission,
                                    domesticStatus: domesticStatus,
                                    totalFare: fareId?.fd?.CHILD?.fC?.TF,
                                    netFare: fareId?.fd?.CHILD?.fC?.NF,
                              }) *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (fareId?.fd?.INFANT) {
                        newAmount =
                              newAmount +
                              checkCommission({
                                    airlineCode: item?.sI[0]?.fD?.aI?.code,
                                    fareDetail: fareId,
                                    commission: userCtx?.profile?.commission,
                                    domesticStatus: domesticStatus,
                                    totalFare: fareId?.fd?.INFANT?.fC?.TF,
                                    netFare: fareId?.fd?.INFANT?.fC?.NF,
                                    children: true,
                              }) *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }

            return convertAmount(convertFloat(newAmount));
      };

      const calculateTotalTime = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return totalMinutes;
      };

      const checkTime = (items) => {
            let totalTime = 0;

            items?.sI?.map((sI, index) => {
                  totalTime = totalTime + sI?.duration;
                  if (items?.sI?.length > 1 && items?.sI.length !== index + 1) {
                        totalTime =
                              totalTime +
                              calculateTotalTime(
                                    sI?.at,
                                    items?.sI[index + 1]?.dt
                              );
                  }
            });

            const hours = calculateDuration(totalTime).hours;
            const minutes = calculateDuration(totalTime).minutes;

            return { hours, minutes };
      };

      const checkAirline = (sI) => {
            if (sI.length === 0) return false; // Return false for an empty array

            const firstAirlineCode = sI[0].fD.aI.code;
            return sI.every(
                  (segment) => segment.fD.aI.code === firstAirlineCode
            );
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <FlightInfoContainer status={status}>
                              <FlightInfoContent>
                                    <FlightInfoTitle className="gap-2">
                                          {checkAirline(item?.sI) ? (
                                                <FlightInfoLogo>
                                                      <img
                                                            src={`/images/AirlinesLogo/${item?.sI[0]?.fD?.aI?.code}.png`}
                                                            alt={
                                                                  item?.sI[0]
                                                                        ?.fD?.aI
                                                                        ?.name
                                                            }
                                                      />
                                                </FlightInfoLogo>
                                          ) : (
                                                <FlightInfoMultipleLogo>
                                                      {item?.sI?.map(
                                                            (image, key) =>
                                                                  image?.fD?.aI
                                                                        ?.code !==
                                                                        item
                                                                              ?.sI[
                                                                              key +
                                                                                    1
                                                                        ]?.fD
                                                                              ?.aI
                                                                              ?.code && (
                                                                        <img
                                                                              src={`/images/AirlinesLogo/${image?.fD?.aI?.code}.png`}
                                                                              alt={
                                                                                    image
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.name
                                                                              }
                                                                        />
                                                                  )
                                                      )}
                                                </FlightInfoMultipleLogo>
                                          )}
                                          <div className="title">
                                                <h4 className="pb-0 mb-0">
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.name
                                                      }
                                                </h4>
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                >
                                                      {
                                                            item?.sI[0]?.fD?.aI
                                                                  ?.code
                                                      }{" "}
                                                      {item?.sI[0]?.fD?.fN}
                                                </div>
                                          </div>
                                    </FlightInfoTitle>

                                    <FlightInfoTime>
                                          <div>
                                                {formatTime(item?.sI[0]?.dt)}
                                          </div>
                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {`${item?.sI[0]?.da?.city} (${item?.sI[0]?.da?.cityCode})`}
                                          </div>
                                    </FlightInfoTime>

                                    <FlightInfoDuration>
                                          <div>
                                                {`${checkTime(item)?.hours}h ${
                                                      checkTime(item)?.minutes
                                                }m`}
                                          </div>

                                          <div
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {item?.sI?.length === 1
                                                      ? "Non-stop"
                                                      : "1 Stop(s)"}
                                          </div>
                                    </FlightInfoDuration>

                                    <FlightInfoTime>
                                          <div>
                                                {formatTime(
                                                      arrivalTime(item?.sI)?.at
                                                )}
                                          </div>
                                          <div
                                                className="place d-flex gap-1 align-items-center"
                                                style={{
                                                      fontSize: "11px",
                                                      color: "#666",
                                                }}
                                          >
                                                {`${
                                                      item?.sI[
                                                            item?.sI?.length - 1
                                                      ]?.aa?.city
                                                } (${
                                                      item?.sI[
                                                            item?.sI?.length - 1
                                                      ]?.aa?.cityCode
                                                })`}
                                          </div>
                                    </FlightInfoTime>

                                    <FlightInfoPrice>
                                          <div className="flight__info-price">
                                                {"₹ "}
                                                {calculateTotalAmount()}
                                                {netFare && (
                                                      <span className="flight__info-net text-muted">{` | NET ${calculateTotalNetAmount()}`}</span>
                                                )}
                                          </div>
                                          {fareId?.fd?.ADULT?.sR <= 5 && (
                                                <div
                                                      style={{
                                                            fontSize: "11px",
                                                            color: "#666",
                                                      }}
                                                      className="text-danger"
                                                >
                                                      {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                </div>
                                          )}
                                    </FlightInfoPrice>

                                    <FlightInfoButton>
                                          <CommonButton
                                                title={"Book Now"}
                                                slug={"/checkout"}
                                                padding={"10px 20px"}
                                                fontSize={"13px"}
                                                width={"90px"}
                                                button={true}
                                                type="submit"
                                                loading={loading}
                                          />
                                    </FlightInfoButton>
                              </FlightInfoContent>
                              <FlightPrice
                                    setStatus={setStatus}
                                    status={status}
                                    setFareId={setFareId}
                                    fullFare={fullFare}
                                    domesticStatus={domesticStatus}
                                    commission={userCtx?.profile?.commission}
                                    fareId={fareId}
                                    totalPriceList={item?.totalPriceList}
                                    searchDetail={searchDetail}
                                    netFare={netFare}
                                    airlineCode={item?.sI[0]?.fD?.aI?.code}
                              />
                              {status && (
                                    <div className="bg-gray w-100">
                                          <FlightAccordion
                                                fareId={fareId}
                                                items={item}
                                                setFareId={setFareId}
                                                searchDetail={searchDetail}
                                                domesticStatus={domesticStatus}
                                                commission={
                                                      userCtx?.profile
                                                            ?.commission
                                                }
                                                airlineCode={
                                                      item?.sI[0]?.fD?.aI?.code
                                                }
                                          />
                                    </div>
                              )}
                        </FlightInfoContainer>
                  </Form>
            </>
      );
};

export default FlightInfo;
