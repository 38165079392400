import React from "react";
import HotelTravellers from "./HotelTravellers";
import HotelReviewDetail from "./HotelReviewDetail";
import HotelConfirmDetail from "../hotel_confirmations/HotelConfirmDetail";

const HotelReviewOptions = ({
      optionId,
      searchQuery,
      priceInfo,
      hOptionId,
      hotelId,
      bookingId,
      hotelInfo,
      totalAmount,
}) => {
      return (
            <>
                  {optionId === "travellers" && (
                        <>
                              <HotelConfirmDetail
                                    priceInfos={priceInfo}
                                    searchQuery={searchQuery}
                                    tripInfos={hotelInfo}
                              />
                              <HotelTravellers
                                    searchQuery={searchQuery}
                                    marginBottom={"30px"}
                                    priceInfo={priceInfo}
                                    hotelId={hotelId}
                                    hOptionId={hOptionId}
                                    totalAmount={totalAmount}
                                    bookingId={bookingId}
                              />
                        </>
                  )}
                  {optionId === "review" && (
                        <HotelReviewDetail
                              bookingId={bookingId}
                              hotelId={hotelId}
                              hOptionId={hOptionId}
                              totalAmounts={totalAmount}
                              priceInfo={priceInfo}
                              hotelInfo={hotelInfo}
                        />
                  )}
            </>
      );
};

export default HotelReviewOptions;
