import React, { useState } from "react";
import {
      FlightTabButton,
      FlightTabContainer,
      FlightTabContent,
      FlightTabHeader,
      FlightTabPanel,
} from "./style";
import FlightDetails from "../FlightDetails";
import FlightFare from "../FlightFare";
import BaggageInformation from "../BaggageInformation";
import FlightFareRule from "../FlightFareRule";

const FlightAccordion = ({
      fareId,
      items,
      searchDetail,
      airlineCode,
      domesticStatus,
      commission,
}) => {
      const [activeTab, setActiveTab] = useState(0);

      const handleTabClick = (tabName) => {
            setActiveTab(tabName);
      };

      const options = [
            {
                  title: "Flight Details",
                  value: 0,
                  description: <FlightDetails items={items} />,
            },
            {
                  title: "Fare Summary",
                  value: 1,
                  description: (
                        <FlightFare
                              searchDetail={searchDetail}
                              fareId={fareId}
                              commission={commission}
                              airlineCode={airlineCode}
                              domesticStatus={domesticStatus}
                        />
                  ),
            },
            {
                  title: "Fare Rules",
                  value: 2,
                  description: (
                        <FlightFareRule
                              fareId={fareId}
                              searchDetail={searchDetail}
                        />
                  ),
            },
            {
                  title: "Baggage Information",
                  value: 3,
                  description: <BaggageInformation items={items} />,
            },
      ];

      return (
            <>
                  <FlightTabContainer>
                        <FlightTabHeader>
                              {options?.map((item, index) => (
                                    <FlightTabButton
                                          type="button"
                                          className={`${
                                                activeTab === index
                                                      ? "active"
                                                      : ""
                                          }`}
                                          onClick={() => handleTabClick(index)}
                                    >
                                          {item.title}
                                    </FlightTabButton>
                              ))}
                        </FlightTabHeader>
                        <FlightTabContent>
                              {options?.map((item, index) => (
                                    <FlightTabPanel
                                          className={`${
                                                activeTab === index
                                                      ? "active"
                                                      : ""
                                          }`}
                                    >
                                          {item.description}
                                    </FlightTabPanel>
                              ))}
                        </FlightTabContent>
                  </FlightTabContainer>
            </>
      );
};

export default FlightAccordion;
