import React, { useState } from "react";
import { PriceSummary, PriceTravellerItem } from "../checkout/styles";
import PriceSummaryContent from "../../components/Frontend/checkout/PriceSummaryBox/PriceSummaryContent";
import { AdultIcon, ChildIcon, MapIcon } from "../../icons";
import {
      calculateTotalNights,
      convertAmount,
      convertFloat,
      convertFullDate,
} from "../../hooks/CurrentData";
import { MdBedroomChild } from "react-icons/md";
import HotelPriceDetails from "./HotelPriceDetails";
import {
      HotelFareCheck,
      HotelFareCheckContent,
      HotelFareCheckInfo,
      HotelFareContainer,
      HotelFareHeader,
      HotelFareInfoSummary,
      HotelFareName,
      HotelFareRoomButton,
      HotelFareRoomInfo,
} from "../../components/Frontend/hotel/HotelFareSummary/style";
import HotelRating from "../../components/Frontend/hotel/HotelRating";
import PolicyModalView from "../../components/Frontend/hotel/HotelFareSummary/PolicyModalView";

const HotelPriceSummary = ({
      priceInfo,
      searchQuery,
      optionId,
      userCtx,
      hotelInfo,
      totalAmount,
      loading,
}) => {
      const [show, setShow] = useState(false);
      const handleShow = () => {
            setShow(true);
      };
      const handleClose = () => setShow(false);

      const totalChild = searchQuery?.roomInfo?.reduce(
            (sum, room) => sum + room.numberOfChild,
            0
      );

      const totalAdults = searchQuery?.roomInfo?.reduce(
            (sum, room) => sum + room.numberOfAdults,
            0
      );

      const totalNights =
            searchQuery?.checkinDate &&
            searchQuery?.checkoutDate &&
            calculateTotalNights(
                  searchQuery?.checkinDate,
                  searchQuery?.checkoutDate
            );

      return (
            <>
                  {hotelInfo && optionId === "review" && (
                        <>
                              <PriceSummary>
                                    <div className="price__box mb-4">
                                          <div className="price__heading border-bottom">
                                                <div className="price__title w-100">
                                                      <HotelFareCheckInfo marginBottom="0rem">
                                                            <HotelFareRoomInfo>
                                                                  <HotelFareCheck>
                                                                        {
                                                                              totalNights
                                                                        }{" "}
                                                                        Night
                                                                        {totalNights >
                                                                        1
                                                                              ? "s"
                                                                              : ""}{" "}
                                                                        |{" "}
                                                                        {
                                                                              totalAdults
                                                                        }{" "}
                                                                        Adult
                                                                        {totalAdults >
                                                                        1
                                                                              ? "s"
                                                                              : ""}{" "}
                                                                        {totalChild >
                                                                        0
                                                                              ? totalChild +
                                                                                      totalChild >
                                                                                1
                                                                                    ? "Child"
                                                                                    : "Children"
                                                                              : ""}{" "}
                                                                        |{" "}
                                                                        {
                                                                              searchQuery
                                                                                    ?.roomInfo
                                                                                    ?.length
                                                                        }{" "}
                                                                        Room
                                                                  </HotelFareCheck>
                                                                  <HotelFareRoomButton
                                                                        onClick={
                                                                              handleShow
                                                                        }
                                                                  >
                                                                        Room
                                                                        Policy
                                                                  </HotelFareRoomButton>
                                                            </HotelFareRoomInfo>
                                                      </HotelFareCheckInfo>
                                                </div>
                                          </div>
                                          <HotelFareHeader>
                                                <HotelFareName iconFont="16px">
                                                      {hotelInfo?.name}
                                                      <HotelRating
                                                            num={hotelInfo?.rt}
                                                      />
                                                </HotelFareName>
                                                <HotelFareInfoSummary
                                                      gap="2px"
                                                      iconFont="22px"
                                                >
                                                      <MapIcon />{" "}
                                                      {hotelInfo?.ad?.adr}
                                                      {hotelInfo?.ad?.state
                                                            ?.name &&
                                                            `, ${hotelInfo?.ad?.state?.name}`}
                                                      {hotelInfo?.ad?.city
                                                            ?.name &&
                                                            `, ${hotelInfo?.ad?.city?.name}`}
                                                </HotelFareInfoSummary>
                                          </HotelFareHeader>
                                          <HotelFareCheckContent marginTop="1.5rem">
                                                <HotelFareCheckInfo marginBottom="0rem">
                                                      <HotelFareCheck>
                                                            Check-in:{" "}
                                                            <span>
                                                                  {searchQuery?.checkinDate
                                                                        ? convertFullDate(
                                                                                searchQuery?.checkinDate
                                                                          )
                                                                        : "-"}
                                                            </span>
                                                      </HotelFareCheck>
                                                      <HotelFareCheck>
                                                            Check-out:{" "}
                                                            <span>
                                                                  {searchQuery?.checkoutDate
                                                                        ? convertFullDate(
                                                                                searchQuery?.checkoutDate
                                                                          )
                                                                        : "-"}
                                                            </span>{" "}
                                                      </HotelFareCheck>
                                                </HotelFareCheckInfo>
                                          </HotelFareCheckContent>
                                    </div>
                              </PriceSummary>
                        </>
                  )}
                  <PriceSummary>
                        <div className="price__box mb-4">
                              <div className="price__heading">
                                    <div className="price__title w-75">
                                          Fare Summary
                                    </div>
                                    <div className="price__people d-flex gap-3">
                                          <PriceTravellerItem>
                                                <MdBedroomChild />
                                                <div>
                                                      {
                                                            searchQuery
                                                                  ?.roomInfo
                                                                  ?.length
                                                      }
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <AdultIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {searchQuery?.roomInfo?.reduce(
                                                            (sum, room) =>
                                                                  sum +
                                                                  room.numberOfAdults,
                                                            0
                                                      )}
                                                </div>
                                          </PriceTravellerItem>
                                          <PriceTravellerItem>
                                                <ChildIcon color="rgba(33, 37, 41, 0.75)" />
                                                <div>
                                                      {searchQuery?.roomInfo?.reduce(
                                                            (sum, room) =>
                                                                  sum +
                                                                  room.numberOfChild,
                                                            0
                                                      )}
                                                </div>
                                          </PriceTravellerItem>
                                    </div>
                              </div>

                              {optionId === "travellers" && (
                                    <>
                                          <HotelPriceDetails
                                                priceInfo={priceInfo}
                                                border="0px"
                                                userCtx={userCtx}
                                                hotelInfo={hotelInfo}
                                          />
                                          <div className=" d-flex align-items-center mb-0">
                                                <div className="total__title  w-75">
                                                      Grand Total
                                                </div>
                                                <div className="total__price">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  totalAmount
                                                            )
                                                      )}
                                                </div>
                                          </div>
                                    </>
                              )}

                              {optionId === "review" && (
                                    <PriceSummaryContent
                                          title="Grand Total"
                                          grandTotalStatus={true}
                                          totalServicesAmount={convertAmount(
                                                convertFloat(totalAmount)
                                          )}
                                    >
                                          <HotelPriceDetails
                                                priceInfo={priceInfo}
                                                border="0px"
                                                userCtx={userCtx}
                                                hotelInfo={hotelInfo}
                                          />
                                    </PriceSummaryContent>
                              )}
                        </div>
                        <div className="support">
                              Hotel support : +91 8766601888
                        </div>
                  </PriceSummary>
                  {show && (
                        <PolicyModalView
                              optionId={
                                    hotelInfo?.ops ? hotelInfo?.ops[0]?.id : ""
                              }
                              show={show}
                              hotelId={hotelInfo?.id}
                              handleClose={handleClose}
                        />
                  )}
            </>
      );
};

export default HotelPriceSummary;
