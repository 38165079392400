import React, { useEffect, useState } from "react";
import FlightFooter from "../../components/Frontend/checkout/FlightFooter";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FlightDetails } from "../checkout/styles";
import ContactInfo from "../../components/Frontend/checkout/ContactInfo";
import HotelTravellerDetail from "./HotelTravellerDetail";

const HotelTravellers = ({
      marginBottom,
      searchQuery,
      priceInfo,
      hotelId,
      hOptionId,
      totalAmount,
}) => {
      console.log(priceInfo);
      const [loading, setLoading] = useState(false);
      const [loadingBack, setLoadingBack] = useState(false);
      const navigate = useNavigate();
      const previousSearch = `/hotel/${hotelId}`;

      const [validated, setValidated] = useState(false);
      const [isValid, setIsValid] = useState(true);

      const [travellers, setTravellers] = useState([]);

      const [contactDetails, setContactDetails] = useState({
            countryCode: "+91",
            emailAddress: "",
            phoneNumber: "",
      });

      const updateTravellers = () => {
            const newTravellers = searchQuery?.roomInfo?.map(
                  (room, roomIndex) => {
                        const roomTravellers = []; // Array to store travelers for this room

                        // Add adults
                        for (let i = 0; i < room?.numberOfAdults; i++) {
                              roomTravellers.push({
                                    title: "Mr", // Default title for adults, can be adjusted dynamically
                                    firstName: "",
                                    lastName: "",
                                    pt: "ADULT", // Passenger type
                                    pan: "",
                                    ptName: `ADULT-${i + 1}`,
                              });
                        }

                        // Add children
                        for (let j = 0; j < room?.numberOfChild; j++) {
                              roomTravellers.push({
                                    title: "Master/Miss", // Default title for children
                                    firstName: "",
                                    lastName: "",
                                    pt: "CHILD", // Passenger type
                                    pan: "",
                                    dateOfBirth: "",
                                    ptName: `CHILD-${j + 1}`,
                              });
                        }

                        return {
                              roomIndex: roomIndex + 1, // Optional: room index for reference
                              travellerInfo: roomTravellers, // List of travelers for this room
                        };
                  }
            );
            setTravellers(newTravellers);
      };

      useEffect(() => {
            updateTravellers();
      }, []);

      const handleInputChange = (
            index,
            event,
            type,
            data,
            inputField,
            roomId
      ) => {
            // Create a copy of the travellers state to update
            const updatedTravellers = [...travellers];

            // Check if the room and traveller index exist
            if (updatedTravellers[roomId]?.travellerInfo?.[index]) {
                  if (type === "SELECT") {
                        // Update for dropdown/select type input
                        updatedTravellers[roomId].travellerInfo[index] = {
                              ...updatedTravellers[roomId].travellerInfo[index],
                              [inputField]: data, // Set the inputField key with the data value
                        };
                  } else if (event?.target) {
                        // Update for regular input fields (like text, date, etc.)
                        const { name, value } = event.target;
                        updatedTravellers[roomId].travellerInfo[index] = {
                              ...updatedTravellers[roomId].travellerInfo[index],
                              [name]: value, // Set the field corresponding to the input's `name` attribute
                        };
                  }
            }

            // Update the state with the modified travellers
            setTravellers(updatedTravellers);
      };

      const handleTitle = (value, type, index, event, roomId) => {
            if (type === "title") {
                  handleInputChange(
                        index,
                        event,
                        "SELECT",
                        value,
                        type,
                        roomId
                  );
            }
            if (type === "country") {
                  handleInputChange(
                        index,
                        event,
                        "SELECT",
                        value,
                        type,
                        roomId
                  );
            }
            if (type === "nationality") {
                  handleInputChange(
                        index,
                        event,
                        "SELECT",
                        value,
                        type,
                        roomId
                  );
            }
      };

      const handleContactDetails = (event, type, data) => {
            if (type === "SELECT") {
                  setContactDetails((prevDetails) => ({
                        ...prevDetails,
                        ["countryCode"]: data,
                  }));
            } else {
                  const { name, value } = event.target;

                  setContactDetails((prevDetails) => ({
                        ...prevDetails,
                        [name]: value,
                  }));
            }
      };

      const handleBack = () => {
            setLoadingBack(true);
            setTimeout(() => {
                  setLoadingBack(false);
                  navigate(previousSearch);
            }, 3000);
      };

      const handleSubmit = (event) => {
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
            }

            setValidated(true);
            setLoading(true);

            event.preventDefault();

            localStorage.setItem(
                  "contactDetails",
                  JSON.stringify(contactDetails)
            );
            localStorage.setItem("hotelTotalAmount", totalAmount);
            localStorage.setItem("hOptionId", hOptionId);
            localStorage.setItem("hotelTravellers", JSON.stringify(travellers));

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/hotel/review/${hotelId}/${hOptionId}`);
            }, 3000);
      };

      useEffect(() => {
            if (hOptionId.toString() === localStorage.getItem("hOptionId")) {
                  if (localStorage.getItem("hotelTravellers")) {
                        setTravellers(
                              JSON.parse(
                                    localStorage.getItem("hotelTravellers")
                              )
                        );
                  }
                  if (localStorage.getItem("contactDetails")) {
                        setContactDetails(
                              JSON.parse(localStorage.getItem("contactDetails"))
                        );
                  }
            } else {
                  localStorage.removeItem("contactDetails");
                  localStorage.removeItem("hotelTravellers");
                  localStorage.removeItem("hotelTotalAmount");
            }
      }, []);

      return (
            <>
                  <Form method="post" onSubmit={handleSubmit}>
                        <HotelTravellerDetail
                              travellers={travellers}
                              handleInputChange={handleInputChange}
                              handleTitle={handleTitle}
                              searchQuery={searchQuery}
                              marginBottom={marginBottom}
                              priceInfo={priceInfo}
                        />
                        <FlightDetails style={{ marginBottom }}>
                              <ContactInfo
                                    contactDetails={contactDetails}
                                    isValid={isValid}
                                    setIsValid={setIsValid}
                                    handleContactDetails={handleContactDetails}
                              />
                        </FlightDetails>
                        <FlightFooter
                              loading={loading}
                              handleBack={handleBack}
                              loadingBack={loadingBack}
                        />
                  </Form>
            </>
      );
};

export default HotelTravellers;
