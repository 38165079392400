import React from "react";
import {
      BannerContainer,
      BannerContent,
      BannerContentForm,
      BannerImage,
      BannerSection,
      BannerWrapper,
} from "./styles";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

const BannerBox = ({ banners, children, hotel }) => {
      return (
            <>
                  <BannerSection>
                        <BannerContainer>
                              <Swiper>
                                    {banners.map((banner, index) => (
                                          <SwiperSlide
                                                key={index}
                                                className="position-relative"
                                          >
                                                <img
                                                      src={banner.image}
                                                      alt=""
                                                      className="banner__image"
                                                />
                                                <Container className="h-100">
                                                      <BannerWrapper>
                                                            <BannerContent>
                                                                  <h2
                                                                        className="banner__title"
                                                                        style={{
                                                                              color: "#fff",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: banner.title,
                                                                        }}
                                                                  ></h2>
                                                                  <p
                                                                        className="banner__title mb-4"
                                                                        style={{
                                                                              color: "#fff",
                                                                        }}
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: banner.subtitle,
                                                                        }}
                                                                  ></p>
                                                            </BannerContent>
                                                            <BannerImage>
                                                                  <div className="banner__image-container">
                                                                        <img
                                                                              src={
                                                                                    banner?.extraImage
                                                                              }
                                                                              alt=""
                                                                        />
                                                                  </div>
                                                            </BannerImage>
                                                      </BannerWrapper>
                                                </Container>
                                          </SwiperSlide>
                                    ))}
                              </Swiper>
                              <BannerContentForm hotel={hotel}>
                                    {children}
                              </BannerContentForm>
                        </BannerContainer>
                  </BannerSection>
            </>
      );
};

export default BannerBox;
