import React, { useEffect, useState } from "react";
import { FilterContainer } from "../dashboard/styles";
import HotelFilterSearch from "./HotelFilterSearch";
import HotelFilterRating from "./HotelFilterRating";
import HotelMealList from "./HotelMealList";
import { ratings } from "../../data/dashboard";
import HotelPropertyList from "./HotelPropertyList";

const normalizeMealBasis = (mb) => mb.toLowerCase().replace(/\s+/g, "");

const HotelFilter = ({ setFilteredHotels, hotels, loading }) => {
      const [propertyList, setPropertyList] = useState([]);
      const [mealBasis, setMealBasis] = useState([]);
      const [searchName, setSearchName] = useState("");
      const [ratingList, setRatingList] = useState(ratings?.items || []);

      const handleRatingClick = async (index) => {
            setRatingList((prevList) =>
                  prevList.map((rating, i) =>
                        i === index
                              ? { ...rating, checked: !rating.checked }
                              : rating
                  )
            );
      };

      const handlePropertyClick = async (index) => {
            setPropertyList((prevList) =>
                  prevList.map((property, i) =>
                        i === index
                              ? { ...property, checked: !property.checked }
                              : property
                  )
            );
      };

      const handleMealBasisClick = async (index) => {
            setMealBasis((prevList) =>
                  prevList.map((property, i) =>
                        i === index
                              ? { ...property, checked: !property.checked }
                              : property
                  )
            );
      };

      const calculateRatingCounts = (hotelsList) => {
            let stopCounts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

            hotelsList &&
                  hotelsList.forEach((hotel) => {
                        const totalStops = hotel.rt;
                        if (totalStops === 0) {
                              stopCounts[0]++;
                        } else if (totalStops === 1) {
                              stopCounts[1]++;
                        } else if (totalStops === 2) {
                              stopCounts[2]++;
                        } else if (totalStops === 3) {
                              stopCounts[3]++;
                        } else if (totalStops === 4) {
                              stopCounts[4]++;
                        } else if (totalStops === 5) {
                              stopCounts[5]++;
                        }
                  });

            return stopCounts;
      };

      const getUniquePropertyLists = (hotels) => {
            const propertyTypeMap = {};

            // Count the occurrences of each property type
            hotels.forEach((hotel) => {
                  if (hotel.pt) {
                        propertyTypeMap[hotel.pt] =
                              (propertyTypeMap[hotel.pt] || 0) + 1;
                  }
            });

            const propertyTypeArray = Object.keys(propertyTypeMap)
                  .map((pt) => ({
                        title: pt,
                        checked: false,
                        value: propertyTypeMap[pt], // Count of hotels for this property type
                  }))
                  .sort((a, b) => a.title.localeCompare(b.title));

            setPropertyList(propertyTypeArray);
      };

      const generateMealBasisList = (hotels) => {
            const mealBasisMap = {};

            // Collect meal basis data
            hotels.forEach((hotel) => {
                  if (hotel.ops) {
                        hotel.ops.forEach((op) => {
                              if (op.ris) {
                                    op.ris.forEach((ri) => {
                                          if (ri.mb) {
                                                const normalizedMB = ri.mb
                                                      .toLowerCase()
                                                      .replace(/\s+/g, ""); // Normalize the meal basis
                                                mealBasisMap[normalizedMB] =
                                                      (mealBasisMap[
                                                            normalizedMB
                                                      ] || 0) + 1;
                                          }
                                    });
                              }
                        });
                  }
            });

            // Convert to an array, capitalize, and sort
            const mealBasisArray = Object.keys(mealBasisMap)
                  .map((normalizedMB) => ({
                        title: normalizedMB
                              .replace(/([a-z])([A-Z])/g, "$1 $2") // Add spaces between camelCase
                              .split(" ") // Split into words
                              .map(
                                    (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1).toLowerCase()
                              ) // Capitalize each word
                              .join(" "), // Join back into a string
                        checked: false,
                        value: mealBasisMap[normalizedMB], // Count of hotels offering this meal basis
                  }))
                  .sort((a, b) => a.title.localeCompare(b.title)); // Sort alphabetically

            setMealBasis(mealBasisArray);
      };

      useEffect(() => {
            getUniquePropertyLists(hotels);
            generateMealBasisList(hotels);
      }, [hotels]);

      useEffect(() => {
            const filterBySearchName = (hotels, searchName) => {
                  if (!searchName) {
                        return hotels;
                  }

                  return hotels.filter((hotel) =>
                        hotel?.name
                              ?.toLowerCase()
                              .includes(searchName.toLowerCase())
                  );
            };

            let filteredHotels = hotels;
            if (searchName !== null || searchName !== "") {
                  filteredHotels = filterBySearchName(hotels, searchName);
            }

            const ratingCounts = calculateRatingCounts(filteredHotels);

            setRatingList((prevRating) => {
                  const updatedRating = prevRating.map((item) => ({
                        ...item,
                        value:
                              item.rate === 5
                                    ? ratingCounts[5]
                                    : item.rate === 4
                                    ? ratingCounts[4]
                                    : item.rate === 3
                                    ? ratingCounts[3]
                                    : item.rate === 2
                                    ? ratingCounts[2]
                                    : item.rate === 1
                                    ? ratingCounts[1]
                                    : item.rate === 0
                                    ? ratingCounts[0]
                                    : 0,
                  }));

                  return JSON.stringify(updatedRating) !==
                        JSON.stringify(prevRating)
                        ? updatedRating
                        : prevRating;
            });

            const checkedRatings = ratingList
                  .filter((item) => item.checked)
                  .map((item) => item.rate);

            if (checkedRatings.length > 0) {
                  filteredHotels = filteredHotels.filter((hotel) => {
                        const totalRatings = hotel.rt;
                        return checkedRatings.some((rateType) => {
                              if (rateType === totalRatings) {
                                    return true;
                              }
                        });
                  });
            }

            const checkedProperty = propertyList
                  .filter((item) => item.checked)
                  .map((item) => item.title);
            if (checkedProperty.length > 0) {
                  filteredHotels = filteredHotels.filter((hotel) => {
                        return checkedProperty.some((property) => {
                              if (property === hotel.pt) {
                                    return true;
                              }
                        });
                  });
            }

            const checkedMeals = mealBasis
                  .filter((item) => item.checked)
                  .map((item) => normalizeMealBasis(item.title));

            if (checkedMeals.length > 0) {
                  filteredHotels = filteredHotels.filter((hotel) => {
                        return hotel.ops.some((op) =>
                              op.ris.some((ri) =>
                                    checkedMeals.includes(
                                          normalizeMealBasis(ri.mb)
                                    )
                              )
                        );
                  });
            }

            setFilteredHotels(
                  !searchName &&
                        checkedRatings.length === 0 &&
                        checkedProperty.length === 0
                        ? hotels
                        : filteredHotels
            );
      }, [searchName, ratingList, hotels, propertyList, mealBasis]);

      return (
            <>
                  <FilterContainer>
                        <HotelFilterSearch setSearchName={setSearchName} />
                        <HotelFilterRating
                              ratings={ratings}
                              ratingList={ratingList}
                              handleClick={handleRatingClick}
                              loading={loading}
                        />
                        {/* <HotelUserRating /> */}
                        <HotelMealList
                              handleClick={handleMealBasisClick}
                              mealBasis={mealBasis}
                              loading={loading}
                        />
                        <HotelPropertyList
                              handleClick={handlePropertyClick}
                              propertyList={propertyList}
                              loading={loading}
                        />
                        {/* 
                        <DashboardButton>
                              <Button>See More</Button>
                        </DashboardButton> */}
                  </FilterContainer>
            </>
      );
};

export default HotelFilter;
