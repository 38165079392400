import React from "react";
import { Link, useParams } from "react-router-dom";
import { CheckoutBreadcrumb } from "../../components/Frontend/flight_reviews/CheckoutReview/style";
import { Breadcrumb } from "react-bootstrap";

const HotelCheckoutReview = ({ hotelId, hOptionId }) => {
      const { optionId } = useParams();

      return (
            <>
                  <CheckoutBreadcrumb>
                        <Breadcrumb.Item>
                              <Link to={`/hotel/${hotelId}`}>
                                    Hotel Details
                              </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                              active={optionId === "travellers" ? true : false}
                        >
                              {optionId === "travellers" ? (
                                    "Traveller Details"
                              ) : (
                                    <Link
                                          to={`/hotel/travellers/${hotelId}/${hOptionId}`}
                                    >
                                          Traveller Details
                                    </Link>
                              )}
                        </Breadcrumb.Item>
                        {optionId === "review" && (
                              <Breadcrumb.Item active>Review</Breadcrumb.Item>
                        )}
                  </CheckoutBreadcrumb>
            </>
      );
};

export default HotelCheckoutReview;
