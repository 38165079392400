import React, { useEffect, useRef, useState } from "react";
import { FormIcon, FormWrapper } from "../styles";
import { Form, Spinner } from "react-bootstrap";
import {
      AirlineContent,
      AirlineInfo,
      HotelLocation,
} from "../HotelInput/style";
import axios from "axios";
import CheckError from "../CheckError";
import { LuMapPin } from "react-icons/lu";

const HotelCheckInput = ({
      label,
      type,
      placeholder,
      width,
      padding,
      marginRight,
      backgroundNone,
      inputPadding,
      border,
      destination,
      setDestination,
      required,
      options,
      seletedValue,
      setDestinations,
      leavingFrom,
      borderRadius,
      fontWeight,
      fontSize,
}) => {
      const [status, setStatus] = useState(false);
      const [inputValue, setInputValue] = useState("");
      const token = JSON.parse(localStorage.getItem("token"));

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);
      const [errorStatus, setErrorStatus] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const handleDestination = (item) => {
            setStatus(false);
            setInputValue("");
            if (seletedValue) {
                  setDestination(item);
            } else {
                  setDestination(item);
            }
      };

      const handleTyped = async () => {
            if (inputValue.trim().length >= 3) {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel_destinations?search=${inputValue}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    setDestinations(response.data.destinations);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
      };

      useEffect(() => {
            handleTyped();
      }, [inputValue]);

      useEffect(() => {
            if (leavingFrom && destination) {
                  if (leavingFrom?.code === destination?.code) {
                        setErrorStatus(true);
                        setErrorMessage("Please select different to and from");
                  } else {
                        setErrorStatus(false);
                  }
            } else {
                  setErrorStatus(false);
            }
      }, [destination]);

      return (
            <>
                  <FormWrapper
                        width={width}
                        padding={padding}
                        marginRight={marginRight}
                        backgroundNone={backgroundNone}
                        inputPadding={inputPadding}
                        border={border}
                        borderRadius={borderRadius}
                        fontSize={fontSize}
                        fontWeight={fontWeight}
                  >
                        <Form.Group
                              onClick={() => setStatus(true)}
                              ref={formGroupRef}
                        >
                              {label && <Form.Label>{label}</Form.Label>}
                              {!errorStatus ? (
                                    <Form.Control
                                          type={type}
                                          onInput={(event) =>
                                                setInputValue(
                                                      event.target.value
                                                )
                                          }
                                          onClick={() => setDestination("")}
                                          value={
                                                (inputValue && inputValue) ||
                                                (destination?.fullRegionName
                                                      ? `${destination?.fullRegionName}`
                                                      : "")
                                          }
                                          placeholder={placeholder}
                                          required={required}
                                    />
                              ) : (
                                    <CheckError
                                          errorStatus={errorStatus}
                                          errorMessage={errorMessage}
                                    >
                                          <Form.Control
                                                type={type}
                                                onInput={(event) =>
                                                      setInputValue(
                                                            event.target.value
                                                      )
                                                }
                                                onClick={() =>
                                                      setDestination("")
                                                }
                                                value={
                                                      (inputValue != "" &&
                                                            inputValue) ||
                                                      (destination?.fullRegionName
                                                            ? `${destination?.fullRegionName}`
                                                            : "")
                                                }
                                                placeholder={placeholder}
                                                required={required}
                                          />
                                    </CheckError>
                              )}
                        </Form.Group>
                        {!(type === "icon") && (
                              <FormIcon>
                                    <LuMapPin />
                              </FormIcon>
                        )}
                        {status && (
                              <HotelLocation ref={locationRef}>
                                    <ul>
                                          {options?.length > 0 ? (
                                                options.map((item, index) => (
                                                      <li
                                                            onClick={() =>
                                                                  handleDestination(
                                                                        item
                                                                  )
                                                            }
                                                            key={index}
                                                      >
                                                            <AirlineContent>
                                                                  <AirlineInfo>
                                                                        <div className="airline__destination">
                                                                              {
                                                                                    item.fullRegionName
                                                                              }
                                                                        </div>
                                                                  </AirlineInfo>
                                                            </AirlineContent>
                                                            <div className="airline__name text-muted">
                                                                  {item.name}
                                                            </div>
                                                      </li>
                                                ))
                                          ) : (
                                                <li>
                                                      Typing... 
                                                </li>
                                          )}
                                    </ul>
                              </HotelLocation>
                        )}
                  </FormWrapper>
            </>
      );
};

export default HotelCheckInput;
