import React from "react";
import { HotelHeading } from "../../hotels/styles";
import { RoomContainer } from "../../hotels/HotelRoom/style";
import { FaCheck } from "react-icons/fa6";
import { HotelAmenityList } from "./style";
import HotelSummaryList from "../../../components/Frontend/hotel/HotelSummary/HotelSummaryList";

const HotelAmenities = ({ facilities, facilitySection, loading }) => {
      const uniqueFacilities = [...new Set(facilities)];

      return (
            <>
                  {facilities && (
                        <>
                              <HotelHeading
                                    ref={facilitySection}
                                    className="mt-4 pt-2"
                              >
                                    Hotel Facilities
                              </HotelHeading>
                              <RoomContainer>
                                    <HotelAmenityList>
                                          <ul className="row ps-0 mb-0">
                                                {!loading ? (
                                                      uniqueFacilities?.map(
                                                            (item, index) =>
                                                                  item !==
                                                                        "" && (
                                                                        <li
                                                                              className="col-lg-4"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <FaCheck />{" "}
                                                                              <span>
                                                                                    {
                                                                                          item
                                                                                    }
                                                                              </span>
                                                                        </li>
                                                                  )
                                                      )
                                                ) : (
                                                      <HotelSummaryList
                                                            classes={"col-lg-4"}
                                                      />
                                                )}
                                          </ul>
                                    </HotelAmenityList>
                              </RoomContainer>
                        </>
                  )}
            </>
      );
};

export default HotelAmenities;
