import React, { useState } from "react";
import {
      RoundtripBookContainer,
      RoundtripInfoContent,
      RoundtripInfoLogo,
      RoundtripNetPrice,
} from "./style";
import { Container, Form } from "react-bootstrap";
import {
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "../../../Dashboard/FlightInfo/styles";
import CommonButton from "../../../Common/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
      convertAmount,
      convertFloat,
      calculateTotalAmount,
      calculateTotalNetAmount,
} from "../../../../hooks/CurrentData";
import CheckError from "../../../form/CheckError";

const RoundtripBook = ({
      returnFare,
      fareId,
      flights,
      returnFlights,
      searchDetail,
      netFare,
      domesticStatus,
      fullFare,
      commission,
}) => {
      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;
      const [errorStatus, setErrorStatus] = useState(false);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const flightDetail = flights.find((item) =>
            item?.totalPriceList?.find(
                  (currentFare) => currentFare?.id === fareId?.id
            )
      );
      const returnDetail = returnFlights.find((item) =>
            item?.totalPriceList?.find(
                  (currentFare) => currentFare?.id === returnFare?.id
            )
      );
      let airlineCode = "";
      let returnAirlineCode = "";

      if (flightDetail) {
            airlineCode = flightDetail?.sI[0]?.fD?.aI?.code;
      }
      if (returnDetail) {
            returnAirlineCode = returnDetail?.sI[0]?.fD?.aI?.code;
      }

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const grandTotalAmount = () => {
            let grandTotal = 0;

            grandTotal =
                  calculateTotalAmount({
                        item: fareId,
                        searchDetail,
                        commission,
                        fullFare,
                        domesticStatus,
                        airlineCode,
                  }) +
                  calculateTotalAmount({
                        item: returnFare,
                        searchDetail,
                        commission,
                        fullFare,
                        domesticStatus,
                        airlineCode,
                  });

            return grandTotal;
      };

      const grandNetTotalAmount = () => {
            let grandTotal = 0;

            grandTotal =
                  calculateTotalNetAmount({
                        item: fareId,
                        searchDetail,
                        commission,
                        fullFare,
                        domesticStatus,
                        airlineCode,
                  }) +
                  calculateTotalNetAmount({
                        item: returnFare,
                        searchDetail,
                        commission,
                        fullFare,
                        domesticStatus,
                        returnAirlineCode,
                  });

            return grandTotal;
      };

      const checkTimeDifference = (at, dt) => {
            // Parse the date-time strings into Date objects
            const arrivalTime = new Date(at);
            const departureTime = new Date(dt);

            // Calculate the difference in milliseconds
            const differenceInMillis = departureTime - arrivalTime;

            // Convert milliseconds to hours
            const differenceInHours = differenceInMillis / (1000 * 60 * 60); // 1000 ms * 60 sec * 60 min = 1 hour

            // Check if the difference is greater than or equal to 2 hours
            return differenceInHours >= 2;
      };

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            setErrorStatus(false);
            if (
                  checkTimeDifference(
                        flightDetail?.sI[flightDetail?.sI?.length - 1]?.at,
                        returnDetail?.sI[0]?.dt
                  )
            ) {
                  const optionId = `${fareId?.id},${returnFare?.id}`;

                  localStorage.setItem("previousSearch", previousLink);
                  localStorage.setItem("reviewId", optionId);

                  localStorage.removeItem("contactDetails");
                  localStorage.removeItem("gstDetails");
                  localStorage.removeItem("travellers");
                  localStorage.removeItem("services");

                  setTimeout(() => {
                        setLoading(false);
                        navigate(`/flight/travellers/${optionId}`);
                  }, 3000);
            } else {
                  setErrorStatus(true);
                  setLoading(false);
            }
      };

      return (
            <>
                  <RoundtripBookContainer>
                        <Container>
                              <Form onSubmit={handleSubmit}>
                                    <div
                                          className="d-flex justify-content-between"
                                          style={{ gap: "20px" }}
                                    >
                                          <div className="d-flex gap-4">
                                                {flightDetail && (
                                                      <>
                                                            <RoundtripInfoContent>
                                                                  <FlightInfoTitle className="gap-2">
                                                                        <RoundtripInfoLogo>
                                                                              <img
                                                                                    src={`/images/AirlinesLogo/${flightDetail?.sI[0]?.fD?.aI?.code}.png`}
                                                                                    alt={
                                                                                          flightDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.name
                                                                                    }
                                                                              />
                                                                        </RoundtripInfoLogo>
                                                                        <div className="title">
                                                                              <h4 className="pb-0 mb-0">
                                                                                    {
                                                                                          flightDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.name
                                                                                    }
                                                                              </h4>
                                                                              <div
                                                                                    style={{
                                                                                          fontSize: "11px",
                                                                                          color: "#666",
                                                                                    }}
                                                                              >
                                                                                    {
                                                                                          flightDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.code
                                                                                    }{" "}
                                                                                    {
                                                                                          flightDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.fN
                                                                                    }
                                                                              </div>
                                                                        </div>
                                                                  </FlightInfoTitle>
                                                                  <FlightInfoTime>
                                                                        <FlightInfoTime>
                                                                              {formatTime(
                                                                                    flightDetail
                                                                                          ?.sI[0]
                                                                                          ?.dt
                                                                              )}{" "}
                                                                              -{" "}
                                                                              {formatTime(
                                                                                    arrivalTime(
                                                                                          flightDetail?.sI
                                                                                    )
                                                                                          ?.at
                                                                              )}
                                                                        </FlightInfoTime>

                                                                        <div
                                                                              className="place d-flex gap-1 align-items-center"
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                        >
                                                                              {
                                                                                    searchDetail?.leavingFrom
                                                                              }
                                                                              <span
                                                                                    className="material-symbols-outlined"
                                                                                    style={{
                                                                                          fontSize: "15px",
                                                                                    }}
                                                                              >
                                                                                    east
                                                                              </span>
                                                                              {
                                                                                    searchDetail?.destination
                                                                              }
                                                                        </div>
                                                                  </FlightInfoTime>
                                                                  <FlightInfoPrice>
                                                                        <div className="flight__info-price ">
                                                                              <span>
                                                                                    {`₹ ${convertAmount(
                                                                                          convertFloat(
                                                                                                calculateTotalAmount(
                                                                                                      {
                                                                                                            item: fareId,
                                                                                                            searchDetail,
                                                                                                            commission,
                                                                                                            fullFare,
                                                                                                            domesticStatus,
                                                                                                            airlineCode,
                                                                                                      }
                                                                                                )
                                                                                          )
                                                                                    )}`}
                                                                              </span>
                                                                              {netFare && (
                                                                                    <span className="flight__info-net text-muted">
                                                                                          {` | NET ₹ ${convertAmount(
                                                                                                convertFloat(
                                                                                                      calculateTotalNetAmount(
                                                                                                            {
                                                                                                                  item: fareId,
                                                                                                                  searchDetail,
                                                                                                                  commission,
                                                                                                                  fullFare,
                                                                                                                  domesticStatus,
                                                                                                                  airlineCode,
                                                                                                            }
                                                                                                      )
                                                                                                )
                                                                                          )}`}
                                                                                    </span>
                                                                              )}
                                                                        </div>
                                                                        {fareId
                                                                              .fd
                                                                              ?.ADULT
                                                                              ?.sR <=
                                                                              5 && (
                                                                              <div
                                                                                    style={{
                                                                                          fontSize: "11px",
                                                                                          color: "#666",
                                                                                    }}
                                                                                    className="text-danger"
                                                                              >
                                                                                    {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                                              </div>
                                                                        )}
                                                                  </FlightInfoPrice>
                                                            </RoundtripInfoContent>
                                                      </>
                                                )}
                                                {returnDetail && (
                                                      <>
                                                            <RoundtripInfoContent>
                                                                  <FlightInfoTitle className="gap-2">
                                                                        <RoundtripInfoLogo>
                                                                              <img
                                                                                    src={`/images/AirlinesLogo/${returnDetail?.sI[0]?.fD?.aI?.code}.png`}
                                                                                    alt={
                                                                                          returnDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.name
                                                                                    }
                                                                              />
                                                                        </RoundtripInfoLogo>
                                                                        <div className="title">
                                                                              <h4 className="pb-0 mb-0">
                                                                                    {
                                                                                          returnDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.name
                                                                                    }
                                                                              </h4>
                                                                              <div
                                                                                    style={{
                                                                                          fontSize: "11px",
                                                                                          color: "#666",
                                                                                    }}
                                                                              >
                                                                                    {
                                                                                          returnDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.aI
                                                                                                ?.code
                                                                                    }{" "}
                                                                                    {
                                                                                          returnDetail
                                                                                                ?.sI[0]
                                                                                                ?.fD
                                                                                                ?.fN
                                                                                    }
                                                                              </div>
                                                                        </div>
                                                                  </FlightInfoTitle>
                                                                  <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                        <FlightInfoTime className="d-flex flex-column justify-content-center">
                                                                              {formatTime(
                                                                                    returnDetail
                                                                                          ?.sI[0]
                                                                                          ?.dt
                                                                              )}{" "}
                                                                              -{" "}
                                                                              {formatTime(
                                                                                    arrivalTime(
                                                                                          returnDetail?.sI
                                                                                    )
                                                                                          ?.at
                                                                              )}
                                                                        </FlightInfoTime>

                                                                        <div
                                                                              className="place d-flex gap-1 align-items-center"
                                                                              style={{
                                                                                    fontSize: "11px",
                                                                                    color: "#666",
                                                                              }}
                                                                        >
                                                                              {
                                                                                    searchDetail?.destination
                                                                              }
                                                                              <span
                                                                                    className="material-symbols-outlined"
                                                                                    style={{
                                                                                          fontSize: "15px",
                                                                                    }}
                                                                              >
                                                                                    east
                                                                              </span>
                                                                              {
                                                                                    searchDetail?.leavingFrom
                                                                              }
                                                                        </div>
                                                                  </FlightInfoTime>
                                                                  <FlightInfoPrice>
                                                                        <div className="flight__info-price ">
                                                                              <span>
                                                                                    {`₹ ${convertAmount(
                                                                                          convertFloat(
                                                                                                calculateTotalAmount(
                                                                                                      {
                                                                                                            item: returnFare,
                                                                                                            searchDetail,
                                                                                                            commission,
                                                                                                            fullFare,
                                                                                                            domesticStatus,
                                                                                                            airlineCode
                                                                                                      }
                                                                                                )
                                                                                          )
                                                                                    )}`}
                                                                              </span>
                                                                              {netFare && (
                                                                                    <span className="flight__info-net text-muted">
                                                                                          {` | NET ₹ ${convertAmount(
                                                                                                convertFloat(
                                                                                                      calculateTotalNetAmount(
                                                                                                            {
                                                                                                                  item: returnFare,
                                                                                                                  searchDetail,
                                                                                                                  commission,
                                                                                                                  fullFare,
                                                                                                                  domesticStatus,
                                                                                                                  returnAirlineCode,
                                                                                                            }
                                                                                                      )
                                                                                                )
                                                                                          )}`}
                                                                                    </span>
                                                                              )}
                                                                        </div>
                                                                        {returnFare
                                                                              ?.fd
                                                                              ?.ADULT
                                                                              ?.sR <=
                                                                              5 && (
                                                                              <div
                                                                                    style={{
                                                                                          fontSize: "11px",
                                                                                          color: "#666",
                                                                                    }}
                                                                                    className="text-danger"
                                                                              >
                                                                                    {`${returnFare?.fd?.ADULT?.sR} Seats left`}
                                                                              </div>
                                                                        )}
                                                                  </FlightInfoPrice>
                                                            </RoundtripInfoContent>
                                                      </>
                                                )}
                                                <div>
                                                      <RoundtripNetPrice fontSize="16px">
                                                            <div>
                                                                  ₹{" "}
                                                                  {convertAmount(
                                                                        convertFloat(
                                                                              grandTotalAmount()
                                                                        )
                                                                  )}
                                                            </div>
                                                      </RoundtripNetPrice>
                                                      <RoundtripNetPrice className="text-muted ">
                                                            NET ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        grandNetTotalAmount()
                                                                  )
                                                            )}
                                                      </RoundtripNetPrice>
                                                </div>
                                          </div>
                                          <CheckError
                                                errorStatus={errorStatus}
                                                placement={true}
                                                errorMessage="The time difference should be 2 hours"
                                          >
                                                <CommonButton
                                                      type="submit"
                                                      button={true}
                                                      justifyContent="end"
                                                      title="Book Now"
                                                      loading={loading}
                                                      classes="ms-auto"
                                                />
                                          </CheckError>
                                    </div>
                              </Form>
                        </Container>
                  </RoundtripBookContainer>
            </>
      );
};

export default RoundtripBook;
