import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { FormIcon, FormWrapper } from "../../../components/form/styles";
import { LuMapPin } from "react-icons/lu";
import {
      AirlineContent,
      AirlineInfo,
      HotelLocation,
} from "../../../components/form/HotelInput/style";

const HotelModalDestination = ({
      modifySearch,
      handleChange,
      padding,
      selectedValue,
}) => {
      const [destinations, setDestinations] = useState([]);
      const token = JSON.parse(localStorage.getItem("token"));

      const [status, setStatus] = useState(false);
      const [inputValue, setInputValue] = useState("");

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const handleDestination = (item) => {
            setStatus(false);
            setInputValue("");
            if (selectedValue) {
                  handleChange(item, "destination");
            } else {
                  handleChange(item, "destination");
            }
      };

      const handleTyped = async () => {
            if (inputValue.trim().length >= 3) {
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel_destinations?search=${inputValue}`,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    setDestinations(response.data.destinations);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
      };



      useEffect(() => {
            handleTyped();
      }, [inputValue]);

      const inputRef = useRef(null);

      const handleClick = () => {
            if (inputRef.current) {
                  inputRef.current.select();
            }
      };

      return (
            <>
                  <FormWrapper
                        width="30%"
                        inputPadding={padding}
                        borderRadius="5px"
                        fontSize="16px"
                        fontWeight="600"
                        backgroundColor="white"
                        labelFontSize="16px"
                        labelFontWeight="500"
                  >
                        <Form.Group
                              onClick={() => setStatus(true)}
                              ref={formGroupRef}
                        >
                              <Form.Label>Enter City or Hotel</Form.Label>
                              <Form.Control
                                    type="text"
                                    ref={inputRef}
                                    onInput={(event) =>
                                          setInputValue(event.target.value)
                                    }
                                    onClick={handleClick}
                                    value={
                                          (inputValue && inputValue) ||
                                          (modifySearch?.destination
                                                ?.fullRegionName
                                                ? `${modifySearch?.destination?.fullRegionName}`
                                                : "")
                                    }
                                    required={true}
                              />
                        </Form.Group>
                        <FormIcon backgroundColor="white">
                              <LuMapPin />
                        </FormIcon>
                        {status && destinations?.length > 0 && (
                              <HotelLocation ref={locationRef}>
                                    <ul>
                                          {destinations?.length > 0 &&
                                                destinations.map(
                                                      (item, index) => (
                                                            <li
                                                                  onClick={() =>
                                                                        handleDestination(
                                                                              item
                                                                        )
                                                                  }
                                                                  key={index}
                                                            >
                                                                  <AirlineContent>
                                                                        <AirlineInfo>
                                                                              <div className="airline__destination">
                                                                                    {
                                                                                          item.fullRegionName
                                                                                    }
                                                                              </div>
                                                                        </AirlineInfo>
                                                                  </AirlineContent>
                                                                  <div className="airline__name text-muted">
                                                                        {
                                                                              item.name
                                                                        }
                                                                  </div>
                                                            </li>
                                                      )
                                                )}
                                    </ul>
                              </HotelLocation>
                        )}
                  </FormWrapper>
            </>
      );
};

export default HotelModalDestination;
