import React from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { HotelIcon } from "../../icons";
import HotelTable from "./HotelTable";

const HotelBooking = () => {
      return (
            <>
                  <DashboardLayout title="Search Hotel Bookings">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <HotelIcon />
                                          </MenuIcon>
                                          Search Your Bookings
                                    </DashboardCardTitle>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="20px">
                                    <HotelTable />
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default HotelBooking;
