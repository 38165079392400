import React from "react";
import {
      FlightItemItem,
      FlightItemValue,
} from "../Frontend/flight/FlightItem/style";
import { Skeleton } from "@mui/material";

const ItemSkeletonList = () => {
      return (
            <>
                  <FlightItemItem>
                        <FlightItemValue>
                              <Skeleton
                                    height="18px"
                                    width="160px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </FlightItemValue>
                  </FlightItemItem>
                  <FlightItemItem>
                        <FlightItemValue>
                              <Skeleton
                                    height="18px"
                                    width="160px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </FlightItemValue>
                  </FlightItemItem>
                  <FlightItemItem>
                        <FlightItemValue>
                              <Skeleton
                                    height="18px"
                                    width="160px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </FlightItemValue>
                  </FlightItemItem>
                  <FlightItemItem>
                        <FlightItemValue>
                              <Skeleton
                                    height="18px"
                                    width="160px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </FlightItemValue>
                  </FlightItemItem>
                  <FlightItemItem>
                        <FlightItemValue>
                              <Skeleton
                                    height="18px"
                                    width="160px"
                                    style={{
                                          transform: "scale(1)",
                                    }}
                              />
                        </FlightItemValue>
                  </FlightItemItem>
            </>
      );
};

export default ItemSkeletonList;
