import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
      AdultChildSection,
      AdultChildTitle,
      AdultSection,
      ChildSection,
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
} from "./styles";
import { FormInfo, FormWrapper } from "../../Frontend/home/TravelerBox/styles";

const HotelTraveler = ({ label, rooms, setRooms }) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();

      const handleClick = () => setShow(true);

      const handleOutsideClick = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutsideClick);
            return () =>
                  document.removeEventListener("click", handleOutsideClick);
      }, []);

      const incrementRoom = () => {
            if (rooms.length < 5) {
                  // Create a shallow copy of the rooms array
                  const updatedRooms = [...rooms];

                  // Get the last room in the array
                  const lastRoom = updatedRooms[updatedRooms.length - 1];

                  // Check if the last room has no child
                  if (lastRoom?.numberOfChild === 0) {
                        lastRoom.numberOfChild = 1;
                        lastRoom.childAge = [5]; // Default child age, can be adjusted as needed
                  }

                  // Add a new room with default values
                  updatedRooms.push({
                        numberOfAdults: 2,
                        numberOfChild: 0,
                        childAge: [],
                  });

                  // Update the state with the modified rooms array
                  setRooms(updatedRooms);
            }
      };

      const decrementRoom = () => {
            if (rooms.length > 1) {
                  const updatedRooms = [...rooms];
                  updatedRooms.pop();
                  setRooms(updatedRooms);
            }
      };

      const handleIncrement = (roomIndex, type) => {
            const updatedRooms = [...rooms];
            if (
                  type === "adult" &&
                  updatedRooms[roomIndex].numberOfAdults < 9
            ) {
                  updatedRooms[roomIndex].numberOfAdults += 1;
            } else if (
                  type === "children" &&
                  updatedRooms[roomIndex].numberOfChild < 3
            ) {
                  updatedRooms[roomIndex].numberOfChild += 1;
                  updatedRooms[roomIndex].childAge.push("");
            }
            setRooms(updatedRooms);
      };

      const handleDecrement = (roomIndex, type) => {
            const updatedRooms = [...rooms];
            if (
                  type === "adult" &&
                  updatedRooms[roomIndex].numberOfAdults > 1
            ) {
                  updatedRooms[roomIndex].numberOfAdults -= 1;
            } else if (
                  type === "children" &&
                  updatedRooms[roomIndex].numberOfChild > 0
            ) {
                  updatedRooms[roomIndex].numberOfChild -= 1;
                  updatedRooms[roomIndex].childAge.pop();
            }
            setRooms(updatedRooms);
      };

      const handleChildAgeChange = (roomIndex, childIndex, value) => {
            const updatedRooms = [...rooms];
            updatedRooms[roomIndex].childAge[childIndex] = value;
            setRooms(updatedRooms);
      };

      return (
            <FormWrapper onClick={handleClick} ref={divRef}>
                  {label && <Form.Label>{label}</Form.Label>}
                  <FormInfo>
                        <span style={{ fontSize: "13px" }}>
                              {rooms.length} rooms,{" "}
                              {rooms.reduce(
                                    (sum, room) => sum + room.numberOfAdults,
                                    0
                              )}{" "}
                              adults,{" "}
                              {rooms.reduce(
                                    (sum, room) => sum + room.numberOfChild,
                                    0
                              )}{" "}
                              children
                        </span>
                  </FormInfo>
                  <FormAdditional className={show ? "show" : ""}>
                        <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                              <FormAdditionalTitle>Rooms</FormAdditionalTitle>
                              <FormAdditionalCounter>
                                    <FormAdditionalCounterIcon
                                          onClick={decrementRoom}
                                          className="align-items-center justify-content-center substract"
                                    >
                                          <span>-</span>
                                    </FormAdditionalCounterIcon>
                                    <FormAdditionalCounterInput>
                                          <input
                                                type="number"
                                                value={rooms.length}
                                                readOnly
                                          />
                                    </FormAdditionalCounterInput>
                                    <FormAdditionalCounterIcon
                                          onClick={incrementRoom}
                                          className="align-items-center justify-content-center add"
                                    >
                                          <span>+</span>
                                    </FormAdditionalCounterIcon>
                              </FormAdditionalCounter>
                        </FormAdditionalHeader>

                        {rooms.map((room, roomIndex) => (
                              <div key={roomIndex} className="px-3 py-2">
                                    <AdultChildSection className="d-flex align-items-center justify-content-between">
                                          <AdultSection>
                                                <AdultChildTitle>
                                                      Adults (12+)
                                                </AdultChildTitle>
                                                <FormAdditionalCounter>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  handleDecrement(
                                                                        roomIndex,
                                                                        "adult"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center substract"
                                                      >
                                                            <span>-</span>
                                                      </FormAdditionalCounterIcon>
                                                      <FormAdditionalCounterInput>
                                                            <input
                                                                  type="number"
                                                                  value={
                                                                        room.numberOfAdults
                                                                  }
                                                                  readOnly
                                                            />
                                                      </FormAdditionalCounterInput>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  handleIncrement(
                                                                        roomIndex,
                                                                        "adult"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center add"
                                                      >
                                                            <span>+</span>
                                                      </FormAdditionalCounterIcon>
                                                </FormAdditionalCounter>
                                          </AdultSection>
                                          <ChildSection>
                                                <AdultChildTitle>
                                                      Children
                                                </AdultChildTitle>
                                                <FormAdditionalCounter>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  handleDecrement(
                                                                        roomIndex,
                                                                        "children"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center substract"
                                                      >
                                                            <span>-</span>
                                                      </FormAdditionalCounterIcon>
                                                      <FormAdditionalCounterInput>
                                                            <input
                                                                  type="number"
                                                                  value={
                                                                        room.numberOfChild
                                                                  }
                                                                  readOnly
                                                            />
                                                      </FormAdditionalCounterInput>
                                                      <FormAdditionalCounterIcon
                                                            onClick={() =>
                                                                  handleIncrement(
                                                                        roomIndex,
                                                                        "children"
                                                                  )
                                                            }
                                                            className="align-items-center justify-content-center add"
                                                      >
                                                            <span>+</span>
                                                      </FormAdditionalCounterIcon>
                                                </FormAdditionalCounter>
                                          </ChildSection>
                                    </AdultChildSection>

                                    {room.numberOfChild > 0 && (
                                          <Row className="mt-3">
                                                {room.childAge.map(
                                                      (age, childIndex) => (
                                                            <Col
                                                                  lg={4}
                                                                  key={
                                                                        childIndex
                                                                  }
                                                            >
                                                                  <Form.Select
                                                                        value={
                                                                              age
                                                                        }
                                                                        className="p-1"
                                                                        style={{
                                                                              fontSize: "12px",
                                                                        }}
                                                                        onChange={(
                                                                              e
                                                                        ) =>
                                                                              handleChildAgeChange(
                                                                                    roomIndex,
                                                                                    childIndex,
                                                                                    e
                                                                                          .target
                                                                                          .value
                                                                              )
                                                                        }
                                                                  >
                                                                        <option value="">
                                                                              Child
                                                                              Age
                                                                        </option>
                                                                        {[
                                                                              ...Array(
                                                                                    12
                                                                              ),
                                                                        ].map(
                                                                              (
                                                                                    _,
                                                                                    i
                                                                              ) => (
                                                                                    <option
                                                                                          key={
                                                                                                i
                                                                                          }
                                                                                          value={`${i}`}
                                                                                    >
                                                                                          {i <
                                                                                          2
                                                                                                ? "<"
                                                                                                : ""}
                                                                                          {
                                                                                                i
                                                                                          }{" "}
                                                                                          yrs
                                                                                    </option>
                                                                              )
                                                                        )}
                                                                  </Form.Select>
                                                            </Col>
                                                      )
                                                )}
                                          </Row>
                                    )}
                              </div>
                        ))}
                  </FormAdditional>
            </FormWrapper>
      );
};

export default HotelTraveler;
