import { Container } from "react-bootstrap";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

export const BannerSection = styled.div`
      position: relative;
      z-index: 0;
`;

export const BannerContainer = styled.div`
      max-width: 100%;
      height: 100vh;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      /* border-radius: 0 0 80px 80px; */
      position: relative;
      /* padding: 14rem 0; */
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
            rgba(17, 17, 26, 0.1) 0px 8px 24px,
            rgba(17, 17, 26, 0.1) 0px 16px 56px;
      .swiper {
            height: 100%;
      }

      .banner__image {
            width: 100%;
            height: 100%;
      }
`;
export const BannerWrapper = styled.div`
      display: flex;
      gap: 5rem;
      align-items: center;
      position: absolute;
      top: -10%;
      width: 80%;
      z-index: 99999;
      left: 10%;
      right: 10%;

      @media screen and (max-width: 1400px) {
            bottom: 4%;
      }
`;

export const BannerImage = styled.div`
      width: 50%;

      .banner__image-container {
            height: ${(props) => (props.hotel ? "600px" : "620px")};
            display: flex;
            align-items: center;
            justify-content: end;
            margin-right: -120px;
            margin-top: 80px;

            img {
                  height: 89%;
                  width: 89%;
                  object-fit: contain;
            }
      }

      

      @media screen and (min-height: 722px) {
            .banner__image-container {
                  height: 750px;

                  img {
                        height: 100%;
                        width: 100%;
                  }
            }
      }
`;

export const BannerContentForm = styled(Container)`
      position: absolute;
      bottom: ${(props) => (props.hotel ? "6vh" : "-5vh")};
      z-index: 999;
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      width: ${(props) => (props.hotel ? "80%" : "100%")};
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-height: 722px) {
            bottom: ${(props) => (props.hotel ? "2vh" : "-10vh")};
      }
`;

export const BannerContent = styled.div`
      width: 50%;
      margin-bottom: 20px;

      h2 {
            font-size: 42px;
            color: ${(props) => props.theme.white};
            font-weight: 600;
      }

      p {
            font-size: 20px;
            font-weight: 600;
      }
`;
