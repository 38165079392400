import styled from "styled-components";

export const HotelRatingContainer = styled.div`
      display: flex;
      align-items: center;

      svg {
            color: #ffa918;
      }
`;
