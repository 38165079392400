import styled from "styled-components";

export const HotelSearchContainer = styled.div`
      display: flex;
      align-items: center;
      border: 1px solid #dfe0e4;
      border-radius: 5rem;
      padding: 2.5px 10px;

      svg {
            height: 18px;
            width: 18px;
            fill: #22222299;
      }

      .form-control {
            width: calc(100% - 24px);
            font-size: 14px;
            border: 0;
            background-color: transparent;
            padding: 5px;

            &:focus,
            &:focus-visible {
                  box-shadow: none;
                  border: 0;
            }
      }
`;

export const CancellationContainer = styled.div`
      border: 1px solid #dfe0e4;
      border-radius: 5rem;
      padding: 8px 10px;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &.active {
            background-color: ${(props) => props.theme.primary};
            color: ${(props) => props.theme.white};
      }
`;
