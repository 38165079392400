import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { CheckoutBreadcrumb } from "./style";
import { Link, useNavigate, useParams } from "react-router-dom";

const CheckoutReview = ({ reviewId }) => {
      const { optionId } = useParams();

      const previewPage = localStorage.getItem("previousSearch");

      return (
            <>
                  <CheckoutBreadcrumb>
                        <Breadcrumb.Item>
                              <Link to={previewPage}>Flight Search</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                              active={optionId === "travellers" ? true : false}
                        >
                              {optionId === "travellers" ? (
                                    "Traveller Details"
                              ) : (
                                    <Link to={`/flight/travellers/${reviewId}`}>
                                          Traveller Details
                                    </Link>
                              )}
                        </Breadcrumb.Item>
                        {optionId === "review" && (
                              <Breadcrumb.Item active>Review</Breadcrumb.Item>
                        )}
                  </CheckoutBreadcrumb>
            </>
      );
};

export default CheckoutReview;
