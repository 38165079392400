import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CheckoutContainer } from "../checkout/styles";
import { DashboardContainer } from "../dashboard/styles";
import axios from "axios";
import HotelCheckoutReview from "./HotelCheckoutReview";
import { Col, Row, Spinner } from "react-bootstrap";
import { FlightReviewContent } from "../flight_reviews/style";
import CountdownTimer from "./HotelCountdown";
import HotelPriceSummary from "./HotelPriceSummary";
import HotelReviewOptions from "./HotelReviewOptions";
import UserContext from "../../context/UserContext";
import { handleHotelCommission } from "../../hooks/hotelCommission";

const HotelReviewPage = () => {
      const { hotelId } = useParams();
      const { hOptionId } = useParams();
      const { optionId } = useParams();
      const userCtx = useContext(UserContext);

      const [statusShow, setStatusShow] = useState(false);
      const [loading, setLoading] = useState(false);
      const [conditions, setConditions] = useState([]);
      const [bookingId, setBookingId] = useState("");
      const [hotelInfo, setHotelInfo] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);
      const [priceInfo, setPriceInfo] = useState([]);
      const [modalStatus, setModalStatus] = useState(false);
      const [totalAmount, setTotalAmount] = useState(0);

      const handleTotalPrice = () => {
            const totalAmt = handleHotelCommission({
                  commissions: userCtx?.profile?.hotelCommission,
                  prices: priceInfo?.ris,
                  domesticStatus: true,
                  hotelRating: hotelInfo?.rt,
            });
            return totalAmt;
      };

      const loadData = async () => {
            setLoading(true);

            const data = {
                  hotelId: hotelId,
                  optionId: hOptionId,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-review`,
                        data,
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.status.success === true) {
                              setConditions(response.data.conditions);
                              setStatusShow(true);
                              setBookingId(response.data.bookingId);
                              setHotelInfo(response.data.hInfo);
                              setPriceInfo(response.data.hInfo?.ops[0]);
                              setSearchQuery(response.data.query);
                              localStorage.setItem(
                                    "hotelReviewTime",
                                    response.data.conditions.st
                              );
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [hotelId, hOptionId, userCtx]);

      useEffect(() => {
            setTotalAmount(handleTotalPrice(priceInfo?.ris));
      }, [priceInfo, userCtx]);

      return (
            <>
                  <CheckoutContainer>
                        <DashboardContainer
                              padding="130px"
                              flexDirection="column"
                        >
                              <HotelCheckoutReview
                                    hotelId={hotelId}
                                    hOptionId={hOptionId}
                              />
                              <Row className="w-100">
                                    {!loading ? (
                                          <>
                                                <Col lg={8}>
                                                      <HotelReviewOptions
                                                            optionId={optionId}
                                                            searchQuery={
                                                                  searchQuery
                                                            }
                                                            priceInfo={
                                                                  priceInfo
                                                            }
                                                            hotelId={hotelId}
                                                            hOptionId={
                                                                  hOptionId
                                                            }
                                                            bookingId={
                                                                  bookingId
                                                            }
                                                            totalAmount={
                                                                  totalAmount
                                                            }
                                                            hotelInfo={
                                                                  hotelInfo
                                                            }
                                                      />
                                                </Col>
                                                <Col lg={4}>
                                                      <FlightReviewContent>
                                                            {!loading &&
                                                            statusShow ? (
                                                                  <CountdownTimer
                                                                        conditions={
                                                                              conditions
                                                                        }
                                                                        hotelId={
                                                                              hotelId
                                                                        }
                                                                        modalRequired={
                                                                              true
                                                                        }
                                                                        modalStatus={
                                                                              modalStatus
                                                                        }
                                                                        setModalStatus={
                                                                              setModalStatus
                                                                        }
                                                                  />
                                                            ) : null}
                                                            <HotelPriceSummary
                                                                  priceInfo={
                                                                        priceInfo
                                                                  }
                                                                  searchQuery={
                                                                        searchQuery
                                                                  }
                                                                  optionId={
                                                                        optionId
                                                                  }
                                                                  userCtx={
                                                                        userCtx
                                                                  }
                                                                  hotelInfo={
                                                                        hotelInfo
                                                                  }
                                                                  loading={
                                                                        loading
                                                                  }
                                                                  totalAmount={
                                                                        totalAmount
                                                                  }
                                                            />
                                                      </FlightReviewContent>
                                                </Col>
                                          </>
                                    ) : (
                                          <Col
                                                lg={12}
                                                className="text-center mt-5 pt-5"
                                          >
                                                <Spinner />
                                          </Col>
                                    )}
                              </Row>
                        </DashboardContainer>
                  </CheckoutContainer>
            </>
      );
};

export default HotelReviewPage;
