import React from "react";
import {
      DashboardListContainer,
      DashboardListTitle,
} from "../../../components/Dashboard/DashboardList/styles";
import { HotelSearchContainer } from "./style";
import { FormControl } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";

const HotelFilterSearch = ({ setSearchName }) => {
      const handleSearchName = (value) => {
            setSearchName(value);
      };

      return (
            <>
                  <DashboardListContainer className="pt-0">
                        <DashboardListTitle>Filter</DashboardListTitle>
                        <HotelSearchContainer>
                              <IoSearch />
                              <FormControl
                                    type="text"
                                    onChange={(event) =>
                                          handleSearchName(event.target.value)
                                    }
                                    placeholder="Search by Hotel name"
                              />
                        </HotelSearchContainer>
                  </DashboardListContainer>
                  {/* <DashboardListContainer className="pt-3">
                        <CancellationContainer
                              className={status ? "active" : ""}
                              onClick={handleCancellation}
                        >
                              Free Cancellation
                        </CancellationContainer>
                  </DashboardListContainer> */}
            </>
      );
};

export default HotelFilterSearch;
