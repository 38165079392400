import React from "react";
import {
      DashboardListContainer,
      DashboardListTitle,
      DashboardListValue,
} from "../../../components/Dashboard/DashboardList/styles";
import {
      FlightItemItem,
      FlightItemValue,
      FlightItemWrapper,
} from "../../../components/Frontend/flight/FlightItem/style";
import { Form } from "react-bootstrap";
import { ItemSkeletonList } from "../../../components/skeletons";

const HotelMealList = ({ mealBasis, handleClick, loading }) => {
      return (
            <>
                  <DashboardListContainer>
                        <DashboardListTitle>Meal Basis</DashboardListTitle>
                        <FlightItemWrapper className="list-inline mb-0">
                              {!loading ? (
                                    mealBasis?.map((item, index) => (
                                          <FlightItemItem key={index}>
                                                <Form.Check // prettier-ignore
                                                      type="checkbox"
                                                      checked={item?.checked}
                                                      onClick={() =>
                                                            handleClick(index)
                                                      }
                                                      label={
                                                            <FlightItemValue fontSize="14px">
                                                                  {item?.title}
                                                            </FlightItemValue>
                                                      }
                                                      className="mb-0"
                                                />

                                                <DashboardListValue>
                                                      {item.value}
                                                </DashboardListValue>
                                          </FlightItemItem>
                                    ))
                              ) : (
                                    <>
                                          <ItemSkeletonList />
                                    </>
                              )}
                        </FlightItemWrapper>
                  </DashboardListContainer>
            </>
      );
};

export default HotelMealList;
