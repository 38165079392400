import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
      DashboardContainer,
      DashboardContent,
      DashboardMenu,
      DashboardMenuContent,
} from "../dashboard/styles";
import { Col, Row, Spinner } from "react-bootstrap";
import HotelCardSkeleton from "../../components/Frontend/hotel/HotelCardSkeleton";
import HotelSearchList from "./HotelSearchList";
import HotelModify from "./HotelModify";
import { HotelContainer, HotelInfoTitle } from "./styles";
import HotelFilter from "./HotelFilter";

const getRoomInfoFromLocalStorage = (key) => {
      const data = localStorage.getItem(key);
      return data ? JSON.parse(data) : null; // Return parsed data or null if not found
};

const HotelSearch = () => {
      const location = useLocation();
      const searchParams = new URLSearchParams(location.search);
      const navigate = useNavigate();
      const hotelSearchDetails = `${location?.pathname}${location?.search}`;

      const searchDestination = searchParams.get("destination");
      const findDestination = JSON.parse(
            localStorage.getItem("hotelDestination")
      );
      let foundDestination = [];
      if (findDestination?.fullRegionName === searchDestination) {
            foundDestination = findDestination;
      }

      const ratingsParam = searchParams.get("ratings");

      const [searchDetail, setSearchDetail] = useState({
            checkInDate: searchParams.get("checkInDate"),
            nationality: searchParams.get("nationality"),
            destination: foundDestination,
            ratings: ratingsParam ? ratingsParam.split(",") : [],
            roomInfo: getRoomInfoFromLocalStorage(searchParams.get("roomInfo")),
            checkOutDate: searchParams.get("checkOutDate"),
      });

      if (
            searchDetail?.checkInDate === null ||
            searchDetail?.checkOutDate === null ||
            searchDetail?.destination === null ||
            searchDetail?.roomInfo === null ||
            searchDetail?.destination === null
      ) {
            navigate(`/hotel`);
      }

      const [loading, setLoading] = useState(false);
      const [hotels, setHotels] = useState([]);
      const [filteredHotels, setFilteredHotels] = useState([]);
      const [totalHotels, setTotalHotels] = useState(0);

      const handleData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-searchquery-list`,
                        {
                              searchQuery: {
                                    checkinDate: searchDetail?.checkInDate,
                                    checkoutDate: searchDetail?.checkOutDate,
                                    roomInfo: searchDetail?.roomInfo,
                                    searchCriteria: {
                                          city: searchDetail?.destination
                                                ? searchDetail?.destination
                                                        ?.hotel_id
                                                : "",
                                          nationality:
                                                searchDetail?.nationality,
                                          currency: "INR",
                                    },
                                    searchPreferences: {
                                          ratings: searchDetail?.ratings,
                                          fsc: true,
                                    },
                              },
                              sync: true,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.status.httpStatus === 200) {
                              setHotels(response.data.searchResult.his);
                              setFilteredHotels(response.data.searchResult.his);
                              setTotalHotels(response.data.searchResult.size);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            handleData();
      }, []);

      return (
            <>
                  <DashboardContainer padding="130px" flexDirection="column">
                        <HotelModify searchDetail={searchDetail} />
                        <HotelContainer>
                              <DashboardMenu>
                                    <DashboardMenuContent marginTop="2rem">
                                          <HotelFilter
                                                setFilteredHotels={
                                                      setFilteredHotels
                                                }
                                                filteredHotels={filteredHotels}
                                                hotels={hotels}
                                                loading={loading}
                                          />
                                    </DashboardMenuContent>
                              </DashboardMenu>
                              <DashboardContent>
                                    <Row>
                                          <Col lg={12}>
                                                <HotelInfoTitle>
                                                      {!loading ? (
                                                            filteredHotels?.length
                                                      ) : (
                                                            <Spinner />
                                                      )}{" "}
                                                      Hotels In{" "}
                                                      {
                                                            searchDetail
                                                                  ?.destination
                                                                  ?.fullRegionName
                                                      }
                                                </HotelInfoTitle>
                                          </Col>
                                    </Row>
                                    <Row>
                                          {!loading ? (
                                                <>
                                                      <Col lg={12}>
                                                            <HotelSearchList
                                                                  hotels={
                                                                        filteredHotels
                                                                  }
                                                                  hotelSearchDetails={
                                                                        hotelSearchDetails
                                                                  }
                                                            />
                                                      </Col>
                                                </>
                                          ) : (
                                                <Col lg={12}>
                                                      <HotelCardSkeleton />
                                                      <HotelCardSkeleton />
                                                      <HotelCardSkeleton />
                                                      <HotelCardSkeleton />
                                                      <HotelCardSkeleton />
                                                </Col>
                                          )}
                                    </Row>
                              </DashboardContent>
                        </HotelContainer>
                  </DashboardContainer>
            </>
      );
};

export default HotelSearch;
