import React from "react";
import {
      HotelCardContainer,
      HotelCardContent,
      HotelCardHeader,
      HotelFooterContainer,
      HotelFooterContent,
      HotelFooterLine,
      HotelFooterSubtitle,
      HotelFooterTitle,
      HotelHeaderImage,
      HotelHeaderInfo,
      HotelHeaderSubtitle,
      HotelHeaderTitle,
      HotelHorizontalBorder,
      HotelHorizontalLine,
      HotelNightRoom,
      HReviewContainer,
      HReviewContent,
      HReviewExtra,
      HReviewExtraDetail,
      HReviewFacility,
      HReviewImage,
      HReviewTitle,
} from "./style";
import HotelRating from "../../components/Frontend/hotel/HotelRating";
import {
      calculateTotalAdults,
      calculateTotalChild,
      calculateTotalNights,
      convertDayMonth,
      convertDayYear,
} from "../../hooks/CurrentData";
import { MapIcon } from "../../icons";
import { HotelAddressIcon } from "../hotels/styles";
import { FaCheck } from "react-icons/fa6";
import { FlightDetails } from "../checkout/styles";

const normalizeMealBasis = (mb) =>
      mb
            .replace(/([a-z])([A-Z])/g, "$1 $2") // Add spaces between camelCase
            .split(" ") // Split into words
            .map(
                  (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
            ) // Capitalize each word
            .join(" ");

const HotelConfirmDetail = ({ tripInfos, priceInfos, searchQuery }) => {
      const totalNights =
            searchQuery?.checkinDate &&
            searchQuery?.checkoutDate &&
            calculateTotalNights(
                  searchQuery?.checkinDate,
                  searchQuery?.checkoutDate
            );

      const totalAdults = searchQuery
            ? calculateTotalAdults(searchQuery?.roomInfo)
            : 0;

      const totalChild = searchQuery
            ? calculateTotalChild(searchQuery?.roomInfo)
            : 0;

      const totalGuests = totalAdults + totalChild;

      const roomDetail = priceInfos?.ris ? priceInfos?.ris[0] : [];
      const roomView = roomDetail?.radi?.vi ? roomDetail?.radi?.vi : [];

      return (
            <>
                  <HotelCardContainer>
                        <HotelCardHeader>
                              <HotelCardContent>
                                    <HotelHeaderInfo>
                                          <HotelHeaderSubtitle>
                                                {tripInfos?.rt}-star hotel in{" "}
                                                {tripInfos?.ad?.ctn}
                                          </HotelHeaderSubtitle>
                                          <HotelHeaderTitle>
                                                {tripInfos?.name}
                                          </HotelHeaderTitle>
                                          <HotelAddressIcon className="text-muted mb-1">
                                                <MapIcon /> {tripInfos?.ad?.adr}
                                          </HotelAddressIcon>
                                          <HotelRating num={tripInfos?.rt} />
                                    </HotelHeaderInfo>
                                    <HotelHeaderImage>
                                          {tripInfos?.img ? (
                                                <img
                                                      src={
                                                            tripInfos?.img[0]
                                                                  ?.url
                                                      }
                                                      alt=""
                                                />
                                          ) : (
                                                <img
                                                      src="/images/no-image.webp"
                                                      alt=""
                                                />
                                          )}
                                    </HotelHeaderImage>
                              </HotelCardContent>
                        </HotelCardHeader>
                        <HotelHorizontalLine>
                              <HotelHorizontalBorder />
                              <div className="left__style"></div>
                              <div className="right__style"></div>
                        </HotelHorizontalLine>
                        <HotelFooterContainer>
                              <HotelFooterContent>
                                    <HotelFooterContent gap="48px">
                                          <div>
                                                <HotelFooterSubtitle>
                                                      Check-in
                                                </HotelFooterSubtitle>
                                                <HotelFooterTitle>
                                                      {priceInfos?.ris
                                                            ? convertDayMonth(
                                                                    priceInfos
                                                                          ?.ris[0]
                                                                          ?.checkInDate
                                                              )
                                                            : "-"}
                                                </HotelFooterTitle>
                                                <HotelFooterSubtitle>
                                                      {priceInfos?.ris
                                                            ? convertDayYear(
                                                                    priceInfos
                                                                          ?.ris[0]
                                                                          ?.checkInDate
                                                              )
                                                            : "-"}
                                                </HotelFooterSubtitle>
                                          </div>
                                          <HotelNightRoom>
                                                {totalNights} night
                                                {totalNights > 1 ? "s" : ""}
                                          </HotelNightRoom>
                                          <div>
                                                <HotelFooterSubtitle>
                                                      Check-out
                                                </HotelFooterSubtitle>
                                                <HotelFooterTitle>
                                                      {priceInfos?.ris
                                                            ? convertDayMonth(
                                                                    priceInfos
                                                                          ?.ris[0]
                                                                          ?.checkOutDate
                                                              )
                                                            : "-"}
                                                </HotelFooterTitle>
                                                <HotelFooterSubtitle>
                                                      {priceInfos?.ris
                                                            ? convertDayYear(
                                                                    priceInfos
                                                                          ?.ris[0]
                                                                          ?.checkOutDate
                                                              )
                                                            : "-"}
                                                </HotelFooterSubtitle>
                                          </div>
                                    </HotelFooterContent>
                                    <HotelFooterLine />
                                    <div>
                                          <HotelFooterSubtitle>
                                                Rooms & Guests
                                          </HotelFooterSubtitle>
                                          <HotelFooterTitle>
                                                {priceInfos?.ris?.length} Room
                                                {priceInfos?.ris?.length > 1
                                                      ? "s"
                                                      : ""}
                                                , {totalGuests} Guest
                                                {totalGuests > 1 ? "s" : ""}
                                          </HotelFooterTitle>
                                          <HotelFooterSubtitle>
                                                {totalAdults} adult
                                                {totalAdults > 1
                                                      ? "s"
                                                      : ""},{" "}
                                                {`${totalChild} ${
                                                      totalChild > 1
                                                            ? "Child "
                                                            : "Children "
                                                }`}
                                          </HotelFooterSubtitle>
                                    </div>
                              </HotelFooterContent>
                        </HotelFooterContainer>
                  </HotelCardContainer>
                  {roomDetail?.imgs && roomDetail?.rt && roomDetail?.fcs && (
                        <FlightDetails className="mb-4">
                              <HReviewContainer>
                                    <HReviewTitle>
                                          {roomDetail?.rt}
                                    </HReviewTitle>

                                    <HReviewContent>
                                          <HReviewImage>
                                                {roomDetail?.imgs ? (
                                                      <img
                                                            src={
                                                                  roomDetail
                                                                        ?.imgs[0]
                                                                        ?.url
                                                            }
                                                            alt=""
                                                      />
                                                ) : (
                                                      <img
                                                            src="/images/no-image.webp"
                                                            alt=""
                                                      />
                                                )}
                                          </HReviewImage>
                                          <HReviewExtraDetail>
                                                {roomView && (
                                                      <HReviewFacility>
                                                            <ul>
                                                                  {roomView?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <li
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    {
                                                                                          item
                                                                                    }
                                                                              </li>
                                                                        )
                                                                  )}
                                                            </ul>
                                                      </HReviewFacility>
                                                )}
                                                {roomDetail && (
                                                      <HReviewExtra>
                                                            <ul>
                                                                  {roomDetail?.fcs
                                                                        ?.slice(
                                                                              0,
                                                                              3
                                                                        )
                                                                        ?.map(
                                                                              (
                                                                                    item,
                                                                                    index
                                                                              ) => (
                                                                                    <li
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <FaCheck />
                                                                                          {
                                                                                                item
                                                                                          }
                                                                                    </li>
                                                                              )
                                                                        )}

                                                                  {roomDetail?.mb && (
                                                                        <li>
                                                                              <FaCheck />
                                                                              {normalizeMealBasis(
                                                                                    roomDetail?.mb
                                                                              )}
                                                                        </li>
                                                                  )}
                                                            </ul>
                                                      </HReviewExtra>
                                                )}
                                          </HReviewExtraDetail>
                                    </HReviewContent>
                              </HReviewContainer>
                        </FlightDetails>
                  )}
            </>
      );
};

export default HotelConfirmDetail;
