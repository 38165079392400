import React, { useContext, useEffect, useState } from "react";
import {
      FlightInfoButton,
      FlightInfoContainer,
      FlightInfoContent,
      FlightInfoDuration,
      FlightInfoLogo,
      FlightInfoMultipleLogo,
      FlightInfoPrice,
      FlightInfoTime,
      FlightInfoTitle,
} from "../FlightInfo/styles";
import CommonButton from "../../Common/CommonButton";
import FlightPrice from "../FlightPrice";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import ComboFlightAccordion from "../ComboFlightAccordion";
import {
      ComboFlightBook,
      ComboFlightContent,
      ComboFlightDetail,
} from "./style";
import {
      checkCommission,
      totalAmountAfterCommission,
} from "../../../data/commission";

const ComboFlightInfo = ({
      flights,
      item,
      netFare,
      searchDetail,
      fullFare,
      domesticStatus,
      commission,
      airlineCode,
}) => {
      const previousSearch = useLocation().pathname;
      const previousQuery = useLocation().search;
      const previousLink = `${previousSearch}${previousQuery}`;

      const calculateDuration = (totalMinutes) => {
            if (typeof totalMinutes !== "number" || isNaN(totalMinutes)) {
                  return { hours: 0, minutes: 0 };
            }

            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            return { hours, minutes };
      };

      const arrivalTime = (sI) => {
            const totalSi = sI.length;
            const lastSi = sI[totalSi - 1];
            return lastSi;
      };

      const formatTime = (isoString) => {
            const date = new Date(isoString);
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            return `${hours}:${minutes}`;
      };

      const [status, setStatus] = useState(false);
      const [fareId, setFareId] = useState([]);

      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      const checkCabinClassPrice = () => {
            let filteredData = item?.totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );

            return filteredData.sort(
                  (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
            );
      };

      useEffect(() => {
            setFareId(checkCabinClassPrice()[0]);
            setStatus(false);
      }, [flights]);

      const handleSubmit = (event) => {
            event.preventDefault();
            setLoading(true);
            const optionId = event.target.elements.option.value;

            localStorage.setItem("previousSearch", previousLink);
            localStorage.setItem("reviewId", optionId);

            localStorage.removeItem("contactDetails");
            localStorage.removeItem("gstDetails");
            localStorage.removeItem("travellers");
            localStorage.removeItem("services");
            localStorage.removeItem("totalAmount");
            localStorage.removeItem("totalNetAmount");

            setTimeout(() => {
                  setLoading(false);
                  navigate(`/flight/travellers/${optionId}`);
            }, 3000);
      };

      const calculateTotalAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        totalAmountAfterCommission({
                              airlineCode,
                              domesticStatus,
                              commission,
                              fareDetail: fareId,
                              totalFare: fareId?.fd?.ADULT?.fC?.TF,
                        }) *
                              (fullFare ? searchDetail?.adult : 1);
            }
            if (fullFare) {
                  if (fareId?.fd?.CHILD) {
                        newAmount =
                              newAmount +
                              totalAmountAfterCommission({
                                    airlineCode,
                                    domesticStatus,
                                    commission,
                                    fareDetail: fareId,
                                    totalFare: fareId?.fd?.CHILD?.fC?.TF,
                              }) *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (fareId?.fd?.INFANT) {
                        newAmount =
                              newAmount +
                              fareId?.fd?.INFANT?.fC?.TF *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }

            return convertAmount(convertFloat(newAmount));
      };

      const calculateTotalNetAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        checkCommission({
                              airlineCode: airlineCode,
                              fareDetail: fareId,
                              commission: commission,
                              domesticStatus: domesticStatus,
                              totalFare: fareId?.fd?.ADULT?.fC?.TF,
                              netFare: fareId?.fd?.ADULT?.fC?.NF,
                        }) *
                              (fullFare ? searchDetail?.adult : 1);
            }

            if (fullFare) {
                  if (fareId?.fd?.CHILD) {
                        newAmount =
                              newAmount +
                              checkCommission({
                                    airlineCode: airlineCode,
                                    fareDetail: fareId,
                                    commission: commission,
                                    domesticStatus: domesticStatus,
                                    totalFare: fareId?.fd?.CHILD?.fC?.TF,
                                    netFare: fareId?.fd?.CHILD?.fC?.NF,
                              }) *
                                    (fullFare ? searchDetail?.children : 1);
                  }
                  if (fareId?.fd?.INFANT) {
                        newAmount =
                              newAmount +
                              checkCommission({
                                    airlineCode: airlineCode,
                                    fareDetail: fareId,
                                    commission: commission,
                                    domesticStatus: domesticStatus,
                                    totalFare: fareId?.fd?.INFANT?.fC?.TF,
                                    netFare: fareId?.fd?.INFANT?.fC?.NF,
                                    children: true,
                              }) *
                                    (fullFare ? searchDetail?.infants : 1);
                  }
            }

            return convertAmount(convertFloat(newAmount));
      };

      const checkAirline = (sI) => {
            if (sI?.length === 0) return false; // Return false for an empty array

            const firstAirlineCode = sI && sI[0].fD.aI.code;
            if (sI) {
                  return sI.every(
                        (segment) => segment.fD.aI.code === firstAirlineCode
                  );
            }
      };

      function groupFlights(segments, start, end) {
            const matchedFlights = [];

            segments.forEach((segment) => {
                  if (segment.da.code === start && segment.aa.code === end) {
                        matchedFlights.push(segment);
                  } else if (segment.da.code === start) {
                        matchedFlights.push(segment);
                  } else if (segment.aa.code === end) {
                        matchedFlights.push(segment);
                  }
            });

            return matchedFlights;
      }

      const oneWayFlight = groupFlights(
            item.sI,
            searchDetail?.leavingFrom,
            searchDetail?.destination
      );

      const returnFilter = groupFlights(
            item.sI,
            searchDetail?.destination,
            searchDetail?.leavingFrom
      );

      const calculateTotalTime = (isoString1, isoString2) => {
            const date1 = new Date(isoString1);
            const date2 = new Date(isoString2);

            const differenceInMs = Math.abs(date1 - date2);

            const totalMinutes = Math.floor(differenceInMs / 60000);

            return totalMinutes;
      };

      const checkTime = (items) => {
            let totalTime = 0;

            items?.map((sI, index) => {
                  totalTime = totalTime + sI?.duration;
                  if (items?.length > 1 && items.length !== index + 1) {
                        totalTime =
                              totalTime +
                              calculateTotalTime(sI?.at, items[index + 1]?.dt);
                  }
            });

            const hours = calculateDuration(totalTime).hours;
            const minutes = calculateDuration(totalTime).minutes;

            return { hours, minutes };
      };

      return (
            <>
                  <Form onSubmit={handleSubmit}>
                        <FlightInfoContainer status={status}>
                              <ComboFlightDetail>
                                    <ComboFlightContent>
                                          <FlightInfoContent>
                                                <FlightInfoTitle className="gap-2">
                                                      {checkAirline(
                                                            oneWayFlight
                                                      ) ? (
                                                            <FlightInfoLogo>
                                                                  <img
                                                                        src={`/images/AirlinesLogo/${oneWayFlight[0]?.fD?.aI?.code}.png`}
                                                                        alt={
                                                                              oneWayFlight[0]
                                                                                    ?.fD
                                                                                    ?.aI
                                                                                    ?.name
                                                                        }
                                                                  />
                                                            </FlightInfoLogo>
                                                      ) : (
                                                            <FlightInfoMultipleLogo>
                                                                  {oneWayFlight?.map(
                                                                        (
                                                                              image,
                                                                              key
                                                                        ) =>
                                                                              image
                                                                                    ?.fD
                                                                                    ?.aI
                                                                                    ?.code !==
                                                                                    oneWayFlight[
                                                                                          key +
                                                                                                1
                                                                                    ]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code && (
                                                                                    <img
                                                                                          src={`/images/AirlinesLogo/${image?.fD?.aI?.code}.png`}
                                                                                          alt={
                                                                                                image
                                                                                                      ?.fD
                                                                                                      ?.aI
                                                                                                      ?.name
                                                                                          }
                                                                                    />
                                                                              )
                                                                  )}
                                                            </FlightInfoMultipleLogo>
                                                      )}
                                                      <div className="title">
                                                            <h4 className="pb-0 mb-0">
                                                                  {
                                                                        oneWayFlight[0]
                                                                              ?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            </h4>
                                                            <div
                                                                  style={{
                                                                        fontSize: "11px",
                                                                        color: "#666",
                                                                  }}
                                                            >
                                                                  {oneWayFlight?.map(
                                                                        (
                                                                              airlineName,
                                                                              airlineIndex
                                                                        ) => (
                                                                              <>
                                                                                    <span>
                                                                                          {`${
                                                                                                airlineName
                                                                                                      ?.fD
                                                                                                      ?.aI
                                                                                                      ?.code
                                                                                          }-${
                                                                                                airlineName
                                                                                                      ?.fD
                                                                                                      ?.fN
                                                                                          }${
                                                                                                airlineIndex !==
                                                                                                oneWayFlight?.length -
                                                                                                      1
                                                                                                      ? ", "
                                                                                                      : ""
                                                                                          }`}
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  )}
                                                            </div>
                                                      </div>
                                                </FlightInfoTitle>

                                                <FlightInfoTime>
                                                      <div>
                                                            {formatTime(
                                                                  oneWayFlight[0]
                                                                        ?.dt
                                                            )}
                                                      </div>
                                                      <div
                                                            className="place d-flex gap-1 align-items-center"
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {`${oneWayFlight[0]?.da?.city} (${oneWayFlight[0]?.da?.cityCode})`}
                                                      </div>
                                                </FlightInfoTime>

                                                <FlightInfoDuration>
                                                      <div>
                                                            {`${
                                                                  checkTime(
                                                                        oneWayFlight
                                                                  )?.hours
                                                            }h ${
                                                                  checkTime(
                                                                        oneWayFlight
                                                                  )?.minutes
                                                            }m`}
                                                      </div>

                                                      <div
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {oneWayFlight?.length ===
                                                            1
                                                                  ? "Non-stop"
                                                                  : "1 Stop(s)"}
                                                      </div>
                                                </FlightInfoDuration>

                                                <FlightInfoTime>
                                                      <div>
                                                            {formatTime(
                                                                  arrivalTime(
                                                                        oneWayFlight
                                                                  )?.at
                                                            )}
                                                      </div>
                                                      <div
                                                            className="place d-flex gap-1 align-items-center"
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {`${
                                                                  oneWayFlight[
                                                                        oneWayFlight?.length -
                                                                              1
                                                                  ]?.aa?.city
                                                            } (${
                                                                  oneWayFlight[
                                                                        oneWayFlight?.length -
                                                                              1
                                                                  ]?.aa
                                                                        ?.cityCode
                                                            })`}
                                                      </div>
                                                </FlightInfoTime>
                                          </FlightInfoContent>
                                          <FlightInfoContent>
                                                <FlightInfoTitle className="gap-2">
                                                      {checkAirline(
                                                            returnFilter
                                                      ) ? (
                                                            <FlightInfoLogo>
                                                                  <img
                                                                        src={`/images/AirlinesLogo/${returnFilter[0]?.fD?.aI?.code}.png`}
                                                                        alt={
                                                                              returnFilter[0]
                                                                                    ?.fD
                                                                                    ?.aI
                                                                                    ?.name
                                                                        }
                                                                  />
                                                            </FlightInfoLogo>
                                                      ) : (
                                                            <FlightInfoMultipleLogo>
                                                                  {returnFilter?.map(
                                                                        (
                                                                              image,
                                                                              key
                                                                        ) =>
                                                                              image
                                                                                    ?.fD
                                                                                    ?.aI
                                                                                    ?.code !==
                                                                                    returnFilter[
                                                                                          key +
                                                                                                1
                                                                                    ]
                                                                                          ?.fD
                                                                                          ?.aI
                                                                                          ?.code && (
                                                                                    <img
                                                                                          src={`/images/AirlinesLogo/${image?.fD?.aI?.code}.png`}
                                                                                          alt={
                                                                                                image
                                                                                                      ?.fD
                                                                                                      ?.aI
                                                                                                      ?.name
                                                                                          }
                                                                                    />
                                                                              )
                                                                  )}
                                                            </FlightInfoMultipleLogo>
                                                      )}
                                                      <div className="title">
                                                            <h4 className="pb-0 mb-0">
                                                                  {
                                                                        returnFilter[0]
                                                                              ?.fD
                                                                              ?.aI
                                                                              ?.name
                                                                  }
                                                            </h4>
                                                            <div
                                                                  style={{
                                                                        fontSize: "11px",
                                                                        color: "#666",
                                                                  }}
                                                            >
                                                                  {returnFilter?.map(
                                                                        (
                                                                              airlineName,
                                                                              airlineIndex
                                                                        ) => (
                                                                              <>
                                                                                    <span>
                                                                                          {`${
                                                                                                airlineName
                                                                                                      ?.fD
                                                                                                      ?.aI
                                                                                                      ?.code
                                                                                          }-${
                                                                                                airlineName
                                                                                                      ?.fD
                                                                                                      ?.fN
                                                                                          }${
                                                                                                airlineIndex !==
                                                                                                oneWayFlight?.length -
                                                                                                      1
                                                                                                      ? ", "
                                                                                                      : ""
                                                                                          }`}
                                                                                    </span>
                                                                              </>
                                                                        )
                                                                  )}
                                                            </div>
                                                      </div>
                                                </FlightInfoTitle>

                                                <FlightInfoTime>
                                                      <div>
                                                            {formatTime(
                                                                  returnFilter[0]
                                                                        ?.dt
                                                            )}
                                                      </div>
                                                      <div
                                                            className="place d-flex gap-1 align-items-center"
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {`${returnFilter[0]?.da?.city} (${returnFilter[0]?.da?.cityCode})`}
                                                      </div>
                                                </FlightInfoTime>

                                                <FlightInfoDuration>
                                                      <div>
                                                            {`${
                                                                  checkTime(
                                                                        returnFilter
                                                                  )?.hours
                                                            }h ${
                                                                  checkTime(
                                                                        returnFilter
                                                                  )?.minutes
                                                            }m`}
                                                      </div>

                                                      <div
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {returnFilter?.length ===
                                                            1
                                                                  ? "Non-stop"
                                                                  : "1 Stop(s)"}
                                                      </div>
                                                </FlightInfoDuration>

                                                <FlightInfoTime>
                                                      <div>
                                                            {formatTime(
                                                                  arrivalTime(
                                                                        returnFilter
                                                                  )?.at
                                                            )}
                                                      </div>
                                                      <div
                                                            className="place d-flex gap-1 align-items-center"
                                                            style={{
                                                                  fontSize: "11px",
                                                                  color: "#666",
                                                            }}
                                                      >
                                                            {`${
                                                                  returnFilter[
                                                                        returnFilter?.length -
                                                                              1
                                                                  ]?.aa?.city
                                                            } (${
                                                                  returnFilter[
                                                                        returnFilter?.length -
                                                                              1
                                                                  ]?.aa
                                                                        ?.cityCode
                                                            })`}
                                                      </div>
                                                </FlightInfoTime>
                                          </FlightInfoContent>
                                    </ComboFlightContent>
                                    <ComboFlightBook>
                                          <FlightInfoContent className="gap-5">
                                                <FlightInfoPrice>
                                                      <div className="flight__info-price flex-column justify-content-start align-items-start">
                                                            {"₹ "}
                                                            {calculateTotalAmount()}
                                                            {netFare && (
                                                                  <div className="flight__info-net text-muted">{`NET ${calculateTotalNetAmount()}`}</div>
                                                            )}
                                                      </div>
                                                      {fareId?.fd?.ADULT?.sR <=
                                                            5 && (
                                                            <div
                                                                  style={{
                                                                        fontSize: "11px",
                                                                        color: "#666",
                                                                  }}
                                                                  className="text-danger"
                                                            >
                                                                  {`${fareId?.fd?.ADULT?.sR} Seats left`}
                                                            </div>
                                                      )}
                                                </FlightInfoPrice>

                                                <FlightInfoButton>
                                                      <CommonButton
                                                            title={"Book Now"}
                                                            slug={"/checkout"}
                                                            padding={
                                                                  "10px 20px"
                                                            }
                                                            fontSize={"13px"}
                                                            width={"90px"}
                                                            button={true}
                                                            type="submit"
                                                            loading={loading}
                                                      />
                                                </FlightInfoButton>
                                          </FlightInfoContent>
                                    </ComboFlightBook>
                              </ComboFlightDetail>

                              <FlightPrice
                                    setStatus={setStatus}
                                    status={status}
                                    setFareId={setFareId}
                                    fullFare={fullFare}
                                    fareId={fareId}
                                    totalPriceList={item?.totalPriceList}
                                    searchDetail={searchDetail}
                                    netFare={netFare}
                                    commission={commission}
                                    domesticStatus={domesticStatus}
                                    airlineCode={airlineCode}
                              />
                              {status && (
                                    <div className="bg-gray w-100">
                                          <ComboFlightAccordion
                                                fareId={fareId}
                                                onward={oneWayFlight}
                                                items={item}
                                                returnFlights={returnFilter}
                                                setFareId={setFareId}
                                                searchDetail={searchDetail}
                                                commission={commission}
                                                domesticStatus={domesticStatus}
                                                airlineCode={airlineCode}
                                          />
                                    </div>
                              )}
                        </FlightInfoContainer>
                  </Form>
            </>
      );
};

export default ComboFlightInfo;
