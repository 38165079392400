import styled from "styled-components";

export const SearchFormWrapper = styled.div`
      width: ${(props) => (props.width ? props.width : "100%")};
      box-shadow: 0px 4px 23.4px 6px #00000040;
      background: ${(props) => props.theme.white};
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: ${(props) => (props.customBackground ? "" : "1rem")};
      padding-top: ${(props) => (props.marginTop ? props.marginTop : "12px")};
      padding-bottom: ${(props) =>
            props.marginBottom ? props.marginBottom : "12px"};
      margin: auto;

      form {
            display: ${(props) => props.flex};
            align-items: center;
            flex-wrap: ${(props) => (props.wrap ? "wrap" : "no-wrap")};
            width: 100%;
            position: relative;
            z-index: 6;

            @media screen and (max-width: 600px) {
                  row-gap: 15px;
            }
            .form-control {
                  &:focus {
                        color: ${(props) => props.theme.textColor};
                        background-color: #f0f0f0;
                        border-color: ${(props) => props.theme.primary};
                        outline: 0;
                        box-shadow: none;
                  }
            }

            .form-label {
                  font-size: 14px;
            }
      }
`;

export const HotelFormContainer = styled.div`
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      z-index: 6;
`;

export const FormContainer = styled.div`
      display: flex;
      align-items: center;
`;

export const FormExtra = styled.div`
      display: flex;
      align-items: center;
      width: ${(props) => props.width || "100%"};
      justify-content: ${(props) => props.justifyContent || "end"};
      gap: 20px;
      border-top: ${(props) => props.border};
      border-color: ${(props) => props.border && props.theme.primary};
      padding-top: ${(props) => props.border && "10px"};
      margin-top: ${(props) => props.border && "20px"};
`;

export const SearchFormAction = styled.div`
      label {
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 0.5rem;
      }
      button {
            border: 0;
      }
      .form-check-input {
            &:hover {
                  cursor: pointer;
            }
      }
      .form-check-input {
            &:checked {
                  background-color: ${(props) => props.theme.primary};
                  border-color: ${(props) => props.theme.primary};
            }
      }
`;
