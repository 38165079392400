import React, { useEffect, useState } from "react";
import {
      RoomDescription,
      RoomImage,
      RoomInfo,
      RoomItem,
      RoomTitle,
} from "../HotelRoom/style";
import RoomOptionList from "../RoomOptionList";

const parseRoomDetails = (details) => {
      return details
            .split(" - ")
            .map((item) => item.trim())
            .filter((item) => item);
};

const RoomItemBox = ({
      item,
      hotel,
      handleSelectFirstRoom,
      handleTotalPrice,
      selectedRoom,
}) => {
      const [limit, setLimit] = useState(2);

      useEffect(() => {
            if (item?.ris?.length < 2) {
                  setLimit(1);
            }
      }, [item]);

      const activeRoom = () => {
            if (selectedRoom) {
                  if (selectedRoom[0]?.selectedOption) {
                        return selectedRoom[0]?.selectedOption;
                  }
            }
      };
      console.log(item)

      return (
            <>
                  <RoomItem>
                        <div className="d-flex">
                              <RoomInfo>
                                    <div className="text-center">
                                          {item?.srn}
                                    </div>
                                    <RoomImage>
                                          <img
                                                src={
                                                      item?.rooms[0]?.ris
                                                            ? item?.rooms[0]
                                                                    ?.ris[0]
                                                                    ?.imgs
                                                                  ? item
                                                                          ?.rooms[0]
                                                                          ?.ris[0]
                                                                          ?.imgs[0]
                                                                          ?.url
                                                                  : "/images/no-image.webp"
                                                            : "/images/no-image.webp" ||
                                                              "/images/no-image.webp"
                                                }
                                                alt="Hotel"
                                                onError={(e) => {
                                                      e.target.onerror = null; // Prevent infinite loop
                                                      e.target.src =
                                                            "/images/no-image.webp"; // Fallback image
                                                }}
                                          />
                                    </RoomImage>
                                    <div>
                                          <RoomTitle>Room Info</RoomTitle>
                                    </div>
                              </RoomInfo>

                              <RoomDescription>
                                    <RoomOptionList
                                          items={item?.rooms}
                                          limit={limit}
                                          hotel={hotel}
                                          setLimit={setLimit}
                                          handleTotalPrice={handleTotalPrice}
                                          activeRoom={activeRoom}
                                          handleSelectFirstRoom={
                                                handleSelectFirstRoom
                                          }
                                    />
                              </RoomDescription>
                        </div>
                  </RoomItem>
            </>
      );
};

export default RoomItemBox;
