import { Skeleton } from "@mui/material";
import React from "react";

const HotelSummaryList = ({ classes }) => {
      return (
            <>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
                  <li className={classes}>
                        <Skeleton
                              height="22px"
                              width={classes ? "100%" : "130px"}
                              style={{
                                    transform: "scale(1)",
                              }}
                        />
                  </li>
            </>
      );
};

export default HotelSummaryList;
