import React from "react";
import {
      DashboardListContainer,
      DashboardListItem,
      DashboardListTitle,
      DashboardListValue,
      DashboardListWrapper,
} from "./styles";
import { Form } from "react-bootstrap";

const DashboardList = ({ lists, stops, handleClick }) => {
      return (
            <>
                  {lists?.map((item, index) => (
                        <DashboardListContainer key={index}>
                              <DashboardListTitle>
                              {item.title}
                              </DashboardListTitle>
                              <DashboardListWrapper className="list-inline mb-0">
                                    {stops?.map((item, index) => (
                                          <DashboardListItem
                                                key={index}
                                                className="d-flex align-items-center justify-content-between"
                                          >
                                                <Form.Check // prettier-ignore
                                                      type="checkbox"
                                                      label={item.title}
                                                      onClick={() =>
                                                            handleClick(index)
                                                      }
                                                      className="mb-0"
                                                />
                                                <DashboardListValue>
                                                      {item.value}
                                                </DashboardListValue>
                                          </DashboardListItem>
                                    ))}
                              </DashboardListWrapper>
                        </DashboardListContainer>
                  ))}
            </>
      );
};

export default DashboardList;
