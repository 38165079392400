import React, { useState, useEffect } from "react";
import {
      CountdownContainer,
      CountdownContent,
      CountdownModalContent,
} from "./style";
import { Modal } from "react-bootstrap";
import CommonButton from "../../../Common/CommonButton";
import { useNavigate } from "react-router-dom";

const CountdownTimer = ({
      conditions,
      modalStatus,
      setModalStatus,
      modalRequired,
      message,
      messageType,
      type,
}) => {
      const [seconds, setSeconds] = useState(conditions?.st);
      const previousSearch = localStorage.getItem("previousSearch");
      const navigate = useNavigate();
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            if (localStorage.getItem("reviewTime") <= -1) {
                  setModalStatus(true);
                  return;
            }

            const intervalId = setInterval(() => {
                  localStorage.setItem(
                        "reviewTime",
                        localStorage.getItem("reviewTime") - 1
                  );
                  setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(intervalId);
      }, [seconds]);

      const formatTime = (totalSeconds) => {
            const minutes = Math.floor(totalSeconds / 60);
            const remainingSeconds = totalSeconds % 60;
            return `${String(minutes).padStart(2, "0")}:${String(
                  remainingSeconds
            ).padStart(2, "0")}`;
      };

      const handleBack = () => {
            setLoading(true);
            setTimeout(() => {
                  setLoading(false);
                  if (type === "hotel") {
                        localStorage.removeItem("hotelTravellers");
                        localStorage.removeItem("contactDetails");
                        localStorage.removeItem("bookingId");
                        localStorage.removeItem("hOptionId");
                        localStorage.removeItem("hotelPayableAmt");
                        localStorage.removeItem("hotelReviewTime");
                        localStorage.removeItem("hotelTotalAmount");
                  } else {
                        localStorage.removeItem("travellers");
                        localStorage.removeItem("services");
                        localStorage.removeItem("contactDetails");
                        localStorage.removeItem("gstDetails");
                  }

                  navigate(previousSearch);
            }, 3000);
      };

      return (
            <>
                  {modalRequired && (
                        <CountdownContainer>
                              <CountdownContent>
                                    <div>Session ends in</div>
                                    <div>{formatTime(seconds)}</div>
                              </CountdownContent>
                        </CountdownContainer>
                  )}
                  <Modal
                        show={modalStatus}
                        size="md"
                        backdrop="static"
                        centered
                  >
                        <Modal.Body>
                              <CountdownModalContent>
                                    <h4 className="text-danger text-center">
                                          {messageType === "808"
                                                ? "SESSION EXPIRED"
                                                : "SOLD OUT"}
                                    </h4>
                                    <p className="text-center">
                                          {message ? (
                                                message
                                          ) : (
                                                <>
                                                      This {type || "flight"}{" "}
                                                      has been sold out. <br />
                                                      Click below to go back to
                                                      the search results.
                                                </>
                                          )}
                                    </p>
                                    <CommonButton
                                          title="BACK TO SEARCH"
                                          padding="10px 30px"
                                          type="button"
                                          justifyContent="center"
                                          fontSize="15px"
                                          handleClick={handleBack}
                                          loading={loading}
                                          button={true}
                                    />
                              </CountdownModalContent>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default CountdownTimer;
