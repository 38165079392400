import React, { useState } from "react";
import DashboardLayout from "../layouts";
import {
      DashboardCard,
      DashboardCardBody,
      DashboardCardHeader,
      DashboardCardTitle,
} from "../layouts/style";
import { DepositRequestIcon } from "../../icons";
import { MenuIcon } from "../layouts/menus/MenuItem/style";
import { MenuOptionButton } from "../bank_deposits/style";
import axios from "axios";
import { Col, Row, Spinner } from "react-bootstrap";
import {
      WalletContainer,
      WalletIcon,
      WalletInput,
} from "../../layout/header/styles";

const AddMoneyPage = () => {
      const [amount, setAmount] = useState(0);
      const [loading, setLoading] = useState(false);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleForm = async () => {
            setLoading(true);

            const callbackUrl = window.location.href;
            const redirectUrl =
                  window.location.origin + `/payments/confirmation`;

            const data = {
                  amount: amount,
                  redirectUrl: redirectUrl,
                  callbackUrl: callbackUrl,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/payment/topup`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.success === true) {
                              const redirectInfoUrl =
                                    response?.data?.data?.instrumentResponse
                                          ?.redirectInfo?.url;

                              window.location.href = redirectInfoUrl;
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <DashboardLayout title="Wallet Topup">
                        <DashboardCard>
                              <DashboardCardHeader>
                                    <DashboardCardTitle>
                                          <MenuIcon>
                                                <DepositRequestIcon />
                                          </MenuIcon>
                                          Add Money
                                    </DashboardCardTitle>
                                    <MenuOptionButton to="/wallet-manage">
                                          Wallet Manage
                                    </MenuOptionButton>
                              </DashboardCardHeader>
                              <DashboardCardBody padding="10px 10px 30px">
                                    <Row>
                                          <Col lg={3}>
                                                <WalletContainer className=" rounded-pill">
                                                      <WalletInput>
                                                            <input
                                                                  type="text"
                                                                  placeholder="₹ 100"
                                                                  className="rounded-pill"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setAmount(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                            />
                                                      </WalletInput>
                                                </WalletContainer>
                                          </Col>
                                          <Col lg={2}>
                                                <WalletIcon
                                                      type="button"
                                                      onClick={handleForm}
                                                      className="rounded-pill mt-0"
                                                >
                                                      <i className="bx bx-plus"></i>{" "}
                                                      Add Money
                                                      {loading ? (
                                                            <Spinner />
                                                      ) : (
                                                            ""
                                                      )}
                                                </WalletIcon>
                                          </Col>
                                    </Row>
                              </DashboardCardBody>
                        </DashboardCard>
                  </DashboardLayout>
            </>
      );
};

export default AddMoneyPage;
