import styled from "styled-components";

export const FooterButtonContainer = styled.div`
      font-size: 20px;
      text-transform: capitalize;
      display: inline-block;
      margin-bottom: 1rem;
      min-width: 93px;
      font-weight: 500;
`;
