import React, { useState } from "react";
import {
      PriceAccordion,
      PriceAccordionHeader,
      PriceIconContainer,
} from "../style";
import { AccordionBody, AccordionHeader } from "react-bootstrap";
import { MinusIcon, PlusIcon } from "../../../../../icons";

const PriceSummaryContent = ({
      title,
      children,
      totalServicesAmount,
      grandTotalStatus,
      border,
}) => {
      const [status, setStatus] = useState(false);

      return (
            <>
                  <PriceAccordion border={border} className="border-bottom">
                        <PriceAccordionHeader>
                              <AccordionHeader
                                    onClick={() => setStatus(!status)}
                                    className={`w-75 `}
                              >
                                    {title}
                                    <PriceIconContainer>
                                          {status ? (
                                                <MinusIcon
                                                      width="20px"
                                                      height="20px"
                                                />
                                          ) : (
                                                <PlusIcon
                                                      width="20px"
                                                      height="20px"
                                                />
                                          )}
                                    </PriceIconContainer>
                              </AccordionHeader>
                              <div
                                    className={`w-25 ${
                                          grandTotalStatus && "total"
                                    }`}
                              >
                                    {totalServicesAmount}
                              </div>
                        </PriceAccordionHeader>
                        <AccordionBody>{children}</AccordionBody>
                  </PriceAccordion>
            </>
      );
};

export default PriceSummaryContent;
