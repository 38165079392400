import React, { useState } from "react";
import {
      FlightTripTabButton,
      FlightTripTabContainer,
      FlightTripTabContent,
      FlightTripTabHeader,
      FlightTripTabPanel,
} from "./style";
import BaggageTripInfo from "../BaggageTripInfo";
import FlightTripDetails from "../FlightTripDetails";
import FlightTripFare from "../FlightTripFare";
import FlightTripRule from "../FlightTripRule";

const FlightTripAccordion = ({
      fareId,
      items,
      searchDetail,
      returnStatus,
      domesticStatus,
      commission,
      airlineCode,
}) => {
      const [activeTab, setActiveTab] = useState(0);

      const handleTabClick = (tabName) => {
            setActiveTab(tabName);
      };

      const options = [
            {
                  title: "Flight Detail",
                  value: 0,
                  description: <FlightTripDetails items={items} />,
            },
            {
                  title: "Fare Detail",
                  value: 1,
                  description: (
                        <FlightTripFare
                              searchDetail={searchDetail}
                              fareId={fareId}
                              commission={commission}
                              airlineCode={airlineCode}
                              domesticStatus={domesticStatus}
                        />
                  ),
            },
            {
                  title: "Fare Rule",
                  value: 2,
                  description: (
                        <FlightTripRule
                              fareId={fareId}
                              returnStatus={returnStatus}
                              searchDetail={searchDetail}
                        />
                  ),
            },
            {
                  title: "Baggage Detail",
                  value: 3,
                  description: <BaggageTripInfo items={items} />,
            },
      ];

      return (
            <>
                  <FlightTripTabContainer>
                        <FlightTripTabHeader>
                              {options?.map((item, index) => (
                                    <FlightTripTabButton
                                          type="button"
                                          className={`${
                                                activeTab === index
                                                      ? "active"
                                                      : ""
                                          }`}
                                          onClick={() => handleTabClick(index)}
                                    >
                                          {item.title}
                                    </FlightTripTabButton>
                              ))}
                        </FlightTripTabHeader>
                        <FlightTripTabContent>
                              {options?.map((item, index) => (
                                    <FlightTripTabPanel
                                          className={`${
                                                activeTab === index
                                                      ? "active"
                                                      : ""
                                          }`}
                                    >
                                          {item.description}
                                    </FlightTripTabPanel>
                              ))}
                        </FlightTripTabContent>
                  </FlightTripTabContainer>
            </>
      );
};

export default FlightTripAccordion;
