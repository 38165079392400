import React, { useEffect, useRef, useState } from "react";

import { addDays, format } from "date-fns";
import { DayPicker } from "react-day-picker";
import {
      FlightModalDestination,
      FlightModalItem,
      FlightModalOptionContent,
} from "../../flight/style";
import {
      FlightModalReturn,
      FlightModalReturnDate,
} from "../../flight/FlightModalDate/style";
import { Form } from "react-bootstrap";

const HotelModalDate = ({ searchDetail, handleChange }) => {
      const [status, setStatus] = useState(false);
      const [checkInStatus, setCheckInStatus] = useState(false);

      const formGroupRef = useRef(null);
      const locationRef = useRef(null);

      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                        setCheckInStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      const newDate = addDays(searchDetail?.checkInDate, 1);

      const convertDate = (isoString) => {
            const date = new Date(isoString);
            return format(date, "EEE, dd MMM");
      };

      const convertNumberDate = (date) => {
            return format(date, "yyyy-MM-dd");
      };

      const handleDate = (item) => {
            setStatus(false);
            setCheckInStatus(false);
            if (item) {
                  handleChange(convertNumberDate(item), "checkInDate");
                  if (convertNumberDate(item) >= searchDetail?.checkOutDate) {
                        handleChange(
                              convertNumberDate(
                                    addDays(convertNumberDate(item), 1)
                              ),
                              "checkOutDate"
                        );
                  }
            }
      };

      const handleRoundtripDate = (item) => {
            setStatus(false);
            if (item) {
                  handleChange(convertNumberDate(item), "checkOutDate");
            }
      };

      const handleSingleTrip = () => {
            setCheckInStatus(true);
      };

      const handleRoundtrip = () => {
            setStatus(true);
      };

      return (
            <>
                  <FlightModalReturn
                        alignItems="start"
                        flexDirection="column"
                        width="15%"
                  >
                        <Form.Label>Check In</Form.Label>
                        <FlightModalItem
                              padding="8px 16px"
                              width="100%"
                              height="38px"
                              ref={formGroupRef}
                        >
                              <FlightModalDestination
                                    width="100%"
                                    onClick={handleSingleTrip}
                              >
                                    <div>
                                          {searchDetail?.checkInDate &&
                                                convertDate(
                                                      convertNumberDate(
                                                            searchDetail?.checkInDate
                                                      )
                                                )}
                                    </div>
                              </FlightModalDestination>
                              {checkInStatus && (
                                    <FlightModalOptionContent ref={locationRef}>
                                          <DayPicker
                                                mode="single"
                                                selected={
                                                      searchDetail?.checkInDate
                                                }
                                                numberOfMonths={2}
                                                onSelect={handleDate}
                                                disabled={{
                                                      before: new Date(),
                                                }}
                                          />
                                    </FlightModalOptionContent>
                              )}
                        </FlightModalItem>
                  </FlightModalReturn>
                  <FlightModalReturn
                        alignItems="start"
                        flexDirection="column"
                        width="15%"
                  >
                        <Form.Label>Check Out</Form.Label>
                        <FlightModalItem
                              padding="8px 16px"
                              width="100%"
                              height="38px"
                              ref={formGroupRef}
                        >
                              <FlightModalReturn width="100%">
                                    <FlightModalReturnDate
                                          onClick={handleRoundtrip}
                                    >
                                          <FlightModalDestination width="100%">
                                                <div>
                                                      {searchDetail && convertDate(
                                                            searchDetail?.checkOutDate
                                                      )}
                                                </div>
                                          </FlightModalDestination>
                                    </FlightModalReturnDate>
                              </FlightModalReturn>
                              {status && (
                                    <FlightModalOptionContent ref={locationRef}>
                                          <DayPicker
                                                mode="single"
                                                numberOfMonths={2}
                                                selected={{
                                                      from: searchDetail?.checkInDate,
                                                      to:
                                                            searchDetail?.checkOutDate ||
                                                            newDate,
                                                }}
                                                onSelect={handleRoundtripDate}
                                                disabled={{
                                                      before: searchDetail?.checkInDate,
                                                }}
                                          />
                                    </FlightModalOptionContent>
                              )}
                        </FlightModalItem>
                  </FlightModalReturn>
            </>
      );
};

export default HotelModalDate;
