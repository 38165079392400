import styled from "styled-components";

export const FlightTripPriceContainer = styled.div`
      display: flex;
      margin-top: 10px;
      justify-content: space-between;
      padding-top: 10px;
      align-items: center;
      border-top: 1px dashed ${(props) => props.theme.primary};
      border-bottom: ${(props) =>
            props.returnStatus ? "1px dashed" + props.theme.primary : ""};
      padding-bottom: 10px;
      margin-bottom: 10px;
`;

export const FlightTripPriceContent = styled.div`
      display: grid;
      gap: 10px;
      align-content: center;
      overflow: hidden;
      grid-template-columns: repeat(2, 1fr);
      width: ${(props) => props.returnStatus && "100%"};

      &::-webkit-scrollbar {
            display: none;
      }
`;

export const FlightTripPriceItem = styled.div`
      background-color: #f5f5f5;
      padding: 2px 10px 4px;
      font-size: 12px;
      border-radius: 5px;
      min-width: max-content;
      cursor: pointer;
      position: relative;
      z-index: 1;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      scroll-snap-align: start;
      flex-shrink: 0;
      margin: 1px;
      min-width: ${(props) => (props.returnStatus ? "50%" : "calc(100% / 3)")};

      &.active {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primary};
      }

      .price {
            font-weight: 600;

            span {
                  font-size: 12px;
            }
      }

      .summary {
            font-size: 9px;
            display: flex;
            align-items: center;
            gap: 10px;
      }

      input {
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: -1;
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
      }
`;

export const FlightTripDetailButton = styled.button`
      font-size: ${(props) => props.fontSize || "12px"};
      background-color: #f5f5f5 !important;
      color: #224082;
      min-width: fit-content;
      text-align: center;
      border: 1px solid #e2e2e2;
      padding: 2.5px 8px;
      border-radius: 2.5px;

      &:hover,
      &.active {
            background-color: #224082 !important;
            color: white;
      }
`;

export const SummaryIconContainer = styled.div`
      border: 1px solid ${(props) => props.color || "#777"};
      border-radius: 50rem;
      padding: 2.5px;
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      color: ${(props) => (props.refundStatus ? "white" : "")};

      &::after {
            content: "";
            position: absolute;
            border: 0.5px solid #777;
            height: 20px;
            transform: rotate(45deg);
            display: ${(props) =>
                  props.refundStatus === 0 ? "block" : "none"};
      }
`;

export const RefundIconContainer = styled.div`
      border: 1px solid ${(props) => props.color || "#777"};
      border-radius: 50rem;
      padding: 2.5px;
      height: ${(props) => props.height || "18px"};
      width: ${(props) => props.width || "18px"};
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow: hidden;
      background-color: ${(props) => (props.status ? "green" : "")};
      color: ${(props) => (props.status ? "white" : "")};

      &::after {
            content: "";
            position: absolute;
            border: 0.5px solid #777;
            height: 20px;
            transform: rotate(45deg);
            display: ${(props) => (props.status === 0 ? "block" : "none")};
      }
`;

export const PriceItemContent = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const PriceItemInfo = styled.div`
      font-size: 13.5px;
      font-weight: 600;

      .price__net {
            font-weight: 500;
            font-size: 12px;
            color: ${(props) =>
                  props.color ? "#ccc" : "rgba(33, 37, 41, 0.75)"};
      }
`;
