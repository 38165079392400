import React, { useState } from "react";
import { CloseButton, Col, Form, FormControl, FormLabel, ModalBody, ModalHeader, Row } from "react-bootstrap";
import { RescheduleDepartureContent } from "../flight_confirmations/RaiseRequest/style";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import CommonButton from "../../components/Common/CommonButton";
import { FlightModal } from "../flight_confirmations/FlightConfirmModal/style";
import axios from "axios";
import toast from "react-hot-toast";

const options = [
      {
            value: "Normal cancellation",
            label: "Normal cancellation",
      },
      {
            value: "⁠Full Refund issued by Hotel",
            label: "⁠Full Refund issued by Hotel",
      },
      {
            value: "Already Cancelled With Hotel",
            label: "Already Cancelled With Hotel",
      },
      {
            value: "⁠Full Refund issued by hotel",
            label: "⁠Full Refund issued by hotel",
      },
      {
            value: "Hotel Cancelled By the hotels",
            label: "Hotel Cancelled By the hotels",
      },
];

const HotelCancelForm = ({
      requestType,
      bookingId,
      mainModalClose,
      setShowMessage,
}) => {
      const [remarks, setRemarks] = useState("");
      const [agreeShow, setAgreeShow] = useState(false);
      const [loading, setLoading] = useState(false);
      const [reason, setReason] = useState("");

      const handleClose = () => setAgreeShow(false);
      const handleShow = () => setAgreeShow(true);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            const data = {
                  request_type: requestType?.value,
                  reason: reason?.value,
                  remarks: remarks,
            };

            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel/${bookingId}/cancel`,
                        data,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              // toast.success(response.data.message);
                              setShowMessage(true);
                              mainModalClose();
                        }
                        if (response.data.result === "error") {
                              if (response.data.message.request_type) {
                                    toast.error(
                                          response.data.message.request_type
                                    );
                              }
                              if (response.data.message.reason) {
                                    toast.error(response.data.message.reason);
                              }
                              if (response.data.message.remarks) {
                                    toast.error(response.data.message.remarks);
                              }
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });

            setLoading(false);
      };

      return (
            <>
                  <Col lg={12}>
                        <Form onSubmit={handleSubmit}>
                              <RescheduleDepartureContent>
                                    <Row className="g-3">
                                          <Col lg={12}>
                                                <FormLabel>
                                                      Cancellation Reason{" "}
                                                      <span className="text-danger">
                                                            *
                                                      </span>
                                                </FormLabel>
                                                <ReactSelect
                                                      value={reason}
                                                      options={options}
                                                      required={true}
                                                      onChange={setReason}
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                <FormLabel>
                                                      Additional Reason
                                                </FormLabel>
                                                <FormControl
                                                      rows={4}
                                                      as="textarea"
                                                      onChange={(event) =>
                                                            setRemarks(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                By submitting this cancellation
                                                request, you acknowledge and
                                                agree to the{" "}
                                                <Link onClick={handleShow}>
                                                      policy
                                                </Link>
                                                .
                                          </Col>
                                          <Col lg={12}>
                                                <CommonButton
                                                      loading={loading}
                                                      button={true}
                                                      type="submit"
                                                      title="Submit"
                                                      classes="justify-content-center
                                                mt-3"
                                                      padding="0.6rem 1.5rem"
                                                />
                                          </Col>
                                    </Row>
                              </RescheduleDepartureContent>
                        </Form>
                  </Col>
                  <FlightModal
                        backdrop="static"
                        show={agreeShow}
                        top="18px"
                        centered
                        backgroundColor={(props) => props.theme.primary}
                  >
                        <ModalHeader>
                              Cancellation Policy Disclaimer
                              <CloseButton onClick={handleClose} />
                        </ModalHeader>

                        <ModalBody>
                              <ol>
                                    <li>
                                          Cancellation charges, as per the
                                          airline or hotel’s policy, will apply
                                          and may vary.
                                    </li>
                                    <li>
                                          Refunds, if applicable, will be
                                          processed as per the supplier’s terms
                                          and conditions.
                                    </li>
                                    <li>
                                          Aircasa is not liable for any delays
                                          in refunds caused by the airline,
                                          hotel, or payment gateway.
                                    </li>
                                    <li>
                                          Additional service charges by Aircasa
                                          may apply for processing the
                                          cancellation.
                                    </li>
                                    <li>
                                          This request does not guarantee an
                                          immediate refund or confirmation of
                                          cancellation.
                                    </li>
                              </ol>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default HotelCancelForm;
