import React, { useState } from "react";
import {
      SliderContainer,
      SliderContent,
      SliderImage,
      SliderImageMore,
} from "./styles";
import { HotelFeatureImage } from "../../../frontend/hotels/styles";
import { Icon } from "@iconify/react";
import { Skeleton } from "@mui/material";
import HotelImageModal from "./HotelImageModal";
import Fancybox from "../../Common/FancyBox";

const HotelImageSlider = ({ images, height, loading }) => {
      const totalImages = images?.length - 5;
      const [show, setShow] = useState(false);

      const handleShow = () => {
            setShow(true);
      };

      const handleClose = () => {
            setShow(false);
      };

      return (
            <>
                  {!loading ? (
                        images[0]?.url && (
                              <Fancybox>
                                    <SliderContainer>
                                          <SliderContent height={height}>
                                                <a
                                                      href={images[0]?.url}
                                                      data-fancybox="video-gallery"
                                                >
                                                      <SliderImage
                                                            src={images[0]?.url}
                                                            onError={(e) => {
                                                                  e.target.onerror =
                                                                        null; // Prevent infinite loop
                                                                  e.target.src =
                                                                        "/images/no-image.webp"; // Fallback image
                                                            }}
                                                      />
                                                </a>
                                          </SliderContent>
                                          <div style={{ width: "25%" }}>
                                                <HotelFeatureImage>
                                                      <a
                                                            href={
                                                                  images[1]?.url
                                                            }
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={
                                                                        images[1]
                                                                              ?.url
                                                                  }
                                                                  alt="Hotel"
                                                                  onError={(
                                                                        e
                                                                  ) => {
                                                                        e.target.onerror =
                                                                              null; // Prevent infinite loop
                                                                        e.target.src =
                                                                              "/images/no-image.webp"; // Fallback image
                                                                  }}
                                                            />
                                                      </a>
                                                </HotelFeatureImage>
                                                <HotelFeatureImage>
                                                      <a
                                                            href={
                                                                  images[2]?.url
                                                            }
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={
                                                                        images[2]
                                                                              ?.url
                                                                  }
                                                                  alt="Hotel"
                                                                  onError={(
                                                                        e
                                                                  ) => {
                                                                        e.target.onerror =
                                                                              null; // Prevent infinite loop
                                                                        e.target.src =
                                                                              "/images/no-image.webp"; // Fallback image
                                                                  }}
                                                            />
                                                      </a>
                                                </HotelFeatureImage>
                                          </div>
                                          <div style={{ width: "25%" }}>
                                                <HotelFeatureImage>
                                                      <a
                                                            href={
                                                                  images[3]?.url
                                                            }
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={
                                                                        images[3]
                                                                              ?.url
                                                                  }
                                                                  alt="Hotel"
                                                                  onError={(
                                                                        e
                                                                  ) => {
                                                                        e.target.onerror =
                                                                              null; // Prevent infinite loop
                                                                        e.target.src =
                                                                              "/images/no-image.webp"; // Fallback image
                                                                  }}
                                                            />
                                                      </a>
                                                </HotelFeatureImage>
                                                <HotelFeatureImage>
                                                      <a
                                                            href={
                                                                  images[4]?.url
                                                            }
                                                            data-fancybox="video-gallery"
                                                      >
                                                            <img
                                                                  src={
                                                                        images[4]
                                                                              ?.url
                                                                  }
                                                                  alt="Hotel"
                                                                  onError={(
                                                                        e
                                                                  ) => {
                                                                        e.target.onerror =
                                                                              null; // Prevent infinite loop
                                                                        e.target.src =
                                                                              "/images/no-image.webp"; // Fallback image
                                                                  }}
                                                            />
                                                      </a>
                                                </HotelFeatureImage>
                                          </div>
                                          {totalImages > 1 && (
                                                <SliderImageMore
                                                      onClick={handleShow}
                                                >
                                                      <Icon icon="material-symbols:image-outline" />{" "}
                                                      <span>
                                                            {totalImages} +
                                                      </span>
                                                </SliderImageMore>
                                          )}
                                    </SliderContainer>
                              </Fancybox>
                        )
                  ) : (
                        <SliderContainer>
                              <SliderContent height={height}>
                                    <Skeleton
                                          height="100%"
                                          width="100%"
                                          style={{
                                                transform: "scale(1)",
                                          }}
                                    />
                              </SliderContent>
                              <div style={{ width: "25%" }}>
                                    <HotelFeatureImage>
                                          <Skeleton
                                                height="100%"
                                                width="100%"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </HotelFeatureImage>
                                    <HotelFeatureImage>
                                          <Skeleton
                                                height="100%"
                                                width="100%"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </HotelFeatureImage>
                              </div>
                              <div style={{ width: "25%" }}>
                                    <HotelFeatureImage>
                                          <Skeleton
                                                height="100%"
                                                width="100%"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </HotelFeatureImage>
                                    <HotelFeatureImage>
                                          <Skeleton
                                                height="100%"
                                                width="100%"
                                                style={{
                                                      transform: "scale(1)",
                                                }}
                                          />
                                    </HotelFeatureImage>
                              </div>
                        </SliderContainer>
                  )}
                  <HotelImageModal
                        images={images?.slice(5)}
                        show={show}
                        handleClose={handleClose}
                  />
            </>
      );
};

export default HotelImageSlider;
