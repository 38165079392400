import React from "react";
import {
      FlightDetailAirline,
      FlightDetailCategory,
      FlightDetailContainer,
      FlightDetailInfo,
} from "../FlightDetails/style";
import { convertAmount, convertFloat } from "../../../hooks/CurrentData";
import { totalAmountAfterCommission } from "../../../data/commission";

const FlightFare = ({
      fareId,
      searchDetail,
      domesticStatus,
      airlineCode,
      commission,
}) => {
      const calculateTotalAmount = () => {
            let newAmount = 0;
            if (fareId?.fd?.ADULT) {
                  newAmount =
                        newAmount +
                        totalAmountAfterCommission({
                              airlineCode,
                              domesticStatus,
                              commission,
                              fareDetail: fareId,
                              totalFare: fareId?.fd?.ADULT?.fC?.TF,
                        }) *
                              searchDetail?.adult;
            }
            if (fareId?.fd?.CHILD) {
                  newAmount =
                        newAmount +
                        totalAmountAfterCommission({
                              airlineCode,
                              domesticStatus,
                              commission,
                              fareDetail: fareId,
                              totalFare: fareId?.fd?.CHILD?.fC?.TF,
                        }) *
                              searchDetail?.children;
            }
            if (fareId?.fd?.INFANT) {
                  newAmount =
                        newAmount +
                        fareId?.fd?.INFANT?.fC?.TF * searchDetail?.infants;
            }
            return convertAmount(convertFloat(newAmount));
      };

      return (
            <>
                  <FlightDetailContainer>
                        <FlightDetailCategory>
                              Fare breakup
                        </FlightDetailCategory>
                        <FlightDetailInfo>
                              <FlightDetailAirline>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                TYPE
                                          </div>
                                    </div>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                FARE
                                          </div>
                                    </div>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                TOTAL
                                          </div>
                                    </div>
                              </FlightDetailAirline>
                              {fareId?.fd?.ADULT && (
                                    <>
                                          <FlightDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for ADULT
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        totalAmountAfterCommission(
                                                                              {
                                                                                    airlineCode,
                                                                                    domesticStatus,
                                                                                    commission,
                                                                                    fareDetail:
                                                                                          fareId,
                                                                                    totalFare:
                                                                                          fareId
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.BF,
                                                                              }
                                                                        )
                                                                  )
                                                            )}{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.adult
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        totalAmountAfterCommission(
                                                                              {
                                                                                    airlineCode:
                                                                                          airlineCode,
                                                                                    domesticStatus:
                                                                                          domesticStatus,
                                                                                    commission:
                                                                                          commission,
                                                                                    fareDetail:
                                                                                          fareId,
                                                                                    totalFare:
                                                                                          fareId
                                                                                                ?.fd
                                                                                                ?.ADULT
                                                                                                ?.fC
                                                                                                ?.BF,
                                                                              }
                                                                        ) *
                                                                              searchDetail?.adult
                                                                  )
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline marginBottom="10px">
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.ADULT
                                                                              ?.fC
                                                                              ?.TAF
                                                                  )
                                                            )}{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.adult
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.ADULT
                                                                              ?.fC
                                                                              ?.TAF *
                                                                              searchDetail?.adult
                                                                  )
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                    </>
                              )}
                              {fareId?.fd?.CHILD && (
                                    <>
                                          <FlightDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for CHILD
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        totalAmountAfterCommission(
                                                                              {
                                                                                    airlineCode:
                                                                                          airlineCode,
                                                                                    domesticStatus:
                                                                                          domesticStatus,
                                                                                    commission:
                                                                                          commission,
                                                                                    fareDetail:
                                                                                          fareId,
                                                                                    totalFare:
                                                                                          fareId
                                                                                                ?.fd
                                                                                                ?.CHILD
                                                                                                ?.fC
                                                                                                ?.BF,
                                                                              }
                                                                        )
                                                                  )
                                                            )}{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.children
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        totalAmountAfterCommission(
                                                                              {
                                                                                    airlineCode:
                                                                                          airlineCode,
                                                                                    domesticStatus:
                                                                                          domesticStatus,
                                                                                    commission:
                                                                                          commission,
                                                                                    fareDetail:
                                                                                          fareId,
                                                                                    totalFare:
                                                                                          fareId
                                                                                                ?.fd
                                                                                                ?.CHILD
                                                                                                ?.fC
                                                                                                ?.BF,
                                                                              }
                                                                        ) *
                                                                              searchDetail?.children
                                                                  )
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline marginBottom="10px">
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.CHILD
                                                                              ?.fC
                                                                              ?.TAF
                                                                  )
                                                            )}{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.children
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.CHILD
                                                                              ?.fC
                                                                              ?.TAF *
                                                                              searchDetail?.children
                                                                  )
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                    </>
                              )}
                              {fareId?.fd?.INFANT && (
                                    <>
                                          <FlightDetailAirline>
                                                <div className="flight__trip-title">
                                                      Fare Details for INFANT
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Base Price</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.INFANT
                                                                              ?.fC
                                                                              ?.BF
                                                                  )
                                                            )}{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.infants
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {convertAmount(
                                                                  convertFloat(
                                                                        fareId
                                                                              ?.fd
                                                                              ?.INFANT
                                                                              ?.fC
                                                                              ?.BF *
                                                                              searchDetail?.infants
                                                                  )
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                          <FlightDetailAirline>
                                                <div className="flight__price-content">
                                                      <div>Taxes & Fees</div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {
                                                                  fareId?.fd
                                                                        ?.INFANT
                                                                        ?.fC
                                                                        ?.TAF
                                                            }{" "}
                                                            x{" "}
                                                            {
                                                                  searchDetail?.infants
                                                            }
                                                      </div>
                                                </div>
                                                <div className="flight__price-content">
                                                      <div>
                                                            ₹{" "}
                                                            {fareId?.fd?.INFANT
                                                                  ?.fC?.TAF *
                                                                  searchDetail?.infants}
                                                      </div>
                                                </div>
                                          </FlightDetailAirline>
                                    </>
                              )}
                              <FlightDetailAirline
                                    border={`1px solid ${(props) =>
                                          props.theme.primary}`}
                                    className="pt-2 mt-2"
                              >
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                Total
                                          </div>
                                    </div>
                                    <div className="flight__price-content">
                                          <div className="flight__price-header">
                                                ₹ {calculateTotalAmount()}
                                          </div>
                                    </div>
                              </FlightDetailAirline>
                        </FlightDetailInfo>
                  </FlightDetailContainer>
            </>
      );
};

export default FlightFare;
