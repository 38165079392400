import React from "react";
import { FlightReviewContent } from "../flight_reviews/style";
import { PriceSummary } from "../checkout/styles";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import HotelRaiseRequest from "./HotelRaiseRequest";
import { FaPhoneAlt } from "react-icons/fa";
import { ServiceContact } from "../flight_confirmations/FlightConfirmPayment/style";
import HotelFeedbackForm from "./HotelFeedbackForm";

const HotelConfirmPayment = ({
      priceInfos,
      agentOrder,
      totalAmount,
      bookingId,
      setShow,
}) => {
      const handleTAF = () => {
            let totalTAF = 0;
            priceInfos?.ris?.map((item) => (totalTAF += item?.tfcs?.TAF));
            return totalTAF;
      };

      const handleBaseFare = () => {
            let totalBaseFare = 0;
            priceInfos?.ris?.map(
                  (item) =>
                        (totalBaseFare +=
                              item?.tfcs?.BF + agentOrder?.commission)
            );
            return totalBaseFare;
      };

      return (
            <div>
                  <FlightReviewContent>
                        <HotelRaiseRequest
                              setShowMessage={setShow}
                              bookingId={bookingId}
                        />
                        <PriceSummary>
                              <div className="price__box mb-4">
                                    <div className="price__heading">
                                          <div className="price__title w-75">
                                                Payment Summary
                                          </div>
                                    </div>
                                    <ul className="price__list list-inline mb-0 border-bottom">
                                          <li className="price__item d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Base Fare
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  handleBaseFare()
                                                            )
                                                      )}
                                                </div>
                                          </li>
                                    </ul>
                                    <ul className="price__list list-inline mb-0 border-bottom">
                                          <li className="price__item d-flex align-items-center">
                                                <div className="price__item--title w-75">
                                                      Taxes & Fees
                                                </div>
                                                <div className="price__item--amount">
                                                      ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  handleTAF()
                                                            )
                                                      )}
                                                </div>
                                          </li>
                                    </ul>
                                    <div className="total d-flex align-items-center mb-0">
                                          <div className="total__title  w-75">
                                                Grand Total
                                          </div>
                                          <div className="total__price">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(totalAmount)
                                                )}
                                          </div>
                                    </div>
                              </div>
                        </PriceSummary>
                        <HotelFeedbackForm
                              bookingId={bookingId}
                              agentOrder={agentOrder}
                        />
                        <PriceSummary>
                              <div className="price__box py-2 mb-4">
                                    <div className="price__heading mb-0">
                                          <div className="price__title d-flex align-items-center gap-3 w-75">
                                                <FaPhoneAlt
                                                      color="#224082"
                                                      size="24px"
                                                />
                                                <ServiceContact>
                                                      <div className="service__contact-title">
                                                            Hotel Support
                                                      </div>
                                                      <div className="text-muted">
                                                            Call us at: +91
                                                            8766601888
                                                      </div>
                                                </ServiceContact>
                                          </div>
                                    </div>
                              </div>
                        </PriceSummary>
                  </FlightReviewContent>
            </div>
      );
};

export default HotelConfirmPayment;
