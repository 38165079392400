import React from "react";
import { HotelRatingContainer } from "./style";
import { Icon } from "@iconify/react";

const HotelRating = ({ num }) => {
      return (
            <>
                  <HotelRatingContainer>
                        {num === 1 && (
                              <>
                                    <Icon icon="material-symbols:star" />
                                    {/* <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" /> */}
                              </>
                        )}
                        {num === 2 && (
                              <>
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    {/* <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" /> */}
                              </>
                        )}
                        {num === 3 && (
                              <>
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    {/* <Icon icon="mdi:star-outline" />
                                    <Icon icon="mdi:star-outline" /> */}
                              </>
                        )}
                        {num === 4 && (
                              <>
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    {/* <Icon icon="mdi:star-outline" /> */}
                              </>
                        )}
                        {num === 5 && (
                              <>
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                                    <Icon icon="material-symbols:star" />
                              </>
                        )}
                  </HotelRatingContainer>
            </>
      );
};

export default HotelRating;
