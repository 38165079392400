import React, { useState } from "react";
import {
      CloseButton,
      Col,
      Form,
      FormControl,
      FormLabel,
      ModalBody,
      ModalHeader,
      Row,
} from "react-bootstrap";
import {
      RescheduleDepartureContent,
      RescheduleDepartureItem,
      RescheduleDepartureList,
} from "./style";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Link } from "react-router-dom";
import { FlightModal } from "../FlightConfirmModal/style";
import CommonButton from "../../../components/Common/CommonButton";
import axios from "axios";
import toast from "react-hot-toast";

const validateDestinations = (destinations) => {
      // Check each destination for required fields
      const invalidDestinations = destinations.filter(
            (dest) => !dest.flightNo.trim() || !dest.date.trim()
      );

      if (invalidDestinations.length > 0) {
            // If any destination is invalid, show an error and stop further processing
            toast.error(
                  "Flight Number and Date are required for all destinations."
            );
            return false;
      }
      return true;
};

const validateByDestination = (groupedByDestination) => {
      const errors = [];

      // Iterate through each destination
      for (const [destination, travelers] of Object.entries(
            groupedByDestination
      )) {
            const isAnyChecked = travelers.some((traveler) => traveler.checked);

            if (!isAnyChecked) {
                  errors.push(
                        `At least one traveler must be selected for destination: ${destination}`
                  );
            }
      }

      if (errors.length > 0) {
            errors.forEach((error) => toast.error(error));
            return false;
      }

      return true;
};

const RescheduleFlightForm = ({
      travellers,
      uniqueRoundTrips,
      bookingId,
      requestType,
      setShowMessage,
      mainModalClose,
}) => {
      const [loading, setLoading] = useState(false);

      const [agreeShow, setAgreeShow] = useState(false);
      const [destinations, setDestinations] = useState(uniqueRoundTrips);
      const [travellerDetails, setTravellerDetails] = useState(travellers);
      const [remarks, setRemarks] = useState("");

      const handleClose = () => setAgreeShow(false);
      const handleShow = () => setAgreeShow(true);

      const handleClick = (index) => {
            setDestinations((prevDestinations) =>
                  prevDestinations.map((item, i) => ({
                        ...item,
                        checked: i === index,
                  }))
            );
      };

      const handleDate = (index, value) => {
            setDestinations((prevDestinations) =>
                  prevDestinations.map((item, i) => ({
                        ...item,
                        date: i === index ? value : item?.date,
                  }))
            );
      };

      const handleFlightNo = (index, value) => {
            setDestinations((prevDestinations) =>
                  prevDestinations.map((item, i) => ({
                        ...item,
                        flightNo: i === index ? value : item?.flightNo,
                  }))
            );
      };

      const currentDestination = destinations.find((item) => item.checked);
      const currentTraveller =
            travellerDetails[currentDestination?.destination];

      const handleTraveller = (index, destination) => {
            setTravellerDetails((prevDetails) => {
                  return {
                        ...prevDetails,
                        [destination]: prevDetails[destination].map(
                              (traveler, i) => ({
                                    ...traveler,
                                    // Toggle the checked state for the selected traveler
                                    checked:
                                          i === index
                                                ? !traveler.checked
                                                : traveler.checked,
                              })
                        ),
                  };
            });
      };

      const token = JSON.parse(localStorage.getItem("token"));

      const handleSubmit = async (event) => {
            event.preventDefault();
            setLoading(true);

            if (
                  validateDestinations(destinations) &&
                  validateByDestination(travellerDetails)
            ) {
                  const data = {
                        request_type: requestType?.value,
                        destinations: JSON.stringify(destinations),
                        travellerDetails: JSON.stringify(travellerDetails),
                        remarks: remarks,
                  };

                  await axios
                        .post(
                              `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/${bookingId}/amendments`,
                              data,
                              {
                                    headers: {
                                          Authorization: `Bearer ${token}`,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.result === "success") {
                                    // toast.success(response.data.message);
                                    setShowMessage(true);
                                    mainModalClose();
                              }
                              if (response.data.result === "error") {
                                    if (response.data.message.request_type) {
                                          toast.error(
                                                response.data.message
                                                      .request_type
                                          );
                                    }
                                    if (response.data.message.new_departure) {
                                          toast.error(
                                                response.data.message
                                                      .new_departure
                                          );
                                    }
                                    if (response.data.message.remarks) {
                                          toast.error(
                                                response.data.message.remarks
                                          );
                                    }
                              }
                        })
                        .catch((error) => {
                              toast.error(error.message);
                        });
            }

            setLoading(false);
      };

      return (
            <>
                  <Col lg={12}>
                        <Form onSubmit={handleSubmit}>
                              <RescheduleDepartureContent>
                                    <RescheduleDepartureList className="mb-3">
                                          {destinations?.map((item, index) => (
                                                <RescheduleDepartureItem
                                                      className={`${
                                                            item?.checked
                                                                  ? "active"
                                                                  : ""
                                                      }`}
                                                      key={index}
                                                      onClick={() =>
                                                            handleClick(index)
                                                      }
                                                >
                                                      {item?.destination}
                                                </RescheduleDepartureItem>
                                          ))}
                                    </RescheduleDepartureList>
                                    <Row className="g-3">
                                          <Col lg={4}>
                                                <FormLabel>
                                                      New{" "}
                                                      {
                                                            currentDestination?.title
                                                      }{" "}
                                                      Date
                                                </FormLabel>
                                                <FormControl
                                                      type="date"
                                                      value={
                                                            currentDestination?.date
                                                      }
                                                      onChange={(event) =>
                                                            handleDate(
                                                                  currentDestination?.index,
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={6}>
                                                <FormLabel>
                                                      Flight No.
                                                </FormLabel>
                                                <FormControl
                                                      type="text"
                                                      value={
                                                            currentDestination?.flightNo
                                                      }
                                                      onChange={(event) =>
                                                            handleFlightNo(
                                                                  currentDestination?.index,
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={8}>
                                                <FormLabel className="mb-0">
                                                      Passengers
                                                </FormLabel>
                                                <FormGroup>
                                                      {currentTraveller?.map(
                                                            (item, index) => (
                                                                  <FormControlLabel
                                                                        checked={
                                                                              item?.checked
                                                                        }
                                                                        control={
                                                                              <Checkbox />
                                                                        }
                                                                        label={`${item?.ti} ${item?.fN} ${item?.lN} (${item.pt})`}
                                                                        onClick={() =>
                                                                              handleTraveller(
                                                                                    index,
                                                                                    currentDestination?.destination
                                                                              )
                                                                        }
                                                                  />
                                                            )
                                                      )}
                                                </FormGroup>
                                          </Col>

                                          <Col lg={12}>
                                                <FormLabel>
                                                      Additional Remarks
                                                </FormLabel>
                                                <FormControl
                                                      rows={4}
                                                      as="textarea"
                                                      onChange={(event) =>
                                                            setRemarks(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                />
                                          </Col>
                                          <Col lg={12}>
                                                By submitting this reschedule
                                                request, you acknowledge and
                                                agree to the{" "}
                                                <Link onClick={handleShow}>
                                                      policy
                                                </Link>
                                                .
                                          </Col>
                                          <Col lg={12}>
                                                <CommonButton
                                                      loading={loading}
                                                      button={true}
                                                      type="submit"
                                                      title="Submit"
                                                      classes="justify-content-center
                                                mt-3"
                                                      padding="0.6rem 1.5rem"
                                                />
                                          </Col>
                                    </Row>
                              </RescheduleDepartureContent>
                        </Form>
                  </Col>
                  <FlightModal
                        backdrop="static"
                        show={agreeShow}
                        top="18px"
                        centered
                        backgroundColor={(props) => props.theme.primary}
                  >
                        <ModalHeader>
                              Reschedule Policy Disclaimer
                              <CloseButton onClick={handleClose} />
                        </ModalHeader>

                        <ModalBody>
                              <ol>
                                    <li>
                                          Rescheduling charges, as per the
                                          airline or hotel’s policy, will apply
                                          and may vary.
                                    </li>
                                    <li>
                                          Fare differences may apply based on
                                          the new dates and availability.
                                    </li>
                                    <li>
                                          Aircasa is not liable for any
                                          availability issues or delays caused
                                          by the airline, hotel, or payment
                                          gateway.
                                    </li>
                                    <li>
                                          Additional service charges by Aircasa
                                          may apply for processing the
                                          reschedule.
                                    </li>
                                    <li>
                                          This request does not guarantee
                                          immediate confirmation of the
                                          reschedule.
                                    </li>
                              </ol>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default RescheduleFlightForm;
