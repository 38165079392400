import React, { useState } from "react";
import { PriceSummary } from "../checkout/styles";
import {
      OtherServiceContainer,
      ServiceButton,
} from "../flight_confirmations/FlightConfirmPayment/style";
import { LuTickets } from "react-icons/lu";
import { MdHomeRepairService } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import HotelRaiseModal from "./HotelRaiseModal";

const HotelRaiseRequest = ({ setShowMessage, bookingId }) => {
      const [show, setShow] = useState(false);
      const [loading, setLoading] = useState(false);

      const handleShow = () => {
            setLoading(true);

            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 2000);
      };

      const handleClose = () => {
            setShow(false);
      };

      return (
            <>
                  <PriceSummary>
                        <div className="price__box py-3 mb-4">
                              <div className="price__heading">
                                    <div className="price__title w-75">
                                          Raise Request
                                    </div>
                              </div>
                              <OtherServiceContainer>
                                    <ul>
                                          <li>
                                                <LuTickets />
                                                <span>Cancel Hotel</span>
                                          </li>
                                          <li>
                                                <MdHomeRepairService />
                                                <span>Add Modification</span>
                                          </li>
                                    </ul>
                                    <div className="d-flex align-items-center justify-content-center">
                                          <ServiceButton onClick={handleShow}>
                                                RAISE REQUEST{" "}
                                                {loading ? <Spinner /> : ""}
                                          </ServiceButton>
                                    </div>
                              </OtherServiceContainer>
                        </div>
                  </PriceSummary>
                  <HotelRaiseModal
                        handleClose={handleClose}
                        bookingId={bookingId}
                        show={show}
                        setShowMessage={setShowMessage}
                  />
            </>
      );
};

export default HotelRaiseRequest;
