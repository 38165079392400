import React, { useEffect, useRef, useState } from "react";
import {
      StarRatingContainer,
      StarRatingContent,
      StarRatingIcon,
      StarRatingTitle,
} from "./style";
import { FaAngleDown } from "react-icons/fa6";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const RatingCheckbox = ({ ratings, setRatings }) => {
      const [status, setStatus] = useState(false); // For dropdown visibility

      const locationRef = useRef(null);
      const formGroupRef = useRef(null);

      // Close dropdown when clicking outside
      useEffect(() => {
            function handleClickOutside(event) {
                  if (
                        formGroupRef.current &&
                        !formGroupRef.current.contains(event.target) &&
                        locationRef.current &&
                        !locationRef.current.contains(event.target)
                  ) {
                        setStatus(false);
                  }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                  document.removeEventListener("mousedown", handleClickOutside);
            };
      }, [formGroupRef, locationRef]);

      // Handle checkbox changes
      const handleCheckboxChange = (rating) => {
            setRatings((prevRatings) => ({
                  ...prevRatings,
                  [rating]: !prevRatings[rating], // Toggle the specific rating
            }));
      };

      return (
            <div className="position-relative">
                  <StarRatingContainer
                        onClick={() => setStatus(!status)} // Toggle dropdown visibility
                        ref={formGroupRef}
                  >
                        <StarRatingTitle>Star Ratings</StarRatingTitle>
                        <StarRatingIcon>
                              <span className="separator__indicator"></span>
                              <div className="star__iconContainer">
                                    <FaAngleDown />
                              </div>
                        </StarRatingIcon>
                  </StarRatingContainer>
                  {status && (
                        <StarRatingContent ref={locationRef}>
                              <FormGroup>
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={ratings.fiveStar}
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "fiveStar"
                                                            )
                                                      }
                                                />
                                          }
                                          label="5 Star"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={ratings.fourStar}
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "fourStar"
                                                            )
                                                      }
                                                />
                                          }
                                          label="4 Star"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={
                                                            ratings.threeStar
                                                      }
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "threeStar"
                                                            )
                                                      }
                                                />
                                          }
                                          label="3 Star"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={ratings.twoStar}
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "twoStar"
                                                            )
                                                      }
                                                />
                                          }
                                          label="2 Star"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={ratings.oneStar}
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "oneStar"
                                                            )
                                                      }
                                                />
                                          }
                                          label="1 Star"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Checkbox
                                                      checked={ratings.unrated}
                                                      onChange={() =>
                                                            handleCheckboxChange(
                                                                  "unrated"
                                                            )
                                                      }
                                                />
                                          }
                                          label="Unrated"
                                    />
                              </FormGroup>
                        </StarRatingContent>
                  )}
            </div>
      );
};

export default RatingCheckbox;
