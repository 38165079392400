const checkFlightType = ({ domesticStatus, commissionInitial, fareType }) => {
      let commissionPercent = 0;
      if (domesticStatus) {
            const domesticItem = commissionInitial?.fareType?.domestic;

            if (fareType === "ECONOMY") {
                  commissionPercent = domesticItem?.economy
                        ? parseFloat(domesticItem?.economy)
                        : 0;
            } else if (fareType === "PREMIUM_ECONOMY") {
                  commissionPercent = domesticItem?.premiumEconomy
                        ? parseFloat(domesticItem?.premiumEconomy)
                        : 0;
            } else if (fareType === "BUSINESS") {
                  commissionPercent = domesticItem?.business
                        ? parseFloat(domesticItem?.business)
                        : 0;
            } else if (fareType === "FIRST") {
                  commissionPercent = domesticItem?.first
                        ? parseFloat(domesticItem?.first)
                        : 0;
            }
      } else {
            const internationalItem =
                  commissionInitial?.fareType?.international;
            if (fareType === "ECONOMY") {
                  commissionPercent = internationalItem?.economy
                        ? parseFloat(internationalItem?.economy)
                        : 0;
            } else if (fareType === "PREMIUM_ECONOMY") {
                  commissionPercent = internationalItem?.premiumEconomy
                        ? parseFloat(internationalItem?.premiumEconomy)
                        : 0;
            } else if (fareType === "BUSINESS") {
                  commissionPercent = internationalItem?.business
                        ? parseFloat(internationalItem?.business)
                        : 0;
            } else if (fareType === "FIRST") {
                  commissionPercent = internationalItem?.first
                        ? parseFloat(internationalItem?.first)
                        : 0;
            }
      }

      return commissionPercent;
};

const checkSeriesFare = ({ domesticStatus, commissionInitial }) => {
      let seriesFare = 0;
      if (domesticStatus) {
            seriesFare = commissionInitial?.fareType?.domestic?.seriesFare;
      } else {
            seriesFare = commissionInitial?.fareType?.international?.seriesFare;
      }

      return seriesFare;
};

export const checkCommission = ({
      airlineCode,
      fareDetail,
      commission,
      domesticStatus,
      totalFare,
      netFare,
      children,
}) => {
      const fareType = fareDetail?.fd?.ADULT?.cc;
      const checkType =
            fareDetail?.fareIdentifier === "OFFER_FARE_WITH_PNR" ? true : false;

      const amountDifference = parseFloat(totalFare - netFare).toFixed(2);

      const commissionInitial = commission?.find(
            (item) => item?.airlineCode === airlineCode
      );
      const defaultCommission = commission?.find(
            (item) => item?.default === true
      );

      if (checkType === true) {
            if (children) {
                  return netFare;
            }
            if (commissionInitial) {
                  const commissionAmount = checkSeriesFare({
                        domesticStatus,
                        commissionInitial,
                  });
                  const totalNetAmount = netFare + commissionAmount;

                  return totalNetAmount;
            } else {
                  const commissionAmount = checkSeriesFare({
                        domesticStatus,
                        commissionInitial: defaultCommission,
                  });
                  const totalNetAmount = netFare + commissionAmount;

                  return totalNetAmount;
            }
      } else {
            if (commissionInitial) {
                  const commissionPercent = checkFlightType({
                        domesticStatus,
                        commissionInitial,
                        fareType,
                  });

                  const commissionAmount =
                        amountDifference * (commissionPercent / 100);
                  const totalNetAmount = netFare + commissionAmount;

                  return totalNetAmount;
            } else {
                  const commissionPercent = checkFlightType({
                        domesticStatus,
                        commissionInitial: defaultCommission,
                        fareType,
                  });

                  const commissionAmount =
                        amountDifference * (commissionPercent / 100);
                  const totalNetAmount = netFare + commissionAmount;

                  return totalNetAmount;
            }
      }
};

export const totalAmountAfterCommission = ({
      airlineCode,
      domesticStatus,
      commission,
      fareDetail,
      totalFare,
}) => {
      const checkType =
            fareDetail?.fareIdentifier === "OFFER_FARE_WITH_PNR" ? true : false;

      const commissionInitial = commission?.find(
            (item) => item?.airlineCode === airlineCode
      );
      const defaultCommission = commission?.find(
            (item) => item?.default === true
      );
      let totalAmount = totalFare;

      if (checkType) {
            if (commissionInitial) {
                  const commissionAmount = checkSeriesFare({
                        domesticStatus,
                        commissionInitial,
                  });
                  totalAmount = totalFare + commissionAmount;

                  return totalAmount;
            } else {
                  const commissionAmount = checkSeriesFare({
                        domesticStatus,
                        commissionInitial: defaultCommission,
                  });
                  totalAmount = totalFare + commissionAmount;

                  return totalAmount;
            }
      } else {
            return totalAmount;
      }
};
