import React, { useState } from "react";
import { FlightModal } from "../flight_confirmations/FlightConfirmModal/style";
import { Col, FormLabel, ModalBody, ModalHeader, Row } from "react-bootstrap";
import Select from "react-select";
import HotelCancelForm from "./HotelCancelForm";
import HotelModificationForm from "./HotelModificationForm";

const options = [
      {
            label: "Cancel Hotel",
            value: "Cancel Hotel",
      },
      {
            label: "Add Modification",
            value: "Add Modification",
      },
];

const HotelRaiseModal = ({ handleClose, bookingId, show, setShowMessage }) => {
      const [requestType, setRequestType] = useState("");

      return (
            <>
                  {" "}
                  <FlightModal
                        backdrop="static"
                        show={show}
                        size="lg"
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Your Request</ModalHeader>
                        <ModalBody>
                              <Row className="g-3">
                                    <Col lg={12}>
                                          <FormLabel>Request Type</FormLabel>
                                          <Select
                                                options={options}
                                                value={requestType}
                                                onChange={setRequestType}
                                          />
                                    </Col>
                                    {requestType.value === "Cancel Hotel" && (
                                          <HotelCancelForm
                                                bookingId={bookingId}
                                                mainModalClose={handleClose}
                                                setShowMessage={setShowMessage}
                                                requestType={requestType}
                                          />
                                    )}
                                    {requestType.value === "Add Modification" && (
                                          <HotelModificationForm
                                                bookingId={bookingId}
                                                mainModalClose={handleClose}
                                                setShowMessage={setShowMessage}
                                                requestType={requestType}
                                          />
                                    )}
                              </Row>
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default HotelRaiseModal;
