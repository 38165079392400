export const flightBannerData = [
      {
            image: "/images/banners/banner-3.png",
            title: "Welcome to Aircasa",
            extraImage: "/images/banners/extra-1.png",
            subtitle: "Find the best Worldwide Flight Deals",
      },
      // {
      //       image: "/images/banners/banner-3.png",
      //       title: "Find the best Worldwide <br />Hotel Deals",
      // },
];
export const homepackages = [
      {
            image: "/images/package/package-img-1.png",
            title: "Thailand Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-2.png",
            title: "Japan Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-3.png",
            title: "Europe Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-1.png",
            title: "Thailand Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-2.png",
            title: "Japan Trip",
            text: "Starting From $100 per person",
      },
      {
            image: "/images/package/package-img-3.png",
            title: "Europe Trip",
            text: "Starting From $100 per person",
      },
];

export const hotelBannerDate = [
      {
            image: "/images/banners/banner-2.png",
            extraImage: "/images/banners/extra-3.png",
            title: "Find the best Worldwide <br />Hotel Deals",
      },
];

export const packagesData = [
      {
            image: "/images/banners/hotel.png",
            title: "20% off on thailand <br/> package bookings. ",
      },
];

export const packageList = [
      {
            image: "/images/package/slider-1.png",
            title: "Thailand",
            price: "98,000",
      },
      {
            image: "/images/package/slider-2.png",
            title: "London",
            price: "98,000",
      },
      {
            image: "/images/package/slider-3.png",
            title: "Australia",
            price: "98,000",
      },
      {
            image: "/images/package/slider-4.png",
            title: "Dubai",
            price: "98,000",
      },
];

export const indianPackageList = [
      {
            image: "/images/package/slider-1.png",
            title: "Goa",
            price: "98,000",
      },
      {
            image: "/images/package/slider-2.png",
            title: "Himachal",
            price: "98,000",
      },
      {
            image: "/images/package/slider-3.png",
            title: "Jaipur",
            price: "98,000",
      },
      {
            image: "/images/package/slider-4.png",
            title: "Manali",
            price: "98,000",
      },
];

export const hotelPackageList = [
      {
            image: "/images/package/hotel-1.png",
            title: "Mumbai",
      },
      {
            image: "/images/package/hotel-2.png",
            title: "Delhi",
      },
      {
            image: "/images/package/hotel-3.png",
            title: "Jaipur",
      },
      {
            image: "/images/package/hotel-4.png",
            title: "Dubai",
      },
];

export const partners = [
      {
            title: "Taj",
            image: "/images/partners/idpIt7VYbp_1732950367914 1.png",
      },
      {
            title: "Oberoi Hotels & Resorts",
            image: "/images/partners/id65GZwbfz_1732950450506 1.png",
      },
      {
            title: "ITC Hotels Responsible Luxury",
            image: "/images/partners/ITC Hotels Cares_idK0jA3D0j_0 1.png",
      },
      {
            title: "Hyatt",
            image: "/images/partners/pngegg 1.png",
      },
      {
            title: "Hilton",
            image: "/images/partners/pngegg (1) 1.png",
      },
      {
            title: "Radisson BLU",
            image: "/images/partners/pngwing.com 1.png",
      },
      {
            title: "Lemon tree Hotels",
            image: "/images/partners/lemon-tree-hotels-seeklogo.com 1.png",
      },
      {
            title: "The Lalit",
            image: "/images/partners/66bcb61e9494c66ac36ecb0f 1.png",
      },
      {
            title: "Holiday Inn",
            image: "/images/partners/589a47955aa6293a4aac48b6 1.png",
      },
      {
            title: "Accor",
            image: "/images/partners/pngwing.com (1) 1.png",
      },
];
