import styled from "styled-components";

export const HotelContainer = styled.div`
      display: flex;
      gap: 20px;
`;

export const HotelHeading = styled.h3`
      font-weight: 510;
      margin-bottom: ${(props) => props.marginBottom || "20px"};
      margin-top: 0rem;
      font-size: 20px;
`;

export const HotelInfoTitle = styled.h4`
      font-size: 20px;

      .spinner-border {
            width: 20px !important;
            height: 20px !important;
      }
`;

export const LocationMap = styled.div`
      border-radius: 10px;
      height: 300px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      overflow: hidden;
      min-width: 60%;
`;

export const HotelAddress = styled.div`
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 5px;
`;

export const HotelAddressIcon = styled.div`
      display: flex;
      align-items: center;
      gap: 0.25rem;
      color: ${(props) => props.color};
      font-size: 14px;

      .material-symbols-outlined {
            font-size: 18px;
      }

      svg {
            font-size: ${(props) => props.iconFont || "20px"};
            color: #ffa918;
      }
`;

export const HotelFeatureImage = styled.div`
      height: 159px;
      margin-bottom: 2.5px;
      overflow: hidden;

      &:last-child {
            margin-bottom: 0;
      }

      img {
            width: 100%;
            height: 100%;
      }
`;
