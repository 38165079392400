import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { partners } from "../../../data/banners";
import { HotelPartnerContainer } from "./style";
import CommonTitle from "../../../components/Common/CommonTitle";

const HotelPartner = () => {
      return (
            <>
                  <Container>
                        <CommonTitle title={"Partnered Hotel Brands"} />
                        <Row className="justify-content-center">
                              <Col lg={10}>
                                    <HotelPartnerContainer>
                                          {partners?.map((item, index) => (
                                                <div key={index}>
                                                      <img
                                                            src={item?.image}
                                                            alt=""
                                                      />
                                                </div>
                                          ))}
                                    </HotelPartnerContainer>
                              </Col>
                        </Row>
                  </Container>
            </>
      );
};

export default HotelPartner;
