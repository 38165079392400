import React from "react";
import { Col } from "react-bootstrap";
import { RescheduleDepartureContent } from "./style";

const SsrRequestForm = () => {
      return (
            <>
                  <Col lg={12}>
                        <RescheduleDepartureContent>
                              Coming Soon
                        </RescheduleDepartureContent>
                  </Col>
            </>
      );
};

export default SsrRequestForm;
