import React from "react";
import PriceSummaryContent from "../../components/Frontend/checkout/PriceSummaryBox/PriceSummaryContent";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";
import { handleHotelCommissionOnly } from "../../hooks/hotelCommission";

const HotelPriceDetails = ({ hotelInfo, priceInfo, border, userCtx }) => {
      const handleBaseFare = () => {
            let totalBaseFare = 0;
            priceInfo?.ris?.map(
                  (item) =>
                        (totalBaseFare +=
                              item?.tfcs?.BF +
                              handleHotelCommissionOnly({
                                    commissions:
                                          userCtx?.profile?.hotelCommission,
                                    domesticStatus: true,
                                    hotelRating: hotelInfo?.rt,
                              }))
            );
            return totalBaseFare;
      };

      const handleTAF = () => {
            let totalTAF = 0;
            priceInfo?.ris?.map((item) => (totalTAF += item?.tfcs?.TAF));
            return totalTAF;
      };

      const handleTotalManagementFee = () => {
            let totalMF = 0;
            priceInfo?.ris?.map((item) => (totalMF += item?.tafcs?.TAF?.MF));
            return totalMF;
      };

      const handleTotalManageGstFee = () => {
            let totalMF = 0;
            priceInfo?.ris?.map((item) => (totalMF += item?.tafcs?.TAF?.MFT));
            return totalMF;
      };

      return (
            <>
                  <PriceSummaryContent
                        title="Base Fare"
                        border={border}
                        totalServicesAmount={convertAmount(
                              convertFloat(handleBaseFare())
                        )}
                  >
                        <ul className="price__list list-inline mb-0">
                              {priceInfo?.ris?.map((item, index) => (
                                    <li
                                          className="price__item  d-flex align-items-center"
                                          key={index}
                                    >
                                          <div className="price__item--title w-75">
                                                {item?.srn}
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            item?.tfcs?.BF +
                                                                  handleHotelCommissionOnly(
                                                                        {
                                                                              commissions:
                                                                                    userCtx
                                                                                          ?.profile
                                                                                          ?.hotelCommission,
                                                                              domesticStatus: true,
                                                                              hotelRating:
                                                                                    hotelInfo?.rt,
                                                                        }
                                                                  )
                                                      )
                                                )}
                                          </div>
                                    </li>
                              ))}
                        </ul>
                  </PriceSummaryContent>
                  <PriceSummaryContent
                        title="Taxes & Fees"
                        border={border}
                        totalServicesAmount={convertAmount(
                              convertFloat(handleTAF())
                        )}
                  >
                        <ul className="price__list list-inline mb-0">
                              {handleTotalManagementFee() && (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                Management Fees
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            handleTotalManagementFee()
                                                      )
                                                )}
                                          </div>
                                    </li>
                              )}
                              {handleTotalManageGstFee() && (
                                    <li className="price__item  d-flex align-items-center">
                                          <div className="price__item--title w-75">
                                                GST
                                          </div>
                                          <div className="price__item--amount">
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(
                                                            handleTotalManageGstFee()
                                                      )
                                                )}
                                          </div>
                                    </li>
                              )}
                        </ul>
                  </PriceSummaryContent>
            </>
      );
};

export default HotelPriceDetails;
