import React, { useEffect, useState } from "react";
import { DashboardContainer } from "../dashboard/styles";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
import { FlightConfirmationDownload, FlightConfirmationHeader } from "./style";
import { Col, Row } from "react-bootstrap";
import FlightConfirmModal from "./FlightConfirmModal";
import FlightConfirmPassenger from "./FlightConfirmPassenger";
import FlightConfirmDetail from "./FlightConfirmDetail";
import { FlightDetailContainer } from "../../components/Dashboard/FlightDetails/style";
import FlightConfirmPayment from "./FlightConfirmPayment";
import { Skeleton } from "@mui/material";
import { Alert as AlertContainer } from "react-bootstrap";

const FlightConfirmation = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();

      const [agentOrder, setAgentOrder] = useState([]);
      const [show, setShow] = useState(false);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [totalPriceInfo, setTotalPriceInfo] = useState([]);
      const [travellerInfos, setTravellerInfos] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.AIR
                                                ?.tripInfos
                                    );
                                    setTotalPriceInfo(
                                          response.data.itemInfos?.AIR
                                                ?.totalPriceInfo
                                                ?.totalFareDetail
                                    );
                                    setTravellerInfos(
                                          response.data.itemInfos?.AIR
                                                ?.travellerInfos
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const updateData = async () => {
            const pnrNumber =
                  travellerInfos[0]?.pnrDetails[
                        `${tripInfos[0]?.sI[0]?.da?.code}-${tripInfos[0]?.sI[0]?.aa?.code}`
                  ];
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              pnr_number: pnrNumber,
                        },
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        console.log(error.message);
                  });
      };

      useEffect(() => {
            updateData();
      }, [travellerInfos]);

      return (
            <>
                  <DashboardContainer flexDirection="column" padding="120px">
                        <Row>
                              {show && (
                                    <Col lg={8}>
                                          <AlertContainer
                                                onClose={() => setShow(false)}
                                                dismissible
                                                variant="success"
                                          >
                                                We have received your request
                                                and will respond shortly to your
                                                registered email. <br /> For
                                                further assistance, please
                                                contact us at{" "}
                                                <Link to="tel:+918766601888">
                                                      +91 8766601888
                                                </Link>
                                                .
                                          </AlertContainer>
                                    </Col>
                              )}
                              <Col lg={8}>
                                    <FlightConfirmationDownload>
                                          <FlightConfirmationHeader>
                                                <div className="confirmation__header-img">
                                                      {!loading ? (
                                                            <img
                                                                  src="/images/booking.png"
                                                                  alt=""
                                                            />
                                                      ) : (
                                                            <Skeleton
                                                                  height="45px"
                                                                  width={"45px"}
                                                                  style={{
                                                                        transform:
                                                                              "scale(1)",
                                                                  }}
                                                                  className="rounded-pill"
                                                            />
                                                      )}
                                                </div>
                                                <div className="confirmation__header-title text-success">
                                                      {!loading ? (
                                                            "Booking Success"
                                                      ) : (
                                                            <Skeleton
                                                                  height="30px"
                                                                  width={
                                                                        "200px"
                                                                  }
                                                                  style={{
                                                                        transform:
                                                                              "scale(1)",
                                                                  }}
                                                                  className="mb-2"
                                                            />
                                                      )}{" "}
                                                      <div className="confirmation__header-subtitle">
                                                            Booking Id:{" "}
                                                            {!loading ? (
                                                                  <span>
                                                                        {
                                                                              bookingId
                                                                        }
                                                                  </span>
                                                            ) : (
                                                                  <Skeleton
                                                                        height="24px"
                                                                        width={
                                                                              "120px"
                                                                        }
                                                                        style={{
                                                                              transform:
                                                                                    "scale(1)",
                                                                        }}
                                                                        className="d-inline-block"
                                                                  />
                                                            )}
                                                      </div>
                                                </div>
                                          </FlightConfirmationHeader>
                                          <div>
                                                <FlightConfirmModal
                                                      totalPriceInfo={
                                                            totalPriceInfo
                                                      }
                                                      bookingId={bookingId}
                                                      order={order}
                                                      travellerInfos={
                                                            travellerInfos
                                                      }
                                                      tripInfos={tripInfos}
                                                      agentOrder={agentOrder}
                                                      loadingData={loading}
                                                />
                                          </div>
                                    </FlightConfirmationDownload>
                                    <FlightConfirmDetail
                                          tripInfos={tripInfos}
                                          bookingId={order?.bookingId}
                                    />
                                    <FlightDetailContainer padding="10px 20px">
                                          <FlightConfirmPassenger
                                                travellerInfos={travellerInfos}
                                                tripInfos={tripInfos}
                                                order={order}
                                          />
                                    </FlightDetailContainer>
                              </Col>
                              <Col lg={4}>
                                    <FlightConfirmPayment
                                          totalPriceInfo={totalPriceInfo}
                                          travellerInfos={travellerInfos}
                                          agentOrder={agentOrder}
                                          loading={loading}
                                          bookingId={bookingId}
                                          setShow={setShow}
                                    />
                              </Col>
                        </Row>
                  </DashboardContainer>
            </>
      );
};

export default FlightConfirmation;
