import { Link } from "react-router-dom";
import styled from "styled-components";

export const MenuOptions = styled.div`
      display: flex;
      gap: 20px;
`;

export const MenuOptionItem = styled(Link)`
      display: block;
      width: calc(25% - 20px);
`;

export const FilterButton = styled.button`
      color: ${(props) => props.theme.white};
      width: ${(props) => props.width || "100px"};
      border: 0;
      border-radius: 5px;
      padding: 6px 20px;
      background-color: ${(props) => props.theme.primary};

      .spinner-border {
            height: 1rem !important;
            width: 1rem !important;
            border-width: 1px !important;
      }
`;

export const MenuOptionButton = styled(Link)`
      display: block;
      font-size: 11px;
      text-transform: uppercase;
      border-radius: 2.5px;
      padding: 2px 8px;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.theme.primary};
      border: 1px solid ${(props) => props.theme.primary};
      transition: all 0.3s ease-in-out;

      &:hover {
            background-color: ${(props) => props.theme.white};
            color: ${(props) => props.theme.primary};
      }
`;

export const MenuOptionIcon = styled.div`
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      background-color: ${(props) => props.color || props.theme.primary};

      span {
            color: ${(props) => props.theme.white};
            font-size: ${(props) => props.fontSize};
      }

      svg {
            fill: ${(props) => props.theme.white};

            g {
                  fill: ${(props) => props.theme.white};
            }
      }
`;

export const MenuOptionName = styled.div`
      font-size: 14px;
      padding: 10px;
      text-align: center;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      color: ${(props) => props.theme.white};
      background-color: ${(props) => props.color || props.theme.primary};
`;
