import React from "react";
import { HotelHeading } from "../../hotels/styles";
import { RoomContainer } from "../../hotels/HotelRoom/style";
import { HotelAboutList } from "../HotelAmenities/style";

const HotelAboutDetail = ({ description }) => {
      return (
            <>
                  {description?.amenities ||
                  description?.dining ||
                  description?.business_amenities ||
                  description?.rooms ||
                  description?.attractions ||
                  description?.headline ||
                  description?.spoken_languages ? (
                        <>
                              <HotelHeading className="mt-4 pt-2">
                                    About Hotel
                              </HotelHeading>
                              <RoomContainer>
                                    <HotelAboutList>
                                          {description?.amenities && (
                                                <div className="about__item-list">
                                                      <h4>Amenities</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.amenities
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.dining && (
                                                <div className="about__item-list">
                                                      <h4>Dinning</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.dining
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.business_amenities && (
                                                <div className="about__item-list">
                                                      <h4>
                                                            Business Amenities
                                                      </h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.business_amenities
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.rooms && (
                                                <div className="about__item-list">
                                                      <h4>Rooms</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.rooms
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.attractions && (
                                                <div className="about__item-list">
                                                      <h4>Attractions</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.attractions
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.locations && (
                                                <div className="about__item-list">
                                                      <h4>Locations</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.locations
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}

                                          {description?.headline && (
                                                <div className="about__item-list">
                                                      <h4>Headline</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.headline
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                          {description?.spoken_languages && (
                                                <div className="about__item-list">
                                                      <h4>Spoken Language</h4>
                                                      <ul>
                                                            <li>
                                                                  <span>
                                                                        {
                                                                              description?.spoken_languages
                                                                        }
                                                                  </span>
                                                            </li>
                                                      </ul>
                                                </div>
                                          )}
                                    </HotelAboutList>
                              </RoomContainer>
                        </>
                  ) : null}
            </>
      );
};

export default HotelAboutDetail;
