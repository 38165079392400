import React from "react";
import { inFantList, titleList } from "../../data/dashboard";
import { FlightDetails, TravellerAccordionForm } from "../checkout/styles";
import {
      TravellersDetail,
      TravellersTitle,
} from "../../components/Frontend/checkout/TravellersDetailBox/styles";
import { Col, FormControl, FormLabel, Row } from "react-bootstrap";
import Select from "react-select";
import { FlightDetailCategory } from "../../components/Dashboard/FlightDetails/style";

const HotelTravellerDetail = ({
      travellers,
      handleTitle,
      handleInputChange,
      priceInfo,
}) => {
      const checkTitle = (title) => {
            if (title === "CHILD" || title === "INFANT") {
                  return inFantList;
            } else {
                  return titleList;
            }
      };

      return (
            <>
                  <FlightDetails className="mb-4">
                        <FlightDetailCategory>
                              Guest Details
                        </FlightDetailCategory>

                        {travellers?.map((rooms, roomIndex) => (
                              <>
                                    <TravellersTitle className="mb-3 d-flex gap-2 mt-4">
                                          <h3>
                                                {" "}
                                                For Room {
                                                      rooms?.roomIndex
                                                }:{" "}
                                          </h3>
                                          <span>
                                                {priceInfo?.ris[roomIndex]?.rc}
                                          </span>
                                    </TravellersTitle>
                                    {rooms?.travellerInfo?.map(
                                          (item, index) => (
                                                <>
                                                      <TravellersDetail
                                                            fontSize="16px"
                                                            className="mt-3"
                                                      >
                                                            <div className="travellers__heading">
                                                                  <div className="travellers__title">
                                                                        {
                                                                              item?.ptName
                                                                        }
                                                                        :{" "}
                                                                        <span>
                                                                              {item?.pt ===
                                                                              "ADULT" ? (
                                                                                    <>
                                                                                          {item?.title ||
                                                                                          item?.firstName ||
                                                                                          item?.lastName
                                                                                                ? `${item?.title} ${item?.firstName} ${item?.lastName}`
                                                                                                : `(12+ yrs)`}
                                                                                    </>
                                                                              ) : (
                                                                                    item?.pt ===
                                                                                          "CHILD" && (
                                                                                          <>
                                                                                                {item?.title ||
                                                                                                item?.firstName ||
                                                                                                item?.lastName
                                                                                                      ? `${item?.title} ${item?.firstName} ${item?.lastName}`
                                                                                                      : `(2 - 12 yrs)`}
                                                                                          </>
                                                                                    )
                                                                              )}
                                                                        </span>
                                                                  </div>
                                                            </div>
                                                      </TravellersDetail>
                                                      <TravellerAccordionForm padding="0">
                                                            <Row className="g-3">
                                                                  <Col lg={2}>
                                                                        <FormLabel>
                                                                              Title
                                                                        </FormLabel>
                                                                        <Select
                                                                              required={
                                                                                    true
                                                                              }
                                                                              name="title"
                                                                              options={checkTitle(
                                                                                    item?.pt
                                                                              )}
                                                                              onChange={(
                                                                                    value,
                                                                                    event
                                                                              ) =>
                                                                                    handleTitle(
                                                                                          value.value,
                                                                                          "title",
                                                                                          index,
                                                                                          event,
                                                                                          roomIndex
                                                                                    )
                                                                              }
                                                                              value={checkTitle(
                                                                                    item?.pt
                                                                              )?.find(
                                                                                    (
                                                                                          option
                                                                                    ) =>
                                                                                          option.value ===
                                                                                          item?.title
                                                                              )}
                                                                        />
                                                                  </Col>
                                                                  <Col lg={5}>
                                                                        <FormLabel>
                                                                              First
                                                                              Name
                                                                        </FormLabel>
                                                                        <FormControl
                                                                              type="text"
                                                                              name="firstName"
                                                                              onChange={(
                                                                                    event
                                                                              ) =>
                                                                                    handleInputChange(
                                                                                          index,
                                                                                          event,
                                                                                          "",
                                                                                          "",
                                                                                          "",
                                                                                          roomIndex
                                                                                    )
                                                                              }
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    item?.firstName
                                                                              }
                                                                        />
                                                                  </Col>
                                                                  <Col lg={5}>
                                                                        <FormLabel>
                                                                              Last
                                                                              Name
                                                                        </FormLabel>
                                                                        <FormControl
                                                                              name="lastName"
                                                                              onChange={(
                                                                                    event
                                                                              ) =>
                                                                                    handleInputChange(
                                                                                          index,
                                                                                          event,
                                                                                          "",
                                                                                          "",
                                                                                          "",
                                                                                          roomIndex
                                                                                    )
                                                                              }
                                                                              required={
                                                                                    true
                                                                              }
                                                                              value={
                                                                                    item?.lastName
                                                                              }
                                                                        />
                                                                  </Col>
                                                                  {item?.pt ===
                                                                        "ADULT" &&
                                                                        priceInfo?.ipr &&
                                                                        index ===
                                                                              0 && (
                                                                              <Col
                                                                                    lg={
                                                                                          4
                                                                                    }
                                                                              >
                                                                                    <FormLabel>
                                                                                          PAN
                                                                                          Card
                                                                                          Number
                                                                                    </FormLabel>
                                                                                    <FormControl
                                                                                          name="pan"
                                                                                          onChange={(
                                                                                                event
                                                                                          ) =>
                                                                                                handleInputChange(
                                                                                                      index,
                                                                                                      event,
                                                                                                      "",
                                                                                                      "",
                                                                                                      "",
                                                                                                      roomIndex
                                                                                                )
                                                                                          }
                                                                                          required={
                                                                                                true
                                                                                          }
                                                                                          value={
                                                                                                item?.pan
                                                                                          }
                                                                                    />
                                                                              </Col>
                                                                        )}
                                                            </Row>
                                                      </TravellerAccordionForm>
                                                </>
                                          )
                                    )}
                              </>
                        ))}
                  </FlightDetails>
            </>
      );
};

export default HotelTravellerDetail;
