import React from "react";
import {
      HotelFareInfoSummary,
      HotelFareRefund,
      HotelFareRefundContent,
      HotelFareRoomButton,
      HotelFareRoomContainer,
      HotelFareRoomFree,
      HotelFareRoomInfo,
      HotelFareRoomTitle,
} from "./style";
import { FaBed, FaUser } from "react-icons/fa6";
import { MdChildFriendly, MdNoMeals } from "react-icons/md";
import { convertFullDate } from "../../../../hooks/CurrentData";
import { RefundIconContainer } from "../../search/FlightTripPrice/style";
import { GiMeal } from "react-icons/gi";

const normalizeMealBasis = (mb) =>
      mb
            ?.toLowerCase() // Convert to lowercase
            ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
            ?.trim() // Remove leading and trailing spaces
            ?.split(" ") // Split into words
            ?.map(
                  (word) => word.charAt(0).toUpperCase() + word.slice(1) // Capitalize the first letter of each word
            )
            ?.join(" ");

const RoomPayList = ({ selectedRoom, handleShow }) => {
      const activeRoom = () => {
            if (selectedRoom) {
                  if (selectedRoom[0]?.selectedOption) {
                        return selectedRoom[0]?.selectedOption;
                  }
            }
      };

      const selectOneRoom = (options, index) => {
            if (index >= 0) {
                  if (index === 0 && options?.ris) {
                        return options?.ris[index];
                  } else {
                        if (options?.id) {
                              return options;
                        } else {
                              return "-";
                        }
                  }
            } else {
                  return "-";
            }
      };
      return (
            <div>
                  {selectedRoom
                        ? selectedRoom?.map((item, index) => (
                                <HotelFareRoomContainer key={index}>
                                      <HotelFareRoomInfo>
                                            <HotelFareRoomTitle>
                                                  Room {index + 1}
                                            </HotelFareRoomTitle>
                                            <HotelFareRoomButton
                                                  onClick={() =>
                                                        handleShow(
                                                              activeRoom()?.id
                                                        )
                                                  }
                                            >
                                                  Room Policy
                                            </HotelFareRoomButton>
                                      </HotelFareRoomInfo>
                                      <div>
                                            <HotelFareInfoSummary gap="20px">
                                                  <HotelFareInfoSummary
                                                        iconFont="14px"
                                                        marginBottom="5px"
                                                        gap="5px"
                                                  >
                                                        <FaUser />{" "}
                                                        {item?.room
                                                              ?.numberOfAdults
                                                              ? item?.room
                                                                      ?.numberOfAdults
                                                              : 0}{" "}
                                                        Adults
                                                  </HotelFareInfoSummary>
                                                  <HotelFareInfoSummary
                                                        iconFont="16px"
                                                        marginBottom="5px"
                                                        gap="5px"
                                                  >
                                                        <MdChildFriendly />{" "}
                                                        {item?.room
                                                              ?.numberOfChild
                                                              ? item?.room
                                                                      ?.numberOfChild
                                                              : 0}{" "}
                                                        Children
                                                  </HotelFareInfoSummary>
                                            </HotelFareInfoSummary>
                                            <HotelFareInfoSummary
                                                  marginBottom="5px"
                                                  gap="5px"
                                            >
                                                  <FaBed />{" "}
                                                  {selectOneRoom(
                                                        item?.selectedOption,
                                                        index
                                                  ) !== "-"
                                                        ? selectOneRoom(
                                                                item?.selectedOption,
                                                                index
                                                          )?.rc
                                                        : "-"}
                                            </HotelFareInfoSummary>
                                            <HotelFareInfoSummary
                                                  marginBottom="5px"
                                                  gap="5px"
                                            >
                                                  {normalizeMealBasis(
                                                        selectOneRoom(
                                                              item?.selectedOption,
                                                              index
                                                        )?.mb
                                                  ) !== "Room Only" ? (
                                                        <GiMeal className="active" />
                                                  ) : (
                                                        <MdNoMeals />
                                                  )}
                                                  {selectOneRoom(
                                                        item?.selectedOption,
                                                        index
                                                  ) !== "-" ? (
                                                        <>
                                                              <span
                                                                    className={
                                                                          normalizeMealBasis(
                                                                                selectOneRoom(
                                                                                      item?.selectedOption,
                                                                                      index
                                                                                )
                                                                                      ?.mb
                                                                          ) !==
                                                                          "Room Only"
                                                                                ? "active"
                                                                                : ""
                                                                    }
                                                              >
                                                                    {
                                                                          selectOneRoom(
                                                                                item?.selectedOption,
                                                                                index
                                                                          )?.mb
                                                                    }
                                                              </span>
                                                        </>
                                                  ) : (
                                                        "-"
                                                  )}
                                            </HotelFareInfoSummary>
                                            {activeRoom() && (
                                                  <HotelFareRefundContent>
                                                        {activeRoom()?.cnp
                                                              ?.ifra ? (
                                                              <HotelFareRefund>
                                                                    R
                                                              </HotelFareRefund>
                                                        ) : (
                                                              <RefundIconContainer
                                                                    status={0}
                                                                    width="20px"
                                                                    height="20px"
                                                              >
                                                                    R
                                                              </RefundIconContainer>
                                                        )}
                                                        <HotelFareRoomFree
                                                              className={
                                                                    activeRoom()
                                                                          ?.cnp
                                                                          ?.ifra
                                                                          ? ""
                                                                          : "text-muted"
                                                              }
                                                        >
                                                              {selectOneRoom(
                                                                    item?.selectedOption,
                                                                    index
                                                              ) !== "-"
                                                                    ? activeRoom()
                                                                            ?.cnp
                                                                            ?.ifra
                                                                          ? "Free Cancellation"
                                                                          : "Non Refundable"
                                                                    : "-"}
                                                        </HotelFareRoomFree>
                                                        {activeRoom()?.cnp
                                                              ?.ifra &&
                                                              activeRoom()
                                                                    ?.ddt && (
                                                                    <span>
                                                                          till{" "}
                                                                          {convertFullDate(
                                                                                activeRoom()
                                                                                      ?.ddt
                                                                          )}
                                                                    </span>
                                                              )}
                                                  </HotelFareRefundContent>
                                            )}
                                      </div>
                                </HotelFareRoomContainer>
                          ))
                        : null}
            </div>
      );
};

export default RoomPayList;
