import styled from "styled-components";

export const HotelFareContainer = styled.div`
      border: 1px solid #dfe0e4;
      border-radius: 5px;
      padding: 20px 20px;
      position: sticky;
      top: 120px;
      background-color: ${(props) => props.theme.white};
`;

export const HotelFareHeader = styled.div`
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
`;

export const HotelFareName = styled.div`
      font-size: 20px;
      font-weight: 550;
      display: flex;
      align-items: baseline;
      gap: 15px;

      svg {
            font-size: ${(props) => props.iconFont || "18px"};
      }
`;

export const HotelFareInfoSummary = styled.div`
      font-size: 14px;
      color: ${(props) => props.color || "rgba(33, 37, 41, 0.75)"};
      display: flex;
      align-items: ${(props) => props.alignItems || "center"};
      gap: ${(props) => props.gap};
      margin-bottom: ${(props) => props.marginBottom};

      .material-symbols-outlined {
            font-size: ${(props) => props.iconFont || "18px"};
      }

      svg {
            fill: ${(props) => props.color || "rgba(33, 37, 41, 0.75)"};
            font-size: ${(props) => props.iconFont || "18px"};
      }

      .active {
            fill: #5fa735;
            color: #5fa735;
      }
`;

export const HotelFareCheckContent = styled.div`
      margin-top: ${(props) => props.marginTop || "2rem"};
`;

export const HotelFareCheckInfo = styled.div`
      margin-bottom: ${(props) => props.marginBottom || "0.5rem"};
`;

export const HotelFareCheck = styled.div`
      display: flex;
      font-size: 16px;
      font-weight: 550;
      gap: 5px;

      span {
            color: rgba(33, 37, 41, 0.75);
            font-weight: 500;
      }
`;

export const HotelFareRoomContainer = styled.div`
      border: 1px solid #dfe0e4;
      border-radius: 10px;
      padding: 15px 20px;
      margin-bottom: 20px;
`;

export const HotelFareRoomInfo = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
`;

export const HotelFareRoomTitle = styled.div`
      font-weight: 550;
`;

export const HotelFareRoomButton = styled.div`
      cursor: pointer;
      font-size: 13px;
      text-decoration: underline;
      transition: all 0.3s ease-in-out;

      &:hover {
            text-decoration: none;
      }
`;

export const HotelFareRefundContent = styled.div`
      display: flex;
      align-items: center;
      gap: 5px;

      span {
            color: ${(props) => props.color || "rgba(33, 37, 41, 0.75)"};
      }
`;

export const HotelFareRefund = styled.div`
      background-color: #5fa735;
      width: 20px;
      height: 20px;
      font-size: 14px;
      border-radius: 50rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${(props) => props.theme.white};
`;

export const HotelFareRoomFree = styled.div`
      color: #5fa735;
`;

export const HotelFareRoomAmount = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      font-size: 18px;
      font-weight: 600;
`;

export const HotelFareContinue = styled.button`
      border: 0;
      color: ${(props) => props.theme.white};
      min-width: 220px;
      padding: 8px 20px;
      border-radius: 25rem;
      margin: 1rem auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.primary};
      text-transform: uppercase;
`;
