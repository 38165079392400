import React from "react";
import BannerBox from "../../components/Frontend/home/BannerSection";
import PackageBox from "../../components/Frontend/home/PackageBox";
import { hotelBannerDate } from "../../data/banners";
import HotelForm from "../../components/Frontend/hotel/HotelForm";
import HotelPartner from "./HotelPartner";

const HotelPage = () => {
      return (
            <>
                  <BannerBox hotel={true} banners={hotelBannerDate}>
                        <HotelForm
                              width={"100%"}
                              marginBottom={"30px"}
                              marginTop={"30px"}
                        />
                  </BannerBox>
                  <PackageBox />
                  <HotelPartner />
            </>
      );
};

export default HotelPage;
