import React, { useEffect, useState } from "react";
import { HotelModifyContainer, HotelTotalNight } from "./style";
import { Form, FormControl, FormLabel, Spinner } from "react-bootstrap";
import { FlightModalButton } from "../../flight/style";
import HotelModalTraveler from "./HotelModalTraveler";
import { isEqual } from "lodash";
import HotelModalDate from "./HotelModalDate";
import HotelModalDestination from "./HotelModalDestination";
import { useNavigate } from "react-router-dom";

const calculateTotalNights = (checkinDate, checkoutDate) => {
      const checkin = new Date(checkinDate);
      const checkout = new Date(checkoutDate);

      if (isNaN(checkin) || isNaN(checkout)) {
            return 0;
      }

      const timeDifference = checkout - checkin;

      const totalNights = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      return totalNights > 0 ? totalNights : 0;
};

const storeRoomInfoInLocalStorage = (room) => {
      const uniqueKey = `roomInfo_${Date.now()}`;

      localStorage.setItem(uniqueKey, JSON.stringify(room));

      return uniqueKey;
};

const HotelModify = ({ searchDetail }) => {
      const [modifySearch, setModifySearch] = useState(searchDetail);
      const [modifyStatus, setModifyStatus] = useState(false);
      const [loading, setLoading] = useState(false);
      const navigate = useNavigate();

      // useEffect(() => {
      //       setModifySearch(searchDetail);
      // }, []);

      const handleChange = (data, inputName) => {
            setModifySearch((prevDetails) => ({
                  ...prevDetails,
                  [inputName]: data,
            }));
      };

      useEffect(() => {
            if (isEqual(searchDetail, modifySearch)) {
                  setModifyStatus(false);
            } else {
                  setModifyStatus(true);
            }
      }, [modifySearch]);

      const totalNights = calculateTotalNights(
            modifySearch?.checkInDate,
            modifySearch?.checkOutDate
      );

      console.log(searchDetail);
      const handleSubmit = (e) => {
            e.preventDefault();

            setLoading(true);
            const roomInfo = storeRoomInfoInLocalStorage(
                  modifySearch?.roomInfo
            );

            const data = {
                  destination: modifySearch?.destination?.fullRegionName,
                  checkInDate: modifySearch?.checkInDate,
                  checkOutDate: modifySearch?.checkOutDate,
                  nationality: modifySearch?.nationality,
                  ratings: modifySearch?.ratings,
                  roomInfo,
            };

            const queryString = Object.entries(data)
                  .map(
                        ([key, value]) =>
                              `${encodeURIComponent(key)}=${encodeURIComponent(
                                    value
                              )}`
                  )
                  .join("&");

            setTimeout(() => {
                  setLoading(false);
                  localStorage.setItem(
                        "hotelDestination",
                        JSON.stringify(modifySearch?.destination)
                  );

                  navigate(`/hotel/search?${queryString}`);
                  window.location.reload();
            }, 3000);
      };

      return (
            <>
                  {searchDetail.checkInDate !== null && (
                        <HotelModifyContainer>
                              <Form onSubmit={handleSubmit}>
                                    <HotelModalDestination
                                          modifySearch={modifySearch}
                                          padding="7px 12px"
                                          handleChange={handleChange}
                                          filteredField={
                                                modifySearch?.destination
                                          }
                                    />
                                    <HotelModalDate
                                          handleChange={handleChange}
                                          searchDetail={modifySearch}
                                    />
                                    <HotelTotalNight>
                                          <FormLabel>Total Night(s)</FormLabel>
                                          <FormControl
                                                value={`${totalNights} Night${
                                                      totalNights > 1 ? "s" : ""
                                                }`}
                                          />
                                    </HotelTotalNight>
                                    <HotelModalTraveler
                                          modifySearch={modifySearch}
                                          label="Rooms & Guest"
                                          handleChange={handleChange}
                                          fontSize="16px"
                                          fontWeight="600"
                                          padding="6.446px 12px"
                                    />
                                    <FlightModalButton
                                          width="15%"
                                          fontWeight={600}
                                          type="submit"
                                          // marginTop="22px"
                                          height="46px"
                                          status={modifyStatus}
                                    >
                                          {loading ? (
                                                <Spinner />
                                          ) : (
                                                "Modify Search"
                                          )}
                                    </FlightModalButton>
                              </Form>
                        </HotelModifyContainer>
                  )}
            </>
      );
};

export default HotelModify;
