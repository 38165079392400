import styled from "styled-components";

export const HotelItemContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      border-radius: 5px;
      /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
      margin-bottom: 20px;
      border: 1px solid #dfe0e4;
`;

export const HotelItem = styled.div`
      display: flex;
      gap: 10px;
`;

export const HotelItemImage = styled.div`
      width: 255px;
      height: 205px;
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
      }
`;

export const HotelItemTitle = styled.div`
      font-size: 20px;
      padding-top: 5px;
      font-weight: 510;
      width: ${(props) => props.width};
`;

export const HotelItemContent = styled.div`
      display: flex;
      width: calc(100% - 255px);
      padding: 20px;
      padding-left: 10px;
      padding-right: 5px;
      gap: 10px;
`;

export const HotelItemInfo = styled.div`
      width: ${(props) => props.width};
`;

export const HotelItemSummary = styled.div`
      font-size: ${(props) => props.fontSize || "14px"};
      color: #999;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-top: 5px;

      span {
            font-size: 14px;
            line-height: 1;
      }
`;

export const HotelItemOffer = styled.div`
      display: flex;
      gap: 10px;
      margin-top: ${(props) => props.marginTop || "10px"};
      margin-bottom: ${(props) => props.marginBottom};
`;

export const HotelOfferItem = styled.div`
      display: flex;
      align-items: center;
      background-color: #eee;
      color: #888;
      font-size: 12px;
      padding: ${(props) => props.padding || "2.5px 10px"};
      border-radius: 2.5px;
`;

export const HotelSubItem = styled.div`
      padding: 10px 15px;
      text-align: center;
`;

export const HotelSubItemPrice = styled.div`
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 22px;
`;

export const HotelButton = styled.div`
      padding: 0.35rem 2rem;
      background-color: ${(props) => props.theme.primary};
      border-radius: 50rem;
      min-width: 145px;
      text-align: center;
      font-size: ${(props) => props.fontSize};

      .spinner-border {
            width: 1rem !important;
            height: 1rem !important;
            border-width: 1px !important;
      }

      color: ${(props) => props.theme.white};
      cursor: pointer;
`;

export const RatingBadgeContent = styled.div`
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      gap: 10px;
`;

export const RatingBadge = styled.div`
      display: inline-block;
      background-color: #1e4ab6; /* Blue background */
      color: white; /* White text */
      font-size: 15px; /* Adjust text size */
      font-weight: bold; /* Bold text */
      text-align: center;
      line-height: 32px; /* Center text vertically */
      width: 32px; /* Adjust width */
      height: 32px; /* Adjust height */
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
      position: relative;
      z-index: 9999;

      &::after {
            content: "";
            height: 18px;
            width: 18px;
            bottom: -8px;
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
            left: 0;
            background-color: #1e4ab6;
            z-index: -1;
            position: absolute;
      }
`;
