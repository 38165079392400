import styled from "styled-components";

export const HotelAmenityList = styled.div`
      ul {
            margin-bottom: 0;
            li {
                  list-style: none;
                  margin-bottom: 10px;
                  list-style-type: ${(props) => (props.listStyle ? "-" : "")};

                  span {
                        padding-left: 10px;
                  }

                  &:last-child {
                        margin-bottom: 0;
                  }
            }
      }
`;

export const HotelAboutList = styled.div`
      .about__item-list {
            margin-bottom: 20px;

            &:last-child {
                  margin-bottom: 0;
            }
      }
      h4 {
            font-size: 16px;
            font-weight: 550;
            text-transform: capitalize;
      }
      ul {
            margin: 0;
            padding-left: 20px;

            li {
                  position: relative; /* To position the icon or style pseudo-elements */
                  margin-bottom: 10px; /* Spacing between list items */
                  font-size: 15px; /* Adjust font size */
                  margin-bottom: 10px;
            }
      }
`;
