import styled from "styled-components";

export const HotelModifyContainer = styled.div`
      background-color: ${(props) => props.theme.primary};
      padding: 1rem;
      border: 1px dashed #224082;
      border-radius: 5px;

      form {
            display: flex;
            gap: 1rem;
            align-items: center;

            label {
                  color: ${(props) => props.theme.white};
            }
      }
`;


export const HotelTotalNight = styled.div`
      input {
            font-weight: 600;
            padding: 6.446px 12px;
      }
`