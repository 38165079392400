import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { handleHotelCommissionTotal } from "../../hooks/hotelCommission";
import { DashboardContainer } from "../layouts/style";
import HotelTicketLayout from "../../frontend/hotel_confirmations/HotelTicketLayout";

const HotelTicketView = () => {
      const [loading, setLoading] = useState(false);
      const { bookingId } = useParams();

      const [agentOrder, setAgentOrder] = useState([]);
      const [order, setOrder] = useState([]);
      const [tripInfos, setTripInfos] = useState([]);
      const [priceInfos, setPriceInfos] = useState([]);
      const [totalAmount, setTotalAmount] = useState([]);
      const [searchQuery, setSearchQuery] = useState([]);

      const token = JSON.parse(localStorage.getItem("token"));

      const handleTotalPrice = ({ prices, commissionsAmount }) => {
            const totalAmt = handleHotelCommissionTotal({
                  prices: prices,
                  commissionsAmount: commissionsAmount,
            });
            return totalAmt;
      };

      const loadData = async () => {
            setLoading(true);

            const response = await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/flight/bookings/${bookingId}`,
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .catch((error) => {
                        toast.error(error.message);
                  });

            if (response?.data?.result === "success") {
                  setAgentOrder(response.data.agent);
                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/booking-details`,
                              {
                                    bookingId:
                                          response?.data?.agent?.tj_booking_id,
                                    requirePaxPricing: true,
                              },
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status?.success === true) {
                                    setOrder(response.data.order);
                                    setTripInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                    );
                                    setPriceInfos(
                                          response.data.itemInfos?.HOTEL?.hInfo
                                                ?.ops[0]
                                    );
                                    setSearchQuery(
                                          response.data.itemInfos?.HOTEL?.query
                                    );
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            }
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      useEffect(() => {
            setTotalAmount(
                  handleTotalPrice({
                        prices: priceInfos?.ris,
                        commissionsAmount: agentOrder?.commission,
                  })
            );
      }, [priceInfos, agentOrder]);

      return (
            <>
                  <DashboardContainer>
                        <HotelTicketLayout
                              tripInfos={tripInfos}
                              priceInfos={priceInfos}
                              bookingId={bookingId}
                              order={order}
                              searchQuery={searchQuery}
                              totalAmount={totalAmount}
                              agentOrder={agentOrder}
                              markupPrice={0}
                              currentPriceStatus="with-price"
                        />
                  </DashboardContainer>
            </>
      );
};

export default HotelTicketView;
