import React, { useEffect, useState } from "react";
import { FormControl, ModalBody, ModalHeader } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { usePDF } from "react-to-pdf";
import HotelTicketLayout from "./HotelTicketLayout";
import CommonButton from "../../components/Common/CommonButton";
import {
      FlightModal,
      FlightPriceItem,
} from "../flight_confirmations/FlightConfirmModal/style";
import { convertAmount, convertFloat } from "../../hooks/CurrentData";

const HotelConfirmModal = ({
      tripInfos,
      priceInfos,
      bookingId,
      order,
      searchQuery,
      totalAmounts,
      agentOrder,
}) => {
      const { toPDF, targetRef } = usePDF({
            filename: `Hotel-Ticket` + ".pdf",
      });

      const [loading, setLoading] = useState(false);
      const [show, setShow] = useState(false);
      const [loadingPdf, setLoadingPdf] = useState(false);
      const [currentPriceStatus, setCurrentPriceStatus] =
            useState("with-price");
      const [markupPrice, setMarkupPrice] = useState(0);
      const [grandTotal, setGrandTotal] = useState(0);

      const handleShow = () => {
            setLoading(true);

            setTimeout(() => {
                  setShow(true);
                  setLoading(false);
            }, 3000);
      };

      const handleClose = () => {
            setShow(false);
      };

      const handleChange = (event) => {
            setCurrentPriceStatus(event.target.value);
      };

      const handleInput = (event) => {
            // Convert input value to a float for proper addition
            const inputValue = parseFloat(event.target.value) || 0;
            setMarkupPrice(inputValue);
      };

      useEffect(() => {
            setGrandTotal(totalAmounts + markupPrice);
      }, [markupPrice, totalAmounts]);

      useEffect(() => {
            setMarkupPrice(0);
      }, [currentPriceStatus]);

      const handlePrintPdf = async () => {
            setLoadingPdf(true);

            setTimeout(() => {
                  setLoadingPdf(false);
                  toPDF();
            }, 3000);
      };

      return (
            <>
                  <CommonButton
                        handleClick={handleShow}
                        loading={loading}
                        button={true}
                        type="button"
                        title="Download Voucher"
                  />
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Download Voucher</ModalHeader>
                        <ModalBody>
                              <RadioGroup
                                    row
                                    aria-labelledby="demo-radio-buttons-group-label"
                              >
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value="with-price"
                                                      checked={
                                                            currentPriceStatus ===
                                                            "with-price"
                                                      }
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="With Price"
                                    />
                                    <FormControlLabel
                                          control={
                                                <Radio
                                                      value="without-price"
                                                      checked={
                                                            currentPriceStatus ===
                                                            "without-price"
                                                      }
                                                      onChange={handleChange}
                                                />
                                          }
                                          label="Without Price"
                                    />
                              </RadioGroup>
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem className="mt-2 border-top pt-3">
                                          <div>Total Net Price</div>
                                          <div>
                                                ₹{" "}
                                                {convertAmount(
                                                      convertFloat(totalAmounts)
                                                )}
                                          </div>
                                    </FlightPriceItem>
                              )}
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem>
                                          <div>Enter your Markup</div>
                                          <div>
                                                <FormControl
                                                      type="text"
                                                      onChange={handleInput}
                                                />
                                          </div>
                                    </FlightPriceItem>
                              )}
                              {currentPriceStatus === "with-price" && (
                                    <FlightPriceItem className="border-top border-bottom py-2 ">
                                          <div className="grand__total">
                                                Total Price
                                          </div>
                                          <div className="grand__total">
                                                ₹ {convertAmount(grandTotal)}
                                          </div>
                                    </FlightPriceItem>
                              )}
                              <CommonButton
                                    button={true}
                                    type="button"
                                    classes={"mt-3"}
                                    handleClick={handlePrintPdf}
                                    loading={loadingPdf}
                                    justifyContent="center"
                                    title="Download"
                              />
                        </ModalBody>
                  </FlightModal>
                  <div className="offscreen">
                        <div ref={targetRef}>
                              <HotelTicketLayout
                                    tripInfos={tripInfos}
                                    priceInfos={priceInfos}
                                    bookingId={bookingId}
                                    order={order}
                                    searchQuery={searchQuery}
                                    totalAmount={totalAmounts}
                                    agentOrder={agentOrder}
                                    markupPrice={markupPrice}
                                    currentPriceStatus={currentPriceStatus}
                              />
                        </div>
                  </div>
            </>
      );
};

export default HotelConfirmModal;
