import { Link } from "react-router-dom";
import styled from "styled-components";

export const RoomContentInfo = styled.div`
      max-height: ${(props) => props.height};
      overflow-y: auto;
`;

export const RoomContainer = styled.div`
      border: 1px solid #dfe0e4;
      padding: 20px;
      border-radius: 5px;
      background-color: ${(props) => props.theme.white};
      margin-bottom: ${(props) => props.marginBottom};
      position: ${(props) => props.position};
      top: 150px;

      .css-19bb58m {
            padding: 0;
            margin: 0;
      }

      .css-1jqq78o-placeholder {
            font-size: 14px;
      }

      .css-1xc3v61-indicatorContainer {
            padding-top: 6px;
            padding-bottom: 6px;
      }

      .css-1wy0on6 {
            height: auto;
      }

      .css-13cymwt-control,
      .css-b62m3t-container {
            height: auto;
      }

      .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
            font-size: 14px;
      }
`;

export const RoomImage = styled.div`
      width: 160px;
      padding: 10px;
      height: 120px;
      border-radius: 5px;
      overflow: hidden;

      img {
            height: 100%;
            width: 100%;
            border-radius: 5px;
      }
`;

export const RoomInfo = styled.div`
      width: 25%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
`;

export const RoomDescription = styled.div`
      border-left: 1px solid #dfe0e4;
      padding: 0px 20px;
      padding-right: 0;
      width: 75%;
`;

export const RoomDescriptionItem = styled.div`
      border-bottom: 1px solid #dfe0e4;
      display: flex;
      /* justify-content: space-between; */
      padding: 15px 10px;
      gap: 10px;

      &:last-child {
            border-bottom: 0;
      }
`;

export const RoomDetailItem = styled.div`
      font-size: 15px;
      width: ${(props) => props.width || "45%"};
      position: relative;

      .room__detail--title {
            font-weight: 550;
      }

      .room__detail--subtitle {
            color: rgba(33, 37, 41, 0.75);
      }

      .room__detail--deadline {
            color: rgba(33, 37, 41, 0.75);
            font-size: 14px;
      }

      .room__detail--cancellation {
            color: #5fa735;
      }

      .clickable {
            text-decoration: underline;
            color: rgba(33, 37, 41, 0.75);
            cursor: pointer;

            &:hover {
                  text-decoration: none;
            }
      }
`;

export const RoomItem = styled.div`
      border-radius: 10px;
      margin-bottom: 30px;
      background-color: white;
      border: 1px solid #dfe0e4;
      padding: 10px;
      width: calc(100% - 25px);
      position: relative;
`;

export const RoomTitle = styled.h4`
      font-size: 16px;
      font-weight: 550;
      margin-top: 10px;
      text-align: center;
`;

export const RoomAccount = styled.div`
      padding: 20px;
      margin: auto;
      text-align: center;
`;

export const RoomPrice = styled.div`
      font-weight: 550;
      font-size: 20px;
`;

export const RoomButton = styled(Link)`
      color: ${(props) => props.theme.white};
      padding: 10px 30px;
      display: block;
      border-radius: 50rem;
      margin-top: 10px;
      background-color: ${(props) => props.theme.primary};
`;

export const RoomOptionButton = styled.button`
      padding: 10px 30px;
      display: block;
      border-radius: 50rem;
      margin-top: 10px;
      width: 125px;
      border: 0;
      color: rgba(33, 37, 41, 0.75);
      background-color: #fff;
      border: 1px solid rgba(33, 37, 41, 0.75);
      transition: all 0.3s ease-in-out;

      &.active,
      &:hover {
            color: ${(props) => props.theme.white};
            background-color: ${(props) => props.theme.primary};
      }
`;

export const HotelRoomItemContainer = styled.div`
      margin-bottom: 2rem;

      .MuiFormGroup-root {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-direction: row;
      }
`;

export const HotelRoomItem = styled.div`
      border: 1px solid #dfe0e4;
      width: calc(25% - 10px);
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: ${(props) => props.theme.white};

      & > label {
            width: 100%;
            margin: 0;
            padding: 8px 16px;
            padding-left: 0;
      }
`;

export const HotelRoomItemTitle = styled.div`
      .item__title {
            font-size: 18px;
            font-weight: 501;
      }

      .item__subtitle {
            font-size: 14px;
            font-weight: 500;
      }
`;

export const HotelRoomItemIcon = styled.div`
      position: absolute;
      top: 5px;
      right: 10px;
      display: flex;
      align-items: center;
      gap: 5px;

      .item__icon-total {
            font-size: 14px;
            font-weight: 510;
      }
`;

// Filter
export const RoomFilterContainer = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #dfe0e4;
      padding-bottom: 5px;
      margin-bottom: 15px;
      font-size: 15px;

      .clickable {
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &:hover {
                  color: ${(props) => props.theme.primary};
            }
      }
`;

// Room Content
export const RoomContent = styled.div`
      display: flex;
      margin-bottom: 10px;

      .room__content-item {
            width: 25%;
            text-align: center;
            border-right: 1px solid #dfe0e4;
            font-weight: 500;

            &:last-child {
                  border-right: 0;
            }
      }
`;

export const MoreRoomOption = styled.div`
      background-color: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      position: absolute;
      bottom: -16px;
      padding: 0.25rem 2rem;
      border-radius: 25rem;
      left: 57%;
      cursor: pointer;

      .spinner-border {
            width: 1rem !important;
            height: 1rem !important;
      }
`;
