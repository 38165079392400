import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserContext from "../../context/UserContext";
import {
      handleHotelCommission,
      handleHotelCommissionOnly,
} from "../../hooks/hotelCommission";
import axios from "axios";
import toast from "react-hot-toast";
import { DashboardContainer } from "../dashboard/styles";
import { PaymentConfirmationContainer } from "../payment_confirmations/style";
import { Spinner } from "react-bootstrap";
import CountdownTimer from "../hotel_reviews/HotelCountdown";

const HotelPaymentConfirmation = () => {
      const { topupId } = useParams();
      const userCtx = useContext(UserContext);
      const navigate = useNavigate();

      const [topup, setTopup] = useState([]);

      const [loading, setLoading] = useState(false);
      const [showData, setShowData] = useState(false);
      const [title, setTitle] = useState("Payment Processing...");

      const [statusShow, setStatusShow] = useState(false);
      const [conditions, setConditions] = useState([]);
      const [bookingId, setBookingId] = useState("");
      const [hotelInfo, setHotelInfo] = useState([]);
      const [priceInfo, setPriceInfo] = useState([]);

      const [modalStatus, setModalStatus] = useState(false);
      const [message, setMessage] = useState();
      const [messageType, setMessageType] = useState(); // Default Amount

      const travellers = JSON.parse(localStorage.getItem("hotelTravellers"));
      const contactDetails = JSON.parse(localStorage.getItem("contactDetails"));

      const token = JSON.parse(localStorage.getItem("token"));

      const handleTotalPrice = () => {
            const totalAmt = handleHotelCommission({
                  commissions: userCtx?.profile?.hotelCommission,
                  prices: priceInfo?.ris,
                  domesticStatus: true,
                  hotelRating: hotelInfo?.rt,
            });
            console.log(totalAmt)
            return totalAmt;
      };

      const updatePayment = async () => {
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/agent/phonePe/${topupId}`,
                        {},
                        {
                              headers: {
                                    Authorization: `Bearer ${token}`,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              userCtx.loadData();
                              setTimeout(() => {
                                    setTopup(response.data.topup);
                                    setTitle("Payment Processing Successfully");
                              }, 1500);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                        setTimeout(() => {
                              setTitle("Payment Failed");
                        }, 1500);
                  });
      };

      useEffect(() => {
            updatePayment();
      }, []);

      const loadData = async () => {
            setLoading(true);
            setTimeout(() => {
                  setTitle("Booking Processing ...");
            }, 1500);
            const splitArray = topup?.comment?.split(",");
            if (topup?.comment && topup?.status === "Success") {
                  let data;
                  if (splitArray?.length > 1) {
                        data = {
                              hotelId: splitArray[0],
                              optionId: splitArray[1],
                        };
                  }

                  await axios
                        .post(
                              `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-review`,
                              data,
                              {
                                    headers: {
                                          apikey: process.env
                                                .REACT_APP_Flight_Api_Key,
                                    },
                              }
                        )
                        .then((response) => {
                              if (response.data.status.success === true) {
                                    setShowData(true);
                                    setConditions(response.data.conditions);
                                    setStatusShow(true);
                                    setBookingId(response.data.bookingId);
                                    setHotelInfo(response.data.hInfo);
                                    setPriceInfo(response.data.hInfo?.ops[0]);
                                    localStorage.setItem(
                                          "hotelReviewTime",
                                          response.data.conditions.st
                                    );
                              }
                        })
                        .catch((error) => {
                              setStatusShow(true);
                              setTimeout(() => {
                                    setTitle("Booking Failed");
                              }, 1500);
                              if (
                                    error.response.data?.status?.success ===
                                    false
                              ) {
                                    setShowData(false);
                                    setModalStatus(true);
                              }
                              if (error?.response?.status === 400) {
                                    if (error?.response?.data?.errors) {
                                          toast.error(
                                                error?.response?.data?.errors[0]
                                                      ?.details
                                          );
                                    }
                              }
                              if (error?.response?.status === 404) {
                                    if (error?.response?.data?.errors) {
                                          setMessageType(
                                                error?.response?.data?.errors[0]
                                                      ?.errCode
                                          );
                                    }
                                    setMessage(
                                          error?.response?.data?.errors[0]
                                                ?.message
                                    );
                              }
                        });
            }
            if (
                  topup?.status === "Payment Failed" ||
                  topup?.status === "Payment Pending"
            ) {
                  localStorage.setItem("paymentStatus", "Payment Failed");
                  navigate(`/hotel/review/${splitArray[0]}/${splitArray[1]}`);
            }
            setLoading(false);
      };

      useEffect(() => {
            if (topup?.status === "Success") {
                  loadData();
            }
      }, [topup]);

      const handleBooking = async () => {
            let travellerDetails;
            if (showData === true) {
                  if (travellers) {
                        travellerDetails = travellers.map(
                              (room, roomIndex) => ({
                                    travellerInfo: room.travellerInfo.map(
                                          (traveller) => ({
                                                fN: traveller.firstName || "",
                                                lN: traveller.lastName || "",
                                                ti: traveller.title || "",
                                                pt: traveller.pt || "",
                                                pan: traveller.pan || "", // Assuming PAN corresponds to passportNumber
                                          })
                                    ),
                              })
                        );
                  }

                  if (topup?.status === "Success") {
                        if (
                              !(
                                    userCtx?.profile?.balance >=
                                    handleTotalPrice(priceInfo?.ris)
                              )
                        ) {
                              toast.error("Insufficient Balance");
                              setLoading(false);
                              return;
                        }

                        if (travellerDetails && showData) {
                              const data = {
                                    bookingId: bookingId,
                                    type: "HOTEL",
                                    roomTravellerInfo: travellerDetails,
                                    paymentInfos: [
                                          {
                                                amount: priceInfo?.tp,
                                          },
                                    ],
                                    deliveryInfo: {
                                          emails: [
                                                contactDetails?.emailAddress,
                                          ],
                                          contacts: [
                                                contactDetails?.phoneNumber,
                                          ],
                                          code: [contactDetails?.countryCode],
                                    },
                              };
                              const response = await axios
                                    .post(
                                          `${process.env.REACT_APP_Flight_Api_Website}/oms/v1/hotel/book`,
                                          data,
                                          {
                                                headers: {
                                                      apikey: process.env
                                                            .REACT_APP_Flight_Api_Key,
                                                },
                                          }
                                    )
                                    .catch((error) => {
                                          console.log(error.message);
                                          setTimeout(() => {
                                                setTitle("Booking Failed");
                                                setTimeout(() => {
                                                      setTitle(
                                                            "Booking Failed"
                                                      );
                                                }, 1500);
                                                if (
                                                      error.response.data
                                                            ?.status
                                                            ?.success === false
                                                ) {
                                                      setShowData(false);
                                                      setModalStatus(true);
                                                }
                                                if (
                                                      error?.response
                                                            ?.status === 400
                                                ) {
                                                      if (
                                                            error?.response
                                                                  ?.data?.errors
                                                      ) {
                                                            toast.error(
                                                                  error
                                                                        ?.response
                                                                        ?.data
                                                                        ?.errors[0]
                                                                        ?.details
                                                            );
                                                      }
                                                }
                                                if (
                                                      error?.response
                                                            ?.status === 404
                                                ) {
                                                      if (
                                                            error?.response
                                                                  ?.data?.errors
                                                      ) {
                                                            setMessageType(
                                                                  error
                                                                        ?.response
                                                                        ?.data
                                                                        ?.errors[0]
                                                                        ?.errCode
                                                            );
                                                      }
                                                      setMessage(
                                                            error?.response
                                                                  ?.data
                                                                  ?.errors[0]
                                                                  ?.message
                                                      );
                                                }
                                          }, 1500);
                                    });
                              if (response?.data) {
                                    const bookingData = {
                                          booking_id: bookingId,
                                          name: `${travellers[0]?.travellerInfo[0]?.title} ${travellers[0]?.travellerInfo[0]?.firstName} ${travellers[0]?.travellerInfo[0]?.lastName}`,
                                          email: contactDetails?.email,
                                          summary: JSON.stringify(
                                                travellerDetails
                                          ),
                                          amount: handleTotalPrice(
                                                priceInfo?.ris
                                          ),
                                          totalNetAmount: priceInfo?.tp,
                                          order_type: "HOTEL",
                                          status:
                                                response.data.status
                                                      ?.success === true
                                                      ? "Success"
                                                      : "Pending",
                                          commission: handleHotelCommissionOnly(
                                                {
                                                      commissions:
                                                            userCtx?.profile
                                                                  ?.hotelCommission,
                                                      domesticStatus: true,
                                                      hotelRating:
                                                            hotelInfo?.rt,
                                                }
                                          ),
                                          paymentStatus:
                                                topup?.amount === "0"
                                                      ? "Success"
                                                      : "Pending",
                                    };

                                    await axios
                                          .post(
                                                `${process.env.REACT_APP_SECRET_KEY}/api/agent/hotel/bookings`,
                                                bookingData,
                                                {
                                                      headers: {
                                                            Authorization: `Bearer ${token}`,
                                                      },
                                                }
                                          )
                                          .then((response) => {
                                                if (
                                                      response.data.result ===
                                                      "success"
                                                ) {
                                                      setTimeout(() => {
                                                            setTitle(
                                                                  "Booking Processing Successfully"
                                                            );
                                                      }, 1500);
                                                      setLoading(false);
                                                      userCtx.loadData();
                                                      navigate(
                                                            `/hotels/confirmation/${response?.data?.bookingId}`
                                                      );
                                                }
                                          })
                                          .catch((error) => {
                                                toast.error(error.message);
                                                setTimeout(() => {
                                                      setTitle(
                                                            "Booking Failed"
                                                      );
                                                }, 1500);
                                          });
                              }
                        }
                  }
            }
      };

      useEffect(() => {
            if (showData) {
                  handleBooking();
            }
      }, [showData]);

      return (
            <>
                  <DashboardContainer>
                        {!loading ? (
                              <>
                                    <PaymentConfirmationContainer
                                          style={{
                                                height: "320px",
                                          }}
                                    >
                                          <div
                                                lg={12}
                                                className="text-center my-auto py-5"
                                          >
                                                {title}
                                                <br />
                                                <Spinner />
                                          </div>
                                    </PaymentConfirmationContainer>
                                    {!loading && statusShow ? (
                                          <>
                                                <CountdownTimer
                                                      conditions={conditions}
                                                      messageType={messageType}
                                                      message={message}
                                                      modalStatus={modalStatus}
                                                      setModalStatus={
                                                            setModalStatus
                                                      }
                                                      type="hotel"
                                                />
                                          </>
                                    ) : null}
                              </>
                        ) : (
                              <>
                                    <PaymentConfirmationContainer
                                          style={{ height: "320px" }}
                                    >
                                          <div
                                                lg={12}
                                                className="text-center my-auto py-5"
                                          >
                                                {title}
                                                <br />
                                                <Spinner />
                                          </div>
                                    </PaymentConfirmationContainer>
                              </>
                        )}
                  </DashboardContainer>
            </>
      );
};

export default HotelPaymentConfirmation;
