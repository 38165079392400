import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
      FormInfo,
      FormWrapper,
} from "../../../components/Frontend/home/TravelerBox/styles";
import {
      AdultChildSection,
      AdultChildTitle,
      AdultSection,
      ChildSection,
      FormAdditional,
      FormAdditionalCounter,
      FormAdditionalCounterIcon,
      FormAdditionalCounterInput,
      FormAdditionalHeader,
      FormAdditionalTitle,
} from "../../../components/form/HotelTraveler/styles";

const HotelModalTraveler = ({
      handleChange,
      label,
      fontSize,
      fontWeight,
      padding,
      modifySearch,
}) => {
      const [show, setShow] = useState(false);
      const divRef = useRef();

      const handleClick = () => {
            setShow(true);
      };

      const handleOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                  setShow(false);
            }
      };

      useEffect(() => {
            document.addEventListener("click", handleOutside);

            return () => {
                  document.removeEventListener("click", handleOutside);
            };
      }, []);

      const incrementRoom = () => {
            // Check if the room count is less than 5
            if (modifySearch?.roomInfo.length < 5) {
                  const updatedRoomInfo = [...modifySearch?.roomInfo];
                  const lastRoom = updatedRoomInfo[updatedRoomInfo.length - 1];

                  // Check if the last room has no child
                  if (lastRoom?.numberOfChild === 0) {
                        lastRoom.numberOfChild = 1;
                        lastRoom.childAge = [5]; // Default child age, you can modify this as needed
                  }

                  // Add a new room with default values
                  updatedRoomInfo.push({
                        numberOfAdults: 2,
                        numberOfChild: 0,
                        childAge: [],
                  });

                  // Update the state with the modified room info
                  handleChange(updatedRoomInfo, "roomInfo");
            }
      };

      const decrementRoom = () => {
            if (modifySearch.roomInfo.length > 1) {
                  const updatedRooms = [...modifySearch.roomInfo];
                  updatedRooms.pop();
                  handleChange(updatedRooms, "roomInfo");
            }
      };

      const handleIncrement = (roomIndex, type) => {
            const updatedRooms = [...modifySearch.roomInfo];
            if (
                  type === "adult" &&
                  updatedRooms[roomIndex].numberOfAdults < 9
            ) {
                  updatedRooms[roomIndex].numberOfAdults += 1;
            } else if (
                  type === "children" &&
                  updatedRooms[roomIndex].numberOfChild < 3
            ) {
                  updatedRooms[roomIndex].numberOfChild += 1;
                  updatedRooms[roomIndex].childAge.push("");
            }
            handleChange(updatedRooms, "roomInfo");
      };

      const handleDecrement = (roomIndex, type) => {
            const updatedRooms = [...modifySearch.roomInfo];
            if (
                  type === "adult" &&
                  updatedRooms[roomIndex].numberOfAdults > 1
            ) {
                  updatedRooms[roomIndex].numberOfAdults -= 1;
            } else if (
                  type === "children" &&
                  updatedRooms[roomIndex].numberOfChild > 0
            ) {
                  updatedRooms[roomIndex].numberOfChild -= 1;
                  updatedRooms[roomIndex].childAge.pop();
            }
            handleChange(updatedRooms, "roomInfo");
      };

      const handleChildAgeChange = (roomIndex, childIndex, value) => {
            const updatedRooms = [...modifySearch.roomInfo];
            updatedRooms[roomIndex].childAge[childIndex] = value;
            handleChange(updatedRooms, "roomInfo");
      };

      return (
            <div>
                  {label && <Form.Label>{label}</Form.Label>}
                  <FormWrapper width="100%" onClick={handleClick} ref={divRef}>
                        <FormInfo
                              border="var(--bs-border-width) solid var(--bs-border-color)"
                              backgroundColor="#FFF"
                              padding={padding || "8.5px 16px"}
                              borderRadius="0.375rem"
                        >
                              <span
                                    style={{
                                          fontSize: fontSize || "13px",
                                          fontWeight: fontWeight,
                                    }}
                              >
                                    {modifySearch?.roomInfo.length} rooms,{" "}
                                    {modifySearch?.roomInfo.reduce(
                                          (sum, room) =>
                                                sum + room.numberOfAdults,
                                          0
                                    )}{" "}
                                    adults,{" "}
                                    {modifySearch?.roomInfo.reduce(
                                          (sum, room) =>
                                                sum + room.numberOfChild,
                                          0
                                    )}{" "}
                                    children
                              </span>
                        </FormInfo>
                        <FormAdditional className={show ? "show" : ""}>
                              <FormAdditionalHeader className="px-3 py-2 d-flex align-items-center justify-content-between">
                                    <FormAdditionalTitle>
                                          Rooms
                                    </FormAdditionalTitle>
                                    <FormAdditionalCounter>
                                          <FormAdditionalCounterIcon
                                                onClick={decrementRoom}
                                                className="align-items-center justify-content-center substract"
                                          >
                                                <span>-</span>
                                          </FormAdditionalCounterIcon>
                                          <FormAdditionalCounterInput>
                                                <input
                                                      type="number"
                                                      value={
                                                            modifySearch
                                                                  ? modifySearch
                                                                          ?.roomInfo
                                                                          .length
                                                                  : 0
                                                      }
                                                      readOnly
                                                />
                                          </FormAdditionalCounterInput>
                                          <FormAdditionalCounterIcon
                                                onClick={incrementRoom}
                                                className="align-items-center justify-content-center add"
                                          >
                                                <span>+</span>
                                          </FormAdditionalCounterIcon>
                                    </FormAdditionalCounter>
                              </FormAdditionalHeader>

                              {modifySearch &&
                                    modifySearch.roomInfo.map(
                                          (room, roomIndex) => (
                                                <div
                                                      key={roomIndex}
                                                      className="px-3 py-2"
                                                >
                                                      <AdultChildSection className="d-flex align-items-center justify-content-between">
                                                            <AdultSection>
                                                                  <AdultChildTitle>
                                                                        Adults
                                                                        (12+)
                                                                  </AdultChildTitle>
                                                                  <FormAdditionalCounter>
                                                                        <FormAdditionalCounterIcon
                                                                              onClick={() =>
                                                                                    handleDecrement(
                                                                                          roomIndex,
                                                                                          "adult"
                                                                                    )
                                                                              }
                                                                              className="align-items-center justify-content-center substract"
                                                                        >
                                                                              <span>
                                                                                    -
                                                                              </span>
                                                                        </FormAdditionalCounterIcon>
                                                                        <FormAdditionalCounterInput>
                                                                              <input
                                                                                    type="number"
                                                                                    value={
                                                                                          room.numberOfAdults
                                                                                    }
                                                                                    readOnly
                                                                              />
                                                                        </FormAdditionalCounterInput>
                                                                        <FormAdditionalCounterIcon
                                                                              onClick={() =>
                                                                                    handleIncrement(
                                                                                          roomIndex,
                                                                                          "adult"
                                                                                    )
                                                                              }
                                                                              className="align-items-center justify-content-center add"
                                                                        >
                                                                              <span>
                                                                                    +
                                                                              </span>
                                                                        </FormAdditionalCounterIcon>
                                                                  </FormAdditionalCounter>
                                                            </AdultSection>
                                                            <ChildSection>
                                                                  <AdultChildTitle>
                                                                        Children
                                                                  </AdultChildTitle>
                                                                  <FormAdditionalCounter>
                                                                        <FormAdditionalCounterIcon
                                                                              onClick={() =>
                                                                                    handleDecrement(
                                                                                          roomIndex,
                                                                                          "children"
                                                                                    )
                                                                              }
                                                                              className="align-items-center justify-content-center substract"
                                                                        >
                                                                              <span>
                                                                                    -
                                                                              </span>
                                                                        </FormAdditionalCounterIcon>
                                                                        <FormAdditionalCounterInput>
                                                                              <input
                                                                                    type="number"
                                                                                    value={
                                                                                          room.numberOfChild
                                                                                    }
                                                                                    readOnly
                                                                              />
                                                                        </FormAdditionalCounterInput>
                                                                        <FormAdditionalCounterIcon
                                                                              onClick={() =>
                                                                                    handleIncrement(
                                                                                          roomIndex,
                                                                                          "children"
                                                                                    )
                                                                              }
                                                                              className="align-items-center justify-content-center add"
                                                                        >
                                                                              <span>
                                                                                    +
                                                                              </span>
                                                                        </FormAdditionalCounterIcon>
                                                                  </FormAdditionalCounter>
                                                            </ChildSection>
                                                      </AdultChildSection>

                                                      {room.numberOfChild >
                                                            0 && (
                                                            <Row className="mt-3">
                                                                  {room.childAge.map(
                                                                        (
                                                                              age,
                                                                              childIndex
                                                                        ) => (
                                                                              <Col
                                                                                    lg={
                                                                                          4
                                                                                    }
                                                                                    key={
                                                                                          childIndex
                                                                                    }
                                                                              >
                                                                                    <Form.Select
                                                                                          value={
                                                                                                age
                                                                                          }
                                                                                          className="p-1"
                                                                                          style={{
                                                                                                fontSize: "12px",
                                                                                          }}
                                                                                          onChange={(
                                                                                                e
                                                                                          ) =>
                                                                                                handleChildAgeChange(
                                                                                                      roomIndex,
                                                                                                      childIndex,
                                                                                                      e
                                                                                                            .target
                                                                                                            .value
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <option value="">
                                                                                                Child
                                                                                                Age
                                                                                          </option>
                                                                                          {[
                                                                                                ...Array(
                                                                                                      12
                                                                                                ),
                                                                                          ].map(
                                                                                                (
                                                                                                      _,
                                                                                                      i
                                                                                                ) => (
                                                                                                      <option
                                                                                                            key={
                                                                                                                  i
                                                                                                            }
                                                                                                            value={`${i}`}
                                                                                                      >
                                                                                                            {i <
                                                                                                            2
                                                                                                                  ? "<"
                                                                                                                  : ""}
                                                                                                            {
                                                                                                                  i
                                                                                                            }{" "}
                                                                                                            yrs
                                                                                                      </option>
                                                                                                )
                                                                                          )}
                                                                                    </Form.Select>
                                                                              </Col>
                                                                        )
                                                                  )}
                                                            </Row>
                                                      )}
                                                </div>
                                          )
                                    )}
                        </FormAdditional>
                  </FormWrapper>
            </div>
      );
};

export default HotelModalTraveler;
