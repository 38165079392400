import React from "react";
import { HotelHeading } from "../../hotels/styles";
import { RoomContainer } from "../../hotels/HotelRoom/style";
import { HotelAboutList } from "../HotelAmenities/style";

const HotelPolicies = ({ instructions }) => {
      return (
            <>
                  {instructions && (
                        <>
                              <HotelHeading className="mt-4 pt-2">
                                    Hotel Instructions
                              </HotelHeading>
                              <RoomContainer>
                                    <HotelAboutList listStyle={true}>
                                          <div className="instructions-container">
                                                {instructions?.map(
                                                      (item, index) => {
                                                            const message =
                                                                  JSON.parse(
                                                                        item.msg
                                                                  ); // Parse the JSON message
                                                            return (
                                                                  <div
                                                                        key={
                                                                              index
                                                                        }
                                                                        className="about__item-list"
                                                                  >
                                                                        <h4>
                                                                              {item.type.replace(
                                                                                    "_",
                                                                                    " "
                                                                              )}
                                                                        </h4>
                                                                        <ul>
                                                                              {Object.entries(
                                                                                    message
                                                                              ).map(
                                                                                    (
                                                                                          [
                                                                                                key,
                                                                                                value,
                                                                                          ],
                                                                                          idx
                                                                                    ) => (
                                                                                          <li
                                                                                                key={
                                                                                                      idx
                                                                                                }
                                                                                          >
                                                                                                <strong>
                                                                                                      {key.replace(
                                                                                                            /_/g,
                                                                                                            " "
                                                                                                      )}

                                                                                                      :
                                                                                                </strong>{" "}
                                                                                                {
                                                                                                      value
                                                                                                }
                                                                                          </li>
                                                                                    )
                                                                              )}
                                                                        </ul>
                                                                  </div>
                                                            );
                                                      }
                                                )}
                                          </div>
                                    </HotelAboutList>
                              </RoomContainer>
                        </>
                  )}
            </>
      );
};

export default HotelPolicies;
