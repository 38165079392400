import React, { useContext, useEffect, useState } from "react";
import HotelCard from "../../../components/Frontend/hotel/HotelCard";
import { Spinner } from "react-bootstrap";
import UserContext from "../../../context/UserContext";

const HotelSearchList = ({ hotels, hotelSearchDetails }) => {
      const [limit, setLimit] = useState(20);
      const userCtx = useContext(UserContext);

      const handleScroll = () => {
            if (
                  window.innerHeight + document.documentElement.scrollTop >=
                  document.documentElement.offsetHeight - 50
            ) {
                  setLimit((prevLimit) => prevLimit + 20); // Load 20 more flights
            }
      };

      useEffect(() => {
            window.addEventListener("scroll", handleScroll);
            return () => {
                  // Cleanup the event listener on component unmount
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);

      return (
            <>
                  {hotels?.slice(0, limit)?.map((hotel, index) => (
                        <HotelCard
                              hotel={hotel}
                              key={index}
                              userCtx={userCtx}
                              hotelSearchDetails={hotelSearchDetails}
                        />
                  ))}
                  {limit < hotels.length && (
                        <div style={{ textAlign: "center", padding: "10px" }}>
                              <Spinner />
                        </div>
                  )}
            </>
      );
};

export default HotelSearchList;
