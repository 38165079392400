import React, { useState } from "react";
import {
      FlightDetailButton,
      FlightPriceContainer,
      FlightPriceContent,
      FlightPriceGrid,
      FlightPriceItem,
} from "./style";
import { FormControl } from "react-bootstrap";
import {
      calculateTotalAmount,
      calculateTotalNetAmount,
      convertAmount,
      convertFloat,
} from "../../../hooks/CurrentData";
import {
      PriceItemContent,
      PriceItemInfo,
      RefundIconContainer,
      SummaryIconContainer,
} from "../../Frontend/search/FlightTripPrice/style";
import { BaggageIcon, LaunchIcon } from "../../../icons";

const FlightPrice = ({
      setStatus,
      status,
      totalPriceList,
      setFareId,
      flightInfo,
      returnStatus,
      searchDetail,
      fareId,
      fullFare,
      netFare,
      commission,
      domesticStatus,
      airlineCode,
}) => {
      const [limit, setLimit] = useState(false);
      const handlePrice = (item) => {
            setFareId(item);
      };

      const checkCabinClassPrice = () => {
            let filteredData = totalPriceList?.filter(
                  (item) => item?.fd?.ADULT?.cc === searchDetail?.cabinClass
            );
            return filteredData.sort(
                  (a, b) => a.fd.ADULT.fC.TF - b.fd.ADULT.fC.TF
            );
      };

      return (
            <>
                  <FlightPriceContainer returnStatus={returnStatus}>
                        <FlightPriceContent width="calc(75%)" alignItems="end">
                              <FlightPriceGrid>
                                    {checkCabinClassPrice()
                                          ?.slice(
                                                0,
                                                limit
                                                      ? checkCabinClassPrice()
                                                              ?.length
                                                      : 3,
                                                0
                                          )
                                          ?.map((item, index) => (
                                                <FlightPriceItem
                                                      className={
                                                            item?.id ===
                                                            fareId?.id
                                                                  ? "active"
                                                                  : ""
                                                      }
                                                      width="30%"
                                                      onClick={() =>
                                                            handlePrice(item)
                                                      }
                                                      returnStatus={
                                                            returnStatus
                                                      }
                                                      key={index}
                                                >
                                                      <FormControl
                                                            type="radio"
                                                            value={item?.id}
                                                            name="option"
                                                            checked={
                                                                  item?.id ===
                                                                  fareId?.id
                                                                        ? true
                                                                        : false
                                                            }
                                                      />
                                                      <PriceItemContent>
                                                            <div>
                                                                  <div className="price">
                                                                        <span>
                                                                              {`${
                                                                                    item?.fareIdentifier ===
                                                                                    "OFFER_FARE_WITH_PNR"
                                                                                          ? "Series Fare"
                                                                                          : item?.fareIdentifier
                                                                              }`}
                                                                        </span>
                                                                  </div>
                                                                  <div className="summary">
                                                                        <SummaryIconContainer
                                                                              refundStatus={
                                                                                    item
                                                                                          ?.fd
                                                                                          ?.ADULT
                                                                                          ?.mI
                                                                                          ? 1
                                                                                          : 0
                                                                              }
                                                                        >
                                                                              <LaunchIcon
                                                                                    height="12px"
                                                                                    width="12px"
                                                                                    color={
                                                                                          item?.id ===
                                                                                          fareId?.id
                                                                                                ? "white"
                                                                                                : ""
                                                                                    }
                                                                              />
                                                                        </SummaryIconContainer>

                                                                        <SummaryIconContainer
                                                                              refundStatus={
                                                                                    item
                                                                                          ?.fd
                                                                                          ?.ADULT
                                                                                          ?.bI
                                                                                          ?.cB
                                                                                          ? 1
                                                                                          : 0
                                                                              }
                                                                        >
                                                                              <BaggageIcon
                                                                                    height="12px"
                                                                                    width="12px"
                                                                                    color={
                                                                                          item?.id ===
                                                                                          fareId?.id
                                                                                                ? "white"
                                                                                                : ""
                                                                                    }
                                                                              />
                                                                        </SummaryIconContainer>
                                                                        <RefundIconContainer
                                                                              refundStatus={
                                                                                    item
                                                                                          ?.fd
                                                                                          ?.ADULT
                                                                                          ?.rT
                                                                              }
                                                                        >
                                                                              R
                                                                        </RefundIconContainer>
                                                                  </div>
                                                            </div>
                                                            <PriceItemInfo
                                                                  color={
                                                                        item?.id ===
                                                                        fareId?.id
                                                                  }
                                                            >
                                                                  <div>
                                                                        ₹{" "}
                                                                        {convertAmount(
                                                                              convertFloat(
                                                                                    calculateTotalAmount(
                                                                                          {
                                                                                                item,
                                                                                                searchDetail,
                                                                                                commission,
                                                                                                fullFare,
                                                                                                domesticStatus,
                                                                                                airlineCode
                                                                                          }
                                                                                    )
                                                                              )
                                                                        )}
                                                                  </div>
                                                                  {netFare && (
                                                                        <div className="price__net ">
                                                                              NET
                                                                              ₹{" "}
                                                                              {convertAmount(
                                                                                    convertFloat(
                                                                                          calculateTotalNetAmount(
                                                                                                {
                                                                                                      item,
                                                                                                      searchDetail,
                                                                                                      commission,
                                                                                                      fullFare,
                                                                                                      domesticStatus,
                                                                                                      airlineCode,
                                                                                                }
                                                                                          )
                                                                                    )
                                                                              )}
                                                                        </div>
                                                                  )}
                                                            </PriceItemInfo>
                                                      </PriceItemContent>
                                                </FlightPriceItem>
                                          ))}
                              </FlightPriceGrid>
                        </FlightPriceContent>
                        <div className="d-flex" style={{ gap: "10px" }}>
                              {checkCabinClassPrice()?.length > 3 && (
                                    <>
                                          <FlightPriceContent
                                                onClick={() => setLimit(!limit)}
                                          >
                                                <button
                                                      className={
                                                            limit
                                                                  ? "active"
                                                                  : ""
                                                      }
                                                      type="button"
                                                >
                                                      More Fare
                                                </button>
                                          </FlightPriceContent>
                                    </>
                              )}
                              {flightInfo !== true && (
                                    <FlightPriceContent>
                                          <FlightDetailButton
                                                onClick={() =>
                                                      setStatus(!status)
                                                }
                                                className={
                                                      status ? "active" : ""
                                                }
                                          >
                                                Flight Details
                                          </FlightDetailButton>
                                    </FlightPriceContent>
                              )}
                        </div>
                  </FlightPriceContainer>
            </>
      );
};

export default FlightPrice;
