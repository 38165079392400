import React, { useEffect, useState } from "react";
import { FlightModal } from "../../../../frontend/flight_confirmations/FlightConfirmModal/style";
import { ModalBody, ModalHeader, Spinner } from "react-bootstrap";
import axios from "axios";
import { convertDate } from "../../../../frontend/hotel_confirmations/HotelTicketLayout";
import { convertAmount, convertFloat } from "../../../../hooks/CurrentData";

const PolicyModalView = ({ hotelId, optionId, show, handleClose }) => {
      const [policy, setPolicy] = useState([]);
      const [loading, setLoading] = useState(false);

      const handleData = async () => {
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_Flight_Api_Website}/hms/v1/hotel-cancellation-policy`,
                        {
                              id: hotelId,
                              optionId: optionId,
                        },
                        {
                              headers: {
                                    apikey: process.env
                                          .REACT_APP_Flight_Api_Key,
                              },
                        }
                  )
                  .then((response) => {
                        setPolicy(response.data.cancellationPolicy);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            handleData();
      }, [optionId]);

      return (
            <>
                  <FlightModal
                        backdrop="static"
                        show={show}
                        top="18px"
                        size="lg"
                        onHide={handleClose}
                  >
                        <ModalHeader closeButton>Room Policy</ModalHeader>
                        <ModalBody style={{ minHeight: '120px' }}>
                              {!loading ? (
                                    <ul>
                                          {policy?.pd?.map((item, index) => (
                                                <li key={index}>
                                                      Cancellation Charges of ₹{" "}
                                                      {convertAmount(
                                                            convertFloat(
                                                                  item?.am
                                                            )
                                                      )}{" "}
                                                      applicable if cancelled
                                                      between{" "}
                                                      {convertDate(item?.fdt)}{" "}
                                                      to{" "}
                                                      {convertDate(item?.tdt)}.
                                                </li>
                                          ))}
                                          <div className="text-muted mt-3">
                                                No Show will attract full
                                                cancellation charge unless
                                                otherwise specified.
                                          </div>
                                          <div className="text-muted">
                                                Early check out will attract
                                                full cancellation charge unless
                                                otherwise specified.
                                          </div>
                                    </ul>
                              ) : (
                                    <div className="text-center py-4">
                                          <Spinner />
                                    </div>
                              )}
                        </ModalBody>
                  </FlightModal>
            </>
      );
};

export default PolicyModalView;
