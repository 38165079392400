import styled from "styled-components";

export const StarRatingContainer = styled.div`
      border: 1px solid hsl(0, 0%, 80%);
      max-height: 38px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      position: relative;
`;

export const StarRatingContent = styled.div`
      position: absolute;
      top: calc(100% + 10px);
      border-radius: 5px;
      z-index: 99999999999;
      left: 0;
      overflow-x: hidden;
      background-color: white;
      width: ${(props) => props.width || "100%"};
      box-shadow: rgb(0 0 0 / 25%) 0px 0px 0px 1px;

      .css-j204z7-MuiFormControlLabel-root {
            margin: 0;

            &:hover {
                  background-color: #2684ff21;
            }
      }
`;

export const StarRatingTitle = styled.div`
      padding: 2px 10px;
      width: calc(100% - 35px);
      grid-area: 1 / 1 / 2 / 3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
`;

export const StarRatingIcon = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;

      .star__iconContainer {
            padding: 8px;

            svg {
                  color: hsl(0, 0%, 80%);
            }
      }

      .separator__indicator {
            -webkit-align-self: stretch;
            -ms-flex-item-align: stretch;
            align-self: stretch;
            width: 1px;
            background-color: hsl(0, 0%, 80%);
            margin-bottom: 8px;
            margin-top: 10px;
            box-sizing: border-box;
            height: 20px;
      }
`;
