import React from "react";
import {
      HotelRoomItem,
      HotelRoomItemContainer,
      HotelRoomItemIcon,
      HotelRoomItemTitle,
} from "./style";
import {
      FormControl,
      FormControlLabel,
      Radio,
      RadioGroup,
} from "@mui/material";
import { FaRegCircleUser } from "react-icons/fa6";

const HotelRoomList = ({ rooms, currentRoom, setCurrentRoom }) => {
      const totalGuests = (items) => {
            return items?.numberOfAdults + items?.numberOfChild;
      };

      const handleClick = (index) => {
            setCurrentRoom(index + 1);
      };

      const selectOneRoom = (options, index) => {
            if (index >= 0) {
                  if (index === 0 && options?.ris) {
                        return options?.ris[index]?.srn;
                  } else {
                        return options?.srn;
                  }
            } else {
                  return "-";
            }
      };

      return (
            <>
                  <div>
                        <FormControl className="w-100">
                              <HotelRoomItemContainer className="w-100">
                                    <RadioGroup
                                          aria-labelledby="demo-radio-buttons-group-label"
                                          defaultValue="female"
                                          name="radio-buttons-group"
                                          className="w-100"
                                    >
                                          {rooms?.map((item, index) => (
                                                <HotelRoomItem key={index}>
                                                      <FormControlLabel
                                                            checked={
                                                                  currentRoom ===
                                                                  index + 1
                                                            }
                                                            key={index}
                                                            control={<Radio />}
                                                            onClick={() =>
                                                                  handleClick(
                                                                        index
                                                                  )
                                                            }
                                                            label={
                                                                  <HotelRoomItemTitle>
                                                                        <div className="item__title">
                                                                              Room{" "}
                                                                              {index +
                                                                                    1}
                                                                        </div>
                                                                        <div className="item__subtitle">
                                                                              {selectOneRoom(
                                                                                    item?.selectedOption,
                                                                                    index
                                                                              )}
                                                                        </div>
                                                                        <HotelRoomItemIcon>
                                                                              <FaRegCircleUser />
                                                                              <span className="item__icon-total">
                                                                                    {totalGuests(
                                                                                          item?.room
                                                                                    )}
                                                                              </span>
                                                                        </HotelRoomItemIcon>
                                                                  </HotelRoomItemTitle>
                                                            }
                                                      />
                                                </HotelRoomItem>
                                          ))}
                                    </RadioGroup>
                              </HotelRoomItemContainer>
                        </FormControl>
                  </div>
            </>
      );
};

export default HotelRoomList;
