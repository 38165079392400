import React, { useEffect, useState } from "react";
import { RoomContainer, RoomContent, RoomContentInfo } from "./style";
import RoomItemBox from "../RoomItemBox";

import RoomInfoSkeleton from "../RoomItemBox/RoomInfoSkeleton";
import HotelRoomFilter from "./HotelRoomFilter";
import { handleHotelCommission } from "../../../hooks/hotelCommission";
import HotelRoomList from "./HotelRoomList";
import OtherRoomOptions from "./OtherRoomOptions";

const HotelRoom = ({
      items,
      hotel,
      currentRoom,
      setCurrentRoom,
      loading,
      userCtx,
      selectedRoom,
      setSelectedRoom,
}) => {
      const [beforeFiltered, setBeforeFiltered] = useState([]);
      const [filteredRooms, setFilteredRooms] = useState([]);
      const [mealBasis, setMealBasis] = useState([]);
      const [roomTypes, setRoomTypes] = useState([]);

      const generateMealBasisList = (rooms) => {
            const mealBasisMap = {};

            // Collect meal basis data
            if (rooms) {
                  rooms.forEach((op) => {
                        if (op.ris) {
                              op.ris.forEach((ri) => {
                                    if (ri.mb) {
                                          const normalizedMB = ri.mb
                                                ?.toLowerCase() // Convert to lowercase
                                                ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                                                ?.trim() // Remove leading and trailing spaces
                                                ?.split(" ") // Split into words
                                                ?.map(
                                                      (word) =>
                                                            word
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                            word.slice(1) // Capitalize the first letter of each word
                                                )
                                                ?.join(" "); // Normalize the meal basis
                                          mealBasisMap[normalizedMB] =
                                                (mealBasisMap[normalizedMB] ||
                                                      0) + 1;
                                    }
                              });
                        }
                  });
            }

            // Convert to an array, capitalize, and sort
            const mealBasisArray = Object.keys(mealBasisMap)
                  .map((normalizedMB) => ({
                        label: normalizedMB
                              ?.toLowerCase() // Convert to lowercase
                              ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                              ?.trim() // Remove leading and trailing spaces
                              ?.split(" ") // Split into words
                              ?.map(
                                    (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1) // Capitalize the first letter of each word
                              )
                              ?.join(" "), // Join back into a string
                        value: normalizedMB
                              ?.toLowerCase() // Convert to lowercase
                              ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                              ?.trim() // Remove leading and trailing spaces
                              ?.split(" ") // Split into words
                              ?.map(
                                    (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1) // Capitalize the first letter of each word
                              )
                              ?.join(" "), // Count of hotels offering this meal basis
                  }))
                  ?.sort((a, b) => a?.title?.localeCompare(b?.title)); // Sort alphabetically

            setMealBasis(mealBasisArray);
      };

      const generateRoomTypesList = (rooms) => {
            const mealBasisMap = {};

            // Collect meal basis data
            if (rooms) {
                  rooms.forEach((op) => {
                        if (op.ris) {
                              op.ris.forEach((ri) => {
                                    if (ri.rt) {
                                          const normalizedMB = ri.rt
                                                ?.toLowerCase() // Convert to lowercase
                                                ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                                                ?.trim() // Remove leading and trailing spaces
                                                ?.split(" ") // Split into words
                                                ?.map(
                                                      (word) =>
                                                            word
                                                                  .charAt(0)
                                                                  .toUpperCase() +
                                                            word.slice(1) // Capitalize the first letter of each word
                                                )
                                                ?.join(" "); // Normalize the meal basis
                                          mealBasisMap[normalizedMB] =
                                                (mealBasisMap[normalizedMB] ||
                                                      0) + 1;
                                    }
                              });
                        }
                  });
            }

            // Convert to an array, capitalize, and sort
            const mealBasisArray = Object.keys(mealBasisMap)
                  .map((normalizedMB) => ({
                        label: normalizedMB
                              ?.toLowerCase() // Convert to lowercase
                              ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                              ?.trim() // Remove leading and trailing spaces
                              ?.split(" ") // Split into words
                              ?.map(
                                    (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1) // Capitalize the first letter of each word
                              )
                              ?.join(" "), // Join back into a string
                        value: normalizedMB
                              ?.toLowerCase() // Convert to lowercase
                              ?.replace(/\s+/g, " ") // Normalize multiple spaces to a single space
                              ?.trim() // Remove leading and trailing spaces
                              ?.split(" ") // Split into words
                              ?.map(
                                    (word) =>
                                          word.charAt(0).toUpperCase() +
                                          word.slice(1) // Capitalize the first letter of each word
                              )
                              ?.join(" "), // Count of hotels offering this meal basis
                  }))
                  ?.sort((a, b) => a?.title?.localeCompare(b?.title)); // Sort alphabetically

            setRoomTypes(mealBasisArray);
      };

      // Function to filter and group rooms by srn
      const listRoomsBySRN = (rooms) => {
            const groupedRooms = {};

            rooms.forEach((room) => {
                  if (room.ris) {
                        room.ris.forEach((option) => {
                              if (option.rt) {
                                    const normalizedSRN = option.rt
                                          .toLowerCase()
                                          .replace(/\s+/g, ""); // Normalize SRN

                                    // Initialize group if it doesn't exist
                                    if (!groupedRooms[normalizedSRN]) {
                                          groupedRooms[normalizedSRN] = {
                                                srn: option.rt, // Original SRN
                                                title: "", // Placeholder for title
                                                rooms: [], // To store all matching rooms
                                          };
                                    }

                                    // Add the room to the group if not already present
                                    if (
                                          !groupedRooms[
                                                normalizedSRN
                                          ].rooms.includes(room)
                                    ) {
                                          groupedRooms[
                                                normalizedSRN
                                          ].rooms.push(room);
                                    }
                              }
                        });
                  }
            });

            // Create a title for each group based on `srn` and other details
            Object.keys(groupedRooms).forEach((key) => {
                  const group = groupedRooms[key];
                  console.log(group);
                  const roomType = group.srn.split(" ")[0]; // Extract the first word (e.g., "Deluxe")
                  const uniqueBedTypes = [
                        ...new Set(
                              group.rooms
                                    .flatMap((room) => room.ris)
                                    .filter((opt) => opt.rt === group.rt)
                                    .map((opt) => opt.mb)
                        ),
                  ]; // Unique bed types (e.g., "Room Only", "Breakfast Included")
                  group.title = `${roomType}, ${uniqueBedTypes.join(", ")}`; // Combine to create a title
            });

            // Convert the grouped object to an array for easier handling
            return Object.values(groupedRooms);
      };

      useEffect(() => {
            if (items && items.length > 0) {
                  setFilteredRooms(listRoomsBySRN(items));
                  setBeforeFiltered(listRoomsBySRN(items));
                  generateRoomTypesList(items);
                  generateMealBasisList(items);
            }
      }, [hotel]);

      const handleTotalPrice = (prices) => {
            const totalAmount = handleHotelCommission({
                  commissions: userCtx?.profile?.hotelCommission,
                  prices: prices,
                  domesticStatus: true,
                  hotelRating: hotel?.rt,
            });

            return totalAmount;
      };

      const handleSelectFirstRoom = (value) => {
            setSelectedRoom((prevSelectedRooms) => {
                  // Create a shallow copy of the state
                  const updatedRooms = prevSelectedRooms.map((room, index) => {
                        if (index === 0) {
                              // Update the selectedOption for the first room
                              return {
                                    ...room,
                                    selectedOption: value,
                              };
                        } else {
                              // Reset selectedOption for other rooms
                              return {
                                    ...room,
                                    selectedOption: [],
                              };
                        }
                  });

                  return updatedRooms;
            });
            if (selectedRoom?.length > 1) {
                  setCurrentRoom(2);
            }
      };

      return (
            <>
                  <HotelRoomList
                        rooms={selectedRoom}
                        currentRoom={currentRoom}
                        setCurrentRoom={setCurrentRoom}
                  />
                  <HotelRoomFilter
                        rooms={beforeFiltered}
                        filteredRooms={filteredRooms}
                        setFilteredRooms={setFilteredRooms}
                        mealBasis={mealBasis}
                        roomTypes={roomTypes}
                  />
                  <RoomContainer position="sticky">
                        <RoomContent>
                              <div className="room__content-item">
                                    Room Type
                              </div>
                              <div className="room__content-item">
                                    Description
                              </div>
                              <div className="room__content-item">
                                    Inclusions
                              </div>
                              <div className="room__content-item">Price</div>
                        </RoomContent>
                        <RoomContentInfo height="720px">
                              {!loading ? (
                                    currentRoom === 1 ? (
                                          filteredRooms?.map((item, index) => (
                                                <RoomItemBox
                                                      hotel={hotel}
                                                      item={item}
                                                      key={index}
                                                      handleTotalPrice={
                                                            handleTotalPrice
                                                      }
                                                      selectedRoom={
                                                            selectedRoom
                                                      }
                                                      handleSelectFirstRoom={
                                                            handleSelectFirstRoom
                                                      }
                                                />
                                          ))
                                    ) : (
                                          <>
                                                <OtherRoomOptions
                                                      hotel={hotel}
                                                      selectedRoom={
                                                            selectedRoom
                                                      }
                                                      setSelectedRoom={
                                                            setSelectedRoom
                                                      }
                                                      setCurrentRoom={
                                                            setCurrentRoom
                                                      }
                                                      currentRoom={currentRoom}
                                                      handleTotalPrice={
                                                            handleTotalPrice
                                                      }
                                                />
                                          </>
                                    )
                              ) : (
                                    <>
                                          <RoomInfoSkeleton />
                                          <RoomInfoSkeleton />
                                          <RoomInfoSkeleton />
                                    </>
                              )}
                        </RoomContentInfo>
                  </RoomContainer>
            </>
      );
};

export default HotelRoom;
